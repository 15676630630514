import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { Subscription } from 'rxjs';
import { SignInRequest } from 'src/app/interfaces/api/auth.interface';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { PushNotificationService } from 'src/app/services/push-notification/push-notification.service';
import { animationOptions } from 'src/app/utils/animation';

enum LoginType {
  USERNAME = 'username',
  PHONE = 'phone',
}

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  animations: animationOptions,
})
export class AuthComponent implements OnInit, AfterViewInit, OnDestroy {
  loginType: LoginType = LoginType.PHONE;

  form: FormGroup;

  authErrorObserver: Subscription = new Subscription();

  @ViewChild('phone') phone: NgxMatIntlTelInputComponent;

  protected readonly LoginType = LoginType;

  constructor(
    private router: Router,
    private authService: AuthService,
    private ps: PushNotificationService,
    private loader: LoaderService
  ) {}

  ngOnInit(): void {
    this.authService.resetProfileListener();
    this.ps.resetNotificationListener();
    this.initForm();
  }

  ngAfterViewInit(): void {
    this.phone.focused = true;
  }

  initForm() {
    this.form = new FormGroup({
      username: new FormControl(null),
      phone: new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.required),
      recaptcha: new FormControl(null, Validators.required),
    });
  }

  passwordRecovery() {
    this.router.navigate(['users', 'password_recovery']);
  }

  submit() {
    if (this.form.invalid) {
      return;
    }
    this.form.disable();
    const payload = this.form.value as SignInRequest;
    if (this.loginType === 'phone') {
      payload.phone = payload.phone.replace('+', '');
    }
    this.loader.showLoader();
    this.authService.signIn(payload).subscribe({
      next: () => {
        this.loader.hideLoader();
        this.router.navigate(['/']);
      },
      error: () => {
        this.form.enable();
        this.loader.hideLoader();
      },
    });
  }

  signUp() {
    this.router.navigate(['users', 'sign_up']);
  }

  toggleMethod(type: LoginType) {
    if (this.loginType === type) {
      return;
    }
    this.loginType = type;
    if (this.loginType === 'username') {
      this.form.get('username').setValidators(Validators.required);
      this.form.get('phone').clearValidators();
    } else {
      this.form.get('phone').setValidators(Validators.required);
      this.form.get('username').clearValidators();
    }
    this.form.get('phone').updateValueAndValidity({ emitEvent: false });
    this.form.get('username').updateValueAndValidity({ emitEvent: false });
    this.form.reset();
  }

  ngOnDestroy(): void {
    this.authErrorObserver && this.authErrorObserver.unsubscribe();
  }
}
