<div class="container-fluid bg-white p-4"
  [@open]="'opened'">
  <div class="row">
    <h1>{{ 'CLAIMS' | translate }}</h1>
    <!-- <div class="col-sm-6 col-12 text-align-right add-action">
      <button mat-button type="button" routerLink="/claims/appeal_manual_assignment_new">
        <mat-icon>playlist_add</mat-icon>
        {{ 'ADD_CLAIM' | translate }}
      </button>
    </div> -->
  </div>
  <form [formGroup]="filterForm"
    class="row">
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
      <mat-form-field appearance="outline">
        <mat-label>Поиск (номер договора)</mat-label>
        <input matInput
          formControlName="arbitraryValue">
      </mat-form-field>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'CLAIM:TYPE' | translate }}</mat-label>
        <mat-select formControlName="type">
          <mat-option [value]="null"></mat-option>
          <div class="hint ps-2">
            <p>{{ 'broker_service' | translate }}</p>
          </div>
          <mat-option *ngFor="let type of bsTypes"
            [value]="type">
            {{ type | translate }}
          </mat-option>
          <div class="hint ps-2">
            <p>{{ 'depositary_service' | translate }}</p>
          </div>
          <mat-option *ngFor="let type of depoTypes"
            [value]="type">
            {{ type | translate }}
          </mat-option>
          <div class="hint ps-2">
            <p>{{ 'other' | translate }}</p>
          </div>
          <mat-option *ngFor="let type of otherTypes"
            [value]="type">
            {{ type | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'CLAIM:PRODUCT_TYPE' | translate }}</mat-label>
        <mat-select formControlName="category">
          <mat-option *ngFor="let category of categories"
            [value]="category">
            {{ category | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'CLAIM:ClAIM_STATUS' | translate }}</mat-label>
        <mat-select formControlName="status">
          <mat-option *ngFor="let status of statuses"
            [value]="status">
            {{ status | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <mat-checkbox formControlName="hideInactive"
      color="primary"
      class="mb-2">{{ 'CLAIM:HIDE_UNACTIVE' | translate }}</mat-checkbox>
  </form>
  <div class="row">
    <ng-container *ngIf="filteredClaims.length;else notFound">
      <div class="col-12">
        <table mat-table
          [dataSource]="filteredClaims">

          <ng-container matColumnDef="DATE">
            <th mat-header-cell
              *matHeaderCellDef>{{ 'DATE' | translate }}</th>
            <td mat-cell
              *matCellDef="let claim">{{ claim.created_at + 'Z' | date: 'dd.MM.yyyy HH:mm' }}</td>
          </ng-container>

          <ng-container matColumnDef="ORDER_TYPE">
            <th mat-header-cell
              *matHeaderCellDef>{{ 'ORDER_TYPE' | translate }}</th>
            <td mat-cell
              *matCellDef="let claim">{{ claim.type | translate }}</td>
          </ng-container>

          <ng-container matColumnDef="FOND_OR_CONTRACT">
            <th mat-header-cell
              *matHeaderCellDef>{{ 'FOND_OR_CONTRACT' | translate }}</th>
            <td class="fond-or-contract"
              mat-cell
              *matCellDef="let claim"> {{ claim?.agreement?.number ?
                claim?.agreement?.number : ('NOT_FOUND' | translate) }} </td>
          </ng-container>

          <ng-container matColumnDef="ORDER_NUMBER">
            <th mat-header-cell
              *matHeaderCellDef>{{ 'ORDER_NUMBER' | translate }}</th>
            <td mat-cell
              *matCellDef="let claim">{{ claim?.number }}</td>
          </ng-container>

          <ng-container matColumnDef="STATUS">
            <th mat-header-cell
              *matHeaderCellDef>{{ 'STATUS' | translate }}</th>
            <td mat-cell
              *matCellDef="let claim">
              <div class="status">
                <mat-icon class="status"
                  [ngClass]="claim.status">{{ claim.status | claimStatusIcon }}</mat-icon>
                <span>{{claim.status | translate}}</span>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row
            *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row
            *matRowDef="let claim; columns: displayedColumns;"
            (click)="navigateTo(claim.id)"></tr>
        </table>


      </div>
    </ng-container>
    <ng-template #notFound>
      <div class="not-found">
        <mat-icon>search</mat-icon>
        <h4>{{ 'CLAIMS_NOT_FOUND' | translate }}</h4>
      </div>
    </ng-template>
  </div>
</div>