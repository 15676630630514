import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[englishLetters]',
})
export class EnglishLettersDirective {
  pattern: RegExp = new RegExp('[A-Za-z0-9 ]+$');

  element: HTMLInputElement;

  private _allowedKeys: string[] = [
    'Backspace',
    'Tab',
    'Enter',
    'Escape',
    'ArrowLeft',
    'ArrowRight',
    'Delete',
    'PrintScreen',
  ];

  constructor(element: ElementRef) {
    this.element = element.nativeElement;
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this._allowedKeys.includes(event.key)) {
      return true;
    }
    return this.pattern.test(event.key);
  }
}
