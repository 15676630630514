<div class="container">
  <div class="close"
    mat-dialog-close>
    <mat-icon>close</mat-icon>
  </div>
  <div class="title mb-2">
    <h3>{{ 'CHANGE_PASSWORD' | translate }}</h3>
  </div>
  <form (ngSubmit)="submit()"
    [formGroup]="passwordForm">
    <div class="validation-note">
      <p>{{'REGISTRATION:PASSWORD_TITLE' | translate }}</p>
      <ul>
        <li>{{'REGISTRATION:PASSWORD_CONTENT_1' | translate }}</li>
        <li>{{'REGISTRATION:PASSWORD_CONTENT_2' | translate }}</li>
        <li>{{'REGISTRATION:PASSWORD_CONTENT_3' | translate }}</li>
      </ul>
    </div>
    <mat-form-field appearance="outline"
      [matTooltip]="getPasswordValidationMessage(passwordForm.get('old_password').errors)"
      matTooltipClass="custom-tooltip"
      matTooltipPosition="after">
      <mat-label>{{ 'FIELD:OLD_PASSWORD' | translate }}</mat-label>
      <input [type]="old_password.type"
        formControlName="old_password"
        matInput
        required
        autocomplete="off"/>
      <ai-ui-toggle-visibility #old_password
        matSuffix></ai-ui-toggle-visibility>
    </mat-form-field>
    <mat-form-field appearance="outline"
      [matTooltip]="getPasswordValidationMessage(passwordForm.get('new_password').errors)"
      matTooltipClass="custom-tooltip"
      matTooltipPosition="after">
      <mat-label>{{ 'FIELD:NEW_PASSWORD' | translate }}</mat-label>
      <input [type]="new_password.type"
        formControlName="new_password"
        matInput
        required
        autocomplete="off"/>
      <ai-ui-toggle-visibility #new_password
        matSuffix></ai-ui-toggle-visibility>
    </mat-form-field>
    <button [disabled]="passwordForm.invalid"
      class="btn btn-outline-dark filled"
      mat-button
      type="submit">
      {{ 'CHANGE' | translate }}
    </button>
  </form>
</div>