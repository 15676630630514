<div class="container-fluid bg-white p-4">
  <div class="row title-container">
    <div class="col-6">
      <h1>{{ 'CHAT:CONVERSATIONS' | translate }}</h1>
    </div>
    <div class="col-sm-6 col-12 text-align-right add-action">
      <button mat-button
        type="button"
        routerLink="/profile/messages_new">
        <mat-icon>playlist_add</mat-icon>
        {{ 'CHAT:CREATE' | translate }}
      </button>
    </div>
  </div>
  <form [formGroup]="filterForm"
    class="row">
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'CHAT:CATEGORY' | translate }}</mat-label>
        <mat-select formControlName="category">
          <mat-option *ngFor="let category of (messagesService.chatCategories$ | async)"
            [value]="category.id">
            {{ category.name | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'CHAT:STATUS' | translate }}</mat-label>
        <mat-select formControlName="status">
          <mat-option *ngFor="let status of statuses"
            [value]="status">
            {{ 'CHAT:'+status | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="mb-3">
      <mat-checkbox formControlName="hideEmpty"
        color="primary">{{ 'CHAT:HIDE_TICKETS_WITH_EMPTY_MESSAGES' | translate
        }}</mat-checkbox>
    </div>
  </form>
  <div class="row">
    <ng-container *ngIf="filteredTickets;else notFound">
      <div class="col-12">
        <table class="custom"
          mat-table
          [dataSource]="filteredTickets">
          <ng-container matColumnDef="CATEGORY">
            <th mat-header-cell
              *matHeaderCellDef>{{ 'CHAT:CATEGORY' | translate }}</th>
            <td mat-cell
              *matCellDef="let ticket">{{ getCategoryName(ticket.category) }}</td>
          </ng-container>
          <ng-container matColumnDef="STATUS">
            <th mat-header-cell
              *matHeaderCellDef>{{ 'CHAT:STATUS' | translate }}</th>
            <td mat-cell
              *matCellDef="let ticket">{{ 'CHAT:'+ticket.status | translate }}</td>
          </ng-container>
          <ng-container matColumnDef="CREATED_AT">
            <th mat-header-cell
              *matHeaderCellDef>{{ 'CHAT:CREATED_AT' | translate }}</th>
            <td mat-cell
              *matCellDef="let ticket">{{ ticket.created_at + 'Z' | date: 'dd.MM.yyyy HH:mm' }}</td>
          </ng-container>
          <ng-container matColumnDef="CLOSED_AT">
            <th mat-header-cell
              *matHeaderCellDef>{{ 'CHAT:CLOSED_AT' | translate }}</th>
            <td mat-cell
              *matCellDef="let ticket">{{ ticket?.closed_at ? (ticket.closed_at + 'Z' | date: 'dd.MM.yyyy
              HH:mm') : '-' }}</td>
          </ng-container>

          <tr mat-header-row
            *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row
            *matRowDef="let ticket; columns: displayedColumns;"
            (click)="goToChat(ticket.chat)"></tr>
        </table>
      </div>
    </ng-container>
    <ng-template #notFound>
      <div class="not-found">
        <mat-icon>search</mat-icon>
        <h4>{{ 'CHAT:NOT_FOUND_ANYTHING' | translate }}</h4>
      </div>
    </ng-template>
  </div>
</div>