<div *ngIf="consents.edm_consent | async as edm_consent"
  class="confirmation word-wrap">
  <mat-checkbox [formControl]="electronicDocumentProcessing"
    [matTooltip]="isEdmConsentRead ? null : 'REGISTRATION:CONSENT_DID_NOT_READ' | translate"
    matTooltipPosition="above"
    matTooltipClass="custom-tooltip"
    color="primary"
    [disabled]="!isEdmConsentRead"
    class="dark">{{
      'REGISTRATION:ELECTRONIC_DOCUMENT_PROCESSING_PART_1' | translate }}
    <a [href]="edm_consent"
      target="_blank"
      (click)="isEdmConsentRead = true">{{
        'REGISTRATION:ELECTRONIC_DOCUMENT_PROCESSING_PART_2' | translate }}</a>
  </mat-checkbox>
</div>
<div *ngIf="consents.personal_data_consent | async as personal_data_consent"
  class="confirmation word-wrap mb-4">
  <mat-checkbox [formControl]="personalDataProcessing"
    [disabled]="!isPersonalDataConsentRead"
    [matTooltip]="isPersonalDataConsentRead ? null : 'REGISTRATION:CONSENT_DID_NOT_READ' | translate"
    matTooltipPosition="above"
    matTooltipClass="custom-tooltip"
    color="primary"
    class="dark">{{
      'REGISTRATION:PERSONAL_DATA_PROCESSING_PART_1' | translate }}
    <a [href]="personal_data_consent"
      target="_blank"
      (click)="isPersonalDataConsentRead = true">{{
        'REGISTRATION:PERSONAL_DATA_PROCESSING_PART_2' | translate }}</a>
  </mat-checkbox>
</div>

<div class="d-flex justify-content-end">
  <button mat-flat-button
    (click)="sendConsentsToSign()"
    color="primary"
    class="signin-btn"
    [disabled]="!electronicDocumentProcessing.value || !personalDataProcessing.value || !isPersonalDataConsentRead || !isEdmConsentRead">
    {{'CONSENT:ACCEPT' | translate}}
  </button>
</div>