<div class="close" mat-dialog-close>
  <mat-icon>close</mat-icon>
</div>
<div class="container">
  <div class="title">
    <h3>{{ 'CHAT:SEND_AS_A_FILE' | translate }}</h3>
  </div>
  <div class="content">
    <div class="attachment">
      <button class="icon-btn filled">
        <input class="file-input" type="file" [accept]="acceptedFormates" (change)="fileSelected($event)" #file>
        <mat-icon (click)="file.click()" class="icon material-icons-outlined">insert_drive_file</mat-icon>
      </button>
      <div class="filename">
        <span>{{ data.selectedFile.name }}</span>
      </div>
    </div>
    <div class="text">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'CHAT:ENTER_MESSAGE' | translate }}</mat-label>
        <textarea cdkTextareaAutosize matInput [formControl]="text"></textarea>
      </mat-form-field>
    </div>
    <button [disabled]="text.invalid" (click)="send()" class="send filled">{{ 'CHAT:SEND' | translate }}</button>
  </div>
</div>