<div>
  <div class="form_container flex-column">
    <a href="https://aigenis.ru/"
      target="_blank">
      <img alt="logo"
        width="200"
        src="./assets/logos/aigenis_dark.svg"/>
    </a>
    <div class="title">
      404
    </div>
  </div>
  <div class="sign-illustration"></div>
  <button routerLink="/"
    mat-icon-button
    class="terminate">
    <mat-icon>keyboard_arrow_left</mat-icon>
  </button>
</div>