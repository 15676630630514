import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslationService } from '@localization';
import { MessageResponse } from 'src/app/interfaces/api/messages.interface';
import { AuthService, parseJwt } from 'src/app/services/auth/auth.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { MessagesService } from 'src/app/services/messages/messages.service';
import { WebsocketService } from 'src/app/services/websocket/websocket.service';
import { AiUiMessageWithAttachmentComponent } from 'src/app/shared/shared-components/ai-ui-message-with-attachment/ai-ui-message-with-attachment.component';
import { ToasterService } from 'src/app/shared/shared-services/toaster/toaster.service';
import { animationOptions } from 'src/app/utils/animation';
import { acceptedFormates, convertByteToMegaBytes, getFormatDate, maxAttachmentSize } from 'src/app/utils/utils';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
  animations: animationOptions,
})
export class MessagesComponent implements OnInit, OnDestroy {
  @ViewChild('anchor') private anchor: ElementRef;

  acceptedFormates = acceptedFormates;

  selectedFile: File;

  readonly CHAT_MESSAGE_TYPE: string = 'chat_message';

  userId: number;

  chatId: number = 0;

  text: FormControl = new FormControl(null, Validators.required);

  groupedMessages: { [key: string]: MessageResponse[] } = {};

  keys: string[] = [];

  constructor(
    public messagesService: MessagesService,
    private loader: LoaderService,
    private router: Router,
    private webSockerService: WebsocketService,
    private cd: ChangeDetectorRef,
    private toaster: ToasterService,
    private translation: TranslationService,
    private dialog: MatDialog,
    activatedRoute: ActivatedRoute
  ) {
    this.userId = parseJwt(localStorage.getItem(AuthService.ACCESS_TOKEN_KEY)).user_id;
    this.chatId = Number(activatedRoute.snapshot.paramMap.get('id')) || -1;
    const protocol = document.location.protocol === 'https:' ? 'wss://' : 'ws://';
    const url = `${protocol}${window.location.host}/ws/user/`;
    webSockerService.initiate(url);
    webSockerService.messages$.subscribe({
      next: (data) => {
        if (data.chat_id === this.chatId && data.type === this.CHAT_MESSAGE_TYPE) {
          const created_at = new Date(data.created_at);
          const dateISO = getFormatDate(created_at);
          this.groupedMessages[dateISO] = this.groupedMessages[dateISO] || [];
          this.groupedMessages[dateISO].push({
            id: data.id,
            user_id: data.author.user_id,
            created_at: created_at,
            file_name: data.original_filename,
            text: data.message,
            username: data.author.username,
            live: true,
          });
          if (this.keys.indexOf(dateISO) === -1) {
            this.keys.push(dateISO);
          }
          this.cd.markForCheck();
        }
      },
    });
  }

  ngOnInit(): void {
    this.loader.showLoader();
    this.messagesService.getMessagesByChatId(this.chatId).subscribe({
      next: (data) => {
        for (let i = 0; i < data.length; i++) {
          const date = getFormatDate(new Date(data[i].created_at));
          this.groupedMessages[date] = this.groupedMessages[date] || [];
          this.groupedMessages[date].push(data[i]);
        }
        this.loader.hideLoader();
      },
      error: () => {
        this.router.navigate(['/']);
        this.loader.hideLoader();
      },
      complete: () => {
        this.keys = Object.keys(this.groupedMessages);
      },
    });
    document.body.scrollTop = 0;
  }

  fileSelected(event) {
    const file = event.target.files[0];

    if (file.size > maxAttachmentSize) {
      this.toaster.showError(
        this.translation.translate('FILE_SIZE_INCORRECT', [convertByteToMegaBytes(maxAttachmentSize)])
      );
      return;
    }
    this.dialog
      .open(AiUiMessageWithAttachmentComponent, {
        width: '400px',
        height: 'auto',
        panelClass: 'popup-container',
        data: {
          selectedFile: file,
          text: this.text?.value,
        },
      })
      .afterClosed()
      .subscribe({
        next: (data) => {
          if (!data) {
            this.selectedFile = null;
          } else {
            this.text.setValue(data.text);
            this.selectedFile = data.selectedFile;
            this.sendMessage();
          }
        },
        error: () => {},
      });
    this.cd.markForCheck();
  }

  sendMessage() {
    if (this.text.invalid) {
      return;
    }
    let formData: FormData = new FormData();
    formData.append('text', this.text.value);
    if (this.selectedFile) {
      formData.append('file', this.selectedFile, this.selectedFile.name);
    }
    this.messagesService.sendMessage(this.chatId, formData).subscribe({
      next: (_) => {
        this.anchor.nativeElement.scrollTop = this.anchor.nativeElement.scrollHeight;
        this.text.setValue(null);
      },
    });
  }

  downloadFile(message_id: number, filename: string) {
    this.messagesService.downloadFile(this.chatId, message_id).subscribe({
      next: (blob) => {
        const link = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = link;
        // a.target = '_blank';
        a.download = filename;
        a.click();
        URL.revokeObjectURL(link);
      },
    });
  }

  ngOnDestroy(): void {
    this.webSockerService.disconnect();
    this.webSockerService.messages$.unsubscribe();
  }
}
