import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiUrlWithPrefix } from '@env';
import { map } from 'rxjs';
import { RefillAccountResponse } from 'src/app/interfaces/api/refill-account.interface';

@Injectable({
  providedIn: 'root',
})
export class RefillAccountService {
  static readonly PAYMENT_METHODS = `${apiUrlWithPrefix}/refill-account-method/`;

  static readonly _steps: [number, string][] = [
    [0, 'market_choosing'],
    [1, 'refill_methods'],
  ];

  private _stepsMap = new Map(RefillAccountService._steps);

  private _step: number = 0;

  set step(step: number) {
    this._step = step;
  }

  get stepKey(): number {
    return this._step;
  }

  get stepValue() {
    return this._stepsMap.get(this._step);
  }

  get stepsCount() {
    return RefillAccountService._steps.length;
  }

  public goToStep(step: number) {
    this.step = step;
  }

  constructor(private http: HttpClient) {}

  paymentMethods() {
    return this.http
      .get(RefillAccountService.PAYMENT_METHODS)
      .pipe(map((res: any) => res.results as RefillAccountResponse[]));
  }
}
