<ng-container *ngIf="type==='fillPersonalData'">
  <div class="restriction">
    <div class="content">
      <div class="bg"></div>
      <div class="title">
        <span>{{ 'broker_service' | translate }}</span>
      </div>
      <div class="description">
        <span>{{ 'RESTRICTION:PROFILE_INFO' | translate }}</span>
      </div>
      <div class="button">
        <button mat-button class="filled" type="button" (click)="navigate()">
          {{ 'FILLING_PROFILE' | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="type==='waitingForAigenisAccountBinding'">
  <div class="restriction reverse">
    <div class="logo">
      <img src="./assets/logos/wait_for_approval_restriction.svg" alt="logo">
    </div>
    <div class="content">
      <div class="title">
        <span class="lg">{{ 'RESTRICTION:TITLE' | translate }}</span>
      </div>
      <div class="description">
        <span class="sm">{{ 'RESTRICTION:AIGENIS_ACCOUNT_BINDIND' | translate }}</span>
      </div>
      <div class="button">
        <button mat-button class="filled" type="button" (click)="navigate()">
          {{ 'GO' | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="type==='waitingForApproval'">
  <div class="restriction reverse">
    <div class="logo">
      <img src="./assets/logos/wait_for_approval_restriction.svg" alt="logo">
    </div>
    <div class="content">
      <div class="title">
        <span class="lg">{{ 'RESTRICTION:WAIT_FOR_APPROVAL_TITLE' | translate }}</span>
      </div>
      <div class="description">
        <span class="sm">{{ 'RESTRICTION:WAIT_FOR_APPROVAL_CONTENT' | translate }}</span>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="type==='createBrokerageService'">
  <div class="restriction">
    <div class="logo">
      <img src="./assets/logos/fill_profile_restriction.svg" alt="logo">
    </div>
    <div class="content">
      <div class="title">
        <span>{{ 'broker_service' | translate }}</span>
      </div>
      <div class="description">
        <span>{{ 'RESTRICTION:BROKER_INFO' | translate }}</span>
      </div>
      <div class="button">
        <button mat-button class="filled" type="button" (click)="navigate()">
          {{ 'ADD_BROKERAGE_SERVICE' | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="type==='brokerageAgreementNew'">
  <div class="restriction">
    <div class="logo">
      <img src="./assets/logos/fill_profile_restriction.svg" alt="logo">
    </div>
    <div class="content">
      <div class="title">
        <span>{{ 'broker_service' | translate }}</span>
      </div>
      <div class="description">
        <span>{{ 'RESTRICTION:AGREEMENT_NEW_INFO' | translate }}</span>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="type==='brokerageServiceApprovalNew'">
  <div class="restriction">
    <div class="logo">
      <img src="./assets/logos/fill_profile_restriction.svg" alt="logo">
    </div>
    <div class="content">
      <div class="title">
        <span>{{ 'broker_service' | translate }}</span>
      </div>
      <div class="description">
        <span>{{ 'RESTRICTION:BROKER_NEW_INFO' | translate }}</span>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="type==='waitingForBrokerageServiceApproval'">
  <div class="restriction">
    <div class="logo">
      <img src="./assets/logos/fill_profile_restriction.svg" alt="logo">
    </div>
    <div class="content">
      <div class="title">
        <span>{{ 'broker_service' | translate }}</span>
      </div>
      <div class="description">
        <span>{{ 'RESTRICTION:BROKER_APPROVAL_INFO' | translate }}</span>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="type==='createDepositaryService'">
  <div class="restriction">
    <div class="logo">
      <img src="./assets/logos/fill_profile_restriction.svg" alt="logo">
    </div>
    <div class="content">
      <div class="title">
        <span>{{ 'depositary_service' | translate }}</span>
      </div>
      <div class="description">
        <span>{{ 'RESTRICTION:DEPOSITORY_INFO' | translate }}</span>
      </div>
      <div class="button">
        <button mat-button class="filled" type="button" (click)="navigate()">
          {{ 'ADD_DEPOSITARY_SERVICE' | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="type==='depositaryAgreementNew'">
  <div class="restriction">
    <div class="logo">
      <img src="./assets/logos/fill_profile_restriction.svg" alt="logo">
    </div>
    <div class="content">
      <div class="title">
        <span>{{ 'depositary_service' | translate }}</span>
      </div>
      <div class="description">
        <span>{{ 'RESTRICTION:AGREEMENT_NEW_INFO' | translate }}</span>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="type==='depositaryServiceApprovalNew'">
  <div class="restriction">
    <div class="logo">
      <img src="./assets/logos/fill_profile_restriction.svg" alt="logo">
    </div>
    <div class="content">
      <div class="title">
        <span>{{ 'depositary_service' | translate }}</span>
      </div>
      <div class="description">
        <span>{{ 'RESTRICTION:DEPOSITORY_NEW_INFO' | translate }}</span>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="type==='waitingForDepositaryServiceApproval'">
  <div class="restriction">
    <div class="logo">
      <img src="./assets/logos/fill_profile_restriction.svg" alt="logo">
    </div>
    <div class="content">
      <div class="title">
        <span>{{ 'depositary_service' | translate }}</span>
      </div>
      <div class="description">
        <span>{{ 'RESTRICTION:DEPOSITORY_APPROVAL_INFO' | translate }}</span>
      </div>
    </div>
  </div>
</ng-container>