<div class="container-fluid bg-white p-4"
  [@open]="'opened'">
  <h1>{{ 'CLAIM:FILLING' | translate }}</h1>
  <div class="hint">
    <p>{{ 'qualified_investor' | translate }}</p>
  </div>
  <div class="row">
    <ui-step-view class="col-12 mt-3"
      [total]="4"
      [current]="qfService.stepKey + 1"
      [stepValues]="['CLAIM_STEP:CREATION', 'CLAIM_STEP:DOCUMENTS_UPLOADING', 'CLAIM_STEP:DEMANDS', 'CLAIM_STEP:SIGN']">
    </ui-step-view>
    <div class="order-container">
      <div class="form-data col-12">
        <ng-container *ngIf="qfService.stepKey===0">
          <div class="order-content">
            <p>{{ 'QUALIFIED_CLAIM:COUNT_OF_ASSETS' | translate }}</p>
            <mat-radio-group class="row"
              [formControl]="count_of_assets">
              <ng-container *ngFor="let type of assets">
                <mat-radio-button color="primary"
                  [value]="type">
                  {{ 'AIGENIS_ASSETS:' + type | translate }}
                </mat-radio-button>
              </ng-container>
            </mat-radio-group>
          </div>
          <div class="d-flex justify-content-end">
            <button [disabled]="count_of_assets.invalid"
              class="filled"
              mat-flat-button
              (click)="next()">
              {{ 'NEXT' | translate }}
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="qfService.stepKey===1">
          <div class="row attachments">
            <div>
              <p>{{ 'QUALIFICATIONS:TIP_2_TITLE' | translate }}</p>
            </div>
            <div>
              <ul>
                <ng-container *ngFor="let name of documentsName">
                  <li>
                    <p>{{ name }}</p>
                  </li>
                </ng-container>
              </ul>
            </div>
            <ng-container *ngFor="let attachment of attachments">
              <div class="col-xl-3 col-lg-4 col-md-5 col-sm-6 col-12"
                *ngIf="attachment">
                <div class="attachment"
                  [@open]="'opened'">
                  <div class="close"
                    (click)="onDelete(attachment.id)">
                    <mat-icon>close</mat-icon>
                  </div>
                  <ng-container *ngIf="attachment.isImage else document">
                    <img [src]="attachment.url"
                      [alt]="attachment.file_name"
                      (click)="open(attachment.url)">
                  </ng-container>
                  <ng-template #document>
                    <div class="document">
                      <img src="./assets/images/document.svg"
                        alt="logo">
                      <p>{{ attachment.file_name }}</p>
                      <div class="actions">
                        <div class="open">
                          <a [href]="attachment.url"
                            target="_blank">
                            <div>
                              <mat-icon>visibility</mat-icon>
                            </div>
                            {{ 'OPEN' | translate }}
                          </a>
                        </div>
                        <div class="download">
                          <a [href]="attachment.url"
                            [download]="attachment.file_name"
                            target="_blank">
                            <!-- <button mat-button (click)="download('test.docx','blob:http://localhost:4300/ed2a7872-2c21-4cf1-b7db-e66d4ec9710e')"> -->
                            <div>
                              <mat-icon>download</mat-icon>
                            </div>
                            {{ 'DOWNLOAD' | translate }}
                            <!-- </button> -->
                          </a>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <button class="back"
              (click)="back()"
              mat-button
              type="button">
              {{ 'BACK' | translate }}
            </button>
            <div class="d-flex justify-content-start mb-4">
              <input class="file-input"
                type="file"
                [accept]="acceptedFormates"
                (change)="fileSelected($event)"
                  #file>
              <button type="button"
                class="attach btn btn-outline-dark"
                (click)="file.click()">
                {{ 'ATTACHMENT:UPLOAD' | translate }}
              </button>
            </div>
            <div class="hint">
              <p>{{ 'ATTACHMENT:HINT' | translate }}</p>
            </div>
            <div class="hint">
              <p>{{ 'ATTACHMENT:HINT_2' | translate }}</p>
            </div>
            <div class="hint">
              <p>{{ 'QUALIFIED_CLAIM:PIN_ATTACHMENTS' | translate }}</p>
            </div>
            <button [disabled]="attachments?.length===0"
              mat-button
              class="filled"
              type="button"
              (click)="next()">
              {{ 'NEXT' | translate }}
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="qfService.stepKey===2">
          <div>
            <p>{{ 'QUALIFICATIONS:TIP_3_TITLE' | translate }}</p>
          </div>
          <form [formGroup]="form">
            <p>{{ 'QUALIFIED_CLAIM:DEMAND_1' | translate }}</p>
            <mat-form-field appearance="outline">
              <mat-label>{{ 'QUALIFIED_CLAIM:ATTACHED_DOCUMENTS' | translate }}</mat-label>
              <input matInput
                formControlName="total_cost_of_securities_document">
              <mat-error *ngIf="form.get('total_cost_of_securities_document').errors?.maxlength">
                {{'ERROR:MAX_LENGTH' | translate : [255] }}
              </mat-error>
            </mat-form-field>

            <p>{{ 'QUALIFIED_CLAIM:DEMAND_2' | translate }}</p>
            <mat-form-field appearance="outline">
              <mat-label>{{ 'QUALIFIED_CLAIM:ATTACHED_DOCUMENTS' | translate }}</mat-label>
              <input matInput
                formControlName="at_least_two_years_of_experience_document">
              <mat-error *ngIf="form.get('at_least_two_years_of_experience_document').errors?.maxlength">
                {{'ERROR:MAX_LENGTH' | translate : [255] }}
              </mat-error>
            </mat-form-field>

            <p>{{ 'QUALIFIED_CLAIM:DEMAND_3' | translate }}</p>
            <mat-form-field appearance="outline">
              <mat-label>{{ 'QUALIFIED_CLAIM:ATTACHED_DOCUMENTS' | translate }}</mat-label>
              <input matInput
                formControlName="job_title_agreed_with_bank_of_russia_document">
              <mat-error *ngIf="form.get('job_title_agreed_with_bank_of_russia_document').errors?.maxlength">
                {{'ERROR:MAX_LENGTH' | translate : [255] }}
              </mat-error>
            </mat-form-field>

            <p>{{ 'QUALIFIED_CLAIM:DEMAND_4' | translate }}</p>
            <mat-form-field appearance="outline">
              <mat-label>{{ 'QUALIFIED_CLAIM:ATTACHED_DOCUMENTS' | translate }}</mat-label>
              <input matInput
                formControlName="deals_document">
              <mat-error *ngIf="form.get('deals_document').errors?.maxlength">
                {{'ERROR:MAX_LENGTH' | translate : [255] }}
              </mat-error>
            </mat-form-field>

            <p>{{ 'QUALIFIED_CLAIM:DEMAND_5' | translate }}</p>
            <mat-form-field appearance="outline">
              <mat-label>{{ 'QUALIFIED_CLAIM:ATTACHED_DOCUMENTS' | translate }}</mat-label>
              <input matInput
                formControlName="property_size_document">
              <mat-error *ngIf="form.get('property_size_document').errors?.maxlength">
                {{'ERROR:MAX_LENGTH' | translate : [255] }}
              </mat-error>
            </mat-form-field>

            <p>{{ 'QUALIFIED_CLAIM:DEMAND_6' | translate }}</p>
            <mat-form-field appearance="outline">
              <mat-label>{{ 'QUALIFIED_CLAIM:ATTACHED_DOCUMENTS' | translate }}</mat-label>
              <input matInput
                formControlName="higher_economic_education_document">
              <mat-error *ngIf="form.get('higher_economic_education_document').errors?.maxlength">
                {{'ERROR:MAX_LENGTH' | translate : [255] }}
              </mat-error>
            </mat-form-field>
          </form>
          <div class="actions">
            <button class="back"
              (click)="back()"
              mat-button
              type="button">
              {{ 'BACK' | translate }}
            </button>
            <button mat-button
              class="filled"
              type="button"
              (click)="next()">
              {{ 'NEXT' | translate }}
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="qfService.stepKey===3">
          <div class="col-12">
            <ai-ui-documents [attachments]="attachmentsToSign"
              [isCreatingOrder]="true"></ai-ui-documents>
          </div>
          <div class="col-10 confirmation">
            <h4>{{ 'CLAIM_SIGNATURE_TITLE' | translate }}</h4>
            <div class="hint">
              <p>{{ 'CLAIM_SIGNATURE_INFORMATION' | translate }}
              </p>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <button class="back"
              (click)="back()"
              mat-button
              type="button">
              {{ 'BACK' | translate }}
            </button>
            <button mat-button
              class="filled"
              type="button"
              (click)="sendToSign()">
              {{ 'SIGN' | translate }}
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>