<div class="container-fluid">
  <div class="chat">
    <div class="col-12">
      <div class="chat__caption">
        <span>{{ 'CHAT:SUPPORT' | translate }}</span>
      </div>
      <div #anchor class="chat__content">
        <ng-container *ngIf="groupedMessages; else noMessages">
          <ng-container *ngFor="let key of keys">
            <div class="date">
              <span>
                {{ key + 'Z' | date: 'dd.MM.yyyy' }}
              </span>
            </div>
            <ng-container *ngFor="let message of groupedMessages[key]">
              <div [ngClass]="message?.user_id===userId ? 'right-side' : 'left-side'">
                <div class="message">
                  <span class="username">{{ message?.user_id===userId ? '' : 'CHAT:MANAGER' | translate }}</span>
                  <span class="text">{{ message.text }}</span>
                  <ng-container *ngIf="message.file_name">
                    <div class="attachment" (click)="downloadFile(message.id, message.file_name)">
                      <div class="icon-block">
                        <button class="icon-btn filled">
                          <mat-icon class="icon material-icons-outlined">insert_drive_file</mat-icon>
                        </button>
                      </div>
                      <div class="filename">
                        <span>{{ message.file_name }}</span>
                      </div>
                    </div>
                  </ng-container>
                  <div class="details">
                    <span class="time">{{ (message?.live ? message.created_at : message.created_at + 'Z') | date:
                      'HH:mm'
                      }}</span>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
        <ng-template #noMessages>
          <div class="no-messages">
            <span>{{ 'CHAT:NO_MESSAGES' | translate }}</span>
          </div>
        </ng-template>
      </div>
      <div class="chat__compose">
        <div class="attachment-side col-sm-1 col-2">
          <mat-icon (click)="file.click()" [matTooltip]="'MESSAGES:ATTACHMENT' | translate" matTooltipPosition="above">attach_file</mat-icon>
          <input class="file-input" type="file" [accept]="acceptedFormates" (change)="fileSelected($event)" #file>
        </div>
        <div class="message-side col">
          <mat-form-field appearance="outline">
            <textarea [placeholder]="'CHAT:ENTER_MESSAGE' | translate" cdkTextareaAutosize matInput
              [formControl]="text"></textarea>
          </mat-form-field>
        </div>
        <div class="send-side col-sm-1 col-2">
          <mat-icon [matTooltip]="'MESSAGES:SEND' | translate" matTooltipPosition="above" [ngClass]="{'disabled': !text?.value}" (click)="sendMessage()">send</mat-icon>
        </div>
      </div>
    </div>
  </div>
</div>