import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, map, takeUntil } from 'rxjs';
import { FaqResponse } from 'src/app/interfaces/api/faq.interface';
import { FaqType } from 'src/app/utils/utils';
import { LoaderService } from '../loader/loader.service';

@Injectable({
  providedIn: 'root',
})
export class FaqService {
  static readonly FAQ = './assets/staticfiles/faq.json';

  faqList$: BehaviorSubject<FaqResponse[]> = new BehaviorSubject<FaqResponse[]>(null);

  constructor(private http: HttpClient, private loader: LoaderService) {}

  getFaqList() {
    this.loader.showLoader();
    this.http
      .get(FaqService.FAQ)
      .pipe(
        map((res: any) => {
          return res as FaqResponse[];
        })
      )
      .subscribe({
        next: (data) => {
          this.faqList$.next(data);
          this.loader.hideLoader();
        },
        error: () => {
          this.loader.hideLoader();
        },
      });
  }

  getTopFaq() {
    return this.http.get(FaqService.FAQ).pipe(
      map((res: FaqResponse[]) => {
        return res.filter((f) => f.type === FaqType.starting_of_work);
      })
    );
  }
}
