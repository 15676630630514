import { CommonModule } from '@angular/common';
import { LOCALE_ID, ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { RouterModule, Routes } from '@angular/router';
import { TranslationModule } from '@localization';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { MaterialModule } from 'src/app/material.module';
import { SharedModule } from 'src/app/shared/shared-components/shared.module';
import { AuthComponent } from 'src/app/user/auth/auth.component';
import { PasswordRecoveryConfirmationComponent } from './password-recovery/components/password-recovery-confirmation/password-recovery-confirmation.component';
import { PasswordRecoveryComponent } from './password-recovery/components/password-recovery/password-recovery.component';
import { ClientRegistrationComponent } from './registration/client-registration.component';
import { ClientRegistrationFlowComponent } from './registration/components/client-registration-flow/client-registration-flow.component';
import { NgxMaskModule } from 'ngx-mask';
import { SharedDirectivesModule } from '../shared/shared-directives/shared-directives.module';
import {
  RECAPTCHA_LANGUAGE,
  RECAPTCHA_SETTINGS,
  RECAPTCHA_V3_SITE_KEY,
  RecaptchaFormsModule,
  RecaptchaModule,
  RecaptchaSettings,
} from 'ng-recaptcha';
import { environment } from '@env';

const routes: Routes = [
  {
    path: '',
    component: ClientRegistrationComponent,
    children: [
      {
        path: '',
        redirectTo: 'sign_in',
        pathMatch: 'full',
      },
      {
        path: 'sign_in',
        component: AuthComponent,
      },
      {
        path: 'sign_up',
        component: ClientRegistrationFlowComponent,
      },
      {
        path: 'password_recovery',
        component: PasswordRecoveryComponent,
      },
      {
        path: 'reset_password/:uid/:token',
        component: PasswordRecoveryConfirmationComponent,
      },
    ],
  },
];

const RegistrationRouterModule: ModuleWithProviders<RouterModule> = RouterModule.forChild(routes);

@NgModule({
  declarations: [
    AuthComponent,
    ClientRegistrationComponent,
    ClientRegistrationFlowComponent,
    PasswordRecoveryComponent,
    PasswordRecoveryConfirmationComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    SharedModule,
    SharedDirectivesModule,
    RegistrationRouterModule,
    TranslationModule,
    NgxMatIntlTelInputComponent,
    NgxMaskModule,
    RecaptchaFormsModule,
    RecaptchaModule,
  ],
  providers: [
    { provide: 'Window', useValue: window },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline', floatLabel: 'always' },
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.reCaptchaPrivateKey,
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.reCaptchaSiteKey,
      } as RecaptchaSettings,
    },
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: 'RU',
    },
  ],
})
export class UserModule {}
