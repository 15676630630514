<div class="form_container flex-grow-1" [@open]="'opened'">
  <div class="header_logo">
    <a href="https://aigenis.ru/" target="_blank">
      <img src="./assets/logos/aigenis_dark.svg" alt="logo" />
    </a>
  </div>
  <div class="form__title">
    <span>{{ 'WELCOME' | translate }}</span>
  </div>
  <div class="switch-type">
    <button
      class="switch-type-item"
      (click)="toggleMethod(LoginType.PHONE)"
      [class.active]="loginType === LoginType.PHONE">
      Номер телефона
    </button>
    <button
      class="switch-type-item"
      (click)="toggleMethod(LoginType.USERNAME)"
      [class.active]="loginType === LoginType.USERNAME">
      Логин
    </button>
  </div>
  <form (ngSubmit)="submit()" [formGroup]="form">
    <mat-form-field *ngIf="loginType === LoginType.USERNAME" class="mb-3">
      <mat-label>{{ 'FIELD:LOGIN' | translate }}</mat-label>
      <input formControlName="username" matInput />
      <mat-error *ngIf="form.get('username').errors?.required">
        {{ 'ERROR:REQUIRED' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field *ngIf="loginType === LoginType.PHONE" class="mb-3">
      <mat-label>{{ 'FIELD:PHONE_NUMBER' | translate }}</mat-label>
      <ngx-mat-intl-tel-input
        #phone
        [enablePlaceholder]="true"
        [enableSearch]="false"
        [onlyCountries]="['by', 'ru']"
        formControlName="phone"></ngx-mat-intl-tel-input>
      <mat-hint>{{ 'PHONE:EXAMPLE' | translate : [phone.selectedCountry.placeHolder] }}</mat-hint>
      <mat-error *ngIf="form.get('phone').errors?.validatePhoneNumber">
        {{ 'ERROR:PHONE_NUMBER_INCORRECT' | translate }}
      </mat-error>
      <mat-error *ngIf="form.get('phone').errors?.required">
        {{ 'ERROR:REQUIRED' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ 'FIELD:PASSWORD' | translate }}</mat-label>
      <input [type]="visibility.type" formControlName="password" matInput required />
      <ai-ui-toggle-visibility #visibility matSuffix></ai-ui-toggle-visibility>
      <mat-error *ngIf="form.get('password').errors?.required">
        {{ 'ERROR:REQUIRED' | translate }}
      </mat-error>
    </mat-form-field>
    <div class="d-flex justify-content-between">
      <a (click)="passwordRecovery()" class="sign-link mb-2">
        {{ 'AUTH:FORGOT_PASSWORD' | translate }}
      </a>
    </div>
    <re-captcha class="recaptcha" formControlName="recaptcha"></re-captcha>
    <div class="d-flex justify-content-center mb-3">
      <button [disabled]="!form.valid" class="signin-btn" mat-flat-button type="submit">
        {{ 'AUTH:ENTER' | translate }}
      </button>
    </div>
    <ui-divider>{{ 'AUTH:NO_ACCOUNT' | translate }}</ui-divider>
    <div class="justify-content-center">
      <button (click)="signUp()" class="signin-btn" mat-flat-button type="button">
        {{ 'REGISTER' | translate }}
      </button>
    </div>
  </form>
</div>
<div class="sign-illustration"></div>
