import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { acceptedFormates, convertByteToMegaBytes, maxAttachmentSize } from 'src/app/utils/utils';
import { ToasterService } from '../../shared-services/toaster/toaster.service';
import { TranslationService } from '@localization';

export interface Data {
  selectedFile: File;
  text: string;
}

@Component({
  selector: 'app-ai-ui-message-with-attachment',
  templateUrl: './ai-ui-message-with-attachment.component.html',
  styleUrls: ['./ai-ui-message-with-attachment.component.scss'],
})
export class AiUiMessageWithAttachmentComponent {
  acceptedFormates = acceptedFormates;

  text: FormControl = new FormControl(this.data.text, Validators.required);

  selectedFile: File;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Data,
    private dialogRef: MatDialogRef<AiUiMessageWithAttachmentComponent>,
    private toaster: ToasterService,
    private translation: TranslationService
  ) {}

  fileSelected(event) {
    const file = event.target.files[0];
    if (file.size > maxAttachmentSize) {
      this.toaster.showError(
        this.translation.translate('FILE_SIZE_INCORRECT', [convertByteToMegaBytes(maxAttachmentSize)])
      );
      return;
    }
    this.data.selectedFile = file;
  }

  send() {
    this.dialogRef.close({
      text: this.text.value,
      selectedFile: this.data.selectedFile,
    });
  }
}
