<div class="close" mat-dialog-close>
  <mat-icon>close</mat-icon>
</div>
<div class="content">
  <p>{{ data.content }}</p>
</div>
<div class="actions">
  <div class="d-flex justify-content-start mb-3">
    <button class="ok" mat-flat-button (click)="onClose()">
      {{ 'OK' | translate }}
    </button>
    <button class="simple" mat-flat-button mat-dialog-close>
      {{ 'CANCELATION' | translate }}
    </button>
  </div>
</div>