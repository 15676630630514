<div class="container-fluid bg-white p-4"
  [@open]="'opened'">
  <h1>{{ 'CLAIM:FILLING' | translate }}</h1>
  <div class="hint">
    <p>{{ 'currency_instruments_trade' | translate }}</p>
  </div>
  <div class="row">
    <ui-step-view class="col-12 mt-3"
      [total]="2"
      [current]="cfService.stepKey + 1"
      [stepValues]="['CLAIM_STEP:CREATION', 'CLAIM_STEP:SIGN']">
    </ui-step-view>
    <div class="order-container">
      <div class="form-data col-12">
        <ng-container *ngIf="cfService.stepKey===0">
          <form [formGroup]="form">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'CLAIM:TRADE_TYPE' | translate }}</mat-label>
              <mat-select formControlName="trade_type">
                <mat-option *ngFor="let type of tradeTypes"
                  [value]="type">
                  {{ type | translate }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="form.get('trade_type').errors?.required">
                {{'ERROR:REQUIRED' | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>{{ 'SECURITY:EXECUTION_PLACE' | translate }}</mat-label>
              <mat-select formControlName="market">
                <mat-option *ngFor="let exchange of exchanges"
                  [value]="exchange.id">{{ exchange.name |
                    translate }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>{{ 'CLAIM:OUTCOME_CURRENCY' | translate }}</mat-label>
              <mat-select formControlName="outcome_currency">
                <mat-option *ngFor="let currency of currencies"
                  [value]="currency">
                  {{ currency }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="form.get('outcome_currency').errors?.required">
                {{'ERROR:REQUIRED' | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>{{ 'CLAIM:RATE_TYPE' | translate }}</mat-label>
              <mat-select (selectionChange)="onRateTypeChange($event)"
                formControlName="exchange_rate_type">
                <mat-option *ngFor="let type of rateTypes"
                  [value]="type">
                  {{ type | translate }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="form.get('exchange_rate_type').errors?.required">
                {{'ERROR:REQUIRED' | translate }}
              </mat-error>
            </mat-form-field>
            <ng-container *ngIf="(form.get('exchange_rate_type').value!=='market_price')">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'CLAIM:EXCHANGE_RATE' | translate }}</mat-label>
                <input amount
                  [decimalPlace]="4"
                  matInput
                  type="text"
                  formControlName="exchange_rate">
                <mat-error *ngIf="form.get('exchange_rate').errors?.required">
                  {{'ERROR:REQUIRED' | translate }}
                </mat-error>
              </mat-form-field>
            </ng-container>
            <mat-form-field appearance="outline">
              <mat-label>{{ 'CLAIM:INCOME_AMOUNT' | translate }}</mat-label>
              <input matInput
                amount
                name="income_amount"
                type="text"
                formControlName="income_amount">
              <mat-error *ngIf="form.get('income_amount').errors?.required">
                {{'ERROR:REQUIRED' | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>{{ 'CLAIM:INCOME_CURRENCY' | translate }}</mat-label>
              <mat-select formControlName="income_currency">
                <mat-option *ngFor="let currency of currencies"
                  [value]="currency">
                  {{ currency }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="form.get('income_currency').errors?.required">
                {{'ERROR:REQUIRED' | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>{{ 'CLAIM:ADDITIONAL_CONDITION' | translate }}</mat-label>
              <input matInput
                formControlName="additional_terms">
            </mat-form-field>
          </form>
          <div class="d-flex justify-content-end">
            <button [disabled]="form.invalid"
              mat-button
              class="filled"
              type="button"
              (click)="next()">
              {{ 'NEXT' | translate }}
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="cfService.stepKey===1">
          <div class="col-12">
            <ai-ui-documents [attachments]="attachmentsToSign"
              [isCreatingOrder]="true"></ai-ui-documents>
          </div>
          <div class="col-10 confirmation">
            <mat-checkbox [formControl]="theFirstConfirmation"
              color="primary">
              {{ 'BS:CONFIRMATION_4' | translate }}
              <a href="https://www.aigenis.ru/info12"
                target="_blank"
                class="risk-link">{{ 'BS:CONFIRMATION_4_1' | translate }}</a>
                {{ 'BS:CONFIRMATION_4_2' | translate }}
            </mat-checkbox>
            <!-- <mat-checkbox [formControl]="theFirstConfirmation" color="primary">{{ 'BS:CONFIRMATION_1' | translate
              }}</mat-checkbox>
            <mat-checkbox [formControl]="theSecondConfirmation" color="primary">
              {{ 'BS:CONFIRMATION_2' | translate }}
              <span class="risk-link" (click)="openRiskInformation()">{{ 'BS:CONFIRMATION_2_1' | translate }}</span>
            </mat-checkbox> -->
            <h4>{{ 'CLAIM_SIGNATURE_TITLE' | translate }}</h4>
            <div class="hint">
              <p>{{ 'CLAIM_SIGNATURE_INFORMATION' | translate }}
              </p>
            </div>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <button class="back"
              (click)="back()"
              mat-button
              type="button">
              {{ 'BACK' | translate }}
            </button>
            <button mat-button
              [disabled]="!theFirstConfirmation.value"
              class="filled"
              type="button"
              (click)="sendToSign()">
              {{ 'SIGN' | translate }}
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>