import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiUrlWithPrefix } from '@env';
import { BehaviorSubject, filter, map, of } from 'rxjs';
import {
  CityRuResponse,
  DistrictRuResponse,
  LocalityRuResponse,
  LocalityType,
  RegionRuResponse,
  StreetRuResponse,
  StreetType,
} from 'src/app/interfaces/api/geo.interface';

@Injectable({
  providedIn: 'root',
})
export class GeoService {
  static readonly REGION_RUS = `${apiUrlWithPrefix}/geo/region-ru/`;
  static readonly DISTRICT_RUS = `${apiUrlWithPrefix}/geo/district-ru/`;
  static readonly CITY_RUS = `${apiUrlWithPrefix}/geo/city-ru/`;
  static readonly LOCALITY_RUS = `${apiUrlWithPrefix}/geo/locality-ru/`;
  static readonly STREET_RUS = `${apiUrlWithPrefix}/geo/street-ru/`;

  static readonly LOCALITY_TYPES = `${apiUrlWithPrefix}/geo/locality-type/`;
  static readonly STREET_TYPES = `${apiUrlWithPrefix}/geo/street-type/`;

  public regionRusItems$: BehaviorSubject<RegionRuResponse[]> = new BehaviorSubject<RegionRuResponse[]>(null);
  public districtRusItems$: BehaviorSubject<DistrictRuResponse[]> = new BehaviorSubject<DistrictRuResponse[]>(null);
  public cityRusItems$: BehaviorSubject<CityRuResponse[]> = new BehaviorSubject<CityRuResponse[]>(null);
  public localityRusItems$: BehaviorSubject<LocalityRuResponse[]> = new BehaviorSubject<LocalityRuResponse[]>(null);
  public streetRusItems$: BehaviorSubject<StreetRuResponse[]> = new BehaviorSubject<StreetRuResponse[]>(null);

  public localityTypes$: BehaviorSubject<LocalityType[]> = new BehaviorSubject<LocalityType[]>(null);
  public streetTypes$: BehaviorSubject<StreetType[]> = new BehaviorSubject<StreetType[]>(null);

  static readonly REGION_PARAM_NAME = 'region';
  static readonly DISTRICT_PARAM_NAME = 'district';
  static readonly CITY_PARAM_NAME = 'city';
  static readonly LOCALITY_PARAM_NAME = 'locality';
  constructor(private http: HttpClient) {}

  regionRuByName(name: string, params: { [key: string]: string }) {
    const httpParams = this._getHttpParams(name, params);
    return this.http
      .get(GeoService.REGION_RUS, {
        params: httpParams,
      })
      .pipe(map((res: any) => res.results as RegionRuResponse[]));
  }

  districtRuByName(name: string, params: { [key: string]: string }) {
    const httpParams = this._getHttpParams(name, params);
    if (!httpParams.has(GeoService.REGION_PARAM_NAME)) {
      return of([]);
    }
    return this.http
      .get(GeoService.DISTRICT_RUS, {
        params: httpParams,
      })
      .pipe(map((res: any) => res.results as DistrictRuResponse[]));
  }

  cityRuByName(name: string, params: { [key: string]: string }) {
    const httpParams = this._getHttpParams(name, params);
    if (!httpParams.has(GeoService.REGION_PARAM_NAME)) {
      return of([]);
    }
    return this.http
      .get(GeoService.CITY_RUS, {
        params: httpParams,
      })
      .pipe(map((res: any) => res.results as CityRuResponse[]));
  }

  localityRuByName(name: string, params: { [key: string]: string }) {
    const httpParams = this._getHttpParams(name, params);
    if (!httpParams.has(GeoService.REGION_PARAM_NAME)) {
      return of([]);
    }
    return this.http
      .get(GeoService.LOCALITY_RUS, {
        params: httpParams,
      })
      .pipe(map((res: any) => res.results as LocalityRuResponse[]));
  }

  streetRuByName(name: string, params: { [key: string]: string }) {
    const httpParams = this._getHttpParams(name, params);
    if (!httpParams.has(GeoService.REGION_PARAM_NAME)) {
      return of([]);
    }
    return this.http
      .get(GeoService.STREET_RUS, {
        params: httpParams,
      })
      .pipe(map((res: any) => res.results as StreetRuResponse[]));
  }

  getLocalityTypes() {
    this.http
      .get(GeoService.LOCALITY_TYPES)
      .pipe(map((res: any) => res.results as LocalityType[]))
      .subscribe({
        next: (data) => {
          this.localityTypes$.next(data);
        },
        error: () => {
          this.localityTypes$.next(null);
        },
      });
  }

  getStreetTypes() {
    this.http
      .get(GeoService.STREET_TYPES)
      .pipe(map((res: any) => res.results as StreetType[]))
      .subscribe({
        next: (data) => {
          this.streetTypes$.next(data);
        },
        error: () => {
          this.streetTypes$.next(null);
        },
      });
  }

  _getHttpParams(name: string, params: { [key: string]: string }) {
    if (!params) {
      params = {};
    }
    const fromObject = {
      ...params,
    };
    if (!!name) {
      fromObject['name'] = name;
    }
    const httpParams = new HttpParams({
      fromObject: fromObject,
    });
    return httpParams;
  }
}
