import { Component, OnDestroy, OnInit } from '@angular/core';
import { ClaimBaseComponent } from '../claim-base/claim-base.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { OtherType } from 'src/app/utils/utils';
import { ClaimsBatchResponse, KeywordRequest } from 'src/app/interfaces/api/claims.interface';
import { mergeMap } from 'rxjs';

@Component({
  selector: 'app-codeword-new',
  templateUrl: './codeword-new.component.html',
  styleUrls: ['./codeword-new.component.scss'],
})
export class CodewordNewComponent extends ClaimBaseComponent implements OnInit, OnDestroy {
  ngOnInit(): void {
    this.form = this.initForm();
  }

  initForm() {
    return new FormGroup({
      keyword: new FormControl(null, [Validators.required, Validators.minLength(6), Validators.maxLength(20)]),
    });
  }

  next() {
    if (this.form.invalid) {
      return;
    }
    const payload = this.form.value as KeywordRequest;
    payload.type = OtherType.keyword;
    this.loader.showLoader();
    this.claimsService
      .addClaimsBatch([payload])
      .pipe(
        mergeMap((res: ClaimsBatchResponse) => {
          this.claimBatchId = res.id;
          return this.documentService.claimsBatchDocumentsProcessing(res.id);
        })
      )
      .subscribe({
        next: (data) => {
          this.attachmentsToSign = data;
          this.cfService.goToStep(this.cfService.stepKey + 1);
          this.loader.hideLoader();
          this.cd.markForCheck();
        },
        error: () => {
          this.loader.hideLoader();
        },
      });
  }
}
