import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslationService } from '@localization';
import { PushNotificationResponse } from 'src/app/interfaces/api/push-notification.interface';
import { AuthService } from 'src/app/services/auth/auth.service';
import { PushNotificationService } from 'src/app/services/push-notification/push-notification.service';
import { NotificationStatus, NotificationTemplate } from 'src/app/utils/utils';

@Component({
  selector: 'ai-ui-push-notifications',
  templateUrl: './ai-ui-push-notifications.component.html',
  styleUrls: ['./ai-ui-push-notifications.component.scss'],
})
export class AiUiPushNotificationsComponent {
  @Output() navigate: EventEmitter<void> = new EventEmitter();
  isFirst = true;

  @HostListener('document:click', ['$event'])
  click(event) {
    if (this.isFirst) {
      this.isFirst = false;
      return;
    }
    if (!this.el.nativeElement.contains(event.target)) {
      this.navigate.emit();
    }
  }

  constructor(
    public auth: AuthService,
    public pn: PushNotificationService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private el: ElementRef
  ) {}

  navigateTo(notification: PushNotificationResponse) {
    this.navigate.emit();
    if (notification.status === NotificationStatus.sent) {
      this.pn.readById(notification.id);
      notification.status = NotificationStatus.read;
    }
    switch (notification.template) {
      case NotificationTemplate.fill_profile:
        this.router.navigate(['profile', 'edit']);
        break;
      case NotificationTemplate.passport_data_have_not_been_validated:
        this.router.navigate(['profile', 'edit']);
        break;
      case NotificationTemplate.annual_personal_data_confirmation:
        this.router.navigate(['profile', 'edit']);
        break;
      case NotificationTemplate.become_a_qualified_investor:
        this.router.navigate(['profile', 'qualifications']);
        break;
      case NotificationTemplate.apply_for_an_agreement:
        this.router.navigate(['claims', 'bo_origin_new']);
        break;
      case NotificationTemplate.register_an_agreement:
        window.open('https://www.nbrb.by/veb-portal-registracii-valyutnyh-dogovorov/', '_blank');
        break;
      case NotificationTemplate.agreement_has_been_activated:
        this.router.navigate(['claims', 'new']);
        break;
      case NotificationTemplate.verify_personal_data_via_aigenis_invest:
        this.router.navigate(['profile', 'settings']);
        break;

      case NotificationTemplate.documents_have_been_signed_by_manager:
        var claimId = this.getClaimIdByMessage(notification.body);
        this.router.navigate(['claims', claimId]);
        break;

      case NotificationTemplate.claim_has_been_rejected:
        var claimId = this.getClaimIdByMessage(notification.body);
        this.router.navigate(['claims', claimId]);
        break;
      case NotificationTemplate.new_message_in_chat:
        const chat_id = notification.meta?.chat_id;
        if (!!chat_id) {
          this.router.navigate(['profile', 'messages', chat_id]);
        }
        break;

      default:
        break;
    }
  }

  readAll() {
    this.pn.readAll();
    this.cd.markForCheck();
  }

  getClaimIdByMessage(text: string): number {
    let result = '';
    const secondPart = text.split('№')[1];
    let i = 0;
    while (!isNaN(Number(secondPart[i])) && i < secondPart.length) {
      result += secondPart[i];
      i++;
    }
    return Number(result);
  }
}
