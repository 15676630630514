<div class="container">
  <div class="close" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </div>
  <div class="title mb-2">
    <h3>{{ 'CHANGE_LOGIN' | translate }}</h3>
  </div>
    <form (ngSubmit)="submit()" [formGroup]="form">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'FIELD:LOGIN' | translate }}</mat-label>
      <input formControlName="username" matInput required />
      <mat-error *ngIf="form.get('username').errors?.required">
        {{'ERROR:REQUIRED' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'FIELD:CURRENT_PASSWORD' | translate }}</mat-label>
      <input [type]="visibility.type"
        formControlName="password"
        matInput
        required />
      <ai-ui-toggle-visibility #visibility
        matSuffix></ai-ui-toggle-visibility>
      <mat-error *ngIf="form.get('password').errors?.required">
        {{'ERROR:REQUIRED' | translate }}
      </mat-error>
    </mat-form-field>
    <button [disabled]="form.invalid" class="btn btn-outline-dark filled"  mat-button type="submit">
      {{ 'CHANGE' | translate }}
    </button>
  </form>
</div>