import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiUrlWithPrefix } from '@env';
import { BehaviorSubject, map } from 'rxjs';
import { ExchangeResponse } from 'src/app/interfaces/api/exchange.interface';
import { LoaderService } from '../loader/loader.service';

@Injectable({
  providedIn: 'root',
})
export class ExchangeService {
  static readonly EXCHANGES = `${apiUrlWithPrefix}/exchange/`;

  public exchanges$: BehaviorSubject<ExchangeResponse[]> = new BehaviorSubject<ExchangeResponse[]>(null);

  constructor(private http: HttpClient, private loader: LoaderService) {}

  exchages() {
    this.loader.showLoader();
    return this.http
      .get(ExchangeService.EXCHANGES)
      .pipe(
        map((res: any) => {
          return res.results as ExchangeResponse[];
        })
      )
      .subscribe({
        next: (data) => {
          this.exchanges$.next(data);
          this.loader.hideLoader();
        },
        error: () => {
          this.loader.hideLoader();
        },
      });
  }
}
