import { animate, state, style, transition, trigger } from '@angular/animations';

export const animationOptions = [
  trigger('open', [
    state(
      '*',
      style({
        opacity: 0,
      })
    ),
    state(
      'opened',
      style({
        opacity: 1,
      })
    ),
    transition('* => opened', [animate('0.5s')]),
  ]),
];
