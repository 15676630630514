import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[russianLetters]',
})
export class RussianLettersDirective {
  pattern: RegExp = new RegExp('[^A-Za-z0-9]+$');

  element: HTMLInputElement;

  private _allowedKeys: string[] = [
    'Backspace',
    'Tab',
    'Enter',
    'Escape',
    'ArrowLeft',
    'ArrowRight',
    'Delete',
    'PrintScreen',
  ];

  constructor(private readonly control: NgControl, element: ElementRef) {
    this.element = element.nativeElement;
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this._allowedKeys.includes(event.key)) {
      return true;
    }
    return this.pattern.test(event.key);
  }

  @HostListener('paste', ['$event'])
  onPaste(event: any) {
    return false;
  }
}
