import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AmountDirective } from './amount-directive/amount.directive';
import { UppercaseDirective } from './uppercase-directive/uppercase.directive';
import { DateDirective } from './date-directive/date.directive';
import { TimeDirective } from './time-directive/time.directive';
import { EnglishLettersDirective } from './english-letters/english-letters.directive';
import { RussianLettersDirective } from './russian-letters/russian-letters.directive';
import { IntegerDirective } from './integer-directive/integer.directive';

const DIRECTIVES = [
  IntegerDirective,
  AmountDirective,
  UppercaseDirective,
  DateDirective,
  TimeDirective,
  EnglishLettersDirective,
  RussianLettersDirective,
];

@NgModule({
  declarations: [...DIRECTIVES],
  exports: [...DIRECTIVES],
  imports: [CommonModule],
})
export class SharedDirectivesModule {}
