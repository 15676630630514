import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ClaimsBatchResponse, TransferMoneyRequest } from 'src/app/interfaces/api/claims.interface';
import { animationOptions } from 'src/app/utils/animation';
import { AreaType, BsType, Currency, TransferMoneyType, getFormatDate } from 'src/app/utils/utils';
import { ClaimBaseComponent } from '../claim-base/claim-base.component';
import { mergeMap } from 'rxjs';
import { Attachment } from 'src/app/interfaces/api/attachment.interface';
import { ExchangeResponse } from 'src/app/interfaces/api/exchange.interface';
@Component({
  selector: 'app-bo-transfer-money-new',
  templateUrl: './bo-transfer-money-new.component.html',
  styleUrls: ['./bo-transfer-money-new.component.scss'],
  animations: animationOptions,
})
export class BoTransferMoneyNewComponent extends ClaimBaseComponent implements OnInit, OnDestroy {
  transferTypes = Array.from(new Set(Object.keys(TransferMoneyType)));

  exchanges: ExchangeResponse[] = null;

  theFirstConfirmation: FormControl = new FormControl(true, [Validators.required]);

  ngOnInit(): void {
    this.form = this.initForm();
    this.exchangeService.exchanges$.subscribe((data) => {
      const hide_sstock = !this.auth.brokerageService$?.value?.created_by_claim?.trade_on_spb_stock;
      this.exchanges = data?.filter((n) => n.code !== (hide_sstock ? AreaType.sstock : undefined));
    });
  }

  initForm() {
    return new FormGroup({
      transfer_currency: new FormControl(Currency.RUB, Validators.required),
      transfer_amount: new FormControl(null, Validators.required),
      transfer_all_money: new FormControl(false),
      transfer_type: new FormControl(TransferMoneyType.exchange, Validators.required),
      transfer_from_agreement: new FormControl(
        { value: this.auth.brokerageService$.value.id, disabled: true },
        Validators.required
      ),
      transfer_from_exchange: new FormControl(null, Validators.required),
      transfer_to_agreement: new FormControl(
        { value: this.auth.brokerageService$.value.id, disabled: true },
        Validators.required
      ),
      transfer_to_exchange: new FormControl(null, Validators.required),
      purpose_of_payment: new FormControl(null),
    });
  }

  next() {
    if (this.form.invalid) {
      return;
    }
    const payload = this.form.getRawValue() as TransferMoneyRequest;
    payload.type = BsType.money_transfer;
    this.loader.showLoader();
    this.claimsService
      .addClaimsBatch([payload])
      .pipe(
        mergeMap((res: ClaimsBatchResponse) => {
          this.claimBatchId = res.id;
          return this.documentService.claimsBatchDocumentsProcessing(res.id);
        })
      )
      .subscribe({
        next: (data) => {
          this.attachmentsToSign = data;
          this.cfService.goToStep(this.cfService.stepKey + 1);
          this.loader.hideLoader();
          this.cd.markForCheck();
        },
        error: () => {
          this.loader.hideLoader();
        },
      });
  }

  onCheckboxChanged(event: MatCheckboxChange) {
    if (event.checked) {
      this.form.controls.transfer_amount.disable();
      this.form.patchValue({
        transfer_amount: null,
      });
      this.form.controls.transfer_amount.clearValidators();
    } else {
      this.form.controls.transfer_amount.setValidators(Validators.required);
      this.form.controls.transfer_amount.enable();
    }
    this.form.controls.transfer_amount.updateValueAndValidity({ emitEvent: false });
  }
}
