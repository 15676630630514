import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiUrlWithPrefix } from '@env';
import { map } from 'rxjs';
import { SecurityResponse } from 'src/app/interfaces/api/security.interface';

@Injectable({
  providedIn: 'root',
})
export class SecurityService {
  static readonly SECURITIES = `${apiUrlWithPrefix}/securities/`;

  constructor(private http: HttpClient) {}

  findByParams(httpParams: HttpParams) {
    return this.http
      .get(SecurityService.SECURITIES, {
        params: httpParams,
      })
      .pipe(map((res: any) => res.results as SecurityResponse[]));
  }
}
