import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { TranslationModule } from '@localization';
import { NgxMaskModule } from 'ngx-mask';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { MaterialModule } from 'src/app/material.module';
import { ClaimGuard } from 'src/app/services/claims/claim.guard';
import { SharedModule } from 'src/app/shared/shared-components/shared.module';
import { SharedDirectivesModule } from 'src/app/shared/shared-directives/shared-directives.module';
import { SharedPipesModule } from 'src/app/shared/shared-pipes/shared-pipes.module';
import { AppealManualAssignmentComponent } from './appeal-manual-assignment/appeal-manual-assignment.component';
import { BoCurrencyInstrumentsNewComponent } from './bo-currency-instruments-new/bo-currency-instruments-new.component';
import { BoOriginNewComponent } from './bo-origin-new/bo-origin-new.component';
import { ClaimInfoComponent } from './claim-info/claim-info.component';
import { ClaimSelectionComponent } from './claim-selection/claim-selection.component';
import { ClaimComponent } from './claim.component';
import { ClaimsComponent } from './claims-list/claims.component';
import { BoManualAssignmentComponent } from './bo-manual-assignment/bo-manual-assignment.component';
import { BoTerminationNewComponent } from './bo-termination-new/bo-termination-new.component';
import { BoWithdrawMoneyNewComponent } from './bo-withdraw-money-new/bo-withdraw-money-new.component';
import { BoWithdrawAreaMoneyNewComponent } from './bo-withdraw-area-money-new/bo-withdraw-area-money-new.component';
import { BoTransferMoneyNewComponent } from './bo-transfer-money-new/bo-transfer-money-new.component';
import { DepoManualAssignmentComponent } from './depo-manual-assignment/depo-manual-assignment.component';
import { DepoOriginNewComponent } from './depo-origin-new/depo-origin-new.component';
import { BoNrdPapersDealNewComponent } from './bo-nrd-papers-deal-new/bo-nrd-papers-deal-new.component';
import { DepoReferenceComponent } from './depo-reference/depo-reference.component';
import { DepoTransactionReferenceComponent } from './depo-transaction-reference/depo-transaction-reference.component';
import { DepoTransactionReferenceRegistryComponent } from './depo-transaction-reference-registry/depo-transaction-reference-registry.component';
import { DepoTransactionTransferReferenceComponent } from './depo-transaction-transfer-reference/depo-transaction-transfer-reference.component';
import { ChangePersonalDataComponent } from './change-personal-data/change-personal-data.component';
import { QualifiedInvestorNewComponent } from './qualified-investor-new/qualified-investor-new.component';
import { CodewordNewComponent } from './codeword-new/codeword-new.component';
import { BoAdditionalConsentNewComponent } from './bo-additional-consent-new/bo-additional-consent-new.component';

const routes: Routes = [
  {
    path: '',
    component: ClaimComponent,
    children: [
      {
        path: '',
        component: ClaimsComponent,
        pathMatch: 'full',
      },
      {
        path: 'new',
        component: ClaimSelectionComponent,
        canActivate: [ClaimGuard],
      },
      // {
      //   path: 'appeal_manual_assignment_new',
      //   component: AppealManualAssignmentComponent,
      //   canActivate: [ClaimGuard],
      // },
      {
        path: 'qualified_investor_new',
        component: QualifiedInvestorNewComponent,
        canActivate: [ClaimGuard],
      },
      {
        path: 'keyword_new',
        component: CodewordNewComponent,
        canActivate: [ClaimGuard],
      },
      {
        path: 'edit_profile',
        component: ChangePersonalDataComponent,
      },
      {
        path: 'bo_origin_new',
        component: BoOriginNewComponent,
        canActivate: [ClaimGuard],
      },
      {
        path: 'depo_origin_new',
        component: DepoOriginNewComponent,
        canActivate: [ClaimGuard],
      },
      {
        path: 'depo_reference_new',
        component: DepoReferenceComponent,
        canActivate: [ClaimGuard],
      },
      {
        path: 'depo_transaction_reference_registry_new',
        component: DepoTransactionReferenceRegistryComponent,
        canActivate: [ClaimGuard],
      },
      {
        path: 'depo_transaction_reference_new',
        component: DepoTransactionReferenceComponent,
        canActivate: [ClaimGuard],
      },
      {
        path: 'depo_transaction_transfer_reference_new',
        component: DepoTransactionTransferReferenceComponent,
        canActivate: [ClaimGuard],
      },
      {
        path: 'depo_manual_assignment_new',
        component: DepoManualAssignmentComponent,
        canActivate: [ClaimGuard],
      },
      {
        path: 'bo_nrd_papers_deal_new',
        component: BoNrdPapersDealNewComponent,
        canActivate: [ClaimGuard],
      },
      {
        path: 'bo_currency_instruments_new',
        component: BoCurrencyInstrumentsNewComponent,
        canActivate: [ClaimGuard],
      },
      {
        path: 'bo_manual_assignment_new',
        component: BoManualAssignmentComponent,
        canActivate: [ClaimGuard],
      },
      {
        path: 'bo_additional_consent_new',
        component: BoAdditionalConsentNewComponent,
        canActivate: [ClaimGuard],
      },
      {
        path: 'bo_termination_new',
        component: BoTerminationNewComponent,
        canActivate: [ClaimGuard],
      },
      {
        path: 'bo_withdraw_money_new',
        component: BoWithdrawMoneyNewComponent,
        canActivate: [ClaimGuard],
      },
      {
        path: 'bo_withdraw_area_money_new',
        component: BoWithdrawAreaMoneyNewComponent,
        canActivate: [ClaimGuard],
      },
      {
        path: 'bo_transfer_money_new',
        component: BoTransferMoneyNewComponent,
        canActivate: [ClaimGuard],
      },
      {
        path: ':id',
        component: ClaimInfoComponent,
      },
    ],
  },
];

const ClaimRouterModule: ModuleWithProviders<RouterModule> = RouterModule.forChild(routes);

@NgModule({
  declarations: [
    ClaimComponent,
    BoOriginNewComponent,
    ClaimsComponent,
    ClaimInfoComponent,
    ClaimSelectionComponent,
    BoCurrencyInstrumentsNewComponent,
    AppealManualAssignmentComponent,
    BoManualAssignmentComponent,
    BoAdditionalConsentNewComponent,
    BoTerminationNewComponent,
    BoWithdrawMoneyNewComponent,
    BoWithdrawAreaMoneyNewComponent,
    BoTransferMoneyNewComponent,
    DepoManualAssignmentComponent,
    DepoOriginNewComponent,
    BoNrdPapersDealNewComponent,
    DepoReferenceComponent,
    DepoTransactionReferenceComponent,
    DepoTransactionReferenceRegistryComponent,
    DepoTransactionTransferReferenceComponent,
    ChangePersonalDataComponent,
    QualifiedInvestorNewComponent,
    CodewordNewComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    TranslationModule,
    NgxMatIntlTelInputComponent,
    SharedPipesModule,
    SharedModule,
    NgxMaskModule,
    SharedDirectivesModule,
    ClaimRouterModule,
  ],
  exports: [ChangePersonalDataComponent],
  entryComponents: [ChangePersonalDataComponent],
  providers: [],
})
export class ClaimModule {}
