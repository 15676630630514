<div class="form_container" [@open]="'opened'">
  <div class="header_logo">
    <a href="https://aigenis.ru/" target="_blank">
      <img src="./assets/logos/aigenis_dark.svg" />
    </a>
  </div>
  <div class="form__title">
    <span>{{ 'PASSWORD_RECOVERY' | translate }}</span>
  </div>
  <form [formGroup]="recoveryForm" (ngSubmit)="submit()">
    <div class="hint">
      <p>{{ 'PASSWORD_RECOVERY_INSTRUCTION' | translate }}</p>
    </div>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'FIELD:EMAIL' | translate }}</mat-label>
      <input formControlName="email" matInput required />
      <mat-error *ngIf="recoveryForm.get('email').errors?.required">
        {{ 'ERROR:REQUIRED' | translate }}
      </mat-error>
    </mat-form-field>
    <div class="d-flex justify-content-center mb-5">
      <button [disabled]="!recoveryForm.valid" mat-flat-button type="submit" class="signin-btn">
        {{ 'PASSWORD_RECOVERY_GET_INSTRUCTION' | translate }}
      </button>
    </div>
  </form>
  <button (click)="back()"
    mat-icon-button
    class="sign-terminate"
    type="button">
    <mat-icon>keyboard_arrow_left</mat-icon>
  </button>
</div>
<div class="sign-illustration"></div>