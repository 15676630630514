<div class="container-fluid"
  [@open]="'opened'">
  <h1 class="bg-white m-0 py-4 px-3">{{ 'BROKING:SECURITIES_PORTFOLIO_TITLE' | translate }}</h1>
  <ng-container *ngIf="auth.userProfile$ | async as user">
    <div class="row">
      <div class="col-12">
        <ng-container *ngIf="user?.status==='pii_not_filled';else bind_aigenis_account">
          <ai-ui-restriction [showFaq]="false"></ai-ui-restriction>
        </ng-container>
        <ng-template #bind_aigenis_account>
          <ng-container *ngIf="user?.status === 'pii_validating' && user?.citizenship==='BY' && !user?.user_aigenis_id; else pii_validating">
            <ai-ui-restriction [type]="'waitingForAigenisAccountBinding'"
              [showFaq]="false"></ai-ui-restriction>
          </ng-container>
        </ng-template>
        <ng-template #pii_validating>
          <ng-container *ngIf="user?.status==='pii_validating';else create_brokerage_service">
            <ai-ui-restriction [type]="'waitingForApproval'"
              [showFaq]="false"></ai-ui-restriction>
          </ng-container>
        </ng-template>
        <ng-template #create_brokerage_service>
          <ng-container
            *ngIf="brokerage===undefined || brokerage?.status==='finished' || brokerage?.status==='suspended' || brokerage?.status==='incorrect';else waiting_brokerage_service">
            <ai-ui-restriction [type]="'createBrokerageService'"
              [showFaq]="false"></ai-ui-restriction>
          </ng-container>
        </ng-template>
        <ng-template #waiting_brokerage_service>
          <ng-container *ngIf="brokerage?.created_by_claim?.status==='new';else agreement_new">
            <ai-ui-restriction [type]="'brokerageServiceApprovalNew'"
              [showFaq]="false"></ai-ui-restriction>
          </ng-container>
        </ng-template>
        <ng-template #agreement_new>
          <ng-container *ngIf="brokerage?.status==='new';else brokerage_service_in_processing">
            <ai-ui-restriction [type]="'brokerageAgreementNew'"
              [showFaq]="false"></ai-ui-restriction>
          </ng-container>
        </ng-template>
        <ng-template #brokerage_service_in_processing>
          <ng-container
            *ngIf="user?.docs_signed===false || brokerage?.created_by_claim?.status==='in_progress';else default">
            <ai-ui-restriction [type]="'waitingForBrokerageServiceApproval'"
              [showFaq]="false"></ai-ui-restriction>
          </ng-container>
        </ng-template>
      </div>
      <ng-template #default>
        <ng-container *ngIf="portfolios?.length && investments?.length">
          <div class="all-portfolio">
            <div class="subtitle col-xl-3 col-lg-4 col-md-3 col-sm-3 col-12">
              <h5>{{ 'BROKING:ALL_PORTFOLIO' | translate }}</h5>
            </div>
            <div class="toggle-group col-xl-2 col-lg-3 col-md-3 col-sm-3 col-12"
              (click)="toggleCurrency($event)">
              <div id="RUB"
                class="toggle"
                [ngClass]="{ 'checked' : currencyToShow === 'RUB' }">
                ₽
              </div>
              <div id="USD"
                class="toggle"
                [ngClass]="{ 'checked' : currencyToShow === 'USD' }">
                $
              </div>
              <div id="EUR"
                class="toggle"
                [ngClass]="{ 'checked' : currencyToShow === 'EUR' }">
                €
              </div>
            </div>
            <div class="amount col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
              <div>
                <span class="text-black">{{ portfolioMoneyCaption | number: '1.2-2' }} </span>
                <span class="text-black">{{ currenciesDict[currencyToShow] }}</span>
              </div>
              <div class="hint">
                <p>{{ 'BROKING:EXCHANGE_RATE_ON' | translate: [today | date: 'dd.MM.yyyy'] }}</p>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="broking col-12">
          <div class="chapter mb-3">
            <div class="left-side">
              <div class="bo-number">
                <span>{{ 'BROKING:CONTRACT_INFO' | translate : [brokerage?.number] }}</span>
              </div>
              <div class="bo-status">
                <mat-icon class="status"
                  [ngClass]="brokerage?.status">{{ brokerage?.status | claimStatusIcon }}</mat-icon>
                <span>{{ brokerage?.status | translate }}</span>
              </div>
            </div>
            <div class="action text-align-right">
              <button class="add-btn"
                mat-button
                type="button"
                [matMenuTriggerFor]="claimTypes">
                <span>{{ 'ADD_ORDER' | translate }}</span>
                <img src="./assets/logos/add_icon.svg"
                alt="logo">
              </button>
              <mat-menu #claimTypes="matMenu">
                <button [matTooltip]="type.name | translate"
                  mat-menu-item
                  [routerLink]="type.routerLink"
                  *ngFor="let type of bsTypes">
                  {{ type.name | translate }}
                </button>
              </mat-menu>
              <button class="menu"
                mat-icon-button
                [matMenuTriggerFor]="menu">
                <img src="./assets/logos/list_icon.svg"
                alt="logo">
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item
                  [routerLink]="['/claims', brokerage?.created_by_claim?.id]">
                  <span class="text-black">{{ 'DOCUMENTS' | translate }}</span>
                </button>
                <!-- <button mat-menu-item disabled>
                <span class="text-black">{{ 'REPORTS' | translate }}</span>
              </button> -->
                <button mat-menu-item
                  routerLink="/claims"
                  [queryParams]="{ type: 'broker_service' }">
                  <span class="text-black">{{ 'CLAIMS' | translate }}</span>
                </button>
              </mat-menu>
            </div>
          </div>
          <div class="chapter row">
            <ng-container *ngIf="portfolios?.length && investments?.length;else missing">
              <div class="portfolio">
                <div class="portfolio-info">
                  <div class="portfolio-estimate">
                    <div class="label">
                      <span>
                        {{ 'BROKING:PORTFOLIO_ESTIMATE' | translate }}
                      </span>
                    </div>
                    <div class="amount">
                      <span>{{ portfolioMoney | number: '1.2-2' }} </span>
                      <span>{{ currenciesDict['RUB'] }}</span>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="portfolio-info">
                    <div class="portfolio-estimate small">
                      <div class="label">
                        <span>
                          {{ 'BROKING:OTHER_COMISSION' | translate }}
                        </span>
                      </div>
                      <div class="amount small">
                        <span>{{ portfolioService.calculateComission(investments) | number: '1.2-2' }} </span>
                        <span>{{ currenciesDict['RUB'] }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="portfolio-info">
                    <div class="portfolio-estimate small">
                      <div class="label">
                        <span>
                          {{ 'BROKING:AIGENIS_COMISSION' | translate }}
                        </span>
                      </div>
                      <div class="amount small">
                        <span>{{ portfolioService.calculateAigenisComission(investments) | number: '1.2-2' }} </span>
                        <span>{{ currenciesDict['RUB'] }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="hint">
                <p>{{ 'BROKING:ACTUAL_ON' | translate: [twoDaysAgo | date: 'dd.MM.yyyy'] }}</p>
              </div>
            </ng-container>
            <ng-template #missing>
              <div class="col missing">
                <span>
                  {{ 'BROKING:MISSING' | translate }}
                </span>
              </div>
            </ng-template>
          </div>
          <div class="bottom-content">
            <div class="chapter">
              <div class="col">
                <div class="label">
                  <span>{{ 'BROKING:RISK_STATUS_LABEL' | translate }}</span>
                </div>
                <div class="value">
                  <span>{{ brokerage?.risk_level | translate }}</span>
                </div>
              </div>
            </div>
            <div class="chapter replenish">
              <button mat-button
                class="filled refill-btn"
                type="button"
                (click)="refillAccount()">
                {{ 'PORTFOLIO:REPLENISH' | translate }}
              </button>
            </div>
          </div>
        </div>
        <div class="hint mt-3 mb-3">
          <p>{{ 'BROKER:HINT' | translate }}</p>
        </div>
        <ng-container *ngIf="portfolios?.length && investments?.length">
          <!-- <div class="stocks-info">
          <mat-accordion multi>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span>{{ 'BROKING:STOCK_MARKET' | translate }}</span>
                </mat-panel-title>
                <mat-panel-description>
                  <span>
                    {{ mstock?.money ? (mstock?.money | number: '1.2-2') : 0 }}
                  </span>
                  <span class="currency">
                    {{ currenciesDict['RUB'] }}
                  </span>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="mark">
                <span>{{ 'BROKING:STOCK_MARKET_LONG_MONEY' | translate }}</span>
                <div class="mark-value">
                  <span>{{ mstock?.money_long ? (mstock?.money_long | number: '1.2-2') : 0 }}</span>
                  <span class="currency">
                    {{ currenciesDict['RUB'] }}
                  </span>
                </div>
              </div>
              <div class="mark">
                <span>{{ 'BROKING:STOCK_MARKET_SHORT_MONEY' | translate }}</span>
                <div class="mark-value">
                  <span>{{ mstock?.money_short ? (mstock?.money_short | number: '1.2-2') : 0 }}</span>
                  <span class="currency">
                    {{ currenciesDict['RUB'] }}
                  </span>
                </div>
              </div>
              <div class="mark">
                <span>{{ 'BROKING:STOCK_MARKET_FREE_MONEY' | translate }}</span>
                <div class="mark-value">
                  <span>{{ mstock?.free_money_sur ? (mstock?.free_money_sur | number: '1.2-2') : 0 }}</span>
                  <span class="currency">
                    {{ currenciesDict['RUB'] }}
                  </span>
                </div>
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel hideToggle disabled>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span>{{ 'BROKING:CURRENCY_MARKET' | translate }}</span>
                </mat-panel-title>
                <mat-panel-description>
                  <span>{{ mcur?.money ? (mcur?.money | number: '1.2-2') : 0 }}</span>
                  <span class="currency">
                    {{ currenciesDict['RUB'] }}
                  </span>
                </mat-panel-description>
              </mat-expansion-panel-header>
            </mat-expansion-panel>
            <mat-expansion-panel hideToggle disabled>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <span>{{ 'BROKING:OUTSIDE_MARKET' | translate }}</span>
                  <i class="fas fa-band-aid"></i>
                </mat-panel-title>
                <mat-panel-description>
                  <span>{{ offe?.money ? (offe?.money | number: '1.2-2') : 0 }}</span>
                  <span class="currency">
                    {{ currenciesDict['RUB'] }}
                  </span> </mat-panel-description>
              </mat-expansion-panel-header>
            </mat-expansion-panel>
          </mat-accordion>
        </div> -->
          <div class="details">
            <div class="details__title">
              <div class="left-side">
                <h4>{{ 'BROKING:PORTFOLIO_COMPOSITION' | translate }}</h4>
                <div class="hint">
                  <p>{{ 'BROKING:CONTRACT_INFO' | translate : [brokerage?.number] }}</p>
                </div>
              </div>
              <div class="right-side">
                <div class="toggle-group col-xl-2 col-lg-3 col-md-3 col-sm-3 col-12"
                  (click)="toggleTab($event)">
                  <div id="stocks"
                    class="toggle tab small"
                    [ngClass]="{ 'tab-checked' : tabToShow === 'stocks' }">
                    {{ 'BROKING:ASSETS_TAB' | translate }}
                  </div>
                  <!-- <div id="venues" class="toggle tab small" [ngClass]="{ 'checked' : tabToShow === 'venues' }">
                  {{ 'BROKING:VENUES_TAB' | translate }}
                </div>
                <div id="currencies" class="toggle tab small" [ngClass]="{ 'checked' : tabToShow === 'currencies' }">
                  {{ 'BROKING:CURRENCIES_TAB' | translate }}
                </div> -->
                </div>
              </div>
            </div>
            <ng-container *ngIf="tabToShow === 'stocks'">
              <div class="details__content">
                <div class="dashboard"
                  *ngIf="assetsData">
                  <ai-ui-pie-chart [data]="assetsData"></ai-ui-pie-chart>
                </div>
                <div class="info"
                  [@open]="'opened'">
                  <div class="info__item"
                    *ngFor="let data of assetsData">
                    <div class="col-1 label"
                      [ngStyle]="{ 'background-color' : data.color }">

                    </div>
                    <div class="col-2">
                      <span class="text-field">{{ data.percent }}%</span>
                    </div>
                    <div class="col-5">
                      <span class="text-field">{{ data.name }}</span>
                    </div>
                    <div class="col-4">
                      <span class="text-field">{{ data?.money ? (data?.money | number: '1.2-2') : 0 }} </span>
                      <span class="text-field">
                        {{ currenciesDict['RUB'] }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="tabToShow === 'venues'">
              <div class="details__content">
                <div class="dashboard"
                  *ngIf="areaData">
                  <ai-ui-pie-chart [index]="2"
                    [data]="areaData"></ai-ui-pie-chart>
                </div>
                <div class="info"
                  [@open]="'opened'">
                  <div class="info__item"
                    *ngFor="let data of areaData">
                    <div class="col-1 label"
                      [ngStyle]="{ 'background-color' : data.color }">

                    </div>
                    <div class="col-2">
                      <span>{{ data?.percent }}%</span>
                    </div>
                    <div class="col-5">
                      <span class="text-field">{{ data.name }}</span>
                    </div>
                    <div class="col-4">
                      <span class="text-field">{{ data?.money ? (data?.money | number: '1.2-2') : 0 }} </span>
                      <span class="text-field">
                        {{ currenciesDict['RUB'] }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="tabToShow === 'currencies'">
              <div class="details__content">
                <div class="dashboard"
                  *ngIf="currenciesData">
                  <ai-ui-pie-chart [index]="3"
                    [data]="currenciesData"></ai-ui-pie-chart>
                </div>
                <div class="info"
                  [@open]="'opened'">
                  <div class="info__item"
                    *ngFor="let data of currenciesData">
                    <div class="col-1 label"
                      [ngStyle]="{ 'background-color' : data.color }">

                    </div>
                    <div class="col-2">
                      <span>{{ data.percent }}%</span>
                    </div>
                    <div class="col-5">
                      <span class="text-field">{{ data.name }}</span>
                    </div>
                    <div class="col-4">
                      <span class="text-field">{{ data?.money ? (data?.money | number: '1.2-2') : 0 }} </span>
                      <span class="text-field">
                        {{ currenciesDict['RUB'] }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          <div *ngIf="portfolios"
            class="security-definitions">
            <mat-tab-group mat-stretch-tabs="false">
              <mat-tab label="{{ 'BROKING:STOCKS_TAB' | translate }}">
                <ng-container *ngIf="stocks.length; else notFound">
                  <div class="content">
                    <div class="row">
                      <ng-container *ngIf="stocks">
                        <div class="col-12">
                          <table class="custom"
                            mat-table
                            [dataSource]="stocks">
                            <ng-container matColumnDef="SECURITY_NAME">
                              <th mat-header-cell
                                *matHeaderCellDef>{{ 'BROKING:SECURITY_NAME' | translate }}</th>
                              <td mat-cell
                                class="py-3"
                                *matCellDef="let portfolio">
                                {{ portfolio.security.full_name }}
                                <div class="hint">
                                  <p>{{ 'BROKING:ISSUER_NAME' | translate }}:&nbsp; {{
                                    portfolio.security.issuer.full_name
                                    }}</p>
                                </div>
                                <div class="hint">
                                  <p>{{ 'BROKING:CLIENT_CODE' | translate }}:&nbsp; {{ portfolio.client_code }}</p>
                                </div>
                              </td>
                            </ng-container>
                            <ng-container matColumnDef="PLACE_OF_STORAGE">
                              <th mat-header-cell
                                *matHeaderCellDef>{{ 'BROKING:PLACE_OF_STORAGE' | translate }}</th>
                              <td mat-cell
                                class="py-3"
                                *matCellDef="let portfolio">
                                <ng-container *ngIf="isSmallScreen">
                                  {{ 'BROKING:PLACE_OF_STORAGE' | translate }}:&nbsp;
                                </ng-container>
                                {{ portfolio.place_of_storage }}
                              </td>
                            </ng-container>
                            <ng-container matColumnDef="AMOUNT">
                              <th mat-header-cell
                                *matHeaderCellDef>{{ 'BROKING:AMOUNT' | translate }}</th>
                              <td mat-cell
                                *matCellDef="let portfolio">
                                <ng-container *ngIf="isSmallScreen">
                                  {{ 'BROKING:AMOUNT' | translate }}:&nbsp;
                                </ng-container>
                                {{ portfolio.amount | number: '1.2-2' }}
                              </td>
                            </ng-container>
                            <ng-container matColumnDef="BALANCE_SUM">
                              <th mat-header-cell
                                *matHeaderCellDef>{{ 'BROKING:BALANCE_SUM' | translate }}</th>
                              <td mat-cell
                                *matCellDef="let portfolio">
                                <ng-container *ngIf="isSmallScreen">
                                  {{ 'BROKING:BALANCE_SUM' | translate }}:&nbsp;
                                </ng-container>
                                {{ portfolio.bal_summa | number: '1.2-2' }}
                              </td>
                            </ng-container>
                            <ng-container matColumnDef="NKD">
                              <th mat-header-cell
                                *matHeaderCellDef>{{ 'BROKING:NKD' | translate }}</th>
                              <td mat-cell
                                *matCellDef="let portfolio">
                                <ng-container *ngIf="isSmallScreen">
                                  {{ 'BROKING:NKD' | translate }}:&nbsp;
                                </ng-container>
                                {{ portfolioService.calculateNkdBySecurity(portfolio.security.id, investments) |
                                number:
                                '1.2-2' }}
                              </td>
                            </ng-container>
                            <ng-container matColumnDef="MARKET_PRICE_RUB">
                              <th mat-header-cell
                                *matHeaderCellDef>{{ 'BROKING:MARKET_PRICE_RUB' | translate }}</th>
                              <td mat-cell
                                *matCellDef="let portfolio">
                                <ng-container *ngIf="isSmallScreen">
                                  {{ 'BROKING:MARKET_PRICE_RUB' | translate }}:&nbsp;
                                </ng-container>
                                {{ portfolio.rub_rate | number: '1.2-2' }}
                              </td>
                            </ng-container>

                            <tr mat-header-row
                              *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row
                              *matRowDef="let portfolio; columns: displayedColumns;">
                            </tr>
                          </table>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </ng-container>
                <ng-template #notFound>
                  <div class="not-found">
                    <h4>{{ 'BROKING:STOCKS_NOT_FOUND' | translate }}</h4>
                  </div>
                </ng-template>
              </mat-tab>
              <mat-tab label="{{ 'BROKING:BONDS_TAB' | translate }}">
                <ng-container *ngIf="bonds">
                  <div class="content">
                    <div class="row">
                      <ng-container *ngIf="bonds">
                        <div class="col-12">
                          <table class="custom"
                            mat-table
                            [dataSource]="bonds">
                            <ng-container matColumnDef="SECURITY_NAME">
                              <th mat-header-cell
                                *matHeaderCellDef>{{ 'BROKING:SECURITY_NAME' | translate }}</th>
                              <td mat-cell
                                class="py-3"
                                *matCellDef="let portfolio">
                                {{ portfolio.security.full_name }}
                                <div class="hint">
                                  <p>{{ 'BROKING:ISSUER_NAME' | translate }}:&nbsp; {{
                                    portfolio.security.issuer.full_name
                                    }}</p>
                                </div>
                                <div class="hint">
                                  <p>{{ 'BROKING:CLIENT_CODE' | translate }}:&nbsp; {{ portfolio.client_code }}</p>
                                </div>
                              </td>
                            </ng-container>
                            <ng-container matColumnDef="PLACE_OF_STORAGE">
                              <th mat-header-cell
                                *matHeaderCellDef>{{ 'BROKING:PLACE_OF_STORAGE' | translate }}</th>
                              <td mat-cell
                                class="py-3"
                                *matCellDef="let portfolio">
                                <ng-container *ngIf="isSmallScreen">
                                  {{ 'BROKING:PLACE_OF_STORAGE' | translate }}:&nbsp;
                                </ng-container>
                                {{ portfolio.place_of_storage }}
                              </td>
                            </ng-container>
                            <ng-container matColumnDef="AMOUNT">
                              <th mat-header-cell
                                *matHeaderCellDef>{{ 'BROKING:AMOUNT' | translate }}</th>
                              <td mat-cell
                                *matCellDef="let portfolio">
                                <ng-container *ngIf="isSmallScreen">
                                  {{ 'BROKING:AMOUNT' | translate }}:&nbsp;
                                </ng-container>
                                {{ portfolio.amount | number: '1.2-2' }}
                              </td>
                            </ng-container>
                            <ng-container matColumnDef="BALANCE_SUM">
                              <th mat-header-cell
                                *matHeaderCellDef>{{ 'BROKING:BALANCE_SUM' | translate }}</th>
                              <td mat-cell
                                *matCellDef="let portfolio">
                                <ng-container *ngIf="isSmallScreen">
                                  {{ 'BROKING:BALANCE_SUM' | translate }}:&nbsp;
                                </ng-container>
                                {{ portfolio.bal_summa | number: '1.2-2' }}
                              </td>
                            </ng-container>
                            <ng-container matColumnDef="NKD">
                              <th mat-header-cell
                                *matHeaderCellDef>{{ 'BROKING:NKD' | translate }}</th>
                              <td mat-cell
                                *matCellDef="let portfolio">
                                <ng-container *ngIf="isSmallScreen">
                                  {{ 'BROKING:NKD' | translate }}:&nbsp;
                                </ng-container>
                                {{ portfolioService.calculateNkdBySecurity(portfolio.security.id, investments) |
                                number:
                                '1.2-2' }}
                              </td>
                            </ng-container>
                            <ng-container matColumnDef="MARKET_PRICE_RUB">
                              <th mat-header-cell
                                *matHeaderCellDef>{{ 'BROKING:MARKET_PRICE_RUB' | translate }}</th>
                              <td mat-cell
                                *matCellDef="let portfolio">
                                <ng-container *ngIf="isSmallScreen">
                                  {{ 'BROKING:MARKET_PRICE_RUB' | translate }}:&nbsp;
                                </ng-container>
                                {{ portfolio.rub_rate | number: '1.2-2' }}
                              </td>
                            </ng-container>

                            <tr mat-header-row
                              *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row
                              *matRowDef="let portfolio; columns: displayedColumns;">
                            </tr>
                          </table>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </ng-container>
                <ng-template #notFound>
                  <div class="not-found">
                    <h4>{{ 'BROKING:BONDS_NOT_FOUND' | translate }}</h4>
                  </div>
                </ng-template>
              </mat-tab>
            </mat-tab-group>
          </div>
        </ng-container>
      </ng-template>
    </div>
  </ng-container>
</div>