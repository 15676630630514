import { Component, Input } from '@angular/core';
import { BankAccountResponse } from 'src/app/interfaces/api/bank.interface';

@Component({
  selector: 'ai-ui-bank-account-card',
  templateUrl: './ai-ui-bank-account-card.component.html',
  styleUrls: ['./ai-ui-bank-account-card.component.scss'],
})
export class AiUiBankAccountCardComponent {
  @Input() bankAccount: BankAccountResponse;

  constructor() {}
}
