import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslationModule } from '@localization';
import { NgxMaskModule } from 'ngx-mask';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { MaterialModule } from 'src/app/material.module';
import { SharedPipesModule } from '../shared-pipes/shared-pipes.module';
import { AiUiBankAccountCardComponent } from './ai-ui-bank-account-card/ai-ui-bank-account-card.component';
import { AiUiChangeEmailComponent } from './ai-ui-change-email/ai-ui-change-email.component';
import { AiUiChangePhoneComponent } from './ai-ui-change-phone/ai-ui-change-phone.component';
import { AiUiClaimDetailsComponent } from './ai-ui-claim-details/ai-ui-claim-details.component';
import { AiUiConfirmDialogComponent } from './ai-ui-confirm-dialog/ai-ui-confirm-dialog.component';
import { AiUiDocumentsComponent } from './ai-ui-documents/ai-ui-documents.component';
import { AiUiEditBankAccountComponent } from './ai-ui-edit-bank-account/ai-ui-edit-bank-account.component';
import { AiUiFooterComponent } from './ai-ui-footer/ai-ui-footer.component';
import { AiUiFrameComponent } from './ai-ui-frame/ai-ui-frame.component';
import { AiUiImageDialogComponent } from './ai-ui-image-dialog/ai-ui-image-dialog.component';
import { AiUiInfoDialogComponent } from './ai-ui-info-dialog/ai-ui-info-dialog.component';
import { AiUiMainInfoComponent } from './ai-ui-main-info/ai-ui-main-info.component';
import { AiUiRiskInformationComponent } from './ai-ui-risk-information/ai-ui-risk-information.component';
import { AiUiToggleVisibilityComponent } from './ai-ui-toggle-visibility/ai-ui-toggle-visibility.component';
import { AiUiRestrictionComponent } from './ai-ui-restriction/ai-ui-restriction.component';
import { AiUiFaqComponent } from './ai-ui-faq/ai-ui-faq.component';
import { AiUiPushNotificationsComponent } from './ai-ui-push-notifications/ai-ui-push-notifications.component';
import { AiUiChangePasswordComponent } from './ai-ui-change-password/ai-ui-change-password.component';
import { SharedDirectivesModule } from '../shared-directives/shared-directives.module';
import { AiUiPieChartComponent } from './ai-ui-pie-chart/ai-ui-pie-chart.component';
import { AiUiCautionComponent } from './ai-ui-caution/ai-ui-caution.component';
import { AiUiManualAssignmentComponent } from './ai-ui-manual-assignment/ai-ui-manual-assignment.component';
import { AiUiServerErrorComponent } from './ai-ui-server-error/ai-ui-server-error.component';
import { AiUiChangeLoginComponent } from './ai-ui-change-login/ai-ui-change-login.component';
import { AiUiSecuritySearchComponent } from './ai-ui-security-search/ai-ui-security-search.component';
import { AiUiBankBaseComponent } from './ai-ui-bank-base/ai-ui-bank-base.component';
import { AiUiAddBankAccountComponent } from './ai-ui-add-bank-account/ai-ui-add-bank-account.component';
import { AiUiMessageWithAttachmentComponent } from './ai-ui-message-with-attachment/ai-ui-message-with-attachment.component';
import { AiUiRefillAccountMethodComponent } from './ai-ui-refill-account-method/ai-ui-refill-account-method.component';
import { AiUiPersonalDataEditableContentComponent } from './ai-ui-personal-data-editable-content/ai-ui-personal-data-editable-content.component';
import { UiDividerComponent } from './ui-divider/ui-divider.component';
import { UiStepViewComponent } from './ui-step-view/ui-step-view.component';
import { UiCodeInputComponent } from './ui-code-input/ui-code-input.component';
import { AiUiRefillAccountMethodInfoComponent } from './ai-ui-refill-account-method-info/ai-ui-refill-account-method-info.component';
import { AiUiSelectComponent } from './ai-ui-select/ai-ui-select.component';
import { AiUiTariffChoiceComponent } from './ai-ui-tariff-choice/ai-ui-tariff-choice.component';
import { AiUiPersonalBrokerInfoDialogComponent } from './ai-ui-personal-broker-info-dialog/ai-ui-personal-broker-info-dialog.component';

const COMPONENTS = [
  AiUiFrameComponent,
  AiUiToggleVisibilityComponent,
  AiUiMainInfoComponent,
  AiUiFooterComponent,
  AiUiInfoDialogComponent,
  AiUiImageDialogComponent,
  AiUiChangePhoneComponent,
  AiUiChangeEmailComponent,
  AiUiConfirmDialogComponent,
  AiUiImageDialogComponent,
  AiUiChangePhoneComponent,
  AiUiChangeLoginComponent,
  AiUiChangePasswordComponent,
  AiUiChangeEmailComponent,
  AiUiConfirmDialogComponent,
  AiUiBankAccountCardComponent,
  AiUiAddBankAccountComponent,
  AiUiEditBankAccountComponent,
  AiUiDocumentsComponent,
  AiUiRiskInformationComponent,
  AiUiClaimDetailsComponent,
  AiUiRestrictionComponent,
  AiUiFaqComponent,
  AiUiSelectComponent,
  AiUiPushNotificationsComponent,
  AiUiPieChartComponent,
  AiUiCautionComponent,
  AiUiManualAssignmentComponent,
  AiUiServerErrorComponent,
  AiUiSecuritySearchComponent,
  AiUiBankBaseComponent,
  AiUiMessageWithAttachmentComponent,
  AiUiRefillAccountMethodComponent,
  AiUiPersonalDataEditableContentComponent,
  AiUiRefillAccountMethodInfoComponent,
  AiUiTariffChoiceComponent,
  AiUiPersonalBrokerInfoDialogComponent,
  UiDividerComponent,
  UiStepViewComponent,
  UiCodeInputComponent,
];

@NgModule({
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    TranslationModule,
    NgxMatIntlTelInputComponent,
    NgxMaskModule,
    SharedPipesModule,
    SharedDirectivesModule,
    FormsModule,
  ],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {},
    },
  ],
})
export class SharedModule {}
