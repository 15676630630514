import { APP_INITIALIZER, NgModule, Provider } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslationModule, TranslationService } from '@localization';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ProfileModule } from './main/components/profile/profile.module';
import { MaterialModule } from './material.module';
import { AuthInterceptor } from './services/auth/auth.interceptor';
import { SharedModule } from './shared/shared-components/shared.module';
import { UserModule } from './user/user.module';
import { NgxMaskModule } from 'ngx-mask';
import { ClaimModule } from './main/components/claims/claim.module';
import { PortfolioModule } from './main/components/portfolio/portfolio.module';
import { HttpErrorInterceptor } from './shared/shared-interceptors/http-error/http-error.interceptor';
import { NotFoundComponent } from './not-found/not-found.component';
import { AuthService } from './services/auth/auth.service';

export function loadTranslations(service: TranslationService) {
  return async () => await service.getTranslations();
}

export function loadProfile(service: AuthService) {
  return async () => await service.getProfileInitial();
}

const AUTH_INTERCEPTOR_PROVIDER: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthInterceptor,
  multi: true,
};

const HTTP_ERROR_INTERCEPTOR_PROVIDER: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: HttpErrorInterceptor,
  multi: true,
};

const APP_INITIALIZER_PROVIDER: Provider = {
  provide: APP_INITIALIZER,
  useFactory: loadTranslations,
  deps: [TranslationService],
  multi: true,
};

const APP_INITIALIZER_PROVIDER_PROFILE: Provider = {
  provide: APP_INITIALIZER,
  useFactory: loadProfile,
  deps: [AuthService],
  multi: true,
};

@NgModule({
  declarations: [AppComponent, NotFoundComponent],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    UserModule,
    ProfileModule,
    ClaimModule,
    PortfolioModule,
    SharedModule,
    TranslationModule,
    HttpClientModule,
    NgxMaskModule.forRoot(),
  ],
  providers: [
    APP_INITIALIZER_PROVIDER,
    APP_INITIALIZER_PROVIDER_PROFILE,
    AUTH_INTERCEPTOR_PROVIDER,
    HTTP_ERROR_INTERCEPTOR_PROVIDER,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
