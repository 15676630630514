import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const maxFileSize: number = 10 * 1024 * 1024;

export const maxAttachmentSize: number = 20 * 1024 * 1024;

export const acceptedFormates: string = '.jpeg, .jpg, .png, .pdf, .doc, .docx, .tif, .tiff.';

export const EMAIL_REGEXP: RegExp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const LOWERCASE_REGEXP = /[a-z]+/;

export const UPPERCASE_REGEXP = /[A-Z]+/;

export const DIGITS_REGEXP = /[0-9]+/;

export const SPECIAL_CHARACTERS_REGEXP = /[()\[\]{}|\\`~!@#$^&*_\-+=;:'",<>./?]+/;

export const NAME_REGEXP = '[a-zA-Zа-яёА-ЯЁ -]*';

export const BANK_NAME_REGEXP = '[a-zA-Zа-яА-Я0-9 "-]*';

export const checkingAccountMaskBY: string = '00 SSSS 0000 AAAA AAAA AAAA AAAA'; // with 'BY' prefix

export const checkingAccountMaskRU: string = '00000 000 0 0000 0000000';

export const bicMaskBY: string = 'AAAAAAAA';

export const bicMaskRU: string = '0000000'; // with '04' prefix

export function substractDaysFromDate(date: Date, daysToSubstract: number) {
  const result = new Date(date);
  result.setDate(result.getDate() - daysToSubstract);
  return result;
}

export function passwordPatternValidator(control: FormControl) {
  const password = control.value;
  if (
    DIGITS_REGEXP.test(password) &&
    LOWERCASE_REGEXP.test(password) &&
    UPPERCASE_REGEXP.test(password) &&
    SPECIAL_CHARACTERS_REGEXP.test(password)
  ) {
    return null;
  }
  DIGITS_REGEXP.test(password) ? delete control?.errors?.digitsPattern : control.setErrors({ digitsPattern: true });
  LOWERCASE_REGEXP.test(password) && UPPERCASE_REGEXP.test(password)
    ? delete control?.errors?.lettersPattern
    : control.setErrors({ lettersPattern: true });
  SPECIAL_CHARACTERS_REGEXP.test(password)
    ? delete control?.errors?.charactersPattern
    : control.setErrors({ charactersPattern: true });
  return control?.errors;
}

export const addIBANPrefix = function (number_iban: string) {
  const prefix = 'BY';
  const modifiedValue = `${number_iban.startsWith(prefix) ? '' : prefix}${number_iban.toUpperCase()}`;
  return modifiedValue;
};

export const addBICPrefix = function (bic_number: string) {
  const prefix = '04';
  const modifiedValue = `${bic_number.startsWith(prefix) ? '' : prefix}${bic_number.toUpperCase()}`;
  return modifiedValue;
};

export function transformDate(dateString: string) {
  const [year, month, day] = dateString.split('-');
  return new Date(+year, +month - 1, +day);
}

export function getFormatDate(date: Date | string) {
  return date && date instanceof Date
    ? `${(date as Date).getFullYear()}-${(date as Date).getMonth() + 1}-${(date as Date).getDate()}`
    : null;
}

export function getISOFormatDateTimeUTC(date: Date | string, time: string) {
  const separatedTime = time.split(':');
  return new Date(
    new Date(
      (date as Date).getFullYear(),
      (date as Date).getMonth() + 1,
      (date as Date).getDate(),
      Number(separatedTime[0] || 0),
      Number(separatedTime[1] || 0),
      0
    ).toUTCString()
  ).toISOString();
}

export function formatDate(date: string) {
  const [year, mm, dd] = date.split('-');
  return `${dd}.${mm}.${year}`;
}

export function convertByteToMegaBytes(value: number) {
  return value / 1024 / 1024;
}

export function dateOfBirthValidator(): ValidatorFn {
  return (formControl: AbstractControl): ValidationErrors | null => {
    const diffTime = Math.abs(Date.now() - formControl.value);
    const age = Math.floor(diffTime / (1000 * 3600 * 24) / 365);
    return age < 18 ? { young: true } : null;
  };
}

export function getClaimIconName(status: ClaimStatus | AgreementStatus | string) {
  switch (status) {
    case ClaimStatus.new:
      return 'access_time';
    case ClaimStatus.in_progress:
      return 'access_time';
    case ClaimStatus.satisfied:
      return 'info_outline';
    case ClaimStatus.rejected:
      return 'remove_circle_outline';
    case AgreementStatus.new:
      return 'access_time';
    case AgreementStatus.active:
      return 'info_outline';
    case AgreementStatus.suspended:
      return 'remove_circle_outline';
    case AgreementStatus.finished:
      return 'remove_circle_outline';
    case AgreementStatus.incorrect:
      return 'remove_circle_outline';
    default:
      return '';
  }
}

export enum Sex {
  m = 'm',
  f = 'f',
}

export enum CountryCode {
  BY = 'BY',
  RU = 'RU',
}

export enum Currency {
  RUB = 'RUB',
  USD = 'USD',
  EUR = 'EUR',
  CNY = 'CNY',
  BYN = 'BYN',
}

// TODO change type names to match fields in database
export enum ContractTerminationType {
  repayment = 'repayment',
  expiration = 'expiration',
}

export enum OtherType {
  manual_assignment = 'manual_assignment',
  edit_profile = 'edit_profile',
  qualified_investor = 'qualified_investor',
  keyword = 'keyword',
}

export enum BsType {
  broker_agreement = 'broker_agreement',
  currency_instruments_trade = 'currency_instruments_trade',
  terminate_agreement = 'terminate_agreement',
  withdraw_money = 'withdraw_money',
  money_transfer = 'money_transfer',
  withdraw_exchange_money = 'withdraw_exchange_money',
  securities_trade = 'securities_trade',
  brokerage_additional_consent = 'brokerage_additional_consent',
  // arbitrary_order = 'arbitrary_order', // TODO change claim type
}

export enum DepoType {
  depositary_agreement = 'depositary_agreement',
  // depo_arbitrary_order = 'depo_arbitrary_order', // TODO change claim type
  // reference_provision = 'reference_provision', // TODO change claim type
  transaction_reference_registry = 'transaction_reference_registry',
  transaction_reference = 'transaction_reference',
  transfer_reference = 'transfer_reference',
  depositary_account_reference = 'depositary_account_reference',
  depositary_additional_consent = 'depositary_additional_consent',
}

export enum ClaimCategory {
  broker_service = 'broker_service',
  depositary_service = 'depositary_service',
  other = 'other',
}

export enum ClaimAttachmentType {
  additional_consent = 'additional_consent',
  other = 'other',
}

export enum AgreementStatus {
  new = 'new',
  active = 'active',
  finished = 'finished',
  suspended = 'suspended',
  incorrect = 'incorrect',
}

export enum ClaimStatus {
  new = 'new', // TODO change to 'new'
  in_progress = 'in_progress',
  satisfied = 'satisfied',
  rejected = 'rejected',
}

export enum ClaimTradeType {
  buy = 'buy',
  sell = 'sell',
  // buy_sell = 'buy_sell',
  // sell_buy = 'sell_buy',
}

export enum TaxResidentStatus {
  russian_tax_resident = 'russian_tax_resident',
  tax_resident_of_another_country = 'tax_resident_of_another_country',
  not_tax_resident = 'not_tax_resident',
}

export enum AgreementTariff {
  investor = 'investor',
  trader = 'trader',
  personal_broker = 'personal_broker',
}

export enum BusinessRelationshipPurpose {
  brokerage_service = 'brokerage_service',
  depository_service = 'depository_service',
  other = 'other',
}

export enum BusinessRelationshipCharacter {
  short = 'short',
  long = 'long',
  one_time = 'one_time',
}

export enum FinancialAndEconomicTransactionPurpose {
  obtaining_income = 'obtaining_income',
  saving_money = 'saving_money',
  other = 'other',
}

export enum FinancialPosition {
  stable = 'stable',
  unstable = 'unstable',
}

export enum OriginFundsSource {
  salary = 'salary',
  entrepreneurial_actvities = 'entrepreneurial_actvities',
  operations_with_securities = 'operations_with_securities',
  loan = 'loan',
  other = 'other',
}

export enum DealPriceType {
  // market = 'market',
  not_greater = 'not_greater',
  fixed = 'fixed',
}

export enum ClaimExchangeRateType {
  market_price = 'market_price',
  not_less = 'not_less',
  not_greater = 'not_greater',
}

export enum TransferMoneyType {
  // agreement = 'agreement',
  exchange = 'exchange',
}

export enum ProfileStatus {
  pii_not_filled = 'pii_not_filled',
  pii_validating = 'pii_validating',
  pii_editing = 'pii_editing',
  pii_approved = 'pii_approved',
}

export enum ConfirmOperation {
  phone = 'phone',
  email = 'email',
}

export enum PassportType {
  id_card = 'id_card',
  passport = 'passport',
  rf_passport = 'rf_passport',
}

export enum FaqType {
  starting_of_work = 'starting_of_work',
  changing_of_personal_data = 'changing_of_personal_data',
  account_problems = 'account_problems',
  mailing_list = 'mailing_list',
}

export enum RiskLevel {
  low = 'low',
  medium = 'medium',
  high = 'high',
}

export enum AreaType {
  mstock = 'mstock',
  mcur = 'mcur',
  offe = 'offe',
  sstock = 'sstock',
}

export enum OperationInitiator {
  depositor = 'depositor',
  authorized_person = 'authorized_person',
  guardian = 'guardian',
}

export enum TransactionType {
  refill = 'refill',
  write_off = 'write_off',
}

export enum TransactionReason {
  agreement_of_sale = 'agreement_of_sale',
  agreement_of_pledge = 'agreement_of_pledge',
  depo_agreement = 'depo_agreement',
  interdepo_agreement = 'interdepo_agreement',
  repo_agreement = 'repo_agreement',
  without_ownership = 'without_ownership',
  other_agreement = 'other_agreement',
}

export enum CalculationPlace {
  nko_ao_nrd = 'nko_ao_nrd',
  euroclear = 'euroclear',
  clearstream = 'clearstream',
}

export enum ParticipantType {
  sender = 'sender',
  recipient = 'recipient',
}

export enum PlaceOfStorage {
  nko_ao_nrd = 'nko_ao_nrd',
  other = 'other',
}

export enum EntityType {
  individual_entity = 'individual_entity',
  legal_entity = 'legal_entity',
}

export enum ReferenceType {
  transfer = 'transfer',
  movement = 'movement',
}

export enum SecurityType {
  shares = 'shares',
  bonds = 'bonds',
  units = 'units',
  depositary_receipts = 'depositary_receipts',
  mortgage_certificate = 'mortgage_certificate',
  clearing_certificate = 'clearing_certificate',
}

export enum HasNoForeignTaxNumberReason {
  tax_residence_country_does_not_assign_tin = 'tax_residence_country_does_not_assign_tin',
  individual_cannot_obtain_tin_due_to_other_reasons = 'individual_cannot_obtain_tin_due_to_other_reasons',
}

export enum SecurityCategory {
  ord = 'ord',
  prf = 'prf',
  adr = 'adr',
  gdr = 'gdr',
  edr = 'edr',
  pdr = 'pdr',
  sdr = 'sdr',
  ddr = 'ddr',
}

export enum QuikType {
  limit = 'limit',
  market = 'market',
}

export enum StatementType {
  depositary_account_reference = 'depositary_account_reference',
  securities_statement = 'securities_statement',
  depositary_account_statement = 'depositary_account_statement',
}

export enum AuthorizedDocument {
  citizen_of_russian_federation = 'citizen_of_russian_federation',
  passport_of_foreign_citizen = 'passport_of_foreign_citizen',
}

export enum AccountType {
  owner_depo_account = 'owner_depo_account',
  trustee_depo_account = 'trustee_depo_account',
  nominal_holder_depo_account = 'nominal_holder_depo_account',
  // foreign_nominal_holder_depo_account = 'foreign_nominal_holder_depo_account',
  // foreign_authorized_holder_depo_account = 'foreign_authorized_holder_depo_account',
  mortgagee_depo_account = 'mortgagee_depo_account',
  issuer_emission_account = 'issuer_emission_account',
  issuer_personal_account = 'issuer_personal_account',
  securities_of_unidentified_persons_personal_account = 'securities_of_unidentified_persons_personal_account',
  personal_account_of_mutual_investment_fund = 'personal_account_of_mutual_investment_fund',
  transit_account = 'transit_account',
  personal_account_of_issued_investment_mutual_funds = 'personal_account_of_issued_investment_mutual_funds',
  personal_account_of_additionalinvestment_mutual_funds = 'personal_account_of_additionalinvestment_mutual_funds',
  issuer_treasury_account = 'issuer_treasury_account',
  personal_account_of_central_depository_of_nominal_holder = 'personal_account_of_central_depository_of_nominal_holder',
}

export enum NotificationTemplate {
  fill_profile = 'fill_profile',
  become_a_qualified_investor = 'become_a_qualified_investor',
  passport_data_have_not_been_validated = 'passport_data_have_not_been_validated',
  apply_for_an_agreement = 'apply_for_an_agreement',
  annual_personal_data_confirmation = 'annual_personal_data_confirmation',
  personal_data_have_not_been_validated = 'personal_data_have_not_been_validated',
  register_in_quik = 'register_in_quik',
  register_an_agreement = 'register_an_agreement',
  agreement_has_been_activated = 'agreement_has_been_activated',
  documents_have_been_signed_by_manager = 'documents_have_been_signed_by_manager',
  claim_has_been_rejected = 'claim_has_been_rejected',
  verify_personal_data_via_aigenis_invest = 'verify_personal_data_via_aigenis_invest',
  new_message_in_chat = 'new_message_in_chat',
}

export enum NotificationStatus {
  queued = 'queued',
  sent = 'sent',
  read = 'read',
  failed = 'failed',
}

export enum TicketStatus {
  new = 'new',
  in_progress = 'in_progress',
  wait_for_reply = 'wait_for_reply',
  closed = 'closed',
}

export enum InvestmentType {
  checking_account = 'checking_account',
  brokerage_account = 'brokerage_account',
  nkd = 'nkd',
  aigenis_comission = 'aigenis_comission',
  other_comission = 'other_comission',
}

export enum AigenisAssetsType {
  full = 'full',
  partially = 'partially',
  nothing = 'nothing',
}

export enum EsiaRegistrationType {
  base_esia = 'base_esia',
  digital_profile = 'digital_profile',
}

export function getPasswordValidationMessage(errors: ValidationErrors | null): string {
  if (!errors) {
    return null;
  }
  let result = '';
  if (errors.required) {
    result += this.translation.translate('ERROR:REQUIRED');
  }
  if (errors.minlength) {
    result += this.translation.translate('ERROR:PASSWORD_MIN_LENGTH');
  }
  if (errors.digitsPattern) {
    result += this.translation.translate('REGISTRATION:PASSWORD_CONTENT_1');
  }
  if (errors.lettersPattern) {
    result += this.translation.translate('REGISTRATION:PASSWORD_CONTENT_2');
  }
  if (errors.charactersPattern) {
    result += this.translation.translate('REGISTRATION:PASSWORD_CONTENT_3');
  }
  return result || null;
}
