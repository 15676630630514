<div class="container-fluid bg-white p-4">
  <div class="row title-container">
    <div class="col-6">
      <h1>{{ 'CHAT:CHOOSE_CATEGORY' | translate }}</h1>
    </div>
  </div>
  <div class="row">
    <div class="categories">
      <div class="category-cards">
        <div class="col-lg-6 col-12"
          *ngFor="let category of (messagesService.chatCategories$ | async)">
          <div class="category-card"
            (click)="goToChat(category)">
            <img src="./assets/logos/arbitrary_order.svg"
              alt="logo">
            <span>{{ category.name }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>