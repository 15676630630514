import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslationService } from '@localization';
import { mergeMap } from 'rxjs';
import { Attachment } from 'src/app/interfaces/api/attachment.interface';
import { BankAccountResponse } from 'src/app/interfaces/api/bank.interface';
import { ClaimsBatchRequest, ClaimsBatchResponse } from 'src/app/interfaces/api/claims.interface';
import { AuthService } from 'src/app/services/auth/auth.service';
import { BankService } from 'src/app/services/bank/bank.service';
import { ClaimFlowService } from 'src/app/services/claim-flow/claim-flow.service';
import { ClaimsService } from 'src/app/services/claims/claims.service';
import { DepositaryAccountService } from 'src/app/services/depositary-account/depositary-account.service';
import { DepositaryService } from 'src/app/services/depositary/depositary.service';
import { DocumentService } from 'src/app/services/document/document.service';
import { IssuerService } from 'src/app/services/issuer/issuer.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { SecurityService } from 'src/app/services/security/security.service';
import { AiUiAddBankAccountComponent } from 'src/app/shared/shared-components/ai-ui-add-bank-account/ai-ui-add-bank-account.component';
import { AiUiEditBankAccountComponent } from 'src/app/shared/shared-components/ai-ui-edit-bank-account/ai-ui-edit-bank-account.component';
import { ToasterService } from 'src/app/shared/shared-services/toaster/toaster.service';
import { animationOptions } from 'src/app/utils/animation';
import { DepoType, ProfileStatus } from 'src/app/utils/utils';

@Component({
  selector: 'app-depo-origin-new',
  templateUrl: './depo-origin-new.component.html',
  styleUrls: ['./depo-origin-new.component.scss'],
  animations: animationOptions,
})
export class DepoOriginNewComponent implements OnInit, OnDestroy {
  claimBatchId: number = 0;

  bankAccounts: BankAccountResponse[] = [];

  attachmentsToSign: Attachment[] = [];

  bank_account: FormControl = new FormControl(null, [Validators.required]);

  income_earning_option: FormControl = new FormControl('bank_account', [Validators.required]);

  confirmation: FormControl = new FormControl(false, [Validators.required]);

  constructor(
    public auth: AuthService,
    public bankService: BankService,
    public profileService: ProfileService,
    public cfService: ClaimFlowService,
    public claimsService: ClaimsService,
    public documentService: DocumentService,
    public ds: DepositaryService,
    public issuerService: IssuerService,
    public depositaryAccountService: DepositaryAccountService,
    public securityService: SecurityService,
    public loader: LoaderService,
    public fb: FormBuilder,
    public cd: ChangeDetectorRef,
    public toaster: ToasterService,
    public translation: TranslationService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    if (this.auth.userProfile$.value?.status === ProfileStatus.pii_not_filled) {
      return;
    }
    this.getBankAccounts();
  }

  getBankAccounts() {
    this.loader.showLoader();
    this.bankService.bankAccounts().subscribe({
      next: (res) => {
        if (res.length) {
          this.bankAccounts = res;
          if (this.bank_account.value === null) {
            this.bank_account.setValue(this.bankAccounts[0].id);
          }
          this.cd.detectChanges();
          this.cd.markForCheck();
        }
        this.loader.hideLoader();
      },
      error: () => {
        this.loader.hideLoader();
      },
    });
  }

  onAddBankAccount() {
    this.dialog
      .open(AiUiAddBankAccountComponent, {
        panelClass: 'popup-container',
        width: '800px',
      })
      .afterClosed()
      .subscribe((res: boolean) => {
        if (res) {
          this.getBankAccounts();
        }
      });
  }

  onEditBankAccount(id: number) {
    this.dialog
      .open(AiUiEditBankAccountComponent, {
        panelClass: 'popup-container',
        width: '800px',
        data: {
          bankAccountId: id,
        },
      })
      .afterClosed()
      .subscribe((res: boolean) => {
        if (res) {
          this.getBankAccounts();
        }
      });
  }

  setBankAccountId(bankAccountId: number) {
    this.bank_account.setValue(bankAccountId);
    this.next();
  }

  next() {
    if (this.bank_account.invalid) {
      return;
    }
    const bank_account = this.bank_account.value;
    this.loader.showLoader();
    this.claimsService
      .addClaimsBatch([
        {
          type: DepoType.depositary_agreement,
          bank_account,
          broker_can_use_client_money: false,
          income_earning_option: this.income_earning_option.value,
        },
      ] as ClaimsBatchRequest[])
      .pipe(
        mergeMap((res: ClaimsBatchResponse) => {
          this.claimBatchId = res.id;
          return this.documentService.claimsBatchDocumentsProcessing(res.id);
        })
      )
      .subscribe({
        next: (data) => {
          this.attachmentsToSign = data;
          this.ds.goToStep(this.ds.stepKey + 1);
          this.loader.hideLoader();
          this.cd.markForCheck();
        },
        error: () => {
          this.loader.hideLoader();
        },
      });
  }

  clearForm() {
    this.bank_account.reset();
    this.ds.goToStep(0);
  }

  back() {
    this.ds.goToStep(this.ds.stepKey - 1);
  }

  sendToSign = () => this.claimsService.sendToSign(this.claimBatchId);

  ngOnDestroy(): void {
    this.clearForm();
  }
}
