import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiUrlWithPrefix } from '@env';
import { TranslationService } from '@localization';
import { BehaviorSubject, catchError, map, throwError } from 'rxjs';
import {
  MessageResponse,
  TicketCategory,
  TicketRequest,
  TicketResponse,
} from 'src/app/interfaces/api/messages.interface';
import { ToasterService } from 'src/app/shared/shared-services/toaster/toaster.service';

@Injectable({
  providedIn: 'root',
})
export class MessagesService {
  public chatCategories$: BehaviorSubject<TicketCategory[]> = new BehaviorSubject<TicketCategory[]>(null);

  static readonly CATEGORIES = `${apiUrlWithPrefix}/helpdesk/category/`;
  static readonly TICKET = `${apiUrlWithPrefix}/helpdesk/ticket/`;

  static categoryById = (id: number) => `${apiUrlWithPrefix}/helpdesk/category/${id}/`;
  static messageByChatId = (chat_id: number) => `${apiUrlWithPrefix}/helpdesk/chat/${chat_id}/message/`;
  static downloadFileByMessageId = (chat_id: number, id: number) =>
    `${apiUrlWithPrefix}/helpdesk/chat/${chat_id}/message/${id}/download-file/`;

  constructor(private http: HttpClient, private toaster: ToasterService, private translation: TranslationService) {}

  getCategories() {
    this.http
      .get(MessagesService.CATEGORIES)
      .pipe(map((res: any) => res.results as TicketCategory[]))
      .subscribe({
        next: (data) => {
          this.chatCategories$.next(data);
        },
        error: () => {
          this.chatCategories$.next(null);
        },
      });
  }

  getTickets() {
    return this.http.get(MessagesService.TICKET).pipe(map((res: any) => res.results as TicketResponse[]));
  }

  createTicket(data: TicketRequest) {
    return this.http
      .post(MessagesService.TICKET, {
        ...data,
      })
      .pipe(map((res) => res as TicketResponse));
  }

  sendMessage(chatId: number, formData: FormData) {
    return this.http.post(MessagesService.messageByChatId(chatId), formData).pipe(map((res) => res as MessageResponse));
  }

  downloadFile(chatId: number, id: number) {
    return this.http.get(MessagesService.downloadFileByMessageId(chatId, id), {
      responseType: 'blob',
    });
  }

  getMessagesByChatId(chatId: number) {
    const httpParams = new HttpParams({
      fromObject: {
        ordering: 'id',
      },
    });
    return this.http
      .get(MessagesService.messageByChatId(chatId), {
        params: httpParams,
      })
      .pipe(
        map((res: any) => res.results as MessageResponse[]),
        catchError((err: HttpErrorResponse) => {
          if (err.status === 404) {
            this.toaster.showError(this.translation.translate('CHAT:NOT_FOUND'));
          }
          return throwError(() => err);
        })
      );
  }
}
