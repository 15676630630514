import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { TranslationService } from '@localization';
import { filter, mergeMap } from 'rxjs';
import { Attachment } from 'src/app/interfaces/api/attachment.interface';
import { AdditionalConsentRequest, ClaimsBatchResponse } from 'src/app/interfaces/api/claims.interface';
import { AdditionalConsentService } from 'src/app/services/additional-consent-service/additional-consent.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ClaimsService } from 'src/app/services/claims/claims.service';
import { DocumentService } from 'src/app/services/document/document.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { AiUiInfoDialogComponent } from 'src/app/shared/shared-components/ai-ui-info-dialog/ai-ui-info-dialog.component';
import { AiUiPersonalBrokerInfoDialogComponent } from 'src/app/shared/shared-components/ai-ui-personal-broker-info-dialog/ai-ui-personal-broker-info-dialog.component';
import { AiUiTariffChoiceComponent } from 'src/app/shared/shared-components/ai-ui-tariff-choice/ai-ui-tariff-choice.component';
import { animationOptions } from 'src/app/utils/animation';
import { AgreementStatus, AgreementTariff, BsType, DepoType } from 'src/app/utils/utils';

interface AgreementData {
  tariff: string;
  trade_on_spb_stock: boolean;
  broker_can_use_client_money: boolean;
  income_earning_option: string;
  refusal_of_transactions_with_incomplete_coverage: boolean;
}

@Component({
  selector: 'app-bo-additional-consent-new',
  templateUrl: './bo-additional-consent-new.component.html',
  styleUrls: ['./bo-additional-consent-new.component.scss'],
  animations: animationOptions,
})
export class BoAdditionalConsentNewComponent implements OnInit, OnDestroy {
  form: FormGroup;

  tariff: FormControl = new FormControl(null, [Validators.required]);

  formDepoContract: FormControl = new FormControl({ value: true, disabled: true });

  attachmentsToSign: Attachment[] = [];

  claimBatchId: number;

  claimId: number;

  tariffs = Array.from(new Set(Object.keys(AgreementTariff)));

  currentAgreementData: AgreementData = null;

  constructor(
    private auth: AuthService,
    private loader: LoaderService,
    private cd: ChangeDetectorRef,
    private translation: TranslationService,
    public claimsService: ClaimsService,
    private documentService: DocumentService,
    private dialog: MatDialog,
    public cfService: AdditionalConsentService
  ) {}
  ngOnInit(): void {
    this.form = this.initForm();
    const created_by_claim = this.auth.brokerageService$.value.created_by_claim;
    this.currentAgreementData = {
      tariff: created_by_claim.tariff,
      trade_on_spb_stock: created_by_claim.trade_on_spb_stock,
      broker_can_use_client_money: created_by_claim.broker_can_use_client_money,
      income_earning_option: created_by_claim.income_earning_option,
      refusal_of_transactions_with_incomplete_coverage:
        created_by_claim.refusal_of_transactions_with_incomplete_coverage,
    };

    this.form.patchValue(this.currentAgreementData);
  }

  initForm() {
    return new FormGroup({
      tariff: new FormControl(null, [Validators.required]),
      trade_on_spb_stock: new FormControl(false, [Validators.required]),
      broker_can_use_client_money: new FormControl(true, [Validators.required]),
      income_earning_option: new FormControl('brokerage_account', [Validators.required]),
      refusal_of_transactions_with_incomplete_coverage: new FormControl(true, [Validators.required]),
    });
  }

  onChangeBrokerCanUseClientMoney(event: MatRadioChange) {
    if (event.value === false) {
      this.dialog.open(AiUiInfoDialogComponent, {
        width: '800px',
        disableClose: true,
        data: {
          content: this.translation.translate('BS:CONDITION_OF_ACCEPTATE_DISAGREE_INFORMATION'),
          links: [{ name: 'Ссылка на тарифы', link: 'https://www.aigenis.ru/info5' }],
        },
      });
    }
  }

  onChangeIncomeEarningOption(event: MatRadioChange) {
    if (event.value === 'bank_account') {
      this.dialog.open(AiUiInfoDialogComponent, {
        width: '800px',
        disableClose: true,
        data: {
          content: this.translation.translate('BS:INCOME_EARNING_OPTION_BANK_ACCOUNT_INFORMATION'),
        },
      });
    }
  }

  changeTariff() {
    this.dialog
      .open(AiUiTariffChoiceComponent, {
        width: '800px',
        disableClose: true,
        panelClass: 'tariff-choice-container',
        data: {
          selectedTariff: this.form.get('tariff')?.value,
        },
      })
      .afterClosed()
      .pipe(filter((n) => !!n))
      .subscribe((selectedTariff) => {
        this.chooseTariff(selectedTariff);
      });
  }

  chooseTariff(tariff: string) {
    if (tariff === AgreementTariff.personal_broker) {
      this.dialog
        .open(AiUiPersonalBrokerInfoDialogComponent, {
          width: '800px',
          disableClose: true,
        })
        .afterClosed()
        .subscribe(() => {
          this.form.patchValue({
            tariff: tariff,
          });
          this.cfService.goToStep(this.cfService.stepKey + 1);
          this.cd.markForCheck();
        });
    } else {
      this.form.patchValue({
        tariff: tariff,
      });
      this.cfService.goToStep(this.cfService.stepKey + 1);
      this.cd.markForCheck();
    }
  }

  next() {
    if (this.cfService.stepKey === 1) {
      if (this.form.invalid) {
        return;
      }
      const payload = this.form.value as AdditionalConsentRequest;
      payload.type = BsType.brokerage_additional_consent;
      this.loader.showLoader();
      const claimsToAdd = [payload];
      if (
        !!this.auth.depositaryService$?.value &&
        this.auth.depositaryService$.value.status === AgreementStatus.active &&
        (this.currentAgreementData.broker_can_use_client_money !== payload.broker_can_use_client_money ||
          this.currentAgreementData.income_earning_option !== payload.income_earning_option ||
          this.currentAgreementData.trade_on_spb_stock !== payload.trade_on_spb_stock)
      ) {
        claimsToAdd.push({
          type: DepoType.depositary_additional_consent,
          trade_on_spb_stock: payload.trade_on_spb_stock,
          broker_can_use_client_money: payload.broker_can_use_client_money,
          income_earning_option: payload.income_earning_option,
        } as AdditionalConsentRequest);
      }
      this.claimsService
        .addClaimsBatch(claimsToAdd)
        .pipe(
          mergeMap((res: ClaimsBatchResponse) => {
            this.claimBatchId = res.id;
            if (payload.trade_on_spb_stock && !this.currentAgreementData.trade_on_spb_stock) {
              this.claimId = res.claims[0].id ?? 0;
            }
            return this.documentService.claimsBatchDocumentsProcessing(res.id);
          })
        )
        .subscribe({
          next: (data) => {
            this.attachmentsToSign = data;
            this.cfService.goToStep(this.cfService.stepKey + 1);
            this.loader.hideLoader();
            this.cd.markForCheck();
          },
          error: () => {
            this.loader.hideLoader();
          },
        });
    } else {
      this.cfService.goToStep(this.cfService.stepKey + 1);
    }
  }

  back() {
    this.cfService.goToStep(this.cfService.stepKey - 1);
  }

  sendToSign = (claimId: number = 0) => this.claimsService.sendToSign(this.claimBatchId, false, claimId);

  ngOnDestroy(): void {
    this.form.reset();
    this.cfService.goToStep(0);
  }
}
