import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslationService } from '@localization';
import { Observable, catchError, throwError } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { ConfirmOperation } from 'src/app/utils/utils';
import { AiUiInfoDialogComponent } from '../ai-ui-info-dialog/ai-ui-info-dialog.component';

export interface Confirmation {
  attempt_id?: number;
  email?: string;
  phone?: string;
  operation: ConfirmOperation;
  updatedAllowedSign?: boolean;
  callback: (...args: any[]) => Observable<any>;
}

@Component({
  selector: 'app-ai-ui-confirm-dialog',
  templateUrl: './ai-ui-confirm-dialog.component.html',
  styleUrls: ['./ai-ui-confirm-dialog.component.scss'],
})
export class AiUiConfirmDialogComponent implements OnInit {
  code = new FormControl(null, [Validators.required]);

  phoneNumber: string;

  handleError = (err: HttpErrorResponse) => {
    const codeInvalid = err.error.find((e) => e.code === 'code_invalid');
    if (codeInvalid) {
      this.dialog.open(AiUiInfoDialogComponent, {
        disableClose: true,
        data: {
          content: this.translation.translate('ERROR:code_invalid'),
        },
      });
    }
    return throwError(() => err);
  };

  constructor(
    private authService: AuthService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<AiUiConfirmDialogComponent>,
    private translation: TranslationService,
    private loader: LoaderService,
    @Inject(MAT_DIALOG_DATA) public data: Confirmation
  ) {}

  ngOnInit(): void {
    if (this.data?.phone) {
      this.phoneNumber = this.data.phone;
      return;
    }
    this.phoneNumber = this.authService.userProfile$.value.phone;
  }

  confirm() {
    if (this.code.invalid) {
      return;
    }
    this.loader.showLoader();
    this.data
      .callback({
        attempt_id: this.data?.attempt_id,
        code: this.code.value,
      })
      .pipe(catchError(this.handleError))
      .subscribe({
        next: () => {
          this.loader.hideLoader();
          this.dialogRef.close(true);
        },
        error: () => {
          this.loader.hideLoader();
          this.code.reset();
        },
      });
  }
}
