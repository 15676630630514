<div class="container-fluid" [@open]="'opened'">
  <h1 class="bg-white m-0 py-4 px-5">{{ 'DEPOSITORY:TITLE' | translate }}</h1>
  <div class="row">
    <div class="col-12">
      <ng-container *ngIf="(auth.userProfile$ | async)?.status==='pii_not_filled';else bind_aigenis_account">
        <ai-ui-restriction [showFaq]="false"></ai-ui-restriction>
      </ng-container>
      <ng-template #bind_aigenis_account>
        <ng-container *ngIf="(auth.userProfile$ | async)?.status === 'pii_validating' && (auth.userProfile$ | async)?.citizenship==='BY' && !(auth.userProfile$ | async)?.user_aigenis_id; else pii_validating">
          <ai-ui-restriction [type]="'waitingForAigenisAccountBinding'"
            [showFaq]="false"></ai-ui-restriction>
        </ng-container>
      </ng-template>
      <ng-template #pii_validating>
        <ng-container *ngIf="(auth.userProfile$ | async)?.status==='pii_validating';else create_depositary_service">
          <ai-ui-restriction [type]="'waitingForApproval'" [showFaq]="false"></ai-ui-restriction>
        </ng-container>
      </ng-template>
      <ng-template #create_depositary_service>
        <ng-container *ngIf="(auth.depositaryService$ | async)===undefined;else waiting_depositary_service">
          <ai-ui-restriction [type]="'createDepositaryService'" [showFaq]="false"></ai-ui-restriction>
        </ng-container>
      </ng-template>
      <ng-template #waiting_depositary_service>
        <ng-container *ngIf="(auth.depositaryService$ | async)?.created_by_claim?.status==='new';else agreement_new">
          <ai-ui-restriction [type]="'depositaryServiceApprovalNew'" [showFaq]="false"></ai-ui-restriction>
        </ng-container>
      </ng-template>
      <ng-template #agreement_new>
        <ng-container *ngIf="(auth.depositaryService$ | async)?.status==='new';else depositary_service_in_processing">
          <ai-ui-restriction [type]="'depositaryAgreementNew'" [showFaq]="false"></ai-ui-restriction>
        </ng-container>
      </ng-template>
      <ng-template #depositary_service_in_processing>
        <ng-container *ngIf="(auth.depositaryService$ | async)?.created_by_claim?.status==='in_progress';else default">
          <ai-ui-restriction [type]="'waitingForDepositaryServiceApproval'" [showFaq]="false"></ai-ui-restriction>
        </ng-container>
      </ng-template>
    </div>
    <ng-template #default>
      <div class="broking col-12">
        <div class="chapter">
          <div class="left-side">
            <div class="bo-number">
              <span>{{ (auth.depositaryService$ | async)?.number }}</span>
            </div>
            <div class="bo-status">
              <mat-icon class="status" [ngClass]="(auth.depositaryService$ | async)?.status">{{ (auth.depositaryService$
                |
                async)?.status | claimStatusIcon }}</mat-icon>
              <span>{{ (auth.depositaryService$ | async)?.status | translate }}</span>
            </div>
          </div>
          <div class="action text-align-right">
            <button class="add-btn" mat-button type="button" [matMenuTriggerFor]="claimTypes">
              <span>{{ 'ADD_ORDER' | translate }}</span>
              <mat-icon>playlist_add</mat-icon>
            </button>
            <mat-menu #claimTypes="matMenu">
              <button [matTooltip]="type.name | translate" mat-menu-item [routerLink]="type.routerLink"
                *ngFor="let type of dsTypes">
                {{ type.name | translate }}
              </button>
            </mat-menu>
            <button class="menu" mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>list</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item [routerLink]="['/claims', (auth.depositaryService$ | async)?.created_by_claim?.id]">
                <span>{{ 'DOCUMENTS' | translate }}</span>
              </button>
              <button mat-menu-item routerLink="/claims" [queryParams]="{ type: 'depositary_service' }">
                <span>{{ 'CLAIMS' | translate }}</span>
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>