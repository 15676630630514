import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { apiUrlWithPrefix } from '@env';
import { TranslationService } from '@localization';
import { forkJoin, mergeMap } from 'rxjs';
import { Attachment, AttachmentBase } from 'src/app/interfaces/api/attachment.interface';
import { ClaimBase, ClaimResponse } from 'src/app/interfaces/api/claims.interface';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ClaimsService } from 'src/app/services/claims/claims.service';
import { DocumentService } from 'src/app/services/document/document.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { AiUiCautionComponent } from 'src/app/shared/shared-components/ai-ui-caution/ai-ui-caution.component';
import { AiUiClaimDetailsComponent } from 'src/app/shared/shared-components/ai-ui-claim-details/ai-ui-claim-details.component';
import { ToasterService } from 'src/app/shared/shared-services/toaster/toaster.service';
import { animationOptions } from 'src/app/utils/animation';
import { ClaimStatus, DepoType, acceptedFormates, convertByteToMegaBytes, maxFileSize } from 'src/app/utils/utils';

@Component({
  selector: 'app-claim-info',
  templateUrl: './claim-info.component.html',
  styleUrls: ['./claim-info.component.scss'],
  animations: animationOptions,
})
export class ClaimInfoComponent implements OnInit, OnDestroy {
  claim: ClaimResponse;

  emittedAttachments: AttachmentBase[] = [];

  attachments: Attachment[] = [];

  claimReasonsDocuments: Attachment[] = [];

  claimAttachmentUrl = (id: number): string => `${apiUrlWithPrefix}/claim-attachment/${id}/preview/`;

  claimDocumentAttachmentUrl = (id: number): string => `${apiUrlWithPrefix}/claim-document/${id}/preview/`;

  constructor(
    public auth: AuthService,
    public claimsService: ClaimsService,
    public documentService: DocumentService,
    private activatedRoute: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private router: Router,
    private toaster: ToasterService,
    private translation: TranslationService,
    private dialog: MatDialog,
    private loader: LoaderService
  ) {}

  ngOnInit(): void {
    const param = this.activatedRoute.snapshot.paramMap.get('id');
    const claimId = Number.parseInt(param);
    this.claimsService.claimById(claimId).subscribe({
      next: (res) => {
        this.claim = res;
        this.cd.markForCheck();
        this.loader.hideLoader();
        if (this.claim.exchange_rate) {
          this.claim.exchange_rate = Number(this.claim.exchange_rate).toFixed(4);
        }
        this.loader.showLoader();
        this.documentService.claimDocumentsProcessing(claimId).subscribe({
          next: (data) => {
            this.attachments = data;
            this.loader.hideLoader();
          },
          error: () => {
            this.loader.hideLoader();
          },
        });
      },
      error: (err: HttpErrorResponse) => {
        if (err.status === 404) {
          this.toaster.showError(this.translation.translate('CLAIM_NOT_FOUND'));
          this.router.navigate(['']);
        }
        this.loader.hideLoader();
      },
    });
  }

  isAllowedToCancel(claim: ClaimResponse): boolean {
    return !([DepoType.depositary_agreement, DepoType.depositary_additional_consent] as string[]).includes(claim.type);
  }

  cancelClaim(id: number) {
    this.dialog
      .open(AiUiCautionComponent, {
        width: '500px',
        data: {
          content: this.translation.translate('CAUTION:CANCEL_CLAIM'),
        },
      })
      .afterClosed()
      .subscribe((result: boolean) => {
        if (!result) {
          return;
        }
        this.loader.showLoader();
        this.claimsService.cancelClaim(id).subscribe({
          next: () => {
            this.claim.status = ClaimStatus.rejected;
            this.toaster.showInfo(this.translation.translate('CLAIM_HAS_BEEN_CANCELED'));
            this.cd.markForCheck();
            this.loader.hideLoader();
          },
          error: () => {
            this.loader.hideLoader();
          },
        });
      });
  }

  openClaimDetails(claim: ClaimBase) {
    this.dialog.open(AiUiClaimDetailsComponent, {
      panelClass: 'popup-container',
      data: claim,
      width: '800px',
    });
  }

  ngOnDestroy(): void {
    this.claimsService.attachments$.next(null);
  }
}
