export const environment = {
  production: true,
  apiUrl: 'https://stage-cabinet.aigenis.ru',
  endPointPrefix: 'api/v1',
  userProfileRefreshInterval: 5000,
  portfolioRefreshInterval: 5000,
  pushNotificationRefreshInterval: 5000,
  reCaptchaSiteKey: '6Ld5-X0oAAAAADFbnCLXH1cfczaGBRQToIFA4GeR',
  reCaptchaPrivateKey: '6Ld5-X0oAAAAAIi9z-ehcLq75XjeJ_5DHR99pa5Y',
};

export const apiUrlWithPrefix = `/${environment.endPointPrefix}`;
