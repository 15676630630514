import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { TranslationService } from '@localization';
import { Observable } from 'rxjs';
import { ToasterService } from 'src/app/shared/shared-services/toaster/toaster.service';
import { ClaimStatus, ProfileStatus } from 'src/app/utils/utils';
import { AgreementStatus } from '../../utils/utils';
import { AuthService } from '../auth/auth.service';
import { LoaderService } from '../loader/loader.service';

@Injectable({
  providedIn: 'root',
})
export class ClaimGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private toaster: ToasterService,
    private translation: TranslationService,
    private loader: LoaderService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (this.authService.userProfile$.value?.status === ProfileStatus.pii_not_filled) {
      this.toaster.showError(this.translation.translate('ERROR:user_has_incorrect_status'));
      this.router.navigate(['/']);
      return false;
    } else if (this.authService.userProfile$.value?.status === ProfileStatus.pii_validating) {
      this.toaster.showError(this.translation.translate('ERROR:personal_data_not_approved'));
      this.router.navigate(['/']);
      return false;
    } else if (
      (!this.authService.brokerageService$.value ||
        this.authService.brokerageService$.value?.created_by_claim?.status === ClaimStatus.rejected) && // TODO check the claim status instead of agreement
      route.url[0].path !== 'bo_origin_new'
    ) {
      this.router.navigate(['/']);
      return false;
    } else if (
      (this.authService.brokerageService$.value?.status !== AgreementStatus.active ||
        this.authService.userProfile$.value?.docs_signed === false) &&
      route.url[0].path !== 'bo_origin_new'
    ) {
      this.toaster.showError(this.translation.translate('ERROR:BROKERAGE_AGREEMENT_IS_NOT_ACTIVE'));
      return false;
    } else if (
      (this.authService.depositaryService$.value?.status !== AgreementStatus.active ||
        this.authService.userProfile$.value?.docs_signed === false) &&
      route.url[0].path !== 'bo_origin_new' &&
      [
        'depo_manual_assignment_new',
        'depo_reference_new',
        'depo_transaction_reference_registry_new',
        'depo_transaction_reference_new',
        'depo_transaction_transfer_reference_new',
      ].includes(route.url[0].path)
    ) {
      this.toaster.showError(this.translation.translate('ERROR:DEPOSITARY_AGREEMENT_IS_NOT_ACTIVE'));
      return false;
    }
    this.loader.showLoader();
    this.authService.getAllowedSignToChangePersonalData().subscribe((data) => {
      if (this.authService.userProfile$?.value?.status === ProfileStatus.pii_editing && data?.result === false) {
        this.toaster.showError(this.translation.translate(data.reason));
        this.router.navigate(['/']);
      }
      this.loader.hideLoader();
    });
    return true;
  }
}
