import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiUrlWithPrefix, environment } from '@env';
import { TranslationService } from '@localization';
import { BehaviorSubject, Subscription, interval, map, mergeMap } from 'rxjs';
import { PushNotificationResponse } from 'src/app/interfaces/api/push-notification.interface';
import { ToasterService } from 'src/app/shared/shared-services/toaster/toaster.service';
import { LoaderService } from '../loader/loader.service';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {
  interval: Subscription;

  static readonly PUSH_NOTIFICATION_FROM_KEY = 'push_notification_from';

  notifications$: BehaviorSubject<PushNotificationResponse[]> = new BehaviorSubject<PushNotificationResponse[]>(null);

  static readonly PUSH_NOTIFICATIONS: string = `${apiUrlWithPrefix}/push_notification/`;
  static readonly READ_ALL_NOTIFICATIONS: string = `${apiUrlWithPrefix}/push_notification/read-all/`;
  static readonly readNotificationById = (id: number) => `${apiUrlWithPrefix}/push_notification/${id}/read/`;

  private get push_notification_from(): string {
    return localStorage.getItem(PushNotificationService.PUSH_NOTIFICATION_FROM_KEY) || null;
  }

  private set push_notification_from(value: string) {
    localStorage.setItem(PushNotificationService.PUSH_NOTIFICATION_FROM_KEY, value);
  }

  constructor(
    private http: HttpClient,
    private loader: LoaderService,
    private toaster: ToasterService,
    private translation: TranslationService
  ) {}

  getNotifications() {
    this.loader.showLoader();
    this.http
      .get(PushNotificationService.PUSH_NOTIFICATIONS)
      .pipe(map((res: any) => res.results as PushNotificationResponse[]))
      .subscribe({
        next: (data) => {
          this.notifications$.next(data);
          this.push_notification_from = new Date().toISOString();
          this.loader.hideLoader();
        },
        error: () => {
          this.loader.hideLoader();
        },
      });
  }

  pushNotificationListener() {
    let now = null;
    this.interval = interval(environment.pushNotificationRefreshInterval)
      .pipe(
        // startWith(0),
        mergeMap(() => {
          now = new Date().toISOString();
          return this.http
            .get(PushNotificationService.PUSH_NOTIFICATIONS, {
              params: new HttpParams({
                fromObject: {
                  datetime_before: now,
                  datetime_after: this.push_notification_from,
                },
              }),
            })
            .pipe(map((res: any) => res.results as PushNotificationResponse[]));
        })
      )
      .subscribe({
        next: (res) => {
          this.push_notification_from = now;
          if (res.length) {
            this.toaster.showInfo(this.translation.translate('PUSH:NEW_NOTIFICATION'));
            const notifications = this.notifications$?.getValue() || [];
            notifications.unshift(...res);
            this.notifications$.next(notifications);
          }
        },
      });
  }

  readById(id: number) {
    this.loader.showLoader();
    this.http.put(PushNotificationService.readNotificationById(id), null).subscribe((_) => {
      this.loader.hideLoader();
    });
  }

  readAll() {
    this.loader.showLoader();
    this.http.put(PushNotificationService.READ_ALL_NOTIFICATIONS, null).subscribe((_) => {
      this.loader.hideLoader();
      this.getNotifications();
    });
  }

  public resetNotificationListener() {
    this.interval && this.interval.unsubscribe();
  }
}
