import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BrokerageService {
  static readonly _brokerageServiceSteps: [number, string][] = [
    [0, 'decor'],
    [1, 'requisites'],
    [2, 'condition_of_acceptate'],
    [3, 'additionally'],
    [4, 'signature'],
  ];

  private _brokerageServiceStepsMap = new Map(BrokerageService._brokerageServiceSteps);

  private _brokerageServiceStep: number = 0;

  set brokerageServiceCurrentStep(step: number) {
    this._brokerageServiceStep = step;
  }

  get brokerageServiceStepKey(): number {
    return this._brokerageServiceStep;
  }

  get brokerageServiceCurrentStepValue() {
    return this._brokerageServiceStepsMap.get(this._brokerageServiceStep);
  }

  get brokerageServiceStepsCount() {
    return BrokerageService._brokerageServiceSteps.length;
  }

  public goToStep(step: number) {
    this.brokerageServiceCurrentStep = step;
  }

  constructor() {}
}
