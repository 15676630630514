import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[formControlName][uppercase]',
})
export class UppercaseDirective {
  pattern: RegExp = new RegExp('^[a-z]+$');

  element: HTMLInputElement;

  constructor(private readonly control: NgControl, element: ElementRef) {
    this.element = element.nativeElement;
  }

  @HostListener('input', ['$event'])
  onKeyDown(event: any) {
    event.target.value = event.target.value.toUpperCase();
    this.control.control.setValue(event.target.value);
    return true;
  }
}
