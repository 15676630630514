<mat-form-field appearance="outline">
  <mat-label>{{ label }}</mat-label>
  <input matInput
    placeholder="{{ 'SEARCH' | translate }}"
    [matAutocomplete]="auto"
    [formControl]="formControlObj">
  <mat-autocomplete #auto="matAutocomplete"
    [displayWith]="displayFn"
    (optionSelected)="onOptionSelected($event)">
    <mat-option *ngFor="let item of (filteredItems | async)"
      [value]="item">
      {{ item.name }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>