import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ai-ui-restriction',
  templateUrl: './ai-ui-restriction.component.html',
  styleUrls: ['./ai-ui-restriction.component.scss'],
})
export class AiUiRestrictionComponent {
  @Input() type:
    | 'fillPersonalData'
    | 'waitingForApproval'
    | 'waitingForAigenisAccountBinding'
    | 'brokerageAgreementNew'
    | 'createBrokerageService'
    | 'brokerageServiceApprovalNew'
    | 'waitingForBrokerageServiceApproval'
    | 'depositaryAgreementNew'
    | 'createDepositaryService'
    | 'depositaryServiceApprovalNew'
    | 'waitingForDepositaryServiceApproval' = 'fillPersonalData';

  @Input() showFaq: boolean = true;

  constructor(private router: Router) {}

  navigate() {
    switch (this.type) {
      case 'fillPersonalData':
        this.router.navigate(['profile', 'edit']);
        break;
      case 'createBrokerageService':
        this.router.navigate(['claims', 'bo_origin_new']);
        break;
      case 'createDepositaryService':
        this.router.navigate(['claims', 'depo_origin_new']);
        break;
      case 'waitingForAigenisAccountBinding':
        this.router.navigate(['profile', 'settings']);
        break;
      default:
        break;
    }
  }
}
