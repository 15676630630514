import { Component, Input } from '@angular/core';

@Component({
  selector: 'ai-ui-footer',
  templateUrl: './ai-ui-footer.component.html',
  styleUrls: ['./ai-ui-footer.component.scss'],
})
export class AiUiFooterComponent {
  year = new Date().getFullYear();

  @Input() background: 'dark' | 'light' = 'dark';
}
