<div [ngClass]="{'shade': !isReadonly}">
  <div class="row">
    <div class="col-12">
      <ng-container *ngIf="!isReadonly">
        <div class="title">
          <h3>{{ 'DOCUMENTS' | translate }}</h3>
        </div>
      </ng-container>
      <ng-container *ngIf="isCreatingOrder && attachments.length">
        <div class="hint">
          <p>{{ 'CLAIM_DOCUMENTS_INFORMATION' | translate }}</p>
        </div>
        <div class="hint" *ngIf="tradeOnSpbStock">
          <p class="alert">{{ 'BS:W8_TIP' | translate: [claimTypeName] }}</p>
        </div>
      </ng-container>
      <ng-container
        *ngIf="isClaimInfo && ['broker_agreement', 'brokerage_additional_consent'].includes(claim.type) && (claim.status === 'new' || claim.status === 'in_progress') && claim.trade_on_spb_stock===true && claim.is_w_eight_scan_attached===false">
        <input class="file-input" type="file" [accept]="acceptedFormates" (change)="fileSelected($event)" #file>
        <div class="restriction">
          <div class="hint">
            <p class="alert">{{ 'W8BEN:RESTRICTION_CONTENT' | translate }}</p>
          </div>
          <div class="file-upload__attach" style="cursor: pointer" (click)="file.click()">
            <p>{{ 'UPLOAD' | translate }}</p>
            <mat-icon> attach_file</mat-icon>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="col">

    </div>
    <div class="w-100"></div>
    <ng-container *ngIf="attachments?.length; else notExists">
      <div class="row attachments">
        <ng-container *ngFor="let attachment of attachments">
          <ng-container *ngIf="attachment?.type==='w8ben_scan' && isClaimInfo && (claim.status === 'new' || claim.status === 'in_progress')">
            <div class="close" (click)="onDelete(attachment.id)">
              <mat-icon>close</mat-icon>
            </div>
          </ng-container>
          <ng-container *ngIf="attachment.isImage else document">
            <div class="attachment col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
              <img [src]="attachment.url" [alt]="attachment.file_name" (click)="open(attachment.url)">
            </div>
          </ng-container>
          <ng-template #document>
            <div class="document d-flex align-items-center justify-content-between">
              <img src="./assets/images/document-gray.svg" alt="logo" class="me-3">
              <div class="flex-grow-1">{{ getDocumentName(attachment.file_name) }}</div>
              <div class="actions">
                <a mat-button class="d-flex align-items-center me-2" [href]="attachment.url" target="_blank">
                  {{ 'OPEN' | translate }}
                  <mat-icon class="ms-2">visibility</mat-icon>
                </a>
                <a mat-button class="d-flex align-items-center" [href]="attachment.url" [download]="attachment.file_name" target="_blank">
                  {{ 'DOWNLOAD' | translate }}
                  <mat-icon class="ms-2">download</mat-icon>
                </a>
                <a *ngIf="abilityToSignConsent && attachment?.is_attachment && attachment.type==='additional_consent' && !attachment?.sign_datetime" mat-button class="d-flex align-items-center" (click)="signAttachment(attachment)">
                  {{ 'SIGN' | translate }}
                  <mat-icon class="ms-2">edit</mat-icon>
                </a>
                <ng-container *ngIf="attachment?.doc_signed">
                  <a mat-button class="d-flex align-items-center" (click)="onComplexDownload(attachment)">
                    {{ 'DOWNLOAD_WITH_SIGNATURE' | translate }}
                    <mat-icon class="ms-2">download</mat-icon>
                  </a>
                </ng-container>
              </div>
            </div>
          </ng-template>
        </ng-container>
      </div>
    </ng-container>
    <ng-template #notExists>
      <div class="hint">
        <p>{{ 'DOCUMENTS_NOT_FOUND' | translate }}</p>
      </div>
    </ng-template>
  </div>
</div>