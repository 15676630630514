import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[amount]',
})
export class AmountDirective {
  @Input() decimalPlace: number = 2;

  maxValue: number = 9999999999;

  private pattern: RegExp = new RegExp('^[0-9.]+$');

  /**
   * allowed keys to press:
   * 8 - backspace
   * 9 - tab
   * 13 - enter
   * 27 - escape
   * 37 - left arrow
   * 39 - right arrow
   * 44 - print screen
   * 46 - delete
   */
  // allowedKeys: number[] = [8, 9, 13, 27, 37, 39, 44, 46]

  private _allowedKeys: string[] = [
    'Backspace',
    'Tab',
    'Enter',
    'Escape',
    'ArrowLeft',
    'ArrowRight',
    'Delete',
    'PrintScreen',
  ];

  element: HTMLInputElement;

  constructor(element: ElementRef) {
    this.element = element.nativeElement;
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this._allowedKeys.includes(event.key)) {
      return true;
    }
    const decimalPart = this.element.value?.split('.')[1];
    if (
      !this.pattern.test(event.key) ||
      (event.key === '.' && !this.element.value) ||
      (event.key === '.' && this.element.value.indexOf('.') !== -1) ||
      (decimalPart &&
        decimalPart.length === this.decimalPlace &&
        this.element.selectionStart === this.element.selectionEnd) ||
      Number(this.element.value.toString() + event.key) > this.maxValue
    ) {
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
    return true;
  }

  @HostListener('blur', ['$event'])
  onBlur(event: any) {
    event.target.value = this.formatValue(event.target.value.trim());
  }

  private formatValue(value: string) {
    return value && Number(value).toFixed(this.decimalPlace);
  }
}
