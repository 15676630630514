import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, Validators } from '@angular/forms';
import { apiUrlWithPrefix } from '@env';
import { mergeMap } from 'rxjs';
import { Attachment, AttachmentBase } from 'src/app/interfaces/api/attachment.interface';
import { ClaimResponse, ClaimsBatchResponse, ManualAssignmentRequest } from 'src/app/interfaces/api/claims.interface';
import { ClaimBaseComponent } from 'src/app/main/components/claims/claim-base/claim-base.component';
import { AiUiImageDialogComponent } from 'src/app/shared/shared-components/ai-ui-image-dialog/ai-ui-image-dialog.component';
import { animationOptions } from 'src/app/utils/animation';
import {
  ClaimAttachmentType,
  OtherType,
  acceptedFormates,
  convertByteToMegaBytes,
  maxFileSize,
} from 'src/app/utils/utils';

@Component({
  selector: 'ai-ui-manual-assignment',
  templateUrl: './ai-ui-manual-assignment.component.html',
  styleUrls: ['./ai-ui-manual-assignment.component.scss'],
  animations: animationOptions,
})
export class AiUiManualAssignmentComponent extends ClaimBaseComponent implements OnInit, OnDestroy {
  @Input() claimType: 'manual_assignment' | 'arbitrary_order' | 'depo_arbitrary_order';

  theFirstConfirmation: FormControl = new FormControl(true, [Validators.required]);

  claim: ClaimResponse;

  claimAttachments: Attachment[] = [];

  acceptedFormates = '.pdf';

  attachmentUrl = (id: number): string => `${apiUrlWithPrefix}/claim-attachment/${id}/preview/`;

  ngOnInit(): void {
    this.form = this.initForm();
  }

  initForm() {
    return this.fb.group({
      text: new FormControl(null, [Validators.required]),
      attachments: this.fb.array([]),
    });
  }

  next() {
    if (this.form.invalid) {
      return;
    }
    const payload = this.form.value as ManualAssignmentRequest;
    // TODO make this field as dynamically
    payload.type = OtherType.manual_assignment;
    this.loader.showLoader();
    this.claimsService
      .addClaimsBatch([payload])
      .pipe(
        mergeMap((res: ClaimsBatchResponse) => {
          this.claimBatchId = res.id;
          return this.documentService.claimsBatchDocumentsProcessing(res.id);
        })
      )
      .subscribe({
        next: (data) => {
          this.attachmentsToSign = data;
          this.cfService.goToStep(this.cfService.stepKey + 1);
          this.loader.hideLoader();
          this.cd.markForCheck();
        },
        error: () => {
          this.loader.hideLoader();
        },
      });
  }

  fileSelected(event) {
    if (this.claimAttachments.length === 1) {
      this.toaster.showError('ERROR: at_most_one_attachment');
      return;
    }
    const file: File = event.target.files[0];
    let formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('type', ClaimAttachmentType.additional_consent);
    if (file.size > maxFileSize) {
      this.toaster.showError(this.translation.translate('FILE_SIZE_INCORRECT', [convertByteToMegaBytes(maxFileSize)]));
      return;
    }
    this.loader.showLoader();
    this.claimsService.addClaimAttachment(formData).subscribe({
      next: (data: AttachmentBase) => {
        (this.form.controls.attachments as FormArray).push(new FormControl(data.id));
        this.updateAttachments(data);
        event.target.value = null;
        this.cd.markForCheck();
        this.loader.hideLoader();
      },
      error: () => {
        this.loader.hideLoader();
      },
    });
  }

  open(url: string) {
    this.dialog.open(AiUiImageDialogComponent, {
      panelClass: 'attachment-container',
      data: {
        url,
      },
    });
  }

  onDelete(id: number) {
    this.loader.showLoader();
    this.claimsService.deleteAttachment(id).subscribe({
      next: () => {
        this.toaster.showInfo(this.translation.translate('ATTACHMENT_HAS_BEEN_REMOVED'));
        this.claimAttachments = this.claimAttachments.filter((att) => att.id !== id);
        const index = (this.form.controls.attachments as FormArray).value.findIndex((n) => n === id);
        (this.form.controls.attachments as FormArray).removeAt(index);
        this.cd.markForCheck();
        this.loader.hideLoader();
      },
      error: () => {
        this.loader.hideLoader();
      },
    });
  }

  private updateAttachments(data: AttachmentBase): void {
    this.loader.showLoader();
    this.claimsService.fileToBlob(this.attachmentUrl(data.id)).subscribe({
      next: (url: string) => {
        this.claimAttachments.push({
          id: data.id,
          file_name: data.file_name,
          url,
          isImage: ['jpeg', 'jpg', 'png']?.indexOf(data.file_name?.split('.')?.pop()?.toLowerCase()) !== -1,
        });
        this.toaster.showSuccess(this.translation.translate('ATTACHMENT_HAS_BEEN_ADDED'));
        this.cd.markForCheck();
        this.loader.hideLoader();
      },
      error: () => {
        this.loader.hideLoader();
      },
    });
  }
}
