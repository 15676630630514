<div class="close" mat-dialog-close>
  <mat-icon>close</mat-icon>
</div>
<div class="title">
    <h5>{{ 'ADDITIONAL_CONSENT:TARIFF_CHANGING_TITLE' | translate }}</h5>
    <span>{{ 'ADDITIONAL_CONSENT:TARIFF_CHANGING_SUBTITLE' | translate }}</span>
</div>
<div class="tariffs col-12">
  <ng-container *ngFor="let tariff of tariffs">
    <div class="tariff col-12">
      <div class="tariff__logo">
        <img [src]="'./assets/logos/' + tariff + '.svg'" alt="logo" />
      </div>
      <div class="tariff__description">
        <span>{{ 'TARIFF:' + tariff | translate }}</span>
      </div>
      <div class="tariff__footer">
        <button mat-button class="filled" type="button" (click)="chooseTariff(tariff)">
          {{ 'CHOOSE' | translate }}
        </button>
      </div>
    </div>
  </ng-container>
</div>
