import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from './translation.service';

@Pipe({
  name: 'translate',
})
export class TranslationPipe implements PipeTransform {
  constructor(private translationsService: TranslationService) {}

  /**
   *
   * @param key
   * @param args
   */
  transform(key: string, args?: Array<string | number>): string {
    return this.translationsService.translate(key, args);
  }
}
