import { HttpParams } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SecurityResponse } from 'src/app/interfaces/api/security.interface';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { SecurityService } from 'src/app/services/security/security.service';

interface Data {
  securities: SecurityResponse[];
  claimSecurityForm: FormGroup;
  exchangeId: number;
}

@Component({
  selector: 'app-ai-ui-security-search',
  templateUrl: './ai-ui-security-search.component.html',
  styleUrls: ['./ai-ui-security-search.component.scss'],
})
export class AiUiSecuritySearchComponent {
  securityId: number;

  displayedColumns: string[] = ['ISIN', 'FULL_NAME', 'INSTR_TYPE', 'INSTR_CATEGORY', 'STATE_REG_NUMBER'];

  constructor(
    public securityService: SecurityService,
    public loader: LoaderService,
    @Inject(MAT_DIALOG_DATA) public data: Data,
    private dialogRef: MatDialogRef<AiUiSecuritySearchComponent>
  ) {}

  onIsinCodeInputted(event: any) {
    const value = event.target.value;
    if (value.length > 2) {
      this.loader.showLoader();
      const httpParams = new HttpParams({
        fromObject: {
          isin: value,
        },
      });
      this.securityService.findByParams(httpParams).subscribe({
        next: (data) => {
          this.data.securities = data;
          this.loader.hideLoader();
        },
        error: () => {
          this.data.securities = [];
          this.loader.hideLoader();
        },
      });
    } else {
      this.data.securities = [];
    }
  }

  onIsinSelected(security: SecurityResponse) {
    this.dialogRef.close({
      security: security,
      claimSecurityForm: this.data.claimSecurityForm,
    });
  }
}
