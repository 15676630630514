import { HttpParams } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { TranslationService } from '@localization';
import { BankAccountRequest, BankAccountResponse, BankResponse } from 'src/app/interfaces/api/bank.interface';
import { BankService } from 'src/app/services/bank/bank.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import {
  BANK_NAME_REGEXP,
  CountryCode,
  Currency,
  addIBANPrefix,
  checkingAccountMaskBY,
  checkingAccountMaskRU,
} from 'src/app/utils/utils';
import { ToasterService } from '../../shared-services/toaster/toaster.service';

@Component({
  selector: 'ai-ui-bank-base',
  templateUrl: './ai-ui-bank-base.component.html',
  styleUrls: ['./ai-ui-bank-base.component.scss'],
})
export class AiUiBankBaseComponent implements OnInit {
  @Input() operation: 'add' | 'edit' = 'add';

  @Input() emitEvent: boolean = false;

  @Output() afterAddBankAccount: EventEmitter<number> = new EventEmitter<number>();

  readonly checkingAccountMaskBY: string = checkingAccountMaskBY;

  readonly checkingAccountMaskRU: string = checkingAccountMaskRU;

  currencies = Array.from(new Set(Object.keys(Currency)));

  form: FormGroup;

  selectedBank: BankResponse;

  banks: BankResponse[] = [];

  country_code = new FormControl(CountryCode.BY, Validators.required);

  name = new FormControl(null, Validators.required);

  address = new FormControl({ value: null, disabled: true });

  city = new FormControl({ value: null, disabled: true });

  bic = new FormControl(null, Validators.required);

  correspondent_account = new FormControl({ value: null, disabled: true });

  // only for russian banks:

  phone = new FormControl({ value: null, disabled: true });

  index = new FormControl({ value: null, disabled: true });

  okato = new FormControl({ value: null, disabled: true });

  okpo = new FormControl({ value: null, disabled: true });

  regnum = new FormControl({ value: null, disabled: true });

  // only for Belarusian banks:

  number = new FormControl({ value: null, disabled: true });

  constructor(
    public bankService: BankService,
    public loader: LoaderService,
    public cd: ChangeDetectorRef,
    public toaster: ToasterService,
    public translation: TranslationService,
    public dialogRef: MatDialogRef<AiUiBankBaseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.form = this.initForm();
    if (this.operation === 'edit') {
      this.loader.showLoader();
      this.bankService.bankAccountById(this.data.bankAccountId).subscribe({
        next: (res: BankAccountResponse) => {
          this.selectedBank = res.bank;
          this.banks = [res.bank];
          this.country_code.setValue(this.selectedBank.country as CountryCode);
          this.updateData();
          this.form.patchValue(res);
          this.form
            .get('checking_account')
            .setValidators([Validators.required, Validators.minLength(this.getCheckingAccountLength())]);
          this.cd.markForCheck();
          this.loader.hideLoader();
        },
        error: () => {
          this.loader.hideLoader();
        },
      });
    } else {
      this.form
        .get('checking_account')
        .setValidators([Validators.required, Validators.minLength(this.getCheckingAccountLength())]);
    }
  }

  initForm() {
    return new FormGroup({
      currency_code: new FormControl(Currency.RUB, [Validators.required]),
      checking_account: new FormControl(null, [Validators.required]),
      personal_account: new FormControl(null),
      internal_account_name: new FormControl(null),
      name: new FormControl(null, [
        Validators.required,
        Validators.pattern(BANK_NAME_REGEXP),
        Validators.maxLength(15),
      ]),
      purpose_of_payment: new FormControl(null),
      bank_id: new FormControl(null, Validators.required),
    });
  }

  onBankNameInputted(event: any) {
    const value = event.target.value;
    if (value.length > 2) {
      this.loader.showLoader();
      const params = new HttpParams({
        fromObject: {
          country: this.country_code.value,
          name: value,
        },
      });
      this.bankService.findBanksByParams(params).subscribe({
        next: (data) => {
          this.banks = data;
          this.loader.hideLoader();
        },
        error: () => {
          this.banks = [];
          this.loader.hideLoader();
        },
      });
    } else {
      this.resetData();
    }
  }

  onBankNameSelected(event: MatSelectChange) {
    this.selectedBank = this.banks.filter((b) => b.id === event.value)[0];
    this.updateData();
  }

  onBicInputted(event: any) {
    const value = event.target.value;
    if (value.length > 2) {
      this.loader.showLoader();
      const params = new HttpParams({
        fromObject: {
          country: this.country_code.value,
          bic: value,
        },
      });
      this.bankService.findBanksByParams(params).subscribe({
        next: (data) => {
          this.banks = data;
          this.loader.hideLoader();
        },
        error: () => {
          this.banks = [];
          this.loader.hideLoader();
        },
      });
    } else {
      this.resetData();
    }
  }

  onBicSelected(event: any) {
    this.selectedBank = this.banks.filter((b) => b.id === event.value)[0];
    this.updateData();
  }

  updateData() {
    this.name.setValue(this.selectedBank.id);
    this.bic.setValue(this.selectedBank.id);
    this.address.setValue(this.selectedBank.address);
    this.correspondent_account.setValue(this.selectedBank.correspondent_account);
    this.phone.setValue(this.selectedBank.phone);
    this.index.setValue(this.selectedBank.index);
    this.okato.setValue(this.selectedBank.okato);
    this.okpo.setValue(this.selectedBank.okpo);
    this.regnum.setValue(this.selectedBank.regnum);
    this.number.setValue(this.selectedBank.number);
    this.form.patchValue({
      bank_id: this.selectedBank.id,
    });
  }

  resetData() {
    this.name.setValue(null);
    this.address.setValue(null);
    this.correspondent_account.setValue(null);
    this.selectedBank = null;
    this.banks = [];
    this.form.patchValue({
      bank_id: null,
    });
  }

  onCountryCodeChange() {
    this.form.patchValue({
      checking_account: '',
      personal_account: '',
      internal_account_name: '',
      phone: '',
      index: '',
      okato: '',
      okpo: '',
      regnum: '',
      number: '',
    });
    this.resetData();
    this.form
      .get('checking_account')
      .setValidators([Validators.required, Validators.minLength(this.getCheckingAccountLength())]);
    this.form.get('checking_account').updateValueAndValidity({ emitEvent: false });
  }

  submit() {
    if (this.form.invalid || !this.selectedBank) {
      return;
    }
    const payload = this.form.value as BankAccountRequest;
    if (this.selectedBank.country === CountryCode.BY) {
      payload.checking_account = addIBANPrefix(payload.checking_account);
    }
    payload.checking_account = payload.checking_account.replace(/\s/g, '');
    if (this.operation === 'add') {
      this.loader.showLoader();
      this.bankService.addBankAccount(payload).subscribe({
        next: (res: BankAccountResponse) => {
          this.toaster.showSuccess(this.translation.translate('BANK_ACCOUNT_HAS_BEEN_ADDED'));
          if (this.emitEvent) {
            this.afterAddBankAccount.emit(res.id);
          } else {
            this.dialogRef.close(true);
          }
          this.loader.hideLoader();
        },
        error: () => {
          this.loader.hideLoader();
        },
      });
    } else {
      this.loader.showLoader();
      this.bankService.editBankAccount(this.data.bankAccountId, payload).subscribe({
        complete: () => {
          this.toaster.showSuccess(this.translation.translate('BANK_ACCOUNT_HAS_BEEN_CHANGED'));
          this.dialogRef.close(true);
          this.loader.hideLoader();
        },
        error: () => {
          this.loader.hideLoader();
        },
      });
    }
  }

  private getCheckingAccountLength() {
    if (this.country_code.value === CountryCode.BY) {
      return ('BY' + checkingAccountMaskBY).length;
    } else {
      return checkingAccountMaskRU.length;
    }
  }
}
