<div class="container-fluid bg-white p-4">
  <h1>{{ 'PROFILE:EDIT' | translate }}</h1>
  <div class="row">
    <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 form-data">
      <div [@open]="'opened'" *ngIf="profileService.profileCurrentStepValue === 'personalData'" class="personal-data">
        <form [formGroup]="personalDataForm">
          <h3>{{ 'PROFILE:PERSONAL_DATA' | translate }}</h3>
          <mat-form-field appearance="outline">
            <mat-label>{{ 'FIELD:LAST_NAME' | translate }}</mat-label>
            <input russianLetters formControlName="last_name" matInput />
            <mat-error *ngIf="personalDataForm.get('last_name').errors?.required">
              {{ 'ERROR:REQUIRED' | translate }}
            </mat-error>
            <mat-error *ngIf="personalDataForm.get('last_name').errors?.pattern">
              {{ 'ERROR:ONLY_LETTERS_AND_SPECIAL_CHARACTERS' | translate }}
            </mat-error>
            <mat-error *ngIf="personalDataForm.get('last_name').errors?.maxlength">
              {{ 'ERROR:MAX_LENGTH' | translate : [15] }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{ 'FIELD:FIRST_NAME' | translate }}</mat-label>
            <input russianLetters formControlName="first_name" matInput />
            <mat-error *ngIf="personalDataForm.get('first_name').errors?.required">
              {{ 'ERROR:REQUIRED' | translate }}
            </mat-error>
            <mat-error *ngIf="personalDataForm.get('first_name').errors?.pattern">
              {{ 'ERROR:ONLY_LETTERS_AND_SPECIAL_CHARACTERS' | translate }}
            </mat-error>
            <mat-error *ngIf="personalDataForm.get('first_name').errors?.maxlength">
              {{ 'ERROR:MAX_LENGTH' | translate : [15] }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{ 'FIELD:MIDDLE_NAME' | translate }}</mat-label>
            <input russianLetters formControlName="middle_name" matInput />
            <mat-error *ngIf="personalDataForm.get('middle_name').errors?.maxlength">
              {{ 'ERROR:MAX_LENGTH' | translate : [15] }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{ 'FIELD:SEX' | translate }}</mat-label>
            <mat-select formControlName="sex">
              <mat-option *ngFor="let type of male_female" [value]="type">
                {{ 'SEX:' + type | translate }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="personalDataForm.get('sex').errors?.required">
              {{ 'ERROR:REQUIRED' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{ 'FIELD:BIRTH_DATE' | translate }}</mat-label>
            <input dateFormat matInput formControlName="birth_date" [max]="today" [matDatepicker]="picker" />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error *ngIf="personalDataForm.get('birth_date').errors?.required">
              {{ 'ERROR:REQUIRED' | translate }}
            </mat-error>
            <mat-error *ngIf="personalDataForm.get('birth_date').errors?.young">
              {{ 'ERROR:NOT_UNDER_18' | translate }}
            </mat-error>
          </mat-form-field>
          <h3>{{ 'PROFILE:PASSPORT' | translate }}</h3>
          <ng-container formGroupName="passport">
            <div class="passport-type">
              <mat-radio-group formControlName="type" (change)="onPassportTypeChange()">
                <ng-container
                  *ngIf="personalDataForm.get('passport').get('citizenship')?.value === 'BY'; else rfpassport">
                  <mat-radio-button color="primary" value="id_card">
                    {{ 'PASSPORT_TYPE:ID_CARD' | translate }}
                  </mat-radio-button>
                  <mat-radio-button color="primary" value="passport">
                    {{ 'PASSPORT_TYPE:PASSPORT' | translate }}
                  </mat-radio-button>
                </ng-container>
                <ng-template #rfpassport>
                  <mat-radio-button color="primary" value="rf_passport">
                    {{ 'PASSPORT_TYPE:RF_PASSPORT' | translate }}
                  </mat-radio-button>
                </ng-template>
              </mat-radio-group>
            </div>
            <mat-form-field appearance="outline">
              <mat-label>{{ 'FIELD:CITIZENSHIP' | translate }}</mat-label>
              <mat-select formControlName="citizenship" (selectionChange)="onCitizenshipChange($event.value)">
                <mat-option *ngFor="let country of countries" [value]="country">
                  {{ 'COUNTRY:' + country | translate }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="personalDataForm.get('passport').get('citizenship').errors?.required">
                {{ 'ERROR:REQUIRED' | translate }}
              </mat-error>
            </mat-form-field>
            <ng-container *ngIf="personalDataForm.get('passport').get('type').value !== 'rf_passport'">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'FIELD:ID_NUMBER' | translate }}</mat-label>
                <input uppercase englishLetters formControlName="id_number" matInput />
                <mat-error *ngIf="personalDataForm.get('passport').get('id_number').errors?.required">
                  {{ 'ERROR:REQUIRED' | translate }}
                </mat-error>
                <mat-error *ngIf="personalDataForm.get('passport').get('id_number').errors?.maxlength">
                  {{ 'ERROR:MAX_LENGTH' | translate : [30] }}
                </mat-error>
              </mat-form-field>
            </ng-container>
            <mat-form-field appearance="outline">
              <mat-label>{{ 'FIELD:FULL_NAME' | translate }}</mat-label>
              <input uppercase englishLetters formControlName="full_name" matInput />
              <mat-hint>{{ 'HINT:AS_IN_THE_PASSPORT' | translate }}</mat-hint>
              <mat-error *ngIf="personalDataForm.get('passport').get('full_name').errors?.required">
                {{ 'ERROR:REQUIRED' | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>{{
                (personalDataForm.get('passport').get('type').value === 'id_card'
                  ? 'FIELD:NEW_PASSPORT_NUMBER'
                  : 'FIELD:NUMBER'
                ) | translate
              }}</mat-label>
              <ng-container
                *ngIf="personalDataForm.get('passport').get('type').value !== 'rf_passport'; else rfPassportNumber">
                <input uppercase englishLetters formControlName="number" matInput />
              </ng-container>
              <ng-template #rfPassportNumber>
                <input
                  uppercase
                  formControlName="number"
                  matInput
                  [showMaskTyped]="true"
                  [dropSpecialCharacters]="false"
                  mask="00 00 000000" />
              </ng-template>
              <mat-error *ngIf="personalDataForm.get('passport').get('number').errors?.maxlength">
                {{ 'ERROR:MAX_LENGTH' | translate : [15] }}
              </mat-error>
              <mat-error *ngIf="personalDataForm.get('passport').get('number').errors?.required">
                {{ 'ERROR:REQUIRED' | translate }}
              </mat-error>
            </mat-form-field>
            <div class="row">
              <mat-form-field class="col" appearance="outline">
                <mat-label>{{
                  (personalDataForm.get('passport').get('type').value === 'id_card'
                    ? 'FIELD:ID_CARD_DATE_OF_ISSUE'
                    : 'FIELD:DATE_OF_ISSUE'
                  ) | translate
                }}</mat-label>
                <input
                  dateFormat
                  formControlName="date_of_issue"
                  matInput
                  [max]="today"
                  [matDatepicker]="passportPicker" />
                <mat-datepicker-toggle matSuffix [for]="passportPicker"></mat-datepicker-toggle>
                <mat-datepicker #passportPicker></mat-datepicker>
                <mat-error *ngIf="personalDataForm.get('passport').get('date_of_issue').errors?.required">
                  {{ 'ERROR:REQUIRED' | translate }}
                </mat-error>
              </mat-form-field>
              <ng-container *ngIf="personalDataForm.get('passport').get('type').value !== 'rf_passport'">
                <mat-form-field class="col" appearance="outline">
                  <mat-label>{{
                    (personalDataForm.get('passport').get('type').value === 'id_card'
                      ? 'FIELD:ID_CARD_DATE_OF_EXPIRATION'
                      : 'FIELD:DATE_OF_EXPIRATION'
                    ) | translate
                  }}</mat-label>
                  <input
                    dateFormat
                    formControlName="date_of_expiration"
                    matInput
                    [min]="today"
                    [matDatepicker]="passportExpirationPicker" />
                  <mat-datepicker-toggle matSuffix [for]="passportExpirationPicker"></mat-datepicker-toggle>
                  <mat-datepicker #passportExpirationPicker></mat-datepicker>
                  <mat-error *ngIf="personalDataForm.get('passport').get('date_of_expiration').errors?.required">
                    {{ 'ERROR:REQUIRED' | translate }}
                  </mat-error>
                </mat-form-field>
              </ng-container>
              <ng-container *ngIf="personalDataForm.get('passport').get('type').value !== 'passport'">
                <mat-form-field class="col" appearance="outline">
                  <mat-label>{{ 'FIELD:ISSUED_BY_CODE' | translate }}</mat-label>
                  <ng-container
                    *ngIf="
                      personalDataForm.get('passport').get('type').value === 'id_card';
                      else passportRfIssuedByCode
                    ">
                    <input formControlName="issued_by_code" matInput mask="000" [showMaskTyped]="true" />
                  </ng-container>
                  <ng-template #passportRfIssuedByCode>
                    <input formControlName="issued_by_code" matInput />
                  </ng-template>
                  <mat-error *ngIf="personalDataForm.get('passport').get('issued_by_code').errors?.required">
                    {{ 'ERROR:REQUIRED' | translate }}
                  </mat-error>
                </mat-form-field>
              </ng-container>
            </div>
            <ng-container *ngIf="personalDataForm.get('passport').get('type').value === 'id_card'">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'FIELD:BIOMETRIC_NUMBER' | translate }}</mat-label>
                <input uppercase englishLetters formControlName="biometric_number" matInput />
                <mat-error *ngIf="personalDataForm.get('passport').get('biometric_number').errors?.maxlength">
                  {{ 'ERROR:MAX_LENGTH' | translate : [15] }}
                </mat-error>
                <mat-error *ngIf="personalDataForm.get('passport').get('biometric_number').errors?.required">
                  {{ 'ERROR:REQUIRED' | translate }}
                </mat-error>
              </mat-form-field>
              <div class="row">
                <mat-form-field class="col" appearance="outline">
                  <mat-label>{{ 'FIELD:BIOMETRIC_DATE_OF_ISSUE' | translate }}</mat-label>
                  <input
                    dateFormat
                    formControlName="biometric_date_of_issue"
                    matInput
                    [max]="today"
                    [matDatepicker]="idCardPassportPicker" />
                  <mat-datepicker-toggle matSuffix [for]="idCardPassportPicker"></mat-datepicker-toggle>
                  <mat-datepicker #idCardPassportPicker></mat-datepicker>
                  <mat-error
                    *ngIf="personalDataForm.get('passport').get('biometric_date_of_issue').errors?.required">
                    {{ 'ERROR:REQUIRED' | translate }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="col" appearance="outline">
                  <mat-label>{{ 'FIELD:BIOMETRIC_DATE_OF_EXPIRATION' | translate }}</mat-label>
                  <input
                    dateFormat
                    formControlName="biometric_date_of_expiration"
                    matInput
                    [min]="today"
                    [matDatepicker]="idCardPassportExpirationPicker" />
                  <mat-datepicker-toggle matSuffix [for]="idCardPassportExpirationPicker"></mat-datepicker-toggle>
                  <mat-datepicker #idCardPassportExpirationPicker></mat-datepicker>
                  <mat-error
                    *ngIf="
                      personalDataForm.get('passport').get('biometric_date_of_expiration').errors?.required
                    ">
                    {{ 'ERROR:REQUIRED' | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
            </ng-container>
            <ng-container *ngIf="personalDataForm.get('passport').get('type').value !== 'id_card'">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'FIELD:ISSUED_BY' | translate }}</mat-label>
                <input formControlName="issued_by" matInput />
                <mat-error *ngIf="personalDataForm.get('passport').get('issued_by').errors?.required">
                  {{ 'ERROR:REQUIRED' | translate }}
                </mat-error>
              </mat-form-field>
            </ng-container>
            <mat-form-field appearance="outline">
              <mat-label>{{ 'PROFILE:PLACE_OF_BIRTH' | translate }}</mat-label>
              <input uppercase russianLetters formControlName="birth_place" matInput />
              <mat-error *ngIf="personalDataForm.get('passport').get('birth_place').errors?.required">
                {{ 'ERROR:REQUIRED' | translate }}
              </mat-error>
              <mat-hint *ngIf="personalDataForm.get('passport').get('type').value === 'id_card'">{{
                'HINT:AS_IN_THE_ID_CARD' | translate
              }}</mat-hint>
              <mat-hint *ngIf="personalDataForm.get('passport').get('type').value === 'passport'">{{
                'HINT:AS_IN_THE_PASSPORT' | translate
              }}</mat-hint>
            </mat-form-field>
          </ng-container>

          <h3 class="mt-2">{{ 'FIELD:PASSPORT_SCANS' | translate }}</h3>
          <div class="hint row">
            <p>
              {{
                (personalDataForm.get('passport').get('type').value === 'id_card'
                  ? 'ID_CARD:ATTACHMENT_INFO_1'
                  : 'PASSPORT:ATTACHMENT_INFO_1'
                ) | translate
              }}
            </p>
            <p>
              {{ 'PASSPORT:ATTACHMENT_INFO_2' | translate }}
            </p>
          </div>
          <input class="file-input" type="file" [accept]="acceptedFormates" (change)="fileSelected($event)" #file />
          <div class="passport-block">
            <div class="row attachments">
              <ng-container *ngIf="attachments?.length < 3">
                <span class="attachments-not-found mb-2">
                  {{ 'PASSPORT:PIN_ATTACHMENTS' | translate }}
                </span>
              </ng-container>
              <ng-container *ngFor="let attachment of attachments">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12" *ngIf="attachment">
                  <div class="attachment">
                    <div class="close" (click)="onDelete(attachment.id)">
                      <mat-icon>close</mat-icon>
                    </div>
                    <ng-container *ngIf="attachment.isImage; else document">
                      <img [src]="attachment.url" [alt]="attachment.file_name" (click)="open(attachment.url)" />
                    </ng-container>
                    <ng-template #document>
                      <div class="document">
                        <img src="./assets/images/document.svg" alt="logo" />
                        <p>{{ attachment.file_name }}</p>
                        <div class="actions">
                          <div class="open">
                            <a [href]="attachment.url" target="_blank">
                              <div>
                                <mat-icon>visibility</mat-icon>
                              </div>
                              {{ 'OPEN' | translate }}
                            </a>
                          </div>
                          <div class="download">
                            <a [href]="attachment.url" [download]="attachment.file_name" target="_blank">
                              <div>
                                <mat-icon>download</mat-icon>
                              </div>
                              {{ 'DOWNLOAD' | translate }}
                            </a>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="passport-content">
              <div (click)="file.click()" class="file-upload">
                <p>{{ 'CHOOSE_FILE' | translate }}</p>
                <div class="file-upload__attach">
                  <p>{{ 'UPLOAD' | translate }}</p>
                  <mat-icon> attach_file</mat-icon>
                </div>
              </div>
            </div>
          </div>

          <h3>{{ 'PROFILE:UNP_AND_INN_AND_SNILS' | translate }}</h3>
          <mat-form-field appearance="outline" class="inn">
            <mat-label>{{ 'FIELD:TAX_NUMBER_UNP' | translate }}</mat-label>
            <input uppercase formControlName="tax_number_unp" matInput mask="SS0000000" [showMaskTyped]="true" />
            <!-- <mat-error *ngIf="personalDataForm.get('tax_number_unp').errors?.required">
              {{'ERROR:REQUIRED' | translate }}
            </mat-error> -->
            <mat-error *ngIf="personalDataForm.get('tax_number_unp').errors?.minlength">
              {{ 'ERROR:MIN_LENGTH' | translate : [9] }}
            </mat-error>
            <mat-error *ngIf="personalDataForm.get('tax_number_unp').errors?.maxlength">
              {{ 'ERROR:MAX_LENGTH' | translate : [9] }}
            </mat-error>
          </mat-form-field>
          <div class="hint">
            <p>
              Вы можете найти свой номер по
              <a href="https://lkfl.portal.nalog.gov.by/findPerson/" target="_blank">ссылке</a>
            </p>
          </div>
          <mat-form-field appearance="outline">
            <mat-label>{{ 'FIELD:TAX_NUMBER_INN' | translate }}</mat-label>
            <input uppercase formControlName="tax_number_inn" matInput />
            <mat-error *ngIf="personalDataForm.get('tax_number_inn').errors?.maxlength">
              {{ 'ERROR:MAX_LENGTH' | translate : [30] }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{ 'PROFILE:SNILS' | translate }}</mat-label>
            <input formControlName="insurance_number" matInput />
            <!-- <mat-error *ngIf="personalDataForm.get('insurance_number').errors?.minlength">
              {{'ERROR:MIN_LENGTH' | translate : [9] }}
            </mat-error>
            <mat-error *ngIf="personalDataForm.get('insurance_number').errors?.maxlength">
              {{'ERROR:MAX_LENGTH' | translate : [9] }}
            </mat-error> -->
          </mat-form-field>
          <!-- <div class="hint">
            <p>Вы можете найти свой номер по <a href="http://portal.ssf.gov.by/mainPage/service/"
                target="_blank">ссылке</a></p>
          </div> -->
        </form>
        <button
          mat-button
          [disabled]="
            (authService.isAllowedToChangeData$ | async)?.result === false ||
            personalDataForm.invalid ||
            attachments.length < 3
          "
          class="filled"
          type="button"
          (click)="next()">
          {{ 'NEXT' | translate }}
        </button>
      </div>
      <div [@open]="'opened'" *ngIf="profileService.profileCurrentStepValue === 'contactData'" class="contact-data">
        <form [formGroup]="contactDataForm">
          <ng-container formGroupName="registration_address">
            <ng-container *ngIf="contactDataForm.get('registration_address') as reg_address">
              <h3>{{ 'PROFILE:REGISTRATION_ADDRESS' | translate }}</h3>
              <mat-form-field appearance="outline">
                <mat-label>{{ 'PROFILE:COUNTRY' | translate }}</mat-label>
                <mat-select
                  formControlName="country"
                  (selectionChange)="onAddressCountryChange(reg_address, $event.value)">
                  <mat-option *ngFor="let country of countries" [value]="country">
                    {{ 'COUNTRY:' + country | translate }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="reg_address.get('country').errors?.required">
                  {{ 'ERROR:REQUIRED' | translate }}
                </mat-error>
              </mat-form-field>

              <div class="row">
                <ng-container *ngIf="reg_address.get('country').value === 'RU'; else byRegAddressRegion">
                  <ai-ui-select
                    class="col"
                    [label]="'FIELD:RU_REGION' | translate"
                    [formControlObj]="converter(reg_address.get('region_rus'))"
                    [request]="geoService.regionRuByName.bind(geoService)"
                    [showAtOnce]="true"
                    (selected)="onRegionSelected(reg_address.get('region_rus_id'), $event)"
                    (notSelected)="onNotRegionSelected(reg_address)"></ai-ui-select>
                </ng-container>
                <ng-template #byRegAddressRegion>
                  <mat-form-field class="col" appearance="outline">
                    <mat-label>{{ 'FIELD:REGION' | translate }}</mat-label>
                    <input formControlName="region" matInput />
                  </mat-form-field>
                </ng-template>
                <mat-form-field class="col" appearance="outline">
                  <mat-label>{{
                    (reg_address.get('country').value === 'RU' ? 'FIELD:RU_REGION_EN' : 'FIELD:REGION_EN') | translate
                  }}</mat-label>
                  <input englishLetters formControlName="region_en" matInput />
                </mat-form-field>
              </div>
              <ng-container *ngIf="reg_address.get('country').value === 'RU'">
                <div class="row mt-3">
                  <ai-ui-select
                    class="col"
                    label="Район"
                    [formControlObj]="converter(reg_address.get('district_rus'))"
                    [request]="geoService.districtRuByName.bind(geoService)"
                    [additionalParams]="
                      reg_address.get('region_rus').value ? { region: reg_address.get('region_rus').value?.id } : null
                    "
                    [showAtOnce]="true"
                    (selected)="onDistrictSelected(reg_address.get('district_rus_id'), $event)"
                    (notSelected)="onNotDistrictSelected(reg_address)"></ai-ui-select>
                  <mat-form-field class="col" appearance="outline">
                    <mat-label>{{ 'FIELD:DISTRICT_EN' | translate }}</mat-label>
                    <input englishLetters formControlName="district_en" matInput />
                  </mat-form-field>
                </div>
                <div class="row mt-3">
                  <ai-ui-select
                    class="col"
                    label="Город"
                    [formControlObj]="converter(reg_address.get('city_rus'))"
                    [request]="geoService.cityRuByName.bind(geoService)"
                    [additionalParams]="getAdditionalParamsForCity(reg_address)"
                    [showAtOnce]="true"
                    (selected)="onCitySelected(reg_address.get('city_rus_id'), $event)"
                    (notSelected)="onNotCitySelected(reg_address)"></ai-ui-select>
                  <mat-form-field class="col" appearance="outline">
                    <mat-label>{{ 'FIELD:CITY_EN' | translate }}</mat-label>
                    <input englishLetters formControlName="city_en" matInput />
                  </mat-form-field>
                </div>
              </ng-container>
              <div class="row mt-3">
                <ng-container *ngIf="reg_address.get('country').value === 'RU'; else byRegAddressLocality">
                  <ai-ui-select
                    class="col"
                    label="Населенный пункт"
                    [formControlObj]="converter(reg_address.get('locality_rus'))"
                    [request]="geoService.localityRuByName.bind(geoService)"
                    [additionalParams]="getAdditionalParamsForLocality(reg_address)"
                    [showAtOnce]="true"
                    (selected)="onLocalitySelected(reg_address.get('locality_rus_id'), $event)"
                    (notSelected)="onNotLocalitySelected(reg_address)"></ai-ui-select>
                </ng-container>
                <ng-template #byRegAddressLocality>
                  <mat-form-field class="col" appearance="outline">
                    <mat-label>{{ 'FIELD:LOCALITY_TYPE' | translate }}</mat-label>
                    <mat-select formControlName="locality_type_id">
                      <mat-option *ngFor="let item of geoService.localityTypes$ | async" [value]="item.id">
                        {{ item.name }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="reg_address.get('locality_type_id').errors?.required">
                      {{ 'ERROR:REQUIRED' | translate }}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="col" appearance="outline">
                    <mat-label>{{ 'FIELD:NAME' | translate }}</mat-label>
                    <input formControlName="locality" matInput />
                    <mat-error *ngIf="reg_address.get('locality').errors?.required">
                      {{ 'ERROR:REQUIRED' | translate }}
                    </mat-error>
                  </mat-form-field>
                </ng-template>
                <mat-form-field class="col" appearance="outline">
                  <mat-label>{{ 'FIELD:NAME_EN' | translate }}</mat-label>
                  <input englishLetters formControlName="locality_en" matInput />
                </mat-form-field>
              </div>
              <div class="row mt-3">
                <ng-container *ngIf="reg_address.get('country').value === 'RU'; else byRegAddressStreet">
                  <ai-ui-select
                    class="col"
                    label="Улица"
                    [formControlObj]="converter(reg_address.get('street_rus'))"
                    [request]="geoService.streetRuByName.bind(geoService)"
                    [additionalParams]="getAdditionalParamsForStreet(reg_address)"
                    [showAtOnce]="true"
                    (selected)="onStreetSelected(reg_address.get('street_rus_id'), $event)"
                    (notSelected)="onNotStreetSelected(reg_address)"></ai-ui-select>
                </ng-container>
                <ng-template #byRegAddressStreet>
                  <mat-form-field class="col" appearance="outline">
                    <mat-label>{{ 'FIELD:STREET_TYPE' | translate }}</mat-label>
                    <mat-select formControlName="street_type_id">
                      <mat-option *ngFor="let item of geoService.streetTypes$ | async" [value]="item.id">
                        {{ item.name }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="reg_address.get('street_type_id').errors?.required">
                      {{ 'ERROR:REQUIRED' | translate }}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="col" appearance="outline">
                    <mat-label>{{ 'FIELD:NAME' | translate }}</mat-label>
                    <input formControlName="street" matInput />
                    <mat-error *ngIf="reg_address.get('street').errors?.required">
                      {{ 'ERROR:REQUIRED' | translate }}
                    </mat-error>
                  </mat-form-field>
                </ng-template>
                <mat-form-field class="col" appearance="outline">
                  <mat-label>{{ 'FIELD:NAME_EN' | translate }}</mat-label>
                  <input englishLetters formControlName="street_en" matInput />
                </mat-form-field>
              </div>
              <div class="row mt-3">
                <mat-form-field class="col" appearance="outline">
                  <mat-label>{{ 'FIELD:HOUSE_NUMBER' | translate }}</mat-label>
                  <input formControlName="house_number" matInput />
                  <mat-error *ngIf="reg_address.get('house_number').errors?.required">
                    {{ 'ERROR:REQUIRED' | translate }}
                  </mat-error>
                  <mat-error *ngIf="reg_address.get('house_number').errors?.maxlength">
                    {{ 'ERROR:MAX_LENGTH' | translate : [20] }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="col" appearance="outline">
                  <mat-label>{{ 'FIELD:APARTMENT_NUMBER' | translate }}</mat-label>
                  <input formControlName="apartment_number" matInput />
                  <mat-error *ngIf="reg_address.get('apartment_number').errors?.maxlength">
                    {{ 'ERROR:MAX_LENGTH' | translate : [20] }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="row">
                <mat-form-field class="col" appearance="outline">
                  <mat-label>{{ 'FIELD:HOUSE_UNIT' | translate }}</mat-label>
                  <input formControlName="house_unit" matInput />
                </mat-form-field>
                <mat-form-field class="col" appearance="outline">
                  <mat-label>{{ 'FIELD:POSTAL_CODE' | translate }}</mat-label>
                  <input formControlName="zip_code" matInput />
                  <mat-error *ngIf="reg_address.get('zip_code').errors?.required">
                    {{ 'ERROR:REQUIRED' | translate }}
                  </mat-error>
                  <mat-error *ngIf="reg_address.get('zip_code').errors?.maxlength">
                    {{ 'ERROR:MAX_LENGTH' | translate : [6] }}
                  </mat-error>
                </mat-form-field>
              </div>
            </ng-container>
          </ng-container>
          <div class="toggle">
            <h3>{{ 'PROFILE:LIVING_ADDRESS' | translate }}</h3>
            <mat-slide-toggle
              formControlName="is_living_address_as_reg"
              [color]="'primary'"
              (change)="onLivingAddressToggleChange()"
              >{{ 'PROFILE:IS_LIVING_ADDRESS_AS_REGISTRATION_ADDRESS' | translate }}</mat-slide-toggle
            >
          </div>
          <ng-container
            *ngIf="
              contactDataForm.get('is_living_address_as_reg').value === false &&
              contactDataForm.get('living_address') as liv_address
            "
            formGroupName="living_address">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'PROFILE:COUNTRY' | translate }}</mat-label>
              <mat-select
                formControlName="country"
                (selectionChange)="onAddressCountryChange(liv_address, $event.value)">
                <mat-option *ngFor="let country of countries" [value]="country">
                  {{ 'COUNTRY:' + country | translate }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="contactDataForm.get('living_address').get('country').errors?.required">
                {{ 'ERROR:REQUIRED' | translate }}
              </mat-error>
            </mat-form-field>

            <div class="row">
              <ng-container *ngIf="liv_address.get('country').value === 'RU'; else byLivAddressRegion">
                <ai-ui-select
                  class="col"
                  [label]="'FIELD:RU_REGION' | translate"
                  [formControlObj]="converter(liv_address.get('region_rus'))"
                  [request]="geoService.regionRuByName.bind(geoService)"
                  [showAtOnce]="true"
                  (selected)="onRegionSelected(liv_address.get('region_rus_id'), $event)"
                  (notSelected)="onNotRegionSelected(liv_address)"></ai-ui-select>
              </ng-container>
              <ng-template #byLivAddressRegion>
                <mat-form-field class="col" appearance="outline">
                  <mat-label>{{ 'FIELD:REGION' | translate }}</mat-label>
                  <input formControlName="region" matInput />
                </mat-form-field>
              </ng-template>
              <mat-form-field class="col" appearance="outline">
                <mat-label>{{
                  (liv_address.get('country').value === 'RU' ? 'FIELD:RU_REGION_EN' : 'FIELD:REGION_EN') | translate
                }}</mat-label>
                <input englishLetters formControlName="region_en" matInput />
              </mat-form-field>
            </div>
            <ng-container *ngIf="liv_address.get('country').value === 'RU'">
              <div class="row mt-3">
                <ai-ui-select
                  class="col"
                  label="Район"
                  [formControlObj]="converter(liv_address.get('district_rus'))"
                  [request]="geoService.districtRuByName.bind(geoService)"
                  [additionalParams]="
                    liv_address.get('region_rus').value ? { region: liv_address.get('region_rus').value?.id } : null
                  "
                  [showAtOnce]="true"
                  (selected)="onDistrictSelected(liv_address.get('district_rus_id'), $event)"
                  (notSelected)="onNotDistrictSelected(liv_address)"></ai-ui-select>
                <mat-form-field class="col" appearance="outline">
                  <mat-label>{{ 'FIELD:DISTRICT_EN' | translate }}</mat-label>
                  <input englishLetters formControlName="district_en" matInput />
                </mat-form-field>
              </div>
              <div class="row mt-3">
                <ai-ui-select
                  class="col"
                  label="Город"
                  [formControlObj]="converter(liv_address.get('city_rus'))"
                  [request]="geoService.cityRuByName.bind(geoService)"
                  [additionalParams]="getAdditionalParamsForCity(liv_address)"
                  [showAtOnce]="true"
                  (selected)="onCitySelected(liv_address.get('city_rus_id'), $event)"
                  (notSelected)="onNotCitySelected(liv_address)"></ai-ui-select>
                <mat-form-field class="col" appearance="outline">
                  <mat-label>{{ 'FIELD:CITY_EN' | translate }}</mat-label>
                  <input englishLetters formControlName="city_en" matInput />
                </mat-form-field>
              </div>
            </ng-container>
            <div class="row mt-3">
              <ng-container *ngIf="liv_address.get('country').value === 'RU'; else byLivAddressLocality">
                <ai-ui-select
                  class="col"
                  label="Населенный пункт"
                  [formControlObj]="converter(liv_address.get('locality_rus'))"
                  [request]="geoService.localityRuByName.bind(geoService)"
                  [additionalParams]="getAdditionalParamsForLocality(liv_address)"
                  [showAtOnce]="true"
                  (selected)="onLocalitySelected(liv_address.get('locality_rus_id'), $event)"
                  (notSelected)="onNotLocalitySelected(liv_address)"></ai-ui-select>
              </ng-container>
              <ng-template #byLivAddressLocality>
                <mat-form-field class="col" appearance="outline">
                  <mat-label>{{ 'FIELD:LOCALITY_TYPE' | translate }}</mat-label>
                  <mat-select formControlName="locality_type_id">
                    <mat-option *ngFor="let item of geoService.localityTypes$ | async" [value]="item.id">
                      {{ item.name }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="liv_address.get('locality_type_id').errors?.required">
                    {{ 'ERROR:REQUIRED' | translate }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="col" appearance="outline">
                  <mat-label>{{ 'FIELD:NAME' | translate }}</mat-label>
                  <input formControlName="locality" matInput />
                  <mat-error *ngIf="liv_address.get('locality').errors?.required">
                    {{ 'ERROR:REQUIRED' | translate }}
                  </mat-error>
                </mat-form-field>
              </ng-template>
              <mat-form-field class="col" appearance="outline">
                <mat-label>{{ 'FIELD:NAME_EN' | translate }}</mat-label>
                <input englishLetters formControlName="locality_en" matInput />
              </mat-form-field>
            </div>
            <div class="row mt-3">
              <ng-container *ngIf="liv_address.get('country').value === 'RU'; else byLivAddressStreet">
                <ai-ui-select
                  class="col"
                  label="Улица"
                  [formControlObj]="converter(liv_address.get('street_rus'))"
                  [request]="geoService.streetRuByName.bind(geoService)"
                  [additionalParams]="getAdditionalParamsForStreet(liv_address)"
                  [showAtOnce]="true"
                  (selected)="onStreetSelected(liv_address.get('street_rus_id'), $event)"
                  (notSelected)="onNotStreetSelected(liv_address)"></ai-ui-select>
              </ng-container>
              <ng-template #byLivAddressStreet>
                <mat-form-field class="col" appearance="outline">
                  <mat-label>{{ 'FIELD:STREET_TYPE' | translate }}</mat-label>
                  <mat-select formControlName="street_type_id">
                    <mat-option *ngFor="let item of geoService.streetTypes$ | async" [value]="item.id">
                      {{ item.name }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="liv_address.get('street_type_id').errors?.required">
                    {{ 'ERROR:REQUIRED' | translate }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="col" appearance="outline">
                  <mat-label>{{ 'FIELD:NAME' | translate }}</mat-label>
                  <input formControlName="street" matInput />
                  <mat-error *ngIf="liv_address.get('street').errors?.required">
                    {{ 'ERROR:REQUIRED' | translate }}
                  </mat-error>
                </mat-form-field>
              </ng-template>
              <mat-form-field class="col" appearance="outline">
                <mat-label>{{ 'FIELD:NAME_EN' | translate }}</mat-label>
                <input englishLetters formControlName="street_en" matInput />
              </mat-form-field>
            </div>
            <div class="row mt-3">
              <mat-form-field class="col" appearance="outline">
                <mat-label>{{ 'FIELD:HOUSE_NUMBER' | translate }}</mat-label>
                <input formControlName="house_number" matInput />
                <mat-error *ngIf="liv_address.get('house_number').errors?.required">
                  {{ 'ERROR:REQUIRED' | translate }}
                </mat-error>
                <mat-error *ngIf="liv_address.get('house_number').errors?.maxlength">
                  {{ 'ERROR:MAX_LENGTH' | translate : [20] }}
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col" appearance="outline">
                <mat-label>{{ 'FIELD:APARTMENT_NUMBER' | translate }}</mat-label>
                <input formControlName="apartment_number" matInput />
                <mat-error *ngIf="liv_address.get('apartment_number').errors?.maxlength">
                  {{ 'ERROR:MAX_LENGTH' | translate : [20] }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="row">
              <mat-form-field class="col" appearance="outline">
                <mat-label>{{ 'FIELD:HOUSE_UNIT' | translate }}</mat-label>
                <input formControlName="house_unit" matInput />
              </mat-form-field>
              <mat-form-field class="col" appearance="outline">
                <mat-label>{{ 'FIELD:POSTAL_CODE' | translate }}</mat-label>
                <input formControlName="zip_code" matInput />
                <mat-error *ngIf="liv_address.get('zip_code').errors?.required">
                  {{ 'ERROR:REQUIRED' | translate }}
                </mat-error>
                <mat-error *ngIf="liv_address.get('zip_code').errors?.maxlength">
                  {{ 'ERROR:MAX_LENGTH' | translate : [6] }}
                </mat-error>
              </mat-form-field>
            </div>
          </ng-container>
          <div class="toggle">
            <h3>{{ 'PROFILE:POSTAL_ADDRESS' | translate }}</h3>
            <mat-slide-toggle
              formControlName="is_postal_address_as_living"
              [color]="'primary'"
              (change)="onPostalAddressToggleChange()"
              >{{ 'PROFILE:IS_POSTAL_ADDRESS_AS_REGISTRATION_ADDRESS' | translate }}</mat-slide-toggle
            >
          </div>
          <ng-container
            *ngIf="
              contactDataForm.get('is_postal_address_as_living').value === false &&
              contactDataForm.get('postal_address') as post_address
            "
            formGroupName="postal_address">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'PROFILE:COUNTRY' | translate }}</mat-label>
              <mat-select
                formControlName="country"
                (selectionChange)="onAddressCountryChange(post_address, $event.value)">
                <mat-option *ngFor="let country of countries" [value]="country">
                  {{ 'COUNTRY:' + country | translate }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="post_address.get('country').errors?.required">
                {{ 'ERROR:REQUIRED' | translate }}
              </mat-error>
            </mat-form-field>
            <div class="row">
              <ng-container *ngIf="post_address.get('country').value === 'RU'; else byPostAddressRegion">
                <ai-ui-select
                  class="col"
                  [label]="'FIELD:RU_REGION' | translate"
                  [formControlObj]="converter(post_address.get('region_rus'))"
                  [request]="geoService.regionRuByName.bind(geoService)"
                  [showAtOnce]="true"
                  (selected)="onRegionSelected(post_address.get('region_rus_id'), $event)"
                  (notSelected)="onNotRegionSelected(post_address)"></ai-ui-select>
              </ng-container>
              <ng-template #byPostAddressRegion>
                <mat-form-field class="col" appearance="outline">
                  <mat-label>{{ 'FIELD:REGION' | translate }}</mat-label>
                  <input formControlName="region" matInput />
                </mat-form-field>
              </ng-template>
              <mat-form-field class="col" appearance="outline">
                <mat-label>{{
                  (post_address.get('country').value === 'RU' ? 'FIELD:RU_REGION_EN' : 'FIELD:REGION_EN') | translate
                }}</mat-label>
                <input englishLetters formControlName="region_en" matInput />
              </mat-form-field>
            </div>
            <ng-container *ngIf="post_address.get('country').value === 'RU'">
              <div class="row mt-3">
                <ai-ui-select
                  class="col"
                  label="Район"
                  [formControlObj]="converter(post_address.get('district_rus'))"
                  [request]="geoService.districtRuByName.bind(geoService)"
                  [additionalParams]="
                    post_address.get('region_rus').value ? { region: post_address.get('region_rus').value?.id } : null
                  "
                  [showAtOnce]="true"
                  (selected)="onDistrictSelected(post_address.get('district_rus_id'), $event)"
                  (notSelected)="onNotDistrictSelected(post_address)"></ai-ui-select>
                <mat-form-field class="col" appearance="outline">
                  <mat-label>{{ 'FIELD:DISTRICT_EN' | translate }}</mat-label>
                  <input englishLetters formControlName="district_en" matInput />
                </mat-form-field>
              </div>
              <div class="row mt-3">
                <ai-ui-select
                  class="col"
                  label="Город"
                  [formControlObj]="converter(post_address.get('city_rus'))"
                  [request]="geoService.cityRuByName.bind(geoService)"
                  [additionalParams]="getAdditionalParamsForCity(post_address)"
                  [showAtOnce]="true"
                  (selected)="onCitySelected(post_address.get('city_rus_id'), $event)"
                  (notSelected)="onNotCitySelected(post_address)"></ai-ui-select>
                <mat-form-field class="col" appearance="outline">
                  <mat-label>{{ 'FIELD:CITY_EN' | translate }}</mat-label>
                  <input englishLetters formControlName="city_en" matInput />
                </mat-form-field>
              </div>
            </ng-container>
            <div class="row mt-3">
              <ng-container *ngIf="post_address.get('country').value === 'RU'; else byPostAddressLocality">
                <ai-ui-select
                  class="col"
                  label="Населенный пункт"
                  [formControlObj]="converter(post_address.get('locality_rus'))"
                  [request]="geoService.localityRuByName.bind(geoService)"
                  [additionalParams]="getAdditionalParamsForLocality(post_address)"
                  [showAtOnce]="true"
                  (selected)="onLocalitySelected(post_address.get('locality_rus_id'), $event)"
                  (notSelected)="onNotLocalitySelected(post_address)"></ai-ui-select>
              </ng-container>
              <ng-template #byPostAddressLocality>
                <mat-form-field class="col" appearance="outline">
                  <mat-label>{{ 'FIELD:LOCALITY_TYPE' | translate }}</mat-label>
                  <mat-select formControlName="locality_type_id">
                    <mat-option *ngFor="let item of geoService.localityTypes$ | async" [value]="item.id">
                      {{ item.name }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="post_address.get('locality_type_id').errors?.required">
                    {{ 'ERROR:REQUIRED' | translate }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="col" appearance="outline">
                  <mat-label>{{ 'FIELD:NAME' | translate }}</mat-label>
                  <input formControlName="locality" matInput />
                  <mat-error *ngIf="post_address.get('locality').errors?.required">
                    {{ 'ERROR:REQUIRED' | translate }}
                  </mat-error>
                </mat-form-field>
              </ng-template>
              <mat-form-field class="col" appearance="outline">
                <mat-label>{{ 'FIELD:NAME_EN' | translate }}</mat-label>
                <input englishLetters formControlName="locality_en" matInput />
              </mat-form-field>
            </div>
            <div class="row mt-3">
              <ng-container *ngIf="post_address.get('country').value === 'RU'; else byPostAddressStreet">
                <ai-ui-select
                  class="col"
                  label="Улица"
                  [formControlObj]="converter(post_address.get('street_rus'))"
                  [request]="geoService.streetRuByName.bind(geoService)"
                  [additionalParams]="getAdditionalParamsForStreet(post_address)"
                  [showAtOnce]="true"
                  (selected)="onStreetSelected(post_address.get('street_rus_id'), $event)"
                  (notSelected)="onNotStreetSelected(post_address)"></ai-ui-select>
              </ng-container>
              <ng-template #byPostAddressStreet>
                <mat-form-field class="col" appearance="outline">
                  <mat-label>{{ 'FIELD:STREET_TYPE' | translate }}</mat-label>
                  <mat-select formControlName="street_type_id">
                    <mat-option *ngFor="let item of geoService.streetTypes$ | async" [value]="item.id">
                      {{ item.name }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="post_address.get('street_type_id').errors?.required">
                    {{ 'ERROR:REQUIRED' | translate }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="col" appearance="outline">
                  <mat-label>{{ 'FIELD:NAME' | translate }}</mat-label>
                  <input formControlName="street" matInput />
                  <mat-error *ngIf="post_address.get('street').errors?.required">
                    {{ 'ERROR:REQUIRED' | translate }}
                  </mat-error>
                </mat-form-field>
              </ng-template>
              <mat-form-field class="col" appearance="outline">
                <mat-label>{{ 'FIELD:NAME_EN' | translate }}</mat-label>
                <input englishLetters formControlName="street_en" matInput />
              </mat-form-field>
            </div>
            <div class="row mt-3">
              <mat-form-field class="col" appearance="outline">
                <mat-label>{{ 'FIELD:HOUSE_NUMBER' | translate }}</mat-label>
                <input formControlName="house_number" matInput />
                <mat-error *ngIf="post_address.get('house_number').errors?.required">
                  {{ 'ERROR:REQUIRED' | translate }}
                </mat-error>
                <mat-error *ngIf="post_address.get('house_number').errors?.maxlength">
                  {{ 'ERROR:MAX_LENGTH' | translate : [20] }}
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col" appearance="outline">
                <mat-label>{{ 'FIELD:APARTMENT_NUMBER' | translate }}</mat-label>
                <input formControlName="apartment_number" matInput />
                <mat-error *ngIf="post_address.get('apartment_number').errors?.maxlength">
                  {{ 'ERROR:MAX_LENGTH' | translate : [20] }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="row">
              <mat-form-field class="col" appearance="outline">
                <mat-label>{{ 'FIELD:HOUSE_UNIT' | translate }}</mat-label>
                <input formControlName="house_unit" matInput />
              </mat-form-field>
              <mat-form-field class="col" appearance="outline">
                <mat-label>{{ 'FIELD:POSTAL_CODE' | translate }}</mat-label>
                <input formControlName="zip_code" matInput />
                <mat-error *ngIf="post_address.get('zip_code').errors?.required">
                  {{ 'ERROR:REQUIRED' | translate }}
                </mat-error>
                <mat-error *ngIf="post_address.get('zip_code').errors?.maxlength">
                  {{ 'ERROR:MAX_LENGTH' | translate : [6] }}
                </mat-error>
              </mat-form-field>
            </div>
          </ng-container>
        </form>
        <button
          mat-button
          [disabled]="
            (authService.isAllowedToChangeData$ | async)?.result === false ||
            contactDataForm.get('registration_address').invalid ||
            (contactDataForm.get('living_address').invalid &&
              contactDataForm.get('is_living_address_as_reg').value === false) ||
            (contactDataForm.get('postal_address').invalid &&
              contactDataForm.get('is_postal_address_as_living').value === false)
          "
          class="filled sign"
          type="button"
          (click)="next()">
          {{ 'NEXT' | translate }}
        </button>
      </div>
      <div [@open]="'opened'" *ngIf="profileService.profileCurrentStepValue === 'extraData'" class="extra-data">
        <form [formGroup]="extraDataForm">
          <h3>{{ 'PROFILE:TAX_COMPLIANCE' | translate }}</h3>
          <div class="questionnaire-block">
            <span>{{ 'FIELD:WORK_CAPACITY' | translate }}</span>
            <mat-radio-group formControlName="work_capacity" class="row">
              <mat-radio-button color="primary" *ngFor="let efficiency of efficiencies" [value]="efficiency">
                {{ 'EFFICIENCY:' + efficiency | translate }}
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="questionnaire-block">
            <span>{{ 'FIELD:IS_PROTECTOR' | translate }}</span>
            <mat-radio-group formControlName="is_protector">
              <mat-radio-button color="primary" [value]="true">
                {{ 'YES' | translate }}
              </mat-radio-button>
              <mat-radio-button color="primary" [value]="false">
                {{ 'NO' | translate }}
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="questionnaire-block">
            <span>{{ 'FIELD:IS_PUBLIC_OFFICIAL' | translate }}</span>
            <mat-radio-group formControlName="is_public_official">
              <mat-radio-button color="primary" [value]="true">
                {{ 'YES' | translate }}
              </mat-radio-button>
              <mat-radio-button color="primary" [value]="false">
                {{ 'NO' | translate }}
              </mat-radio-button>
            </mat-radio-group>
          </div>

          <!-- Main info -->
          <div class="questionnaire-block">
            <span>{{ 'FIELD:IS_FOREIGN_PUBLIC_OFFICIAL' | translate }}</span>
            <mat-radio-group
              formControlName="is_foreign_public_official"
              (change)="onRadioButtonChange($event.value, 'foreign_public_official_info', 255)">
              <mat-radio-button color="primary" [value]="true">
                {{ 'YES' | translate }}
              </mat-radio-button>
              <mat-radio-button color="primary" [value]="false">
                {{ 'NO' | translate }}
              </mat-radio-button>
            </mat-radio-group>
            <div class="extra-field" *ngIf="extraDataForm.value.is_foreign_public_official === true">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'FIELD:FOREIGN_PUBLIC_OFFICIAL_INFO' | translate }}</mat-label>
                <input formControlName="foreign_public_official_info" matInput required />
                <mat-error *ngIf="extraDataForm.get('foreign_public_official_info').errors?.maxlength">
                  {{ 'ERROR:MAX_LENGTH' | translate : [255] }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="questionnaire-block">
            <span>{{ 'FIELD:IS_OFFICIAL_OF_INT_ORG' | translate }}</span>
            <mat-radio-group
              formControlName="is_official_of_int_org"
              (change)="onRadioButtonChange($event.value, 'official_of_int_org_info', 255)">
              <mat-radio-button color="primary" [value]="true">
                {{ 'YES' | translate }}
              </mat-radio-button>
              <mat-radio-button color="primary" [value]="false">
                {{ 'NO' | translate }}
              </mat-radio-button>
            </mat-radio-group>
            <div class="extra-field" *ngIf="extraDataForm.value.is_official_of_int_org === true">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'FIELD:OFFICIAL_OF_INT_ORG_INFO' | translate }}</mat-label>
                <input formControlName="official_of_int_org_info" matInput required />
                <mat-error *ngIf="extraDataForm.get('official_of_int_org_info').errors?.maxlength">
                  {{ 'ERROR:MAX_LENGTH' | translate : [255] }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="questionnaire-block">
            <span>{{ 'FIELD:IS_RELATIVE_OF_OFFICIAL' | translate }}</span>
            <mat-radio-group
              formControlName="is_relative_of_official"
              (change)="onRadioButtonChange($event.value, 'relative_of_official_info', 255)">
              <mat-radio-button color="primary" [value]="true">
                {{ 'YES' | translate }}
              </mat-radio-button>
              <mat-radio-button color="primary" [value]="false">
                {{ 'NO' | translate }}
              </mat-radio-button>
            </mat-radio-group>
            <div class="extra-field" *ngIf="extraDataForm.value.is_relative_of_official === true">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'FIELD:RELATIVE_OF_OFFICIAL_INFO' | translate }}</mat-label>
                <input formControlName="relative_of_official_info" matInput required />
                <mat-error *ngIf="extraDataForm.get('relative_of_official_info').errors?.maxlength">
                  {{ 'ERROR:MAX_LENGTH' | translate : [255] }}
                </mat-error>
              </mat-form-field>
              <div class="hint">
                <p>
                  {{ 'FIELD:RELATIVE_OF_OFFICIAL_INFO_HINT_1' | translate }}<br />{{
                    'FIELD:RELATIVE_OF_OFFICIAL_INFO_HINT_2' | translate
                  }}<br />{{ 'FIELD:RELATIVE_OF_OFFICIAL_INFO_HINT_3' | translate }}
                </p>
              </div>
            </div>
          </div>

          <!-- Foreign taxpayer info -->
          <div class="questionnaire-block">
            <span>{{ 'FIELD:HAS_RESIDENCE_PERMIT_IN_COUNTRY' | translate }}</span>
            <mat-radio-group
              (change)="onChangeResidencePermit($event)"
              [formControl]="has_residence_permit_in_country"
              class="row">
              <mat-radio-button color="primary" [value]="true">
                {{ 'YES' | translate }}
              </mat-radio-button>
              <mat-radio-button color="primary" [value]="false">
                {{ 'NO' | translate }}
              </mat-radio-button>
            </mat-radio-group>
            <div class="extra-field" *ngIf="has_residence_permit_in_country?.value === true">
              <div class="add-button">
                <mat-icon (click)="addResidencePermit()">add</mat-icon>
              </div>
              <ng-container formArrayName="has_residence_permit_in_countries_list">
                <ng-container
                  *ngFor="
                    let country of extraDataForm.controls.has_residence_permit_in_countries_list['controls'];
                    let i = index
                  ">
                  <ng-container [formGroup]="country">
                    <div *ngIf="i > 0" class="clear-button">
                      <mat-icon (click)="removeResidencePermit(i)">clear</mat-icon>
                    </div>
                    <mat-form-field appearance="outline">
                      <mat-label>{{ 'PROFILE:IS_THE_RESIDENT_OF_ANOTHER_COUNTRY_LABEL' | translate }}</mat-label>
                      <input formControlName="name" matInput required />
                    </mat-form-field>
                    <div class="hint">
                      <p>{{ 'PROFILE:IS_THE_RESIDENT_OF_ANOTHER_COUNTRY_HINT' | translate }}</p>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
          </div>
          <div class="questionnaire-block">
            <span class="mb-3">{{ 'FIELD:TAX_RESIDENT_TYPE' | translate }}</span>
            <mat-form-field appearance="outline">
              <mat-label>{{ 'CHOOSE_1' | translate }}</mat-label>
              <mat-select formControlName="tax_resident_type" (selectionChange)="onChangeResidentStatus($event)">
                <mat-option style="height: auto" *ngFor="let status of taxResidentStatuses" [value]="status">
                  {{ status | translate }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="extraDataForm.get('tax_resident_type').errors?.required">
                {{ 'ERROR:REQUIRED' | translate }}
              </mat-error>
            </mat-form-field>
            <ng-container *ngIf="extraDataForm.get('tax_resident_type').value === 'tax_resident_of_another_country'">
              <div class="extra-field">
                <mat-form-field appearance="outline">
                  <mat-label>{{ 'FIELD:TAX_RESIDENT_COUNTRY_NAME' | translate }}</mat-label>
                  <input matInput formControlName="tax_resident_country_name" />
                </mat-form-field>
              </div>
            </ng-container>
          </div>
          <div class="questionnaire-block">
            <span>{{ 'FIELD:IS_OTHER_COUNTRY_TAX_RESIDENT' | translate }}</span>
            <mat-radio-group
              formControlName="is_other_country_tax_resident"
              (change)="onRadioButtonChange($event.value, 'other_country_tax_resident_info', 255)">
              <mat-radio-button color="primary" [value]="true">
                {{ 'YES' | translate }}
              </mat-radio-button>
              <mat-radio-button color="primary" [value]="false">
                {{ 'NO' | translate }}
              </mat-radio-button>
            </mat-radio-group>
            <div class="extra-field" *ngIf="extraDataForm.value.is_other_country_tax_resident === true">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'FIELD:OTHER_COUNTRY_TAX_RESIDENT_INFO' | translate }}</mat-label>
                <input formControlName="other_country_tax_resident_info" matInput required />
                <mat-error *ngIf="extraDataForm.get('other_country_tax_resident_info').errors?.maxlength">
                  {{ 'ERROR:MAX_LENGTH' | translate : [255] }}
                </mat-error>
              </mat-form-field>
              <div class="hint">
                <p>{{ 'FIELD:OTHER_COUNTRY_TAX_RESIDENT_INFO_HINT' | translate }}</p>
              </div>
            </div>
          </div>
          <div class="questionnaire-block">
            <span>{{ 'FIELD:HAS_FOREIGN_TAX_NUMBER' | translate }}</span>
            <mat-radio-group
              (change)="onChangeHasForeignTaxNumber($event.value)"
              formControlName="has_foreign_tax_number">
              <mat-radio-button color="primary" [value]="true">
                {{ 'YES' | translate }}
              </mat-radio-button>
              <mat-radio-button color="primary" [value]="false">
                {{ 'NO' | translate }}
              </mat-radio-button>
            </mat-radio-group>
            <ng-container *ngIf="extraDataForm.value.has_foreign_tax_number === true; else hasNoForeignTaxNumber">
              <div class="extra-field">
                <mat-form-field appearance="outline">
                  <mat-label>{{ 'FIELD:FOREIGN_TAX_NUMBER' | translate }}</mat-label>
                  <input formControlName="foreign_tax_number" matInput required />
                  <mat-error *ngIf="extraDataForm.get('foreign_tax_number').errors?.maxlength">
                    {{ 'ERROR:MAX_LENGTH' | translate : [30] }}
                  </mat-error>
                </mat-form-field>
              </div>
            </ng-container>
            <ng-template #hasNoForeignTaxNumber>
              <div class="extra-field">
                <mat-radio-group
                  formControlName="has_no_foreign_tax_number_reason"
                  (change)="onChangeHasNoForeignTaxNumberReason($event.value)">
                  <mat-radio-button color="primary" value="tax_residence_country_does_not_assign_tin">
                    {{ 'FIELD:HAS_NO_FOREIGN_TAX_NUMBER_REASON_TAX_RESIDENCE_COUNTRY_DOES_NOT_ASSIGN_TIN' | translate }}
                  </mat-radio-button>
                  <mat-radio-button color="primary" value="individual_cannot_obtain_tin_due_to_other_reasons">
                    {{
                      'FIELD:HAS_NO_FOREIGN_TAX_NUMBER_REASON_REASON_INDIVIDUAL_CANNOT_OBTAIN_TIN_DUE_TO_OTHER_REASONS'
                        | translate
                    }}
                  </mat-radio-button>
                </mat-radio-group>
                <mat-form-field
                  *ngIf="
                    this.extraDataForm.get('has_no_foreign_tax_number_reason')?.value ===
                    'individual_cannot_obtain_tin_due_to_other_reasons'
                  "
                  appearance="outline">
                  <mat-label>{{ 'FIELD:INDIVIDUAL_CANNOT_OBTAIN_TIN_DUE_TO_OTHER_REASONS' | translate }}</mat-label>
                  <input formControlName="individual_cannot_obtain_tin_due_to_other_reasons" matInput required />
                  <mat-error
                    *ngIf="extraDataForm.get('individual_cannot_obtain_tin_due_to_other_reasons').errors?.maxlength">
                    {{ 'ERROR:MAX_LENGTH' | translate : [255] }}
                  </mat-error>
                </mat-form-field>
              </div>
            </ng-template>
          </div>
          <div class="questionnaire-block">
            <span>{{ 'FIELD:HAS_ADDRESS_IN_FOREIGN_COUNTRY' | translate }}</span>
            <mat-radio-group
              (change)="onChangeAddressInForeignCountry($event)"
              formControlName="has_address_in_foreign_country"
              class="row">
              <mat-radio-button value="usa" color="primary">
                {{ 'PROFILE:IS_RESIDENTIAL_ADDRESS_IN_THE_FOREIGN_COUNTRY_OPTION_1' | translate }}
              </mat-radio-button>
              <mat-radio-button value="other_country" color="primary">
                {{ 'PROFILE:IS_RESIDENTIAL_ADDRESS_IN_THE_FOREIGN_COUNTRY_OPTION_2' | translate }}
              </mat-radio-button>
              <mat-radio-button value="no" color="primary">
                {{ 'PROFILE:IS_RESIDENTIAL_ADDRESS_IN_THE_FOREIGN_COUNTRY_OPTION_3' | translate }}
              </mat-radio-button>
            </mat-radio-group>
            <div class="extra-field" *ngIf="extraDataForm.value.has_address_in_foreign_country === 'other_country'">
              <div class="add-button">
                <mat-icon (click)="addAddressInForeignCountry()">add</mat-icon>
              </div>
              <ng-container formArrayName="has_address_in_foreign_countries_list">
                <ng-container
                  *ngFor="
                    let address of extraDataForm.controls.has_address_in_foreign_countries_list['controls'];
                    let i = index
                  ">
                  <ng-container [formGroup]="address">
                    <div *ngIf="i > 0" class="clear-button">
                      <mat-icon (click)="removeAddressInForeignCountry(i)">clear</mat-icon>
                    </div>
                    <mat-form-field appearance="outline">
                      <mat-label>{{
                        'PROFILE:IS_RESIDENTIAL_ADDRESS_IN_THE_FOREIGN_COUNTRY_AND_ADDRESS_LABEL' | translate
                      }}</mat-label>
                      <input formControlName="name" matInput required />
                      <mat-error *ngIf="address.get('name').errors?.maxlength">
                        {{ 'ERROR:MAX_LENGTH' | translate : [255] }}
                      </mat-error>
                    </mat-form-field>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
          </div>
          <div class="questionnaire-block">
            <span>{{ 'FIELD:HAS_FOREIGN_PHONE' | translate }}</span>
            <mat-radio-group formControlName="has_foreign_phone">
              <mat-radio-button color="primary" [value]="true">
                {{ 'YES' | translate }}
              </mat-radio-button>
              <mat-radio-button color="primary" [value]="false">
                {{ 'NO' | translate }}
              </mat-radio-button>
            </mat-radio-group>
            <div class="extra-field" *ngIf="extraDataForm.value.has_foreign_phone === true">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'FIELD:FOREIGN_PHONE_NUMBER' | translate }}</mat-label>
                <input formControlName="foreign_phone_number" matInput required />
                <mat-error *ngIf="extraDataForm.get('foreign_phone_number').errors?.maxlength">
                  {{ 'ERROR:MAX_LENGTH' | translate : [128] }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="questionnaire-block">
            <span>{{ 'FIELD:HAS_PERMANENT_INSTRUCTIONS_FOR_FUNDS_TRANSFER_TO_FOREIGN_ACCS' | translate }}</span>
            <mat-radio-group
              formControlName="has_permanent_instructions_for_funds_transfer_to_foreign_accs"
              (change)="
                onRadioButtonChange($event.value, 'permanent_instructions_for_funds_transfer_to_foreign_accs_info', 255)
              ">
              <mat-radio-button color="primary" [value]="true">
                {{ 'YES' | translate }}
              </mat-radio-button>
              <mat-radio-button color="primary" [value]="false">
                {{ 'NO' | translate }}
              </mat-radio-button>
            </mat-radio-group>
            <div
              class="extra-field"
              *ngIf="extraDataForm.value.has_permanent_instructions_for_funds_transfer_to_foreign_accs === true">
              <mat-form-field appearance="outline">
                <mat-label>{{
                  'FIELD:PERMANENT_INSTRUCTIONS_FOR_FUNDS_TRANSFER_TO_FOREIGN_ACCS_INFO' | translate
                }}</mat-label>
                <input
                  formControlName="permanent_instructions_for_funds_transfer_to_foreign_accs_info"
                  matInput
                  required />
                <mat-error
                  *ngIf="
                    extraDataForm.get('permanent_instructions_for_funds_transfer_to_foreign_accs_info').errors
                      ?.maxlength
                  ">
                  {{ 'ERROR:MAX_LENGTH' | translate : [255] }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="questionnaire-block">
            <span>{{ 'FIELD:HAS_ORDER_TO_TRANSFER_FUNDS_TO_FOREIGN_COUNTRY' | translate }}</span>
            <mat-radio-group
              (change)="onChangeOrderToTransferFunds($event)"
              formControlName="has_order_to_transfer_funds_to_foreign_country"
              class="row">
              <mat-radio-button value="usa" color="primary">
                {{ 'PROFILE:IS_TRANSFER_ORDER_TO_USA_OPTION_1' | translate }}
              </mat-radio-button>
              <mat-radio-button value="other_country" color="primary">
                {{ 'PROFILE:IS_TRANSFER_ORDER_TO_USA_OPTION_2' | translate }}
              </mat-radio-button>
              <mat-radio-button value="no" color="primary">
                {{ 'PROFILE:IS_TRANSFER_ORDER_TO_USA_OPTION_3' | translate }}
              </mat-radio-button>
            </mat-radio-group>
            <div
              class="extra-field"
              *ngIf="extraDataForm.value.has_order_to_transfer_funds_to_foreign_country === 'other_country'">
              <div class="add-button">
                <mat-icon (click)="addOrderToTransferFunds()">add</mat-icon>
              </div>
              <ng-container formArrayName="has_order_to_transfer_funds_to_foreign_countries_list">
                <ng-container
                  *ngFor="
                    let order of extraDataForm.controls.has_order_to_transfer_funds_to_foreign_countries_list[
                      'controls'
                    ];
                    let i = index
                  ">
                  <ng-container [formGroup]="order">
                    <div *ngIf="i > 0" class="clear-button">
                      <mat-icon (click)="removeOrderToTransferFunds(i)">clear</mat-icon>
                    </div>
                    <mat-form-field appearance="outline">
                      <mat-label>{{
                        'PROFILE:IS_RESIDENTIAL_ADDRESS_IN_THE_FOREIGN_COUNTRY_LABEL_1' | translate
                      }}</mat-label>
                      <input formControlName="name" matInput required />
                    </mat-form-field>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
          </div>
          <div class="questionnaire-block">
            <span>{{ 'FIELD:IS_FOREIGN_PERSON_AUTHORIZED_TO_MANAGE_ACCOUNT' | translate }}</span>
            <mat-radio-group
              (change)="onChangeForeignPerson($event)"
              formControlName="is_foreign_person_authorized_to_manage_account"
              class="row">
              <mat-radio-button value="usa_person" color="primary">
                {{ 'PROFILE:IS_RESIDENTIAL_ADDRESS_IN_THE_FOREIGN_COUNTRY_OPTION_1' | translate }}
              </mat-radio-button>
              <mat-radio-button value="other_country_person" color="primary">
                {{ 'PROFILE:IS_RESIDENTIAL_ADDRESS_IN_THE_FOREIGN_COUNTRY_OPTION_2' | translate }}
              </mat-radio-button>
              <mat-radio-button value="no" color="primary">
                {{ 'PROFILE:IS_RESIDENTIAL_ADDRESS_IN_THE_FOREIGN_COUNTRY_OPTION_3' | translate }}
              </mat-radio-button>
            </mat-radio-group>
            <div
              class="extra-field"
              *ngIf="extraDataForm.value.is_foreign_person_authorized_to_manage_account === 'other_country_person'">
              <div class="add-button">
                <mat-icon (click)="addCountry()">add</mat-icon>
              </div>
              <ng-container formArrayName="foreign_person_authorized_to_manage_account_countries_list">
                <ng-container
                  *ngFor="
                    let country of extraDataForm.controls.foreign_person_authorized_to_manage_account_countries_list[
                      'controls'
                    ];
                    let i = index
                  ">
                  <ng-container [formGroup]="country">
                    <div *ngIf="i > 0" class="clear-button">
                      <mat-icon (click)="removeCountry(i)">clear</mat-icon>
                    </div>
                    <mat-form-field appearance="outline">
                      <mat-label>{{
                        'PROFILE:IS_RESIDENTIAL_ADDRESS_IN_THE_FOREIGN_COUNTRY_LABEL' | translate
                      }}</mat-label>
                      <input formControlName="name" matInput required />
                    </mat-form-field>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
          </div>

          <!-- USA taxpayer info -->
          <div class="questionnaire-block">
            <span>{{ 'FIELD:IS_USA_CITIZEN' | translate }}</span>
            <mat-radio-group formControlName="is_usa_citizen">
              <mat-radio-button color="primary" [value]="true">
                {{ 'YES' | translate }}
              </mat-radio-button>
              <mat-radio-button color="primary" [value]="false">
                {{ 'NO' | translate }}
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="questionnaire-block">
            <span>{{ 'FIELD:HAS_GREEN_CARD' | translate }}</span>
            <mat-radio-group formControlName="has_green_card">
              <mat-radio-button color="primary" [value]="true">
                {{ 'YES' | translate }}
              </mat-radio-button>
              <mat-radio-button color="primary" [value]="false">
                {{ 'NO' | translate }}
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="questionnaire-block">
            <span>{{ 'FIELD:IS_BIRTH_PLACE_USA_AND_NOT_CITIZEN' | translate }}</span>
            <mat-radio-group formControlName="is_birth_place_usa_and_not_citizen" class="row">
              <mat-radio-button value="not_applicable" color="primary">
                {{ 'PROFILE:IS_THE_BIRTH_PLACE_IS_USA_OPTION_1' | translate }}
              </mat-radio-button>
              <mat-radio-button value="did_not_refuse" color="primary">
                {{ 'PROFILE:IS_THE_BIRTH_PLACE_IS_USA_OPTION_2' | translate }}
              </mat-radio-button>
              <mat-radio-button value="refused" color="primary">
                {{ 'PROFILE:IS_THE_BIRTH_PLACE_IS_USA_OPTION_3' | translate }}
              </mat-radio-button>
            </mat-radio-group>
            <div class="hint">
              <p>{{ 'PROFILE:IS_THE_BIRTH_PLACE_IS_USA_HINT' | translate }}</p>
            </div>
          </div>
          <div class="questionnaire-block">
            <span>{{ 'FIELD:HAS_USA_PHONE' | translate }}</span>
            <mat-radio-group formControlName="has_usa_phone">
              <mat-radio-button color="primary" [value]="true">
                {{ 'YES' | translate }}
              </mat-radio-button>
              <mat-radio-button color="primary" [value]="false">
                {{ 'NO' | translate }}
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div class="questionnaire-block">
            <span>{{ 'FIELD:IS_USA_TAX_RESIDENT' | translate }}</span>
            <mat-radio-group (change)="onChangeUsaTaxResident($event.value)" formControlName="is_usa_tax_resident">
              <mat-radio-button color="primary" [value]="true">
                {{ 'YES' | translate }}
              </mat-radio-button>
              <mat-radio-button color="primary" [value]="false">
                {{ 'NO' | translate }}
              </mat-radio-button>
            </mat-radio-group>
            <div class="extra-field" *ngIf="extraDataForm.value.is_usa_tax_resident === true">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'FIELD:USA_TAX_NUMBER' | translate }}</mat-label>
                <input formControlName="usa_tax_number" matInput required />
                <mat-error *ngIf="extraDataForm.get('usa_tax_number').errors?.maxlength">
                  {{ 'ERROR:MAX_LENGTH' | translate : [30] }}
                </mat-error>
              </mat-form-field>
              <div class="hint">
                <p>{{ 'PROFILE:IS_TAX_USA_RESIDENT_HINT' | translate }}</p>
              </div>
            </div>
          </div>
          <div class="confirmation word-wrap">
            <mat-checkbox color="primary" [formControl]="extraDataProcessing">{{
              'PROFILE:EXTRA_DATA_CONFIRMATION' | translate
            }}</mat-checkbox>
          </div>
        </form>
        <div class="charts">
          <button
            [disabled]="
              (authService.isAllowedToChangeData$ | async)?.result === false ||
              attachments.length < 3 ||
              personalDataForm.invalid ||
              contactDataForm.get('registration_address').invalid ||
              (contactDataForm.get('living_address').invalid &&
                contactDataForm.get('is_living_address_as_reg').value === false) ||
              (contactDataForm.get('postal_address').invalid &&
                contactDataForm.get('is_postal_address_as_living').value === false) ||
              extraDataForm.invalid ||
              !extraDataProcessing.value
            "
            class="change-data sign"
            (click)="submit()"
            mat-button>
            {{ ((authService.userProfile$ | async)?.status === 'pii_approved' ? 'ADD_ORDER' : 'SAVE') | translate }}
          </button>
        </div>
        <!-- <button [disabled]='extraDataForm.invalid' class="btn btn-outline-dark filled" type="button" (click)="next()">
          {{ 'SAVE' | translate }}
        </button> -->
      </div>
    </div>
    <div class="col charts">
      <mat-button-toggle-group vertical="true" name="favoriteColor">
        <mat-button-toggle
          (click)="profileService.goToStep(0)"
          [ngClass]="{ checked: profileService.profileCurrentStepKey === 0 }">
          {{ 'PASSPORT' | translate }}
          <div *ngIf="personalDataForm.invalid" class="valid-flag">
            <span></span>
          </div>
        </mat-button-toggle>
        <mat-button-toggle
          (click)="profileService.goToStep(1)"
          [ngClass]="{ checked: profileService.profileCurrentStepKey === 1 }">
          {{ 'PROFILE:ADDRESS_DATA' | translate }}
          <div
            *ngIf="
              contactDataForm.get('registration_address').invalid ||
              (contactDataForm.get('living_address').invalid &&
                contactDataForm.get('is_living_address_as_reg').value === false) ||
              (contactDataForm.get('postal_address').invalid &&
                contactDataForm.get('is_postal_address_as_living').value === false)
            "
            class="valid-flag">
            <span></span>
          </div>
        </mat-button-toggle>
        <mat-button-toggle
          (click)="profileService.goToStep(2)"
          [ngClass]="{ checked: profileService.profileCurrentStepKey === 2 }">
          {{ 'PROFILE:TAX_COMPLIANCE' | translate }}
          <div *ngIf="extraDataForm.invalid || !extraDataProcessing.value" class="valid-flag">
            <span></span>
          </div>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>
</div>
