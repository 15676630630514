import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { AiUiImageDialogComponent } from '../ai-ui-image-dialog/ai-ui-image-dialog.component';

@Component({
  selector: 'ai-ui-personal-data-editable-content',
  templateUrl: './ai-ui-personal-data-editable-content.component.html',
  styleUrls: ['./ai-ui-personal-data-editable-content.component.scss'],
})
export class AiUiPersonalDataEditableContentComponent {
  constructor(
    private loader: LoaderService,
    private profileService: ProfileService,
    private router: Router,
    private dialogRef: MatDialogRef<AiUiPersonalDataEditableContentComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  open(url: string) {
    this.dialog.open(AiUiImageDialogComponent, {
      panelClass: 'attachment-container',
      data: {
        url,
      },
    });
  }

  rollback() {
    this.loader.showLoader();
    this.profileService.rollBackChanges().subscribe({
      next: () => {
        this.dialogRef.close();
        this.router.navigate(['profile', 'edit']);
        this.loader.hideLoader();
      },
      error: () => {
        this.loader.hideLoader();
      },
    });
  }
}
