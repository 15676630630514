<label>
  <ng-content></ng-content>
</label>
<input
  type="text"
  [(ngModel)]="value"
  [disabled]="disabled"
  (input)="onChange(value)"
  (blur)="onTouched()"
  mask="0000"
/>
