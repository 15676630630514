import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  constructor(private snackBar: MatSnackBar) {}

  public showSuccess(message: string = 'Success') {
    this.snackBar.open(message, '\u2715', {
      panelClass: 'toaster-success',
      duration: 5000,
      horizontalPosition: 'right',
    });
  }

  public showInfo(message: string = 'Info') {
    this.snackBar.open(message, '\u2715', {
      panelClass: 'toaster-info',
      duration: 5000,
      horizontalPosition: 'right',
    });
  }

  public showError(message: string = 'Error') {
    this.snackBar.open(message, '\u2715', {
      panelClass: 'toaster-error',
      duration: 5000,
      horizontalPosition: 'right',
    });
  }
}
