<div class="container-fluid bg-white p-4"
  [@open]="'opened'">
  <h1>{{ 'CLAIM:FILLING' | translate }}</h1>
  <div class="hint">
    <p>{{ 'CLAIM_TYPE:CASH_TRANSFER' | translate }}</p>
  </div>
  <div class="row">
    <ui-step-view class="col-12 mt-3"
      [total]="2"
      [current]="cfService.stepKey + 1"
      [stepValues]="['CLAIM_STEP:CREATION', 'CLAIM_STEP:SIGN']">
    </ui-step-view>
    <div class="order-container">
      <div class="form-data  col-12">
        <ng-container *ngIf="cfService.stepKey===0">
          <form [formGroup]="form">
            <!-- <mat-form-field appearance="outline">
              <mat-label>{{ 'TRANSFER:FROM_AGREEMENT' | translate }}</mat-label>
              <mat-select formControlName="reason">
                <mat-option *ngFor="let type of terminationTypes" [value]="type">
                  {{ type | translate }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="form.get('reason').errors?.required">
                {{'ERROR:REQUIRED' | translate }}
              </mat-error>
            </mat-form-field> -->
            <div class="row">
              <div class="col">
                <mat-form-field appearance="outline">
                  <mat-label>{{ 'BANK:CURRENCY_CODE' | translate }}</mat-label>
                  <mat-select formControlName="transfer_currency">
                    <mat-option *ngFor="let code of currencies"
                      [value]="code">
                      {{ code }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="form.get('transfer_currency').errors?.required">
                    {{'ERROR:REQUIRED' | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field appearance="outline">
                  <mat-label>{{ 'TRANSFER:AMOUNT' | translate }}</mat-label>
                  <input amount
                    [decimalPlace]="2"
                    matInput
                    type="text"
                    formControlName="transfer_amount">
                </mat-form-field>
              </div>
            </div>
            <mat-checkbox (change)="onCheckboxChanged($event)"
              class="mb-4"
              color="primary"
              formControlName="transfer_all_money">{{
                'WITHDRAWAL:IN_THE_AMOUNT_OF_FREE_BALANCE' | translate }}</mat-checkbox>
            <div class="transfer-types">
              <mat-radio-group class="row"
                formControlName="transfer_type">
                <ng-container *ngFor="let type of transferTypes">
                  <mat-radio-button color="primary"
                    [value]="type">
                    {{ 'TRANSFER:' + type | translate }}
                  </mat-radio-button>
                </ng-container>
              </mat-radio-group>
            </div>
            <div class="row">
              <div class="col"
                *ngIf="form.get('transfer_type').value==='agreement'">
                <mat-form-field appearance="outline">
                  <mat-label>{{ 'TRANSFER:FROM_AGREEMENT' | translate }}</mat-label>
                  <mat-select formControlName="transfer_from_agreement">
                    <mat-option *ngFor="let agreement of [(auth.brokerageService$ | async)]"
                      [value]="agreement.id">
                      {{ agreement?.number }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="form.get('transfer_from_agreement').errors?.required">
                    {{'ERROR:REQUIRED' | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field appearance="outline">
                  <mat-label>{{ 'TRANSFER:FROM_AREA' | translate }}</mat-label>
                  <mat-select formControlName="transfer_from_exchange">
                    <mat-option *ngFor="let exchange of this.exchanges"
                      [value]="exchange.id">
                      {{ exchange?.name }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="form.get('transfer_from_exchange').errors?.required">
                    {{'ERROR:REQUIRED' | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col"
                *ngIf="form.get('transfer_type').value==='agreement'">
                <mat-form-field appearance="outline">
                  <mat-label>{{ 'TRANSFER:TO_AGREEMENT' | translate }}</mat-label>
                  <mat-select formControlName="transfer_to_agreement">
                    <mat-option *ngFor="let agreement of [(auth.brokerageService$ | async)]"
                      [value]="agreement.id">
                      {{ agreement?.number }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="form.get('transfer_to_agreement').errors?.required">
                    {{'ERROR:REQUIRED' | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field appearance="outline">
                  <mat-label>{{ 'TRANSFER:TO_AREA' | translate }}</mat-label>
                  <mat-select formControlName="transfer_to_exchange">
                    <mat-option *ngFor="let exchange of this.exchanges"
                      [value]="exchange.id">
                      {{ exchange?.name }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="form.get('transfer_to_exchange').errors?.required">
                    {{'ERROR:REQUIRED' | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </form>
          <div class="actions">
            <div class="d-flex justify-content-end mb-3">
              <button [disabled]="form.invalid"
                class="filled"
                mat-flat-button
                (click)="next()">
                {{ 'NEXT' | translate }}
              </button>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="cfService.stepKey===1">
          <div class="col-12">
            <ai-ui-documents [attachments]="attachmentsToSign"
              [isCreatingOrder]="true"></ai-ui-documents>
          </div>
          <div class="col-10 confirmation">
            <mat-checkbox [formControl]="theFirstConfirmation"
              color="primary">
              {{ 'BS:CONFIRMATION_4' | translate }}
              <a href="https://www.aigenis.ru/info12"
                target="_blank"
                class="risk-link">{{ 'BS:CONFIRMATION_4_1' | translate }}</a>
                {{ 'BS:CONFIRMATION_4_2' | translate }}
            </mat-checkbox>
            <h4>{{ 'CLAIM_SIGNATURE_TITLE' | translate }}</h4>
            <div class="hint">
              <p>{{ 'CLAIM_SIGNATURE_INFORMATION' | translate }}
              </p>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <button class="back"
              (click)="back()"
              mat-button
              type="button">
              {{ 'BACK' | translate }}
            </button>
            <button mat-button
              [disabled]="!theFirstConfirmation.value"
              class="filled"
              type="button"
              (click)="sendToSign()">
              {{ 'SIGN' | translate }}
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>