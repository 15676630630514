<div class="notifications">
  <div class="notification__title">
    <h5>Уведомления</h5>
    <mat-icon [matTooltip]="'PUSH:READ_ALL' | translate" (click)="readAll()">mail_outline</mat-icon>
  </div>

  <ng-container *ngIf="(pn.notifications$ | async)?.length;else notFound">
    <ng-container *ngFor="let notification of (pn.notifications$ | async)">
      <div class="notification content" (click)='navigateTo(notification)'>
        <div class="left-side" *ngIf="notification?.status==='sent'">
          <span></span>
        </div>
        <div class="right-side">
          <b>{{ notification.updated_at + 'Z' | date: "dd.MM.yy HH:mm" }}</b>
          <span>{{ notification.body }}</span>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #notFound>
    <div class="notification not-found">
      <span>У вас нет новых уведомлений</span>
    </div>
  </ng-template>
</div>