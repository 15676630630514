import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SecurityAttachmentPipe } from './security-attachment/security-attachment.pipe';
import { ClaimStatusPipe } from './claim-status/claim-status.pipe';
import { FaqFilterPipe } from './faq/faq-filter.pipe';

const PIPES = [SecurityAttachmentPipe, ClaimStatusPipe, FaqFilterPipe];

@NgModule({
  declarations: [...PIPES],
  exports: [...PIPES],
  imports: [CommonModule],
})
export class SharedPipesModule {}
