<ng-container *ngIf="(authService.userProfile$ | async)">
  <div class="container-fluid bg-white p-4"
    [@open]="'opened'">
    <div class="row">
      <div class="col-12">
        <h1>{{ 'DOCUMENTS' | translate }}</h1>
        <div class="subtitle">
          <p>{{ 'DOCUMENTS_TAB:SUBTITLE' | translate }}</p>
        </div>
        <div class="hint">
          <p>{{ 'DOCUMENTS_TAB:HINT' | translate }}</p>
        </div>
      </div>
      <div class="chapter">
        <div class="chapter__title">
          <h4>{{ 'PASSPORT' | translate }}</h4>
        </div>
        <div class="chapter__subtitle">
          <p>{{ 'DOCUMENTS_TAB:PASSPORT_CHAPTER' | translate }}</p>
        </div>
        <div class="chapter__content">
          <div class="col-12">
            <ai-ui-documents [attachments]="passportScans" [isReadonly]="true"></ai-ui-documents>
          </div>
        </div>
      </div>
      <div class="chapter">
        <div class="chapter__title">
          <h4>{{ 'DOCUMENTS' | translate }}</h4>
        </div>
        <div class="chapter__content">
          <div class="col-12">
            <ai-ui-documents [attachments]="attachments" [isReadonly]="true"></ai-ui-documents>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>