import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Form, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { apiUrlWithPrefix } from '@env';
import { TranslationService } from '@localization';
import { mergeMap } from 'rxjs';
import { Attachment, AttachmentBase } from 'src/app/interfaces/api/attachment.interface';
import { ClaimsBatchResponse, QualifiedInvestorRequest } from 'src/app/interfaces/api/claims.interface';
import { ClaimsService } from 'src/app/services/claims/claims.service';
import { DocumentService } from 'src/app/services/document/document.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { QualifiedClaimFlowService } from 'src/app/services/qualified-claim-flow/qualifiedclaim-flow.service';
import { AiUiImageDialogComponent } from 'src/app/shared/shared-components/ai-ui-image-dialog/ai-ui-image-dialog.component';
import { ToasterService } from 'src/app/shared/shared-services/toaster/toaster.service';
import { animationOptions } from 'src/app/utils/animation';
import {
  AigenisAssetsType,
  ClaimAttachmentType,
  OtherType,
  acceptedFormates,
  convertByteToMegaBytes,
  maxFileSize,
} from 'src/app/utils/utils';

@Component({
  selector: 'app-qualified-investor-new',
  templateUrl: './qualified-investor-new.component.html',
  styleUrls: ['./qualified-investor-new.component.scss'],
  animations: animationOptions,
})
export class QualifiedInvestorNewComponent implements OnInit, OnDestroy {
  form: FormGroup;

  attachmentsToSign: Attachment[] = [];

  assets = Array.from(new Set(Object.keys(AigenisAssetsType)));

  count_of_assets: FormControl = new FormControl(AigenisAssetsType.nothing, Validators.required);

  attachmentsForm: FormArray = new FormArray([]);

  attachments: Attachment[] = [];

  acceptedFormates = acceptedFormates;

  claimBatchId: number = 0;

  documentsName: string[] = [];

  attachmentUrl = (id: number): string => `${apiUrlWithPrefix}/claim-attachment/${id}/preview/`;

  constructor(
    public qfService: QualifiedClaimFlowService,
    private fb: FormBuilder,
    private cfService: ClaimsService,
    private toaster: ToasterService,
    private loader: LoaderService,
    private translation: TranslationService,
    private documentService: DocumentService,
    private cd: ChangeDetectorRef,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.form = this.initForm();
    this.documentsName = [
      this.translation.translate('QUALIFICATIONS:TIP_1_CONTENT_1'),
      this.translation.translate('QUALIFICATIONS:TIP_1_CONTENT_2'),
      this.translation.translate('QUALIFICATIONS:TIP_1_CONTENT_3'),
      this.translation.translate('QUALIFICATIONS:TIP_1_CONTENT_4'),
      this.translation.translate('QUALIFICATIONS:TIP_1_CONTENT_5'),
    ];
  }

  initForm() {
    return this.fb.group({
      total_cost_of_securities_document: new FormControl('', [Validators.maxLength(255)]),
      at_least_two_years_of_experience_document: new FormControl('', [Validators.maxLength(255)]),
      job_title_agreed_with_bank_of_russia_document: new FormControl('', [Validators.maxLength(255)]),
      deals_document: new FormControl('', [Validators.maxLength(255)]),
      property_size_document: new FormControl('', [Validators.maxLength(255)]),
      higher_economic_education_document: new FormControl('', [Validators.maxLength(255)]),
    });
  }

  fileSelected(event) {
    const file: File = event.target.files[0];
    let formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('type', ClaimAttachmentType.other);
    if (file.size > maxFileSize) {
      this.toaster.showError(this.translation.translate('FILE_SIZE_INCORRECT', [convertByteToMegaBytes(maxFileSize)]));
      return;
    }
    this.loader.showLoader();
    this.cfService.addClaimAttachment(formData).subscribe({
      next: (data: AttachmentBase) => {
        this.attachmentsForm.push(new FormControl(data.id));
        this.updateAttachments(data);
        event.target.value = null;
        this.cd.markForCheck();
        this.loader.hideLoader();
      },
      error: () => {
        this.loader.hideLoader();
      },
    });
  }

  private updateAttachments(data: AttachmentBase): void {
    this.loader.showLoader();
    this.cfService.fileToBlob(this.attachmentUrl(data.id)).subscribe({
      next: (url: string) => {
        this.attachments.push({
          id: data.id,
          file_name: data.file_name,
          url,
          isImage: ['jpeg', 'jpg', 'png']?.indexOf(data.file_name?.split('.')?.pop()?.toLowerCase()) !== -1,
        });
        this.toaster.showSuccess(this.translation.translate('ATTACHMENT_HAS_BEEN_ADDED'));
        this.cd.markForCheck();
        this.loader.hideLoader();
      },
      error: () => {
        this.loader.hideLoader();
      },
    });
  }

  open(url: string) {
    this.dialog.open(AiUiImageDialogComponent, {
      panelClass: 'attachment-container',
      data: {
        url,
      },
    });
  }

  onDelete(id: number) {
    this.loader.showLoader();
    this.cfService.deleteAttachment(id).subscribe({
      next: () => {
        this.toaster.showInfo(this.translation.translate('ATTACHMENT_HAS_BEEN_REMOVED'));
        this.attachments = this.attachments.filter((att) => att.id !== id);
        const index = this.attachmentsForm.value.findIndex((n) => n === id);
        this.attachmentsForm.removeAt(index);
        this.cd.markForCheck();
        this.loader.hideLoader();
      },
      error: () => {
        this.loader.hideLoader();
      },
    });
  }

  back() {
    this.qfService.goToStep(this.qfService.stepKey - 1);
  }

  next() {
    if (this.form.invalid) {
      return;
    }
    if (this.qfService.stepKey === 2) {
      const payload = this.form.getRawValue() as QualifiedInvestorRequest;
      payload.type = OtherType.qualified_investor;
      payload.attachments = this.attachmentsForm.value;
      payload.count_of_assets = this.count_of_assets.value;
      this.loader.showLoader();
      this.cfService
        .addClaimsBatch([payload])
        .pipe(
          mergeMap((res: ClaimsBatchResponse) => {
            this.claimBatchId = res.id;
            return this.documentService.claimsBatchDocumentsProcessing(res.id);
          })
        )
        .subscribe({
          next: (data) => {
            this.attachmentsToSign = data;
            this.qfService.goToStep(this.qfService.stepKey + 1);
            this.loader.hideLoader();
            this.cd.markForCheck();
          },
          error: () => {
            this.loader.hideLoader();
          },
        });
    } else {
      this.qfService.goToStep(this.qfService.stepKey + 1);
    }
  }

  sendToSign = () => this.cfService.sendToSign(this.claimBatchId);

  ngOnDestroy(): void {
    this.qfService.goToStep(0);
    this.form.reset();
    this.count_of_assets.reset();
  }
}
