import { Component } from '@angular/core';
import { TranslationService } from '@localization';
import { AuthService } from 'src/app/services/auth/auth.service';
import { PortfolioService } from 'src/app/services/portfolio/portfolio.service';
import { animationOptions } from 'src/app/utils/animation';
import { DepoType } from 'src/app/utils/utils';

@Component({
  selector: 'app-depository',
  templateUrl: './depository.component.html',
  styleUrls: ['./depository.component.scss'],
  animations: animationOptions,
})
export class DepositoryComponent {
  dsTypes = [
    {
      name: this.translation.translate('CLAIM_TYPE:PROVISION'),
      routerLink: '/claims/depo_reference_new',
    },
    {
      name: this.translation.translate('CLAIM_TYPE:TRANSACTION_REFERENCE_REGISTRY'),
      routerLink: '/claims/depo_transaction_reference_registry_new',
    },
    {
      name: this.translation.translate('CLAIM_TYPE:TRANSACTION_REFERENCE'),
      routerLink: '/claims/depo_transaction_reference_new',
    },
    {
      name: this.translation.translate('CLAIM_TYPE:TRANSACTION_TRANSFER_REFERENCE'),
      routerLink: '/claims/depo_transaction_transfer_reference_new',
    },
    {
      name: this.translation.translate('CLAIM_TYPE:ARBITRARY_ORDER'),
      routerLink: '/claims/depo_manual_assignment_new',
    },
  ];

  constructor(
    public auth: AuthService,
    public portfolioService: PortfolioService,
    private translation: TranslationService
  ) {}
}
