import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslationService } from '@localization';
import { Observable, catchError, from, switchMap, throwError } from 'rxjs';
import { ToasterService } from 'src/app/shared/shared-services/toaster/toaster.service';
import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  static readonly AUTHORIZATION: string = 'Authorization';
  static readonly SKIP_AUTH_INTERCEPT: string = 'x-skip-auth-intercept';

  constructor(
    private authService: AuthService,
    private router: Router,
    private toaster: ToasterService,
    private translation: TranslationService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return from(this.authService.getAccessToken()).pipe(
      switchMap((token) => {
        if (token) {
          request = request.clone({
            headers: request.headers.set(AuthInterceptor.AUTHORIZATION, `Bearer ${token}`),
          });
        }
        return next.handle(request).pipe(
          catchError((err: HttpErrorResponse) => {
            if (err.status === 401 && !request.headers.get(AuthInterceptor.SKIP_AUTH_INTERCEPT)) {
              this.authService.removeToken();
              if (!this.router.url.startsWith('/user')) {
                this.router.navigate(['users', 'sign_in']);
              }
            }
            return throwError(() => err);
          })
        );
      })
    );
  }
}
