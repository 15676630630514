import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-ai-ui-image-dialog',
  templateUrl: './ai-ui-image-dialog.component.html',
  styleUrls: ['./ai-ui-image-dialog.component.scss'],
})
export class AiUiImageDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { url: string }) {}
}
