<div class="form_container" [@open]="'opened'">
  <div class="header_logo">
    <a href="https://aigenis.ru/" target="_blank">
      <img src="./assets/logos/aigenis_dark.svg" />
    </a>
  </div>
  <div class="form__title">
    <span>{{ 'REGISTRATION:PASSWORD_RECOVERY' | translate }}</span>
  </div>
  <form (ngSubmit)="submit()" [formGroup]="passwordForm">
    <div class="validation-note">
      <p>{{'REGISTRATION:PASSWORD_TITLE' | translate }}</p>
      <ul>
        <li>{{'REGISTRATION:PASSWORD_CONTENT_1' | translate }}</li>
        <li>{{'REGISTRATION:PASSWORD_CONTENT_2' | translate }}</li>
        <li>{{'REGISTRATION:PASSWORD_CONTENT_3' | translate }}</li>
      </ul>
    </div>
    <mat-form-field appearance="outline"
      [matTooltip]="getPasswordValidationMessage(passwordForm.get('new_password1').errors)"
      matTooltipPosition="after">
      <mat-label>{{ 'FIELD:PASSWORD' | translate }}</mat-label>
      <input [type]="new_password1.type" formControlName="new_password1" matInput required />
      <ai-ui-toggle-visibility #new_password1 matSuffix></ai-ui-toggle-visibility>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'FIELD:REPEAT_PASSWORD' | translate }}</mat-label>
      <input [type]="new_password2.type" formControlName="new_password2" matInput required />
      <mat-error *ngIf="passwordForm.get('new_password2').errors?.required">
        {{ 'ERROR:REQUIRED' | translate }}
      </mat-error>
      <mat-error *ngIf="passwordForm.get('new_password2').errors?.passwordMismatch">
        {{ 'ERROR:PASSWORD_MISMATCH' | translate }}
      </mat-error>
      <ai-ui-toggle-visibility #new_password2 matSuffix></ai-ui-toggle-visibility>
    </mat-form-field>
    <button [disabled]="passwordForm.invalid" class="signin-btn" mat-flat-button type="submit">
      {{ 'RECOVERY_PASSWORD' | translate }}
    </button>
    <button (click)="backToSign()"
      mat-icon-button
      class="sign-terminate"
      type="button">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
  </form>
</div>
<div class="sign-illustration"></div>