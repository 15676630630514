import { Pipe, PipeTransform } from '@angular/core';
import { AgreementStatus, ClaimStatus, getClaimIconName } from 'src/app/utils/utils';

@Pipe({
  name: 'claimStatusIcon',
})
export class ClaimStatusPipe implements PipeTransform {
  transform(status: ClaimStatus | AgreementStatus | string): unknown {
    return getClaimIconName(status);
  }
}
