<div class="container-fluid bg-white p-4"
  [@open]="'opened'">
  <h1>{{ 'CLAIM:FILLING' | translate }}</h1>
  <div class="hint">
    <p>{{ 'securities_trade' | translate }}</p>
  </div>
  <div class="row">
    <ui-step-view class="col-12 mt-3"
      [total]="2"
      [current]="cfService.stepKey + 1"
      [stepValues]="['CLAIM_STEP:CREATION', 'CLAIM_STEP:SIGN']">
    </ui-step-view>
    <div class="order-container">
      <div class="form-data col-12">
        <ng-container *ngIf="cfService.stepKey===0">
          <form [formGroup]="form">
            <h3>{{ 'SECURITY:ADD_TITLE' | translate }}</h3>
            <div class="hint fs-1">
              <p>{{ 'SECURITY:ADD_HINT' | translate }}</p>
              <p style="color:red">&nbsp;*</p>
            </div>
            <button class="reset"
              (click)="resetData(form, false)"
              mat-button
              type="button">
              <mat-icon>clear</mat-icon>
              <span>{{ 'RESET' | translate }}</span>
            </button>
            <mat-form-field appearance="outline">
              <mat-label>{{ 'SECURITY:EXECUTION_PLACE' | translate }}</mat-label>
              <mat-select [(value)]="exchangeId"
                formControlName="exchange"
                (selectionChange)="onExecutionPlaceChanged()">
                <mat-option *ngFor="let exchange of exchanges"
                  [value]="exchange.id">{{ exchange.name |
                    translate }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>{{ 'SECURITY:NAME' | translate }}</mat-label>
              <input matInput
                [formControl]="securityName"
                [readonly]="true">
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>{{ 'SECURITY:ISIN_CODE' | translate }}</mat-label>
              <input matInput
                formControlName="isin"
                (click)="searchSecurity(form, false, form.get('exchange')?.value)"
                [readonly]="true">
              <mat-error *ngIf="form.get('isin').errors?.required">
                {{'ERROR:REQUIRED' | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>{{ 'SECURITY:TYPE' | translate }}</mat-label>
              <mat-select formControlName="instr_type">
                <mat-option *ngFor="let type of securityTypes"
                  [value]="type">
                  {{ type | translate }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="form.get('instr_type').errors?.required">
                {{'ERROR:REQUIRED' | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>{{ 'SECURITY:CATEGORY' | translate }}</mat-label>
              <mat-select formControlName="instr_category">
                <ng-container *ngIf="!securityCategories.length;else categories">
                  <mat-option [disabled]="true">{{ 'NOT_FOUND' | translate }}</mat-option>
                </ng-container>
                <ng-template #categories>
                  <mat-option *ngFor="let type of securityCategories"
                    [value]="type">
                    {{ 'SECURITY_CATEGORY:' + type | translate }}
                  </mat-option>
                </ng-template>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>{{ 'SECURITY:ISSUER' | translate }}</mat-label>
              <mat-select formControlName="issuer"
                (selectionChange)="onFullNameSelected($event, form, form.get('exchange')?.value)">
                <mat-form-field appearance="outline"
                  class="search-field">
                  <input matInput
                    placeholder="{{ 'SEARCH' | translate }}"
                    (input)="onFullNameInputted($event, form)">
                </mat-form-field>
                <ng-container *ngIf="!issuers.length">
                  <mat-option [disabled]="true">{{ 'ENTER_AT_LEAST_THREE_SYMBOLS_ISSUER' | translate }}</mat-option>
                </ng-container>
                <mat-option *ngFor="let issuer of issuers"
                  [value]="issuer.id">
                  {{ issuer.full_name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="form.get('issuer').errors?.required">
                {{'ERROR:REQUIRED' | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>{{ 'SECURITY:REGISTRATION_NUMBER' | translate }}</mat-label>
              <input formControlName="state_reg_number"
                matInput>
            </mat-form-field>
            <div class="row">
              <mat-form-field class="col"
                appearance="outline">
                <mat-label>{{ 'SECURITY:NOMINAL_PRICE' | translate }}</mat-label>
                <input amount
                  [decimalPlace]="2"
                  type="text"
                  formControlName="nominal_price"
                  matInput>
              </mat-form-field>
              <mat-form-field class="col"
                appearance="outline">
                <mat-label>{{ 'SECURITY:NOMINAL_PRICE_CURRENCY' | translate }}</mat-label>
                <input [readonly]="true"
                  formControlName="nominal_price_currency"
                  matInput>
              </mat-form-field>
              <mat-form-field class="col"
                appearance="outline">
                <mat-label>{{ 'SECURITY:ISSUE_NUMBER' | translate }}</mat-label>
                <input formControlName="issue_number"
                  matInput>
              </mat-form-field>
            </div>
            <mat-form-field appearance="outline">
              <mat-label>{{ 'CLAIM:DEPO_ACCOUNT' | translate }}</mat-label>
              <mat-select formControlName="depositary_account">
                <ng-container *ngIf="mainDepositaryAccount;else empty">
                  <mat-option [value]="mainDepositaryAccount.id">
                    {{ mainDepositaryAccount.account }}
                  </mat-option>
                </ng-container>
                <ng-template #empty>
                  <mat-option [disabled]="true">{{ 'NOTHING_DEPO_FOUND' | translate }}</mat-option>
                </ng-template>
              </mat-select>
            </mat-form-field>
            <div class="row">
              <mat-form-field class="col"
                appearance="outline">
                <mat-label>{{ 'SECURITY:TRADE_TYPE' | translate }}</mat-label>
                <mat-select formControlName="deal_type"
                  (selectionChange)="onTradeTypeChanged()">
                  <mat-option *ngFor="let type of dealTypes"
                    [value]="type">
                    {{ type | translate }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="form.get('deal_type').errors?.required">
                  {{'ERROR:REQUIRED' | translate }}
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col"
                appearance="outline">
                <mat-label>{{ 'SECURITY:PRICE_TRADE_TYPE' | translate }}</mat-label>
                <mat-select formControlName="deal_price_type"
                  (selectionChange)="onPriceDealTypeChanged($event)">
                  <mat-option *ngFor="let type of dealPriceTypes"
                    [value]="type">
                    {{ 'CLAIM_DEAL_PRICE_TYPE:' + type | translate }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="form.get('deal_price_type').errors?.required">
                  {{'ERROR:REQUIRED' | translate }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="radio-group">
              <mat-radio-group [disabled]="true"
                formControlName="quik_type">
                <mat-radio-button color="primary"
                  value="limit">
                  {{ 'CLAIM_QUIK_TYPE:limit' | translate }}
                </mat-radio-button>
                <mat-radio-button color="primary"
                  value="market">
                  {{ 'CLAIM_QUIK_TYPE:market' | translate }}
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="hint">
              <p>{{ (form.get('deal_price_type')?.value !== 'market'
                  ? 'CLAIM_QUIK_TYPE_LIMIT_HINT'
                  : 'CLAIM_QUIK_TYPE_MARKET_HINT') | translate }}</p>
              <!-- <p>{{ (form.get('deal_price_type')?.value!=='market'
                  ? 'CLAIM_QUIK_TYPE_LIMIT_HINT'
                  : (form.get('deal_price_type')?.value==='market' ? 'CLAIM_QUIK_TYPE_MARKET_HINT' : '')) | translate }}</p> -->
            </div>
            <mat-form-field class="col"
              appearance="outline"
              *ngIf="form.get('deal_price_type')?.value==='fixed'">
              <mat-label>{{ 'SECURITY:TRADE_PRICE' | translate }}</mat-label>
              <input amount
                [decimalPlace]="2"
                matInput
                type="text"
                formControlName="fixed_security_price">
              <mat-error *ngIf="form.get('fixed_security_price').errors?.required">
                {{'ERROR:REQUIRED' | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col"
              appearance="outline"
              *ngIf="form.get('deal_price_type')?.value==='not_greater'">
              <mat-label>{{ 'SECURITY:PRICE_TRESHOLD' | translate }}</mat-label>
              <input amount
                [decimalPlace]="2"
                matInput
                type="text"
                formControlName="max_security_price">
              <mat-error *ngIf="form.get('max_security_price').errors?.required">
                {{'ERROR:REQUIRED' | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>{{ 'SECURITY:AMOUNT' | translate }}</mat-label>
              <ng-container *ngIf="!!form.get('code_nsd').value && form.get('code_nsd').value?.endsWith('/DR');else integer">
                <input amount
                  [decimalPlace]="2"
                  type="text"
                  formControlName="quantity"
                  matInput>
              </ng-container>
              <ng-template #integer>
                <input integer
                  type="text"
                  formControlName="quantity"
                  matInput>
              </ng-template>
              <mat-error *ngIf="form.get('quantity').errors?.required">
                {{'ERROR:REQUIRED' | translate }}
              </mat-error>
            </mat-form-field>
            <div class="row">
              <mat-form-field class="col"
                appearance="outline">
                <mat-label>{{ 'SECURITY:DEAL_AMOUNT' | translate }}</mat-label>
                <input amount
                  [decimalPlace]="2"
                  matInput
                  type="text"
                  formControlName="deal_amount"
                  [ngModel]="(form.get('max_security_price')?.value 
                  ? ((form.get('max_security_price')?.value) * form.get('quantity')?.value)
                  : form.get('fixed_security_price')?.value 
                  ? ((form.get('fixed_security_price')?.value) * form.get('quantity')?.value) 
                  : 0).toFixed(2)">
                <mat-error *ngIf="form.get('deal_amount').errors?.required">
                  {{'ERROR:REQUIRED' | translate }}
                </mat-error>
              </mat-form-field>
              <!-- <mat-form-field class="col" appearance="outline"
                *ngIf="form.get('deal_price_type')?.value==='fixed' || form.get('deal_price_type')?.value===null">
                <mat-label>{{ 'SECURITY:PRICE_WITHOUT_NKD' | translate }}</mat-label>
                <input amount [decimalPlace]="2" matInput type="text" formControlName="price"
                  [required]="form.get('deal_price_type')?.value==='fixed' || !form.get('deal_price_type')?.value===null">
                <mat-error *ngIf="form.get('price').errors?.required">
                  {{'ERROR:REQUIRED' | translate }}
                </mat-error>
              </mat-form-field> -->
            </div>
            <mat-form-field appearance="outline">
              <mat-label>{{ 'SECURITY:CONTRACTOR' | translate }}</mat-label>
              <input formControlName="counterparty"
                matInput>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>{{ 'SECURITY:INFO_ABOUT_ENCUMBRANCE' | translate }}</mat-label>
              <input formControlName="security_obligations_info"
                matInput>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>{{ 'SECURITY:CURRENCY' | translate }}</mat-label>
              <mat-select formControlName="price_currency">
                <mat-option *ngFor="let currency of currencies"
                  [value]="currency">
                  {{ currency }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="form.get('price_currency').errors?.required">
                {{'ERROR:REQUIRED' | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>{{ 'SECURITY:CALCULATION_CURRENCY' | translate }}</mat-label>
              <mat-select formControlName="calculation_currency">
                <mat-option *ngFor="let currency of currencies"
                  [value]="currency">
                  {{ currency }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="form.get('calculation_currency').errors?.required">
                {{'ERROR:REQUIRED' | translate }}
              </mat-error>
            </mat-form-field>
            <mat-checkbox formControlName="is_repo"
              color="primary"
              class="mb-4"
              (change)="onShowRepoToggle($event, form)">
              {{ 'REPO_DEAL' | translate }}
            </mat-checkbox>
            <ng-container *ngIf="form.get('is_repo').value">
              <div class="row">
                <mat-form-field class="col"
                  appearance="outline">
                  <mat-label>{{ 'SECURITY:REPO_RATE' | translate }}</mat-label>
                  <input amount
                    [decimalPlace]="2"
                    matInput
                    type="text"
                    formControlName="repo_rate">
                </mat-form-field>
                <mat-form-field class="col"
                  appearance="outline">
                  <mat-label>{{ 'SECURITY:PAIR_TRADE_AMOUNT' | translate }}</mat-label>
                  <input amount
                    [decimalPlace]="2"
                    matInput
                    type="text"
                    formControlName="pair_deal_amount">
                </mat-form-field>
                <mat-form-field class="col"
                  appearance="outline">
                  <mat-label>{{ 'SECURITY:REPO_DEAL_TERM' | translate }}</mat-label>
                  <input matInput
                    min="0"
                    type="number"
                    formControlName="repo_deal_term">
                </mat-form-field>
              </div>
            </ng-container>
            <mat-form-field appearance="outline">
              <mat-label>{{ 'SECURITY:INFORMATION' | translate }}</mat-label>
              <input formControlName="additional_info"
                matInput>
            </mat-form-field>
            <div class="d-flex justify-content-end mb-3"
              *ngIf="dataSource.data.length===0">
              <button [disabled]="form.invalid"
                class="filled"
                mat-flat-button
                (click)="addSecurity()">
                {{ 'SECURITY:ADD_BUTTON' | translate }}
              </button>
            </div>
          </form>
          <ng-container *ngIf="dataSource.data.length">
            <table mat-table
              [dataSource]="dataSource">
              <ng-container matColumnDef="ISSUER">
                <th mat-header-cell
                  *matHeaderCellDef>{{ 'SECURITY:ISSUER' | translate }}</th>
                <td mat-cell
                  *matCellDef="let order">{{ order?.issuerName }}</td>
              </ng-container>
              <ng-container matColumnDef="TYPE">
                <th mat-header-cell
                  *matHeaderCellDef>{{ 'SECURITY:TYPE' | translate }}</th>
                <td mat-cell
                  *matCellDef="let order">{{ order?.instr_type | translate }}</td>
              </ng-container>
              <ng-container matColumnDef="TRADE_TYPE">
                <th mat-header-cell
                  *matHeaderCellDef>{{ 'SECURITY:TRADE_TYPE' | translate }}</th>
                <td mat-cell
                  *matCellDef="let order">{{ order?.deal_type | translate }}</td>
              </ng-container>
              <ng-container matColumnDef="AMOUNT">
                <th mat-header-cell
                  *matHeaderCellDef>{{ 'SECURITY:AMOUNT' | translate }}</th>
                <td mat-cell
                  *matCellDef="let order">{{ order?.quantity }}</td>
              </ng-container>
              <ng-container matColumnDef="CURRENCY">
                <th mat-header-cell
                  *matHeaderCellDef>{{ 'SECURITY:CURRENCY' | translate }}</th>
                <td mat-cell
                  *matCellDef="let order">{{ order?.price_currency }}</td>
              </ng-container>
              <ng-container matColumnDef="PRICE">
                <th mat-header-cell
                  *matHeaderCellDef>{{ 'SECURITY:PRICE' | translate }}</th>
                <td mat-cell
                  *matCellDef="let order">{{ order?.deal_price_type === 'market' ?
                    (order?.deal_price_type | translate) : order?.deal_price_type === 'not_greater' ?
                        order?.max_security_price : order?.deal_price_type === 'fixed' ?
                            order?.fixed_security_price : order?.nominal_price }}</td>
              </ng-container>
              <ng-container matColumnDef="REMOVE_ACTION">
                <th mat-header-cell
                  *matHeaderCellDef></th>
                <td mat-cell
                  *matCellDef="let order">
                  <mat-icon (click)="removeSecurity(order)">delete</mat-icon>
                </td>
              </ng-container>

              <tr mat-header-row
                *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row
                *matRowDef="let order; columns: displayedColumns"></tr>
            </table>
          </ng-container>
          <div class="actions">
            <div class="d-flex justify-content-start mb-3">
              <button [disabled]="!securityOrders.length"
                class="filled"
                mat-flat-button
                (click)="next()">
                {{ 'NEXT' | translate }}
              </button>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="cfService.stepKey===1">
          <div class="col-12">
            <ai-ui-documents [attachments]="attachmentsToSign"
              [isCreatingOrder]="true"></ai-ui-documents>
          </div>
          <div class="col-10 confirmation">
            <mat-checkbox [formControl]="theFirstConfirmation"
              color="primary">
              {{ 'BS:CONFIRMATION_4' | translate }}
              <a href="https://www.aigenis.ru/info12"
                target="_blank"
                class="risk-link">{{ 'BS:CONFIRMATION_4_1' | translate }}</a>
                {{ 'BS:CONFIRMATION_4_2' | translate }}
            </mat-checkbox>
            <h4>{{ 'CLAIM_SIGNATURE_TITLE' | translate }}</h4>
            <div class="hint">
              <p>{{ 'CLAIM_SIGNATURE_INFORMATION' | translate }}
              </p>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <button class="back"
              (click)="back()"
              mat-button
              type="button">
              {{ 'BACK' | translate }}
            </button>
            <button mat-button
              [disabled]="!theFirstConfirmation.value"
              class="filled"
              type="button"
              (click)="sendToSign()">
              {{ 'SIGN' | translate }}
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>