import { Injectable } from '@angular/core';
import { apiUrlWithPrefix } from '@env';

@Injectable({
  providedIn: 'root',
})
export class RegulationsService {
  static readonly RISK_STATEMENT_PREVIEW: string = `${apiUrlWithPrefix}/risk/statement-preview/`;
  static readonly RISK_NOTICE_PREVIEW: string = `${apiUrlWithPrefix}/risk/notice-preview/`;
  static readonly MAIN_INFO_PREVIEW: string = `${apiUrlWithPrefix}/risk/main-info-preview/`;

  static readonly RISK_STATEMENT: string = `${apiUrlWithPrefix}/risk/statement-download/`;
  static readonly RISK_NOTICE: string = `${apiUrlWithPrefix}/risk/notice-download/`;
  static readonly MAIN_INFO: string = `${apiUrlWithPrefix}/risk/main-info-download/`;

  constructor() {}
}
