import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-depo-manual-assignment',
  templateUrl: './depo-manual-assignment.component.html',
  styleUrls: ['./depo-manual-assignment.component.scss'],
})
export class DepoManualAssignmentComponent {
  constructor() {}
}
