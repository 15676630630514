import { Inject, Injectable, Optional } from '@angular/core';
import { Observable, Observer, Subject, map } from 'rxjs';
import { AnonymousSubject } from 'rxjs/internal/Subject';

// export interface Message {
//   source: string;
//   content: string;
// }

@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  ws: WebSocket;

  private subject$: AnonymousSubject<MessageEvent>;
  public messages$: Subject<any>;

  constructor() {}

  initiate(url: string) {
    this.messages$ = <Subject<any>>this.getOrCreateSubject(url).pipe(
      map((res: MessageEvent): any => {
        const data = JSON.parse(res.data);
        return data;
      })
    );
  }

  getOrCreateSubject(url: string): AnonymousSubject<MessageEvent> {
    if (!this.subject$) {
      this.subject$ = this.connect(url);
      console.log('Successfully connected to the WebSocket');
    }
    return this.subject$;
  }

  connect(url: string): AnonymousSubject<MessageEvent> {
    url += `?token=${localStorage.getItem('access_token')}`;
    this.ws = new WebSocket(url);
    let observable = new Observable((obs: Observer<MessageEvent>) => {
      this.ws.onmessage = obs.next.bind(obs);
      this.ws.onerror = obs.error.bind(obs);
      this.ws.onclose = obs.complete.bind(obs);
      return this.ws.close.bind(this.ws);
    });
    let observer = {
      error: null,
      complete: null,
      next: (data: any) => {
        if (this.ws.readyState === WebSocket.OPEN) {
          this.ws.send(JSON.stringify(data));
        }
      },
    };
    return new AnonymousSubject<MessageEvent>(observer, observable);
  }

  disconnect() {
    this.subject$ = null;
    this.ws.close();
  }
}
