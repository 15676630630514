import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiUrlWithPrefix } from '@env';
import { map } from 'rxjs';
import { IssuerResponse } from 'src/app/interfaces/api/issuer.interface';

@Injectable({
  providedIn: 'root',
})
export class IssuerService {
  static readonly ISSUER = `${apiUrlWithPrefix}/issuer/`;

  constructor(private http: HttpClient) {}

  issuerByFullName(partName: string) {
    const httpParams = new HttpParams({
      fromObject: {
        full_name: partName,
      },
    });
    return this.http
      .get(IssuerService.ISSUER, {
        params: httpParams,
      })
      .pipe(map((res: any) => res.results as IssuerResponse[]));
  }

  findById(id: number) {
    return this.http.get(`${apiUrlWithPrefix}/issuer/${id}/`).pipe(map((res: any) => res as IssuerResponse));
  }
}
