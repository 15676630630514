import { Component, OnInit } from '@angular/core';
import { TranslationService } from '@localization';
import { Condition } from 'src/app/interfaces/internal/qualifications.inteface';
import { animationOptions } from 'src/app/utils/animation';

@Component({
  selector: 'app-qualifications',
  templateUrl: './qualifications.component.html',
  styleUrls: ['./qualifications.component.scss'],
  animations: animationOptions,
})
export class QualificationsComponent implements OnInit {
  conditions: Condition[];

  constructor(private translation: TranslationService) {}

  ngOnInit(): void {
    this.conditions = [
      {
        logo: './assets/logos/estate_property.svg',
        title: this.translation.translate('QUALIFICATIONS:CONFITION_1_TITLE'),
        content: this.translation.translate('QUALIFICATIONS:CONFITION_1_CONTENT'),
      },
      {
        logo: './assets/logos/security_defenitions_property.svg',
        title: this.translation.translate('QUALIFICATIONS:CONFITION_2_TITLE'),
        content: this.translation.translate('QUALIFICATIONS:CONFITION_2_CONTENT'),
      },
      {
        logo: './assets/logos/investment_experience.svg',
        title: this.translation.translate('QUALIFICATIONS:CONFITION_3_TITLE'),
        content: this.translation.translate('QUALIFICATIONS:CONFITION_3_CONTENT'),
      },
      {
        logo: './assets/logos/work_experience.svg',
        title: this.translation.translate('QUALIFICATIONS:CONFITION_4_TITLE'),
        content: this.translation.translate('QUALIFICATIONS:CONFITION_4_CONTENT'),
      },
      {
        logo: './assets/logos/education.svg',
        title: this.translation.translate('QUALIFICATIONS:CONFITION_5_TITLE'),
        content: this.translation.translate('QUALIFICATIONS:CONFITION_5_CONTENT'),
      },
    ];
  }
}
