import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';
import { ServiceContract } from 'src/app/interfaces/api/claims.interface';
import { ClientInvestment, ClientPortfolio, Estimate } from 'src/app/interfaces/api/portfolio.interface';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { PortfolioService } from 'src/app/services/portfolio/portfolio.service';
import { AiUiRefillAccountMethodComponent } from 'src/app/shared/shared-components/ai-ui-refill-account-method/ai-ui-refill-account-method.component';
import { animationOptions } from 'src/app/utils/animation';
import { substractDaysFromDate } from 'src/app/utils/utils';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss'],
  animations: animationOptions,
})
export class PortfolioComponent implements OnInit {
  twoDaysAgo: Date = substractDaysFromDate(new Date(), 2);

  mstock: Estimate;

  portfolios: ClientPortfolio[] = [];

  portfolioMoney: number = 0;

  stocks: ClientPortfolio[] = [];

  investments: ClientInvestment[] = [];

  brokerage: ServiceContract = null;

  constructor(
    public auth: AuthService,
    public portfolioService: PortfolioService,
    private loader: LoaderService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.auth.brokerageService$.subscribe((data) => {
      this.brokerage = data;
    });
    this.loader.showLoader();
    forkJoin({
      portfolios: this.portfolioService.clientPortfolio(),
      investments: this.portfolioService.clientInvestment(),
    }).subscribe({
      next: (data) => {
        this.portfolios = data.portfolios;
        this.investments = data.investments;
        this.portfolioMoney = this.portfolioService.calculatePortfolio(this.portfolios, this.investments);
        this.loader.hideLoader();
      },
      error: () => {
        this.loader.hideLoader();
      },
    });

    // this.portfolioService.portfolioMoney().subscribe({
    //   next: (data) => {
    //     this.mstock = data.find((e) => e.exchange_code === AreaType.mstock);
    //     this.loader.hideLoader();
    //   },
    //   error: () => {
    //     this.loader.hideLoader();
    //   },
    // });
  }

  refillAccount() {
    this.dialog.open(AiUiRefillAccountMethodComponent, {
      panelClass: 'popup-container',
      width: '800px',
    });
  }
}
