import { Component } from '@angular/core';

@Component({
  selector: 'ai-ui-toggle-visibility',
  templateUrl: './ai-ui-toggle-visibility.component.html',
  styleUrls: ['./ai-ui-toggle-visibility.component.scss'],
})
export class AiUiToggleVisibilityComponent {
  type: 'password' | 'text' = 'password';

  toggleVisibility() {
    this.type = this.type === 'password' ? 'text' : 'password';
  }
}
