import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { TicketCategory, TicketRequest } from 'src/app/interfaces/api/messages.interface';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { MessagesService } from 'src/app/services/messages/messages.service';

@Component({
  selector: 'app-preliminary',
  templateUrl: './preliminary.component.html',
  styleUrls: ['./preliminary.component.scss'],
})
export class PreliminaryComponent {
  categories: TicketCategory[] = [];

  constructor(public messagesService: MessagesService, private loader: LoaderService, private router: Router) {}

  goToChat(category: TicketCategory) {
    this.loader.showLoader();
    const payload: TicketRequest = {
      category: category.id,
    };
    this.messagesService.createTicket(payload).subscribe({
      next: (data) => {
        this.router.navigate(['/profile/messages', data.chat]);
        this.loader.hideLoader();
      },
      error: () => {
        this.loader.hideLoader();
      },
    });
  }
}
