import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslationService } from '@localization';
import { Subscription } from 'rxjs';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { PasswordService } from 'src/app/services/password-recovery/password-recovery.service';
import { ToasterService } from 'src/app/shared/shared-services/toaster/toaster.service';
import { animationOptions } from 'src/app/utils/animation';
import { EMAIL_REGEXP } from 'src/app/utils/utils';

@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.scss'],
  animations: animationOptions,
})
export class PasswordRecoveryComponent implements OnInit {
  recoveryForm: FormGroup;

  recoveryErrorObserver: Subscription = new Subscription();

  constructor(
    private router: Router,
    private prService: PasswordService,
    private translation: TranslationService,
    private toaster: ToasterService,
    private loader: LoaderService
  ) {}

  ngOnInit(): void {
    this.initRecoveryForm();
  }

  initRecoveryForm() {
    this.recoveryForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.pattern(EMAIL_REGEXP)]),
    });
  }

  submit() {
    if (this.recoveryForm.invalid) {
      return;
    }
    this.recoveryForm.disable();
    const email = this.recoveryForm.value.email;
    this.loader.showLoader();
    this.prService.resetPassword(email).subscribe({
      next: () => {
        this.back();
      },
      complete: () => {
        this.recoveryForm.enable();
        this.recoveryForm.reset();
        this.loader.hideLoader();
        this.toaster.showSuccess(this.translation.translate('SUCCESS:RESET_PASSWORD_INSTRUCTION'));
      },
      error: () => {
        this.recoveryForm.enable();
        this.loader.hideLoader();
      },
    });
  }

  back() {
    this.router.navigate(['users', 'sign_in']);
  }
}
