<ng-container *ngIf="claim">
  <div class="container-fluid bg-white p-3" [@open]="'opened'">
    <div class="row">
      <div class="col-6">
        <h1>{{ 'CLAIMS' | translate }}</h1>
      </div>
      <ng-container *ngIf="claim?.status === 'new' && isAllowedToCancel(claim)">
        <div class="col-sm-6 col-12 text-align-right add-action">
          <button mat-button type="button" (click)="cancelClaim(claim?.id)">
            <mat-icon>remove_circle_outline</mat-icon>
            {{ 'CANCEL_CLAIM' | translate }}
          </button>
        </div>
      </ng-container>
    </div>
    <div class="claim-title mb-3">
      <div class="left-side">
        <h2 class="text-white">{{ claim.type | translate }}</h2>
        <div class="edit-action text-align-left">
          <button mat-button type="button" (click)="openClaimDetails(claim)">
            {{ 'CLAIM_DETAILS' | translate }}
            <mat-icon>arrow_right_alt</mat-icon>
          </button>
        </div>
      </div>
      <div class="right-side">
        <div>
          <p class="text-white">{{ claim?.number }}</p>
        </div>
        <div>
          <p class="text-white">{{ claim.created_at + 'Z' | date: 'dd.MM.yyyy HH:mm' }}</p>
        </div>
        <div class="status">
          <mat-icon class="status">{{ claim.status | claimStatusIcon }}</mat-icon>
          <span class="text-white">{{ claim.status | translate }}</span>
        </div>
      </div>
    </div>
    <ai-ui-documents [attachments]="attachments" [claim]="claim" [isClaimInfo]="true" [abilityToSignConsent]="true"></ai-ui-documents>
    <!-- <ai-ui-documents [attachments]="claimReasonsDocuments"></ai-ui-documents> -->
  </div>
</ng-container>