import { HttpParams } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { MatSelectChange } from '@angular/material/select';
import { Router } from '@angular/router';
import { TranslationService } from '@localization';
import { Attachment } from 'src/app/interfaces/api/attachment.interface';
import { DepositaryAccountResponse } from 'src/app/interfaces/api/depositary-account.interface';
import { IssuerResponse } from 'src/app/interfaces/api/issuer.interface';
import { SecurityResponse } from 'src/app/interfaces/api/security.interface';
import { AuthService } from 'src/app/services/auth/auth.service';
import { BankService } from 'src/app/services/bank/bank.service';
import { ClaimFlowService } from 'src/app/services/claim-flow/claim-flow.service';
import { ClaimsService } from 'src/app/services/claims/claims.service';
import { DepositaryAccountService } from 'src/app/services/depositary-account/depositary-account.service';
import { DocumentService } from 'src/app/services/document/document.service';
import { ExchangeService } from 'src/app/services/exchange/exchange.service';
import { IssuerService } from 'src/app/services/issuer/issuer.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { SecurityService } from 'src/app/services/security/security.service';
import { AiUiSecuritySearchComponent } from 'src/app/shared/shared-components/ai-ui-security-search/ai-ui-security-search.component';
import { ToasterService } from 'src/app/shared/shared-services/toaster/toaster.service';
import {
  Currency,
  OperationInitiator,
  ParticipantType,
  PlaceOfStorage,
  SecurityCategory,
  SecurityType,
  TransactionReason,
  TransactionType,
} from 'src/app/utils/utils';

@Component({
  selector: 'app-claim-base',
  templateUrl: './claim-base.component.html',
  styleUrls: ['./claim-base.component.scss'],
})
export class ClaimBaseComponent implements OnDestroy {
  today = new Date();

  claimBatchId: number;

  claimId: number;

  attachmentsToSign: Attachment[] = [];

  initiators = Array.from(new Set(Object.keys(OperationInitiator)));

  transactionTypes = Array.from(new Set(Object.keys(TransactionType)));

  placeOfStorages = Array.from(new Set(Object.keys(PlaceOfStorage)));

  securityTypes = Array.from(new Set(Object.keys(SecurityType)));

  securityCategories = Array.from(new Set(Object.keys(SecurityCategory)));

  currencies = Array.from(new Set(Object.keys(Currency)));

  form: FormGroup;

  selectedAgreement: string;

  issuers: IssuerResponse[] = [];

  issuerId: number;

  securityId: number;

  securities: SecurityResponse[] = [];

  depositaryAccounts: DepositaryAccountResponse[] = [];

  securityName = new FormControl({ value: null, disabled: true });

  constructor(
    public auth: AuthService,
    public bankService: BankService,
    public exchangeService: ExchangeService,
    public profileService: ProfileService,
    public cfService: ClaimFlowService,
    public claimsService: ClaimsService,
    public documentService: DocumentService,
    public issuerService: IssuerService,
    public depositaryAccountService: DepositaryAccountService,
    public securityService: SecurityService,
    public loader: LoaderService,
    public fb: FormBuilder,
    public cd: ChangeDetectorRef,
    public toaster: ToasterService,
    public translation: TranslationService,
    public dialog: MatDialog,
    public router: Router
  ) {}

  initSecurity() {
    return new FormGroup({
      issuer: new FormControl(null, Validators.required),
      issuerName: new FormControl({ value: null, disabled: true }),
      security: new FormControl({ value: null, disabled: true }, Validators.required),
      instr_type: new FormControl(null, Validators.required),
      instr_category: new FormControl(null),
      state_reg_number: new FormControl({ value: null, disabled: true }),
      issue_number: new FormControl({ value: null, disabled: true }),
      isin: new FormControl(null, Validators.required),
      code_nsd: new FormControl(null),
      quantity: new FormControl(null, Validators.required),
      nominal_price: new FormControl(null, Validators.required),
      nominal_price_currency: new FormControl({ value: null, disabled: true }),
      deal_amount: new FormControl({ value: null, disabled: true }),
      price_currency: new FormControl(null, Validators.required),
    });
  }

  initReason() {
    return new FormGroup({
      type: new FormControl(null, Validators.required),
      contract_number: new FormControl(null, Validators.required),
      contract_date: new FormControl(null, Validators.required),
      contract_name: new FormControl(null),
      deal_amount: new FormControl(null),
      document: new FormControl(null),
      documentObject: new FormControl(null),
    });
  }

  initDepositorForm() {
    return new FormGroup({
      depositor_full_name: new FormControl({ value: this.auth.fullName, disabled: true }, Validators.required),
      depositary_account: new FormControl(null, Validators.required),
      depositary_chapter: new FormControl(null),
      operation_initiator: new FormControl(
        { value: OperationInitiator.depositor, disabled: true },
        Validators.required
      ),
      place_of_storage: new FormControl(null, Validators.required),
      place_of_storage_arbitrary: new FormControl(null),
      // is_external_client: new FormControl(false, Validators.required),
    });
  }

  getDepoAccounts(allAccounts: boolean = true) {
    this.loader.showLoader();
    this.depositaryAccountService.depoAccountsByAgreement(this.auth.depositaryService$.value.id).subscribe({
      next: (data) => {
        if (!allAccounts) {
          this.depositaryAccounts = data?.filter((n) => n.type !== 'ncc' || n.chapter !== null);
        } else {
          this.depositaryAccounts = data;
        }
        this.loader.hideLoader();
      },
      error: () => {
        this.loader.hideLoader();
      },
    });
  }

  onChangeTransactionType(event: MatRadioChange) {
    this.form.patchValue({
      participant_type: event.value === TransactionType.refill ? ParticipantType.sender : ParticipantType.recipient,
    });
  }

  onChangePlaceOfStorage(event: MatSelectChange, form: any) {
    if (event.value === PlaceOfStorage.other) {
      form.get('place_of_storage_arbitrary').setValidators(Validators.required);
    } else {
      form.patchValue({
        place_of_storage_arbitrary: null,
      });
      form.get('place_of_storage_arbitrary').clearValidators();
    }
    form.get('place_of_storage_arbitrary').updateValueAndValidity({ emitEvent: false });
    this.cd.markForCheck();
  }

  onAddReason() {
    (<FormArray>this.form.get('reasons')).push(this.initReason());
  }

  onRemoveReason(index: number) {
    (<FormArray>this.form.get('reasons')).removeAt(index);
  }

  onReasonTypeChanged(event: MatSelectChange, form: FormGroup) {
    form.patchValue({
      contract_name: null,
      contract_number: null,
      contract_date: null,
      deal_amount: null,
    });
    if (event.value === TransactionReason.other_agreement) {
      form.get('contract_name').setValidators(Validators.required);
    } else {
      form.get('contract_name').clearValidators();
    }
    if ([TransactionReason.agreement_of_sale, TransactionReason.repo_agreement].includes(event.value)) {
      form.get('deal_amount').setValidators(Validators.required);
    } else {
      form.get('deal_amount').clearValidators();
    }
    form.get('contract_name').updateValueAndValidity({ emitEvent: false });
    form.get('deal_amount').updateValueAndValidity({ emitEvent: false });
    this.cd.markForCheck();
  }

  onFullNameInputted(event: any, formGroup: FormGroup) {
    const value = event.target.value;
    if (value.length > 2) {
      this.loader.showLoader();
      this.issuerService.issuerByFullName(value).subscribe({
        next: (data) => {
          this.issuers = data;
          this.loader.hideLoader();
        },
        error: () => {
          this.issuers = [];
          this.loader.hideLoader();
        },
      });
    } else {
      this.resetData(formGroup);
    }
  }

  onFullNameSelected(event: MatSelectChange, formGroup: FormGroup, reset: boolean = false, exchangeId: number = 0) {
    const issuer_id = event.value;
    this.loader.showLoader();
    const httpParams = new HttpParams({
      fromObject: {
        issuer: issuer_id,
      },
    });
    if (reset) {
      formGroup.patchValue({
        isin: null,
        code_nsd: null,
        security: null,
        instr_type: null,
        instr_category: null,
        state_reg_number: null,
      });
      this.securityName.setValue(null);
    }
    this.securityService.findByParams(httpParams).subscribe({
      next: (data) => {
        this.securities = data;
        this.securityTypes = Array.from(new Set(this.securities.map((s) => s.instr_type)));
        this.securityCategories = Array.from(
          new Set(this.securities.filter((s) => s.instr_category).map((s) => s.instr_category))
        );
        this.loader.hideLoader();
      },
      error: () => {
        this.securities = [];
        this.loader.hideLoader();
      },
    });
  }

  searchSecurity(claim_security: FormGroup, enable: boolean = true, exchangeId: number = 0) {
    this.dialog
      .open(AiUiSecuritySearchComponent, {
        data: {
          securities: this.securities,
          claimSecurityForm: claim_security,
          exchangeId: exchangeId,
        },
        width: '1000px',
        panelClass: 'popup-container',
      })
      .afterClosed()
      .subscribe((data: any) => {
        if (data?.security && data?.claimSecurityForm) {
          this.onIsinCodeSelected(data.security, data.claimSecurityForm, enable);
        }
      });
  }

  onIsinCodeSelected(security: SecurityResponse, formGroup: FormGroup, enable: boolean = true) {
    this.securityTypes = [security.instr_type];
    this.securityCategories = security.instr_category ? [security.instr_category] : [];
    this.issuers = [security.issuer];
    this.issuerId = security.issuer.id;
    formGroup.patchValue({
      isin: security.isin,
      code_nsd: security.code_nsd,
      issuer: security.issuer.id,
      issuerName: security.issuer.full_name,
      security: security.id,
      nominal_price: security.nominal_price,
      price_currency: security.nominal_price_currency,
      nominal_price_currency: security.nominal_price_currency,
      instr_type: security.instr_type,
      instr_category: security.instr_category,
      state_reg_number: security.state_reg_number,
      issue_number: security.issue_number,
      quantity: null,
    });
    this.securityName.setValue(security.full_name);
    if (enable) {
      if (security.nominal_price) {
        formGroup?.get('nominal_price')?.disable();
      } else {
        formGroup?.get('nominal_price')?.enable();
      }
    }
    formGroup.updateValueAndValidity({ emitEvent: false });
    this.cd.markForCheck();
  }

  resetData(formGroup: FormGroup, enable: boolean = true) {
    this.issuers = [];
    this.securities = [];
    this.securityTypes = Array.from(new Set(Object.keys(SecurityType)));
    this.securityCategories = Array.from(new Set(Object.keys(SecurityCategory)));
    formGroup.patchValue({
      isin: null,
      code_nsd: null,
      issuer: null,
      issuerName: null,
      security: null,
      instr_type: null,
      instr_category: null,
      state_reg_number: null,
      issue_number: null,
      quantity: null,
      nominal_price: null,
      nominal_price_currency: null,
      price_currency: null,
      calculation_currency: null,
    });
    this.securityName.setValue(null);
    if (enable) {
      formGroup?.get('nominal_price')?.enable();
    }
    this.form.markAsUntouched();
  }

  back() {
    this.cfService.goToStep(this.cfService.stepKey - 1);
  }

  sendToSign = (claimId: number = 0) => this.claimsService.sendToSign(this.claimBatchId, false, claimId);

  ngOnDestroy(): void {
    if (this.form) {
      this.form.reset();
    }
    this.cfService.goToStep(0);
  }
}
