<div class="container-fluid"
  [@open]="'opened'">
  <ng-container *ngIf="auth.userProfile$ | async as user">
    <div class="row">
      <div class="col-12">
        <ng-container *ngIf="user?.status==='pii_not_filled';else bind_aigenis_account">
          <ai-ui-restriction [showFaq]="false"></ai-ui-restriction>
        </ng-container>
        <ng-template #bind_aigenis_account>
          <ng-container *ngIf="user?.status === 'pii_validating' && user?.citizenship==='BY' && !user?.user_aigenis_id; else pii_validating">
            <ai-ui-restriction [type]="'waitingForAigenisAccountBinding'"
              [showFaq]="false"></ai-ui-restriction>
          </ng-container>
        </ng-template>
        <ng-template #pii_validating>
          <ng-container *ngIf="user?.status === 'pii_validating'; else create_brokerage_service">
            <ai-ui-restriction [type]="'waitingForApproval'"
              [showFaq]="false"></ai-ui-restriction>
          </ng-container>
        </ng-template>
        <ng-template #create_brokerage_service>
          <ng-container
            *ngIf="brokerage===undefined || brokerage?.status==='finished' || brokerage?.status==='suspended' || brokerage?.status==='incorrect';else waiting_brokerage_service">
            <ai-ui-restriction [type]="'createBrokerageService'"
              [showFaq]="false"></ai-ui-restriction>
          </ng-container>
        </ng-template>
        <ng-template #waiting_brokerage_service>
          <ng-container *ngIf="brokerage?.created_by_claim?.status==='new';else agreement_new">
            <ai-ui-restriction [type]="'brokerageServiceApprovalNew'"
              [showFaq]="false"></ai-ui-restriction>
          </ng-container>
        </ng-template>
        <ng-template #agreement_new>
          <ng-container *ngIf="brokerage?.status==='new';else brokerage_service_in_processing">
            <ai-ui-restriction [type]="'brokerageAgreementNew'"
              [showFaq]="false"></ai-ui-restriction>
          </ng-container>
        </ng-template>
        <ng-template #brokerage_service_in_processing>
          <ng-container
            *ngIf="user?.docs_signed===false || brokerage?.created_by_claim?.status==='in_progress'; else default">
            <ai-ui-restriction [type]="'waitingForBrokerageServiceApproval'"
              [showFaq]="false"></ai-ui-restriction>
          </ng-container>
        </ng-template>
      </div>
      <ng-template #default>
        <div class="col-12">
          <div class="portfolio-card">
            <div class="portfolio-amount">
              <div class="left-side col-4">
                <span>{{ 'PORTFOLIO:ALL_LABEL' | translate }}</span>
                <div class="hint">
                  <p>По курсу ЦБ РФ</p>
                </div>
              </div>
              <mat-divider [vertical]="true"></mat-divider>
              <div class="right-side col-6">
                <span>{{ portfolioMoney | number: '1.2-2' }} </span>
                <span>₽</span>
              </div>
            </div>
            <div class="details">
              <div class="proportion">
                <div class="proportion-text">
                  <ng-container *ngIf="portfolios;else noData">
                    <span class="percent">100%</span>
                    <div class="hint">
                      <p>{{ 'PORTFOLIO:PROPORTION_LABEL' | translate }}</p>
                    </div>
                  </ng-container>
                  <ng-template #noData>
                    <div class="hint">
                      <p>{{ 'PORTFOLIO:NO_DATA_TO_SHOW' | translate }}</p>
                    </div>
                  </ng-template>
                </div>
                <div class="propotion-logo">
                  <svg xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="250"
                    height="250"
                    version="1.1"
                    filter="url(#blur-filter)">
                    <g transform="translate(125, 125)">
                      <circle stroke="#FFFFFF"
                        stroke-width="17"
                        fill="transparent"
                        r="116"></circle>
                      <g class="slices">
                        <path fill="#B3B9C0"
                          d="M7.34788079488412e-15,-120A120,120,0,1,1,-7.34788079488412e-15,120A120,120,0,1,1,7.34788079488412e-15,-120M-2.0574066225675533e-14,-112A112,112,0,1,0,2.0574066225675533e-14,112A112,112,0,1,0,-2.0574066225675533e-14,-112Z"
                          stroke="#FFFFFF"
                          transform="rotate(0)"
                          stroke-width="5px"
                          style="opacity: 1;"
                          class="active">
                        </path>
                      </g>
                    </g>
                    <defs>
                      <filter id="blur-filter">
                        <feDropShadow stdDeviation="15"
                          flood-color="rgba(166, 171, 189, 0.4)"
                          dx="0"
                          dy="0">
                        </feDropShadow>
                      </filter>
                    </defs>
                  </svg>
                </div>
              </div>
              <div class="info">
                <div class="col-12 field contract-number">
                  <span>{{ 'BROKING:CONTRACT_INFO' | translate : [brokerage?.number] }}</span>
                </div>
                <div class="col-12 field amount">
                  <span>{{ portfolioMoney | number: '1.2-2' }} </span>
                  <span>₽</span>
                </div>
                <mat-divider></mat-divider>
                <div class="hint"
                  *ngIf="brokerage?.created_at">
                  <p>{{ 'PORTFOLIO:INFO_ACTUAL_ON' | translate: [twoDaysAgo |
                    date: 'dd.MM.yyyy'] }}</p>
                </div>
                <div class="replenish">
                  <button mat-button
                    class="filled"
                    type="button"
                    (click)="refillAccount()">
                    {{ 'PORTFOLIO:REPLENISH' | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="hint mt-3">
          <p>{{ 'BROKER:HINT' | translate }}</p>
        </div>
      </ng-template>
    </div>
  </ng-container>
</div>