import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ClaimsBatchResponse, CurrencyInstrumentsRequest } from 'src/app/interfaces/api/claims.interface';
import { ExchangeResponse } from 'src/app/interfaces/api/exchange.interface';
import { AiUiRiskInformationComponent } from 'src/app/shared/shared-components/ai-ui-risk-information/ai-ui-risk-information.component';
import { animationOptions } from 'src/app/utils/animation';
import { AreaType, BsType, ClaimExchangeRateType, ClaimTradeType, ProfileStatus } from 'src/app/utils/utils';
import { ClaimBaseComponent } from '../claim-base/claim-base.component';
import { mergeMap } from 'rxjs';

@Component({
  selector: 'app-bo-currency-instruments-new',
  templateUrl: './bo-currency-instruments-new.component.html',
  styleUrls: ['./bo-currency-instruments-new.component.scss'],
  animations: animationOptions,
})
export class BoCurrencyInstrumentsNewComponent extends ClaimBaseComponent implements OnInit, OnDestroy {
  rateTypes = Array.from(new Set(Object.keys(ClaimExchangeRateType)));

  tradeTypes = Array.from(new Set(Object.keys(ClaimTradeType)));

  theFirstConfirmation: FormControl = new FormControl(true, [Validators.required]);

  exchanges: ExchangeResponse[] = [];

  ngOnInit(): void {
    if (this.auth.userProfile$.value?.status === ProfileStatus.pii_not_filled) {
      return;
    }
    this.form = this.initForm();
    this.getExchanges();
  }

  initForm() {
    return new FormGroup({
      trade_type: new FormControl(null, [Validators.required]),
      outcome_currency: new FormControl(null, [Validators.required]),
      market: new FormControl(null, Validators.required),
      exchange_rate_type: new FormControl(null, [Validators.required]),
      exchange_rate: new FormControl(null),
      income_amount: new FormControl(null, [Validators.required]),
      income_currency: new FormControl(null, [Validators.required]),
      additional_terms: new FormControl(null),
    });
  }

  getExchanges() {
    this.exchanges = this.exchangeService.exchanges$?.value.filter((e) => e.code === AreaType.mcur);
  }

  next() {
    if (this.form.invalid) {
      return;
    }
    if (this.cfService.stepKey === 0) {
      const payload = this.form.value as CurrencyInstrumentsRequest;
      payload.type = BsType.currency_instruments_trade;
      this.loader.showLoader();
      this.claimsService
        .addClaimsBatch([payload])
        .pipe(
          mergeMap((res: ClaimsBatchResponse) => {
            this.claimBatchId = res.id;
            return this.documentService.claimsBatchDocumentsProcessing(res.id);
          })
        )
        .subscribe({
          next: (data) => {
            this.attachmentsToSign = data;
            this.cfService.goToStep(this.cfService.stepKey + 1);
            this.loader.hideLoader();
            this.cd.markForCheck();
          },
          error: () => {
            this.loader.hideLoader();
          },
        });
    }
  }

  clearForm() {
    this.form.reset();
    this.cfService.goToStep(0);
  }

  openRiskInformation() {
    this.dialog.open(AiUiRiskInformationComponent, {
      panelClass: 'risk-info-container',
    });
  }

  onRateTypeChange(event: any) {
    if (event.value === ClaimExchangeRateType.market_price) {
      this.form.patchValue({
        exchange_rate: '',
      });
      this.form.get('exchange_rate').setValue('');
      this.form.get('exchange_rate').clearValidators();
    } else {
      this.form.get('exchange_rate').setValidators(Validators.required);
    }
    this.form.get('exchange_rate').updateValueAndValidity({ emitEvent: false });
    this.cd.markForCheck();
  }
}
