import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { MatSelectChange } from '@angular/material/select';
import { TranslationService } from '@localization';
import { forkJoin, mergeMap } from 'rxjs';
import { Attachment } from 'src/app/interfaces/api/attachment.interface';
import { BankAccountResponse } from 'src/app/interfaces/api/bank.interface';
import { ClaimsBatchRequest, ClaimsBatchResponse } from 'src/app/interfaces/api/claims.interface';
import { AuthService } from 'src/app/services/auth/auth.service';
import { BankService } from 'src/app/services/bank/bank.service';
import { BrokerageService } from 'src/app/services/brokerage-service/brokerage-service.service';
import { ClaimsService } from 'src/app/services/claims/claims.service';
import { DocumentService } from 'src/app/services/document/document.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { RegulationsService } from 'src/app/services/regulations/regulations.service';
import { AiUiAddBankAccountComponent } from 'src/app/shared/shared-components/ai-ui-add-bank-account/ai-ui-add-bank-account.component';
import { AiUiEditBankAccountComponent } from 'src/app/shared/shared-components/ai-ui-edit-bank-account/ai-ui-edit-bank-account.component';
import { AiUiInfoDialogComponent } from 'src/app/shared/shared-components/ai-ui-info-dialog/ai-ui-info-dialog.component';
import { AiUiPersonalBrokerInfoDialogComponent } from 'src/app/shared/shared-components/ai-ui-personal-broker-info-dialog/ai-ui-personal-broker-info-dialog.component';
import { AiUiRiskInformationComponent } from 'src/app/shared/shared-components/ai-ui-risk-information/ai-ui-risk-information.component';
import { animationOptions } from 'src/app/utils/animation';
import {
  AgreementTariff,
  BsType,
  BusinessRelationshipCharacter,
  BusinessRelationshipPurpose,
  DepoType,
  FinancialAndEconomicTransactionPurpose,
  FinancialPosition,
  OriginFundsSource,
  ProfileStatus,
} from 'src/app/utils/utils';

@Component({
  selector: 'app-bo-origin-new',
  templateUrl: './bo-origin-new.component.html',
  styleUrls: ['./bo-origin-new.component.scss'],
  animations: animationOptions,
})
export class BoOriginNewComponent implements OnInit, OnDestroy {
  claimBatchId: number = 0;

  claimId: number = 0;

  bankAccounts: BankAccountResponse[] = [];

  attachmentsToSign: Attachment[] = [];

  tariff: FormControl = new FormControl(null, [Validators.required]);

  bank_account: FormControl = new FormControl(null, [Validators.required]);

  broker_can_use_client_money: FormControl = new FormControl(true, [Validators.required]);

  income_earning_option: FormControl = new FormControl('brokerage_account', [Validators.required]);

  trade_on_spb_stock: FormControl = new FormControl(false, [Validators.required]);

  refusal_of_transactions_with_incomplete_coverage: FormControl = new FormControl(true, [Validators.required]);

  formDepoContract: FormControl = new FormControl({ value: true, disabled: true }, [Validators.required]);

  theMainConfirmation: FormControl = new FormControl(false, [Validators.required]);

  theFirstConfirmation: FormControl = new FormControl(false, [Validators.required]);

  theSecondConfirmation: FormControl = new FormControl(false, [Validators.required]);

  theThirdConfirmation: FormControl = new FormControl(false, [Validators.required]);

  theFourthConfirmation: FormControl = new FormControl(false, [Validators.required]);

  additionalForm: FormGroup;

  riskStatementLink: string;

  riskNoticeLink: string;

  mainInfoLink: string;

  tariffs = Array.from(new Set(Object.keys(AgreementTariff)));

  relationshipPurposes = Array.from(new Set(Object.keys(BusinessRelationshipPurpose)));

  relationshipCharacters = Array.from(new Set(Object.keys(BusinessRelationshipCharacter)));

  economicTransactions = Array.from(new Set(Object.keys(FinancialAndEconomicTransactionPurpose)));

  financialPositions = Array.from(new Set(Object.keys(FinancialPosition)));

  sources = Array.from(new Set(Object.keys(OriginFundsSource)));

  constructor(
    public auth: AuthService,
    public bs: BrokerageService,
    private bankService: BankService,
    private documentService: DocumentService,
    private claimService: ClaimsService,
    private cd: ChangeDetectorRef,
    private dialog: MatDialog,
    private loader: LoaderService,
    private fb: FormBuilder,
    private translation: TranslationService
  ) {}

  ngOnInit(): void {
    if (this.auth.userProfile$.value?.status === ProfileStatus.pii_not_filled) {
      return;
    }
    this.additionalForm = this.initAdditionallyForm();
    this.getBankAccounts();
    this.getRegulations();
  }

  initAdditionallyForm() {
    return this.fb.group({
      tax_benefits_presence: new FormControl(null, [Validators.required]),
      business_relationship_purposes_list: new FormControl(
        [BusinessRelationshipPurpose.brokerage_service, BusinessRelationshipPurpose.depository_service],
        [Validators.required]
      ),
      business_relationship_purpose_arbitrary: new FormControl(null),
      business_relationship_character: new FormControl(BusinessRelationshipCharacter.long, [Validators.required]),
      financial_and_economic_transaction_purposes_list: new FormControl(null, [Validators.required]),
      financial_and_economic_transaction_purpose_arbitrary: new FormControl(null),
      financial_position: new FormControl(FinancialPosition.stable, [Validators.required]),
      info_about_business_reputation: new FormControl(null, [Validators.required]),
      origin_funds_sources_list: new FormControl(null, [Validators.required]),
      origin_funds_sources_arbitrary: new FormControl(null),
    });
  }

  getRegulations() {
    this.loader.showLoader();
    forkJoin({
      riskStatementLink: this.claimService.fileToBlob(RegulationsService.RISK_STATEMENT_PREVIEW),
      riskNoticeLink: this.claimService.fileToBlob(RegulationsService.RISK_NOTICE_PREVIEW),
      mainInfoLink: this.claimService.fileToBlob(RegulationsService.MAIN_INFO_PREVIEW),
    }).subscribe({
      next: (data) => {
        this.riskStatementLink = data.riskStatementLink;
        this.riskNoticeLink = data.riskNoticeLink;
        this.mainInfoLink = data.mainInfoLink;
        this.loader.hideLoader();
      },
      error: () => {
        this.loader.hideLoader();
      },
    });
  }

  getBankAccounts() {
    this.loader.showLoader();
    this.bankService.bankAccounts().subscribe({
      next: (res) => {
        if (res.length) {
          this.bankAccounts = res;
          if (this.bank_account.value === null) {
            this.bank_account.setValue(this.bankAccounts[0].id);
          }
          this.cd.detectChanges();
          this.cd.markForCheck();
        }
        this.loader.hideLoader();
      },
      error: () => {
        this.loader.hideLoader();
      },
    });
  }

  onAddBankAccount() {
    this.dialog
      .open(AiUiAddBankAccountComponent, {
        panelClass: 'popup-container',
        width: '800px',
      })
      .afterClosed()
      .subscribe((res: boolean) => {
        if (res) {
          this.getBankAccounts();
        }
      });
  }

  onEditBankAccount(id: number) {
    this.dialog
      .open(AiUiEditBankAccountComponent, {
        panelClass: 'popup-container',
        width: '800px',
        data: {
          bankAccountId: id,
        },
      })
      .afterClosed()
      .subscribe((res: boolean) => {
        if (res) {
          this.getBankAccounts();
        }
      });
  }

  setBankAccountId(bankAccountId: number) {
    this.bank_account.setValue(bankAccountId);
    this.next();
  }

  onChangeBrokerCanUseClientMoney(event: MatRadioChange) {
    if (event.value === false) {
      this.dialog.open(AiUiInfoDialogComponent, {
        width: '800px',
        disableClose: true,
        data: {
          content: this.translation.translate('BS:CONDITION_OF_ACCEPTATE_DISAGREE_INFORMATION'),
          links: [{ name: 'Ссылка на тарифы', link: 'https://www.aigenis.ru/info5' }],
        },
      });
    }
  }

  onChangeIncomeEarningOption(event: MatRadioChange) {
    if (event.value === 'bank_account') {
      this.dialog.open(AiUiInfoDialogComponent, {
        width: '800px',
        disableClose: true,
        data: {
          content: this.translation.translate('BS:INCOME_EARNING_OPTION_BANK_ACCOUNT_INFORMATION'),
        },
      });
    }
  }

  onChangeRelationshipPurpose(event: MatSelectChange) {
    this.additionalForm.controls.business_relationship_purpose_arbitrary.setValue(null);
    const values = event.value as string[];
    if (!values.includes(BusinessRelationshipPurpose.other)) {
      this.additionalForm.controls.business_relationship_purpose_arbitrary.clearValidators();
      this.additionalForm.controls.business_relationship_purpose_arbitrary.updateValueAndValidity({ emitEvent: false });
    } else {
      this.additionalForm.controls.business_relationship_purpose_arbitrary.setValidators(Validators.required);
      this.additionalForm.controls.business_relationship_purpose_arbitrary.updateValueAndValidity({ emitEvent: false });
    }
  }

  onEconomicTransactionChange(event: MatSelectChange) {
    this.additionalForm.controls.financial_and_economic_transaction_purpose_arbitrary.setValue(null);
    const values = event.value as string[];
    if (!values.includes(FinancialAndEconomicTransactionPurpose.other)) {
      this.additionalForm.controls.financial_and_economic_transaction_purpose_arbitrary.clearValidators();
      this.additionalForm.controls.financial_and_economic_transaction_purpose_arbitrary.updateValueAndValidity({
        emitEvent: false,
      });
    } else {
      this.additionalForm.controls.financial_and_economic_transaction_purpose_arbitrary.setValidators(
        Validators.required
      );
      this.additionalForm.controls.financial_and_economic_transaction_purpose_arbitrary.updateValueAndValidity({
        emitEvent: false,
      });
    }
  }

  onOriginFundsSourceChange(event: MatSelectChange) {
    this.additionalForm.controls.origin_funds_sources_arbitrary.setValue(null);
    const values = event.value as string[];
    if (!values.includes(OriginFundsSource.other)) {
      this.additionalForm.controls.origin_funds_sources_arbitrary.clearValidators();
      this.additionalForm.controls.origin_funds_sources_arbitrary.updateValueAndValidity({ emitEvent: false });
    } else {
      this.additionalForm.controls.origin_funds_sources_arbitrary.setValidators(Validators.required);
      this.additionalForm.controls.origin_funds_sources_arbitrary.updateValueAndValidity({ emitEvent: false });
    }
  }

  chooseTariff(tariff: string) {
    if (tariff === AgreementTariff.personal_broker) {
      this.dialog
        .open(AiUiPersonalBrokerInfoDialogComponent, {
          width: '800px',
          disableClose: true,
        })
        .afterClosed()
        .subscribe(() => {
          this.tariff.setValue(tariff);
          this.bs.goToStep(this.bs.brokerageServiceStepKey + 1);
          this.cd.markForCheck();
        });
    } else {
      this.tariff.setValue(tariff);
      this.bs.goToStep(this.bs.brokerageServiceStepKey + 1);
      this.cd.markForCheck();
    }
  }

  next() {
    if (this.bs.brokerageServiceStepKey === 3) {
      if (this.additionalForm.invalid) {
        return;
      }
      const additionalFormPayload = this.additionalForm.value;
      let claims: ClaimsBatchRequest[] = [];
      claims.push({
        type: BsType.broker_agreement,
        tariff: this.tariff.value,
        bank_account: this.bank_account.value,
        broker_can_use_client_money: this.broker_can_use_client_money.value,
        refusal_of_transactions_with_incomplete_coverage: this.refusal_of_transactions_with_incomplete_coverage.value,
        trade_on_spb_stock: this.trade_on_spb_stock.value,
        tax_benefits_presence: additionalFormPayload.tax_benefits_presence,
        business_relationship_purposes_list: additionalFormPayload.business_relationship_purposes_list,
        business_relationship_purpose_arbitrary: additionalFormPayload.business_relationship_purpose_arbitrary,
        business_relationship_character: additionalFormPayload.business_relationship_character,
        financial_and_economic_transaction_purposes_list:
          additionalFormPayload.financial_and_economic_transaction_purposes_list,
        financial_and_economic_transaction_purpose_arbitrary:
          additionalFormPayload.financial_and_economic_transaction_purpose_arbitrary,
        financial_position: additionalFormPayload.financial_position,
        info_about_business_reputation: additionalFormPayload.info_about_business_reputation,
        origin_funds_sources_list: additionalFormPayload.origin_funds_sources_list,
        origin_funds_sources_arbitrary: additionalFormPayload.origin_funds_sources_arbitrary,
        income_earning_option: this.income_earning_option.value,
      });
      if (this.formDepoContract.value) {
        claims.push({
          type: DepoType.depositary_agreement,
          bank_account: this.bank_account.value,
          broker_can_use_client_money: this.broker_can_use_client_money.value,
          income_earning_option: this.income_earning_option.value,
        });
      }
      this.loader.showLoader();
      this.claimService
        .addClaimsBatch(claims)
        .pipe(
          mergeMap((res: ClaimsBatchResponse) => {
            this.claimBatchId = res.id;
            if (this.trade_on_spb_stock.value) {
              this.claimId = res.claims.find((c) => c.type === BsType.broker_agreement)?.id ?? 0;
            }
            return this.documentService.claimsBatchDocumentsProcessing(res.id);
          })
        )
        .subscribe({
          next: (data) => {
            this.attachmentsToSign = data;
            this.bs.goToStep(this.bs.brokerageServiceStepKey + 1);
            this.loader.hideLoader();
            this.cd.detectChanges();
            this.cd.markForCheck();
          },
          error: () => {
            this.loader.hideLoader();
          },
        });
    } else {
      this.bs.goToStep(this.bs.brokerageServiceStepKey + 1);
    }
  }

  back() {
    this.bs.goToStep(this.bs.brokerageServiceStepKey - 1);
  }

  sendToSign = () => this.claimService.sendToSign(this.claimBatchId, false, this.claimId);

  clearForm() {
    this.formDepoContract.reset();
    this.bank_account.reset();
    this.theMainConfirmation.reset();
    this.theFirstConfirmation.reset();
    this.theSecondConfirmation.reset();
    this.theThirdConfirmation.reset();
    this.theFourthConfirmation.reset();
    this.bs.goToStep(0);
  }

  openRiskInformation() {
    this.dialog.open(AiUiRiskInformationComponent, {
      panelClass: 'risk-info-container',
    });
  }

  ngOnDestroy(): void {
    this.clearForm();
  }
}
