import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, filter, map, mergeMap, pipe } from 'rxjs';
import { ClaimResponse } from 'src/app/interfaces/api/claims.interface';
import { ClaimsService } from 'src/app/services/claims/claims.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { animationOptions } from 'src/app/utils/animation';
import { BsType, ClaimCategory, ClaimStatus, DepoType, OtherType } from 'src/app/utils/utils';

export interface Parameters {
  arbitraryValue: string;
  category: ClaimCategory;
  type: string;
  status: ClaimStatus;
  hideInactive: boolean;
}

@Component({
  selector: 'app-claims',
  templateUrl: './claims.component.html',
  styleUrls: ['./claims.component.scss'],
  animations: animationOptions,
})
export class ClaimsComponent implements OnInit {
  otherTypes = Array.from(new Set(Object.keys(OtherType)));

  bsTypes = Array.from(new Set(Object.keys(BsType)));

  depoTypes = Array.from(new Set(Object.keys(DepoType)));

  categories = [null, ...Array.from(new Set(Object.keys(ClaimCategory)))];

  statuses = [null, ...Array.from(new Set(Object.keys(ClaimStatus)))];

  displayedColumns: string[] = ['DATE', 'ORDER_TYPE', 'FOND_OR_CONTRACT', 'ORDER_NUMBER', 'STATUS'];

  claims: ClaimResponse[] = [];

  filteredClaims: ClaimResponse[] = [];

  filterForm: FormGroup;

  constructor(
    private claimsService: ClaimsService,
    private cd: ChangeDetectorRef,
    private router: Router,
    private loader: LoaderService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.loader.showLoader();
    this.activatedRoute.queryParams
      .pipe(
        mergeMap((params) => {
          return this.claimsService.claims(params.type);
        })
      )
      .subscribe({
        next: (data) => {
          this.claims = this.filteredClaims = data;
          this.cd.markForCheck();
          this.loader.hideLoader();
        },
        error: () => {
          this.loader.hideLoader();
        },
      });

    this.filterForm = this.initFilterForm();
    this.filterFormListener();
  }

  initFilterForm() {
    return new FormGroup({
      arbitraryValue: new FormControl(null),
      type: new FormControl(null),
      category: new FormControl(null),
      status: new FormControl(null),
      hideInactive: new FormControl(false),
    });
  }

  filterFormListener() {
    this.filterForm.valueChanges.subscribe({
      next: (params: Parameters) => {
        this.filteredClaims = this.claims.filter(
          (c) =>
            (c?.agreement?.number ? c?.agreement?.number : '').includes(
              !!params.arbitraryValue ? params.arbitraryValue : ''
            ) &&
            (!!params.type ? [params.type] : [...this.otherTypes, ...this.bsTypes, ...this.depoTypes]).includes(
              c.type
            ) &&
            (!!params.category ? [params.category] : this.categories).includes(c.category) &&
            (!!params.status ? [params.status] : this.statuses).includes(c.status) &&
            (params.hideInactive ? c.status !== ClaimStatus.rejected : true)
        );
        this.cd.markForCheck();
      },
    });
  }

  navigateTo(claimId: number) {
    this.router.navigate(['claims', claimId]);
  }
}
