import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

interface DialogData {
  content: string;
  links: [{ name: string; link: string }];
}

@Component({
  selector: 'app-ai-ui-info-dialog',
  templateUrl: './ai-ui-info-dialog.component.html',
  styleUrls: ['./ai-ui-info-dialog.component.scss'],
})
export class AiUiInfoDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}
}
