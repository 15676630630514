import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[integer]',
})
export class IntegerDirective {
  maxValue: number = 9999999999;

  private pattern: RegExp = new RegExp('^[0-9]+$');

  /**
   * allowed keys to press:
   * 8 - backspace
   * 9 - tab
   * 13 - enter
   * 27 - escape
   * 37 - left arrow
   * 39 - right arrow
   * 44 - print screen
   * 46 - delete
   */
  // allowedKeys: number[] = [8, 9, 13, 27, 37, 39, 44, 46]

  private _allowedKeys: string[] = [
    'Backspace',
    'Tab',
    'Enter',
    'Escape',
    'ArrowLeft',
    'ArrowRight',
    'Delete',
    'PrintScreen',
  ];

  element: HTMLInputElement;

  constructor(element: ElementRef) {
    this.element = element.nativeElement;
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this._allowedKeys.includes(event.key)) {
      return true;
    }
    if (
      !this.pattern.test(event.key) ||
      Number(this.element.value.toString() + event.key) > this.maxValue ||
      (!this.element.value && event.key == '0')
    ) {
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
    return true;
  }
}
