<div class="container">
  <!-- <div class="close" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </div> -->
  <form [formGroup]="form" (ngSubmit)="sendCode()">
    <h3>{{ 'CHANGING_PHONE_NUMBER' | translate }}</h3>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'FIELD:NEW_PHONE_NUMBER' | translate }}</mat-label>
      <div class="d-flex">
        <ngx-mat-intl-tel-input formControlName="phone" [onlyCountries]="['ru','by']" [enablePlaceholder]="true"
          [enableSearch]="false" #phone
          (countryChanged)="onChangePhoneCountry($event)"></ngx-mat-intl-tel-input>
      </div>
      <mat-hint>{{ 'PHONE:EXAMPLE' | translate : [phone.selectedCountry.placeHolder] }}</mat-hint>
      <mat-error *ngIf="form.get('phone').errors?.validatePhoneNumber">
        {{'ERROR:PHONE_NUMBER_INCORRECT' | translate }}
      </mat-error>
      <mat-error *ngIf="form.get('phone').errors?.required">
        {{'ERROR:REQUIRED' | translate }}
      </mat-error>
    </mat-form-field>
    <div class="d-flex justify-content-center mb-3">
      <button [disabled]="form.invalid" class="signin-btn" mat-flat-button type="submit">
        {{ 'NEXT' | translate }}
      </button>
    </div>
  </form>
</div>