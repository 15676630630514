import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslationModule } from '@localization';
import { Observable } from 'rxjs';
import { apiUrlWithPrefix } from '@env';
import { AuthService } from '../../../../services/auth/auth.service';
import { ProfileService } from '../../../../services/profile/profile.service';
import { MatButtonModule } from '@angular/material/button';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { AiUiConfirmDialogComponent } from '../../ai-ui-confirm-dialog/ai-ui-confirm-dialog.component';
import { ConfirmOperation } from 'src/app/utils/utils';

@Component({
  selector: 'app-edm-modal',
  standalone: true,
  imports: [CommonModule, MatCheckboxModule, MatTooltipModule, ReactiveFormsModule, TranslationModule, MatButtonModule],
  templateUrl: './edm-modal.component.html',
  styleUrls: ['./edm-modal.component.scss'],
})
export class EdmModalComponent implements OnInit {
  isEdmConsentRead = false;
  isPersonalDataConsentRead = false;
  personalDataProcessing = new FormControl(null, Validators.required);
  electronicDocumentProcessing = new FormControl(null, Validators.required);
  consents: {
    edm_consent: Observable<string>;
    personal_data_consent: Observable<string>;
  } = {
    edm_consent: null,
    personal_data_consent: null,
  };

  url = (id) => `${apiUrlWithPrefix}/protected-file/${id}/preview/`;

  constructor(
    public matDialogRef: MatDialogRef<EdmModalComponent>,
    private authService: AuthService,
    private profileService: ProfileService,
    private loader: LoaderService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getConsents();
  }

  getConsents() {
    this.consents.edm_consent = this.profileService.fileToBlob(
      this.url(this.authService.userProfile$.value.edm_consent)
    );
    this.consents.personal_data_consent = this.profileService.fileToBlob(
      this.url(this.authService.userProfile$.value.personal_data_consent)
    );
  }

  sendConsentsToSign() {
    this.profileService.sendCodeToSignConsents().subscribe({
      next: (data: any) => {
        this.dialog
          .open(AiUiConfirmDialogComponent, {
            data: {
              operation: ConfirmOperation.phone,
              callback: this.profileService.checkCodeToSignConsents.bind(this.profileService),
            },
          })
          .afterClosed()
          .subscribe({
            next: (res: boolean) => {
              if (res) {
                this.matDialogRef.close(true);
              }
            },
          });
        this.loader.hideLoader();
      },
      error: () => {
        this.loader.hideLoader();
      },
    });
  }
}
