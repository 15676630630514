import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiUrlWithPrefix } from '@env';
import { Observable, map } from 'rxjs';
import { BankAccountRequest, BankAccountResponse, BankResponse } from 'src/app/interfaces/api/bank.interface';

@Injectable({
  providedIn: 'root',
})
export class BankService {
  static readonly BANK = `${apiUrlWithPrefix}/bank/`;
  static readonly BANK_ACCOUNT = `${apiUrlWithPrefix}/bank-account/`;

  constructor(private http: HttpClient) {}

  findBanksByParams(params: HttpParams) {
    return this.http
      .get(BankService.BANK, {
        params: params,
      })
      .pipe(map((res: any) => res.results as BankResponse[]));
  }

  bankAccounts() {
    return this.http.get(BankService.BANK_ACCOUNT).pipe(
      map((res: any) => {
        return res.results as BankAccountResponse[];
      })
    );
  }

  addBankAccount(data: BankAccountRequest) {
    return this.http.post(BankService.BANK_ACCOUNT, data);
  }

  bankAccountById(id: number): Observable<BankAccountResponse> {
    return this.http.get<BankAccountResponse>(`${BankService.BANK_ACCOUNT}${id}/`);
  }

  editBankAccount(id: number, data: BankAccountRequest) {
    return this.http.put(`${BankService.BANK_ACCOUNT}${id}/`, data);
  }

  deleteBankAccount(id: number) {
    return this.http.delete(`${BankService.BANK_ACCOUNT}${id}/`);
  }
}
