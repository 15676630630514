import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ClaimsBatchResponse, ContractTerminationRequest } from 'src/app/interfaces/api/claims.interface';
import { animationOptions } from 'src/app/utils/animation';
import { BsType, ContractTerminationType } from 'src/app/utils/utils';
import { ClaimBaseComponent } from '../claim-base/claim-base.component';
import { Attachment } from 'src/app/interfaces/api/attachment.interface';
import { mergeMap } from 'rxjs';

@Component({
  selector: 'app-bo-termination-new',
  templateUrl: './bo-termination-new.component.html',
  styleUrls: ['./bo-termination-new.component.scss'],
  animations: animationOptions,
})
export class BoTerminationNewComponent extends ClaimBaseComponent implements OnInit, OnDestroy {
  terminationTypes = Array.from(new Set(Object.keys(ContractTerminationType)));

  theFirstConfirmation: FormControl = new FormControl(true, [Validators.required]);

  ngOnInit(): void {
    this.form = this.initForm();
  }

  initForm() {
    return new FormGroup({
      termination_type: new FormControl(null, Validators.required),
    });
  }

  next() {
    if (this.form.invalid) {
      return;
    }
    const payload = this.form.value as ContractTerminationRequest;
    payload.type = BsType.terminate_agreement;
    this.loader.showLoader();
    this.claimsService
      .addClaimsBatch([payload])
      .pipe(
        mergeMap((res: ClaimsBatchResponse) => {
          this.claimBatchId = res.id;
          return this.documentService.claimsBatchDocumentsProcessing(res.id);
        })
      )
      .subscribe({
        next: (data) => {
          this.attachmentsToSign = data;
          this.cfService.goToStep(this.cfService.stepKey + 1);
          this.loader.hideLoader();
          this.cd.markForCheck();
        },
        error: () => {
          this.loader.hideLoader();
        },
      });
  }
}
