import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class QualifiedClaimFlowService {
  static readonly _steps: [number, string][] = [
    [0, 'decor'],
    [1, 'documents_uploading'],
    [2, 'demands'],
    [3, 'signature'],
  ];

  private _stepsMap = new Map(QualifiedClaimFlowService._steps);

  private _step: number = 0;

  set step(step: number) {
    this._step = step;
  }

  get stepKey(): number {
    return this._step;
  }

  get stepValue() {
    return this._stepsMap.get(this._step);
  }

  get stepsCount() {
    return QualifiedClaimFlowService._steps.length;
  }

  public goToStep(step: number) {
    this.step = step;
  }

  constructor() {}
}
