import { Component, OnInit } from '@angular/core';
import { apiUrlWithPrefix } from '@env';
import { forkJoin } from 'rxjs';
import { Attachment } from 'src/app/interfaces/api/attachment.interface';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DocumentService } from 'src/app/services/document/document.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { animationOptions } from 'src/app/utils/animation';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
  animations: animationOptions,
})
export class DocumentsComponent implements OnInit {
  attachments: Attachment[] = [];

  passportScans: Attachment[] = [];

  claimDocumentAttachmentUrl = (id: number): string => `${apiUrlWithPrefix}/claim-document/${id}/preview/`;

  constructor(
    public authService: AuthService,
    public documentService: DocumentService,
    private loader: LoaderService
  ) {}

  ngOnInit(): void {
    this.loader.showLoader();
    this.documentService.passportScanProcessing().subscribe({
      next: (data) => {
        this.passportScans.push(...data);
        this.loader.hideLoader();
      },
      error: () => {
        this.loader.hideLoader();
      },
    });

    this.loader.showLoader();
    this.documentService.additionalConsentDocumentProcessing().subscribe({
      next: (data) => {
        this.attachments.push(data);
        this.loader.hideLoader();
      },
      error: () => {
        this.loader.hideLoader();
      },
    });
    if (this.authService.userProfile$?.value) {
      const user = this.authService.userProfile$?.value;
      this.loader.showLoader();
      this.documentService.profileDocumentsProcessing(user).subscribe({
        next: (data) => {
          this.attachments.push(...data);
          this.loader.hideLoader();
        },
        error: () => {
          this.loader.hideLoader();
        },
      });
      if (user.additional_consent_claims_batch_id) {
        this.documentService.claimsBatchDocumentsProcessing(user.additional_consent_claims_batch_id, true).subscribe({
          next: (data) => {
            data.map((item) => {
              this.attachments.push(item);
            });
            this.loader.hideLoader();
          },
          error: () => {
            this.loader.hideLoader();
          },
        });
      }
    }
    if (this.authService.brokerageService$?.value) {
      this.loader.showLoader();
      this.documentService
        .claimAgreementsProcessing(this.authService.brokerageService$?.value?.created_by_claim?.id)
        .subscribe({
          next: (data) => {
            this.attachments.push(...data);
            this.loader.hideLoader();
          },
          error: () => {
            this.loader.hideLoader();
          },
        });
    }
    if (this.authService.depositaryService$?.value) {
      this.loader.showLoader();
      this.documentService
        .claimAgreementsProcessing(this.authService.depositaryService$?.value?.created_by_claim?.id)
        .subscribe({
          next: (data) => {
            this.attachments.push(...data);
            this.loader.hideLoader();
          },
          error: () => {
            this.loader.hideLoader();
          },
        });
    }
  }
}
