import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-ai-ui-caution',
  templateUrl: './ai-ui-caution.component.html',
  styleUrls: ['./ai-ui-caution.component.scss'],
})
export class AiUiCautionComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<AiUiCautionComponent>) {}

  onClose() {
    this.dialogRef.close(true);
  }
}
