import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiUrlWithPrefix } from '@env';
import { map } from 'rxjs';
import { DepositaryAccountResponse } from 'src/app/interfaces/api/depositary-account.interface';

@Injectable({
  providedIn: 'root',
})
export class DepositaryAccountService {
  static readonly DEPOSITARY_ACCOUNTS = `${apiUrlWithPrefix}/depositary-account/`;

  constructor(private http: HttpClient) {}

  depoAccountsByAgreement(agreementId: number) {
    const httpParams = new HttpParams({
      fromObject: {
        agreement: agreementId,
      },
    });
    return this.http
      .get(DepositaryAccountService.DEPOSITARY_ACCOUNTS, {
        params: httpParams,
      })
      .pipe(map((res: any) => res.results as DepositaryAccountResponse[]));
  }

  depoAccountById(id: number) {
    return this.http
      .get(`${apiUrlWithPrefix}/depositary-account/${id}/`)
      .pipe(map((res: any) => res as DepositaryAccountResponse));
  }
}
