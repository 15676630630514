import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { apiUrlWithPrefix } from '@env';
import { TranslationService } from '@localization';
import {
  APPOINTMENT_OF_AUTHORIZED_PERSON_FILE_NAME,
  BROKERAGE_ADDITIONAL_CONSENT_FILE_NAME,
  BROKER_AGREEMENT_DEFAULT_FILE_NAME,
  CLIENT_QUESTIONNAIRE_DEFAULT_FILE_NAME,
  CLIENT_QUESTIONNAIRE_EDIT_DEFAULT_FILE_NAME,
  CONSENT_TO_ELECTRONIC_DOCUMENT_MANAGEMENT_FILE_NAME,
  CONSENT_TO_PERSONAL_DATA_PROCESSING_FILE_NAME,
  CURRENCY_INSTRUMENTS_DEFAULT_FILE_NAME,
  DEPOSITARY_ACCOUNT_REFERENCE_FILE_NAME,
  DEPOSITARY_ADDITIONAL_CONSENT_FILE_NAME,
  DEPOSITARY_AGREEMENT_DEFAULT_FILE_NAME,
  DEPOSITARY_CREATE_ACCOUNT_ORDER_FILE_NAME,
  EDM_FILE_NAME,
  FATCA_FILE_NAME,
  FOREIGN_TAXPAYER_DEFAULT_FILE_NAME,
  MANUAL_ASSIGNMENT_FILE_NAME,
  MONEY_TRANSFER_FILE_NAME,
  OPENING_DEPO_ACCOUNT_FILE_NAME,
  PERSONAL_DATA_FILE_NAME,
  POWER_OF_ATTORNEY_FILE_NAME,
  PROFILE_FILE_NAME,
  SECURITIES_TRADE_PREFIX_FILE_NAME,
  TERMINATE_AGREEMENT_FILE_NAME,
  TRANSACTION_REFERENCE_REGISTRY_FILE_NAME,
  WITHDRAWAL_MONEY_FILE_NAME,
  WITHDRAW_EXCHANGE_MONEY_FILE_NAME,
} from 'src/app/constants/constants';
import { Attachment } from 'src/app/interfaces/api/attachment.interface';
import { ClaimResponse } from 'src/app/interfaces/api/claims.interface';
import { ClaimsService } from 'src/app/services/claims/claims.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { ConfirmOperation, acceptedFormates, convertByteToMegaBytes, maxFileSize } from 'src/app/utils/utils';
import { ToasterService } from '../../shared-services/toaster/toaster.service';
import { AiUiImageDialogComponent } from '../ai-ui-image-dialog/ai-ui-image-dialog.component';
import { AiUiConfirmDialogComponent } from '../ai-ui-confirm-dialog/ai-ui-confirm-dialog.component';

@Component({
  selector: 'ai-ui-documents',
  templateUrl: './ai-ui-documents.component.html',
  styleUrls: ['./ai-ui-documents.component.scss'],
})
export class AiUiDocumentsComponent {
  acceptedFormates = acceptedFormates;

  @Input() isReadonly: boolean = false;

  @Input() isClaimInfo: boolean = false;

  @Input() isClaimReasonsDocumentsInfo: boolean = false;

  @Input() abilityToSignConsent: boolean = false;

  @Input() attachments: Attachment[] = [];

  @Input() claimId: number = 0;

  @Input() claim: ClaimResponse = null;

  @Input() claimTypeName: string = null;

  @Input() isCreatingOrder: boolean = false;

  @Input() tradeOnSpbStock: boolean = false;

  constructor(
    private profileService: ProfileService,
    private claimService: ClaimsService,
    private toaster: ToasterService,
    private translation: TranslationService,
    private cd: ChangeDetectorRef,
    private loader: LoaderService,
    private dialog: MatDialog
  ) {}

  fileSelected(event) {
    const file: File = event.target.files[0];
    if (file.size > maxFileSize) {
      this.toaster.showError(this.translation.translate('FILE_SIZE_INCORRECT', [convertByteToMegaBytes(maxFileSize)]));
      return;
    }
    this.loader.showLoader();
    let formData: FormData = new FormData();
    formData.append('claim_id', this.claim.id.toString());
    formData.append('file', file, file.name);
    this.claimService.attachW8BEN(formData).subscribe({
      next: (data: any) => {
        const file_id = data.file_id;
        this.claimService.fileToBlob(this.claimService.claimDocumentAttachmentUrl(file_id)).subscribe({
          next: (scan) => {
            this.attachments.push({
              id: file_id,
              file_name: data.file_name,
              url: scan,
              type: data.file_type,
            });
            this.loader.hideLoader();
          },
          error: () => {
            this.loader.hideLoader();
          },
        });
        this.toaster.showInfo(this.translation.translate('W8BEN:SCAN_ATTACHED'));
        this.claim.is_w_eight_scan_attached = true;
        this.cd.markForCheck();
        this.loader.hideLoader();
      },
      error: () => {
        this.loader.hideLoader();
      },
    });
  }

  onDelete(file_id) {
    this.loader.showLoader();
    this.claimService.deleteDocument(file_id).subscribe({
      next: () => {
        this.toaster.showInfo(this.translation.translate('W8BEN:SCAN_DELETED'));
        this.attachments = this.attachments.filter((a) => a.id !== file_id);
        this.claim.is_w_eight_scan_attached = false;
        this.cd.markForCheck();
        this.loader.hideLoader();
      },
      error: () => {
        this.loader.hideLoader();
      },
    });
  }

  signAttachment(attachment: Attachment) {
    this.loader.showLoader();
    this.claimService.sendToSignAttachment(attachment.id).subscribe({
      next: (data: any) => {
        this.loader.hideLoader();
        this.dialog
          .open(AiUiConfirmDialogComponent, {
            data: {
              operation: ConfirmOperation.phone,
              callback: this.claimService.confirmAttachentSign.bind(this.claimService, attachment.id),
            },
          })
          .afterClosed()
          .subscribe({
            next: (res: boolean) => {
              this.loader.showLoader();
              this.claimService.fileToBlob(this.claimService.claimAttachmentUrl(attachment.id)).subscribe({
                next: (scan) => {
                  attachment.url = scan;
                  attachment.sign_datetime = new Date();
                  this.loader.hideLoader();
                  this.cd.markForCheck();
                },
                error: () => {
                  this.loader.hideLoader();
                },
              });
              this.toaster.showSuccess(this.translation.translate('CLAIM:ADDITIONAL_CONSENT_HAS_BEEN_SIGNED'));
            },
          });
      },
      error: () => {
        this.loader.hideLoader();
      },
    });
  }

  onComplexDownload(file: Attachment) {
    const fileName = file.file_name.split('.')[0];
    this.profileService
      .fileToBlob(
        file.is_attachment
          ? this.claimService.claimAttachmentComplexDownloadUrl(file.id)
          : this.claimService.claimDocumentComplexDownloadUrl(file.id)
      )
      .subscribe({
        next: (data: any) => {
          const a = document.createElement('a');
          a.href = data.changingThisBreaksApplicationSecurity;
          a.download = fileName;
          a.click();
        },
      });
  }

  getDocumentName(fileName: string) {
    if (fileName.startsWith(SECURITIES_TRADE_PREFIX_FILE_NAME)) {
      return (
        this.translation.translate(`securities_trade_prefix_file_name`) +
        fileName.split(SECURITIES_TRADE_PREFIX_FILE_NAME)[1].replace('.pdf', '')
      );
    }
    switch (fileName) {
      case BROKER_AGREEMENT_DEFAULT_FILE_NAME:
        return this.translation.translate(`broker_agreement`);
      case DEPOSITARY_AGREEMENT_DEFAULT_FILE_NAME:
        return this.translation.translate(`depositary_agreement`);
      case FOREIGN_TAXPAYER_DEFAULT_FILE_NAME:
        return this.translation.translate(`foreign_taxpayer`);
      case CLIENT_QUESTIONNAIRE_DEFAULT_FILE_NAME:
        return this.translation.translate(`client_questionnaire`);
      case CLIENT_QUESTIONNAIRE_EDIT_DEFAULT_FILE_NAME:
        return this.translation.translate(`client_questionnaire`);
      case CURRENCY_INSTRUMENTS_DEFAULT_FILE_NAME:
        return this.translation.translate(`currency_instruments_trade`);
      case MANUAL_ASSIGNMENT_FILE_NAME:
        return this.translation.translate(`manual_assignment`);
      case FATCA_FILE_NAME:
        return this.translation.translate(`fatca_file_name`);
      case PROFILE_FILE_NAME:
        return this.translation.translate(`profile_file_name`);
      case EDM_FILE_NAME:
        return this.translation.translate(`edm_file_name`);
      case CONSENT_TO_ELECTRONIC_DOCUMENT_MANAGEMENT_FILE_NAME:
        return this.translation.translate(`edm_file_name`);
      case CONSENT_TO_PERSONAL_DATA_PROCESSING_FILE_NAME:
        return this.translation.translate(`consent_to_personal_data_file_name`);
      case PERSONAL_DATA_FILE_NAME:
        return this.translation.translate(`personal_data_file_name`);
      case WITHDRAWAL_MONEY_FILE_NAME:
        return this.translation.translate(`withdrawal_money_file_name`);
      case DEPOSITARY_ACCOUNT_REFERENCE_FILE_NAME:
        return this.translation.translate(`depositary_account_reference_file_name`);
      case DEPOSITARY_CREATE_ACCOUNT_ORDER_FILE_NAME:
        return this.translation.translate(`depositary_create_account_order_file_name`);
      case TRANSACTION_REFERENCE_REGISTRY_FILE_NAME:
        return this.translation.translate(`transaction_reference_registry_file_name`);
      case TERMINATE_AGREEMENT_FILE_NAME:
        return this.translation.translate(`terminate_agreement_file_name`);
      case MONEY_TRANSFER_FILE_NAME:
        return this.translation.translate(`money_transfer_file_name`);
      case WITHDRAW_EXCHANGE_MONEY_FILE_NAME:
        return this.translation.translate(`withdraw_exchange_money_file_name`);
      case BROKERAGE_ADDITIONAL_CONSENT_FILE_NAME:
        return this.translation.translate(`brokerage_additional_consent_file_name`);
      case DEPOSITARY_ADDITIONAL_CONSENT_FILE_NAME:
        return this.translation.translate(`depositary_additional_consent_file_name`);
      case OPENING_DEPO_ACCOUNT_FILE_NAME:
        return this.translation.translate(`opening_depo_account_file_name`);
      case APPOINTMENT_OF_AUTHORIZED_PERSON_FILE_NAME:
        return this.translation.translate(`appointment_of_authorized_person_file_name`);
      case POWER_OF_ATTORNEY_FILE_NAME:
        return this.translation.translate(`power_of_attorney_file_name`);

      default:
        return fileName;
    }
  }

  open(url: string) {
    this.dialog.open(AiUiImageDialogComponent, {
      panelClass: 'attachment-container',
      data: {
        url,
      },
    });
  }
}
