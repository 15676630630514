import { Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';

import * as d3 from 'd3-selection';
import * as d3Scale from 'd3-scale';
import * as d3Shape from 'd3-shape';
import { PieData } from 'src/app/interfaces/internal/pie.interface';
export * from 'd3-transition';

@Component({
  selector: 'ai-ui-pie-chart',
  templateUrl: './ai-ui-pie-chart.component.html',
  styleUrls: ['./ai-ui-pie-chart.component.scss'],
})
export class AiUiPieChartComponent implements OnInit {
  @Input() index = 1;

  @Input() data: PieData[] = [];

  empty: boolean;

  width: number;
  height: number;
  radius: number;

  arc: any;
  // labelArc: any;
  // labelPer: any;
  pie: any;
  color: any;
  svg: any;

  constructor(private renderer2: Renderer2, private el: ElementRef) {}

  ngOnInit(): void {
    if (!!!this.data || this.data.every((n) => n.money === '0')) {
      return;
    }
    this.width = 900;
    this.height = 600;
    this.radius = Math.min(this.width, this.height) / 2;
    this.initSvg();
    this.drawPie();
  }

  initSvg() {
    if (this.empty) {
      this.color = d3Scale.scaleOrdinal().range(['#B3B9C0']);
    } else {
      this.color = d3Scale.scaleOrdinal().range([...this.data.map((n) => n.color)]);
    }
    this.arc = d3Shape
      .arc()
      .outerRadius(this.radius)
      .innerRadius(this.radius - 30);
    // this.labelArc = d3Shape.arc()
    //   .outerRadius(this.radius - 40)
    //   .innerRadius(this.radius - 40);

    // this.labelPer = d3Shape.arc()
    //   .outerRadius(this.radius - 80)
    //   .innerRadius(this.radius - 80);

    this.pie = d3Shape
      .pie()
      .sort(null)
      .value((d: any) => d.percent);

    this.svg = d3
      .select('#pieChart')
      .attr('id', this.index)
      .append('svg')
      .attr('width', '12rem')
      .attr('height', '12rem')
      .attr('viewBox', '0 0 ' + Math.min(this.width, this.height) + ' ' + Math.min(this.width, this.height))
      .append('g')
      .attr(
        'transform',
        'translate(' + Math.min(this.width, this.height) / 2 + ',' + Math.min(this.width, this.height) / 2 + ')'
      )
      .append('g')
      .attr('class', 'pieces');
  }

  drawPie() {
    this.svg
      .selectAll('.arc')
      .data(this.pie(this.data))
      .enter()
      .append('path')
      .attr('d', this.arc)
      .on('mouseover', function () {
        d3.select(this).transition().duration(100).style('stroke-width', '0.20rem');
      })
      .on('mouseout', function () {
        d3.select(this).transition().duration(100).style('stroke-width', '0.5rem');
      })
      .style('fill', (d: any) => this.color(d))
      .style('stroke-width', '2rem')
      .transition()
      .duration(500)
      .style('stroke-width', '0.5rem')
      .style('stroke', '#ffffff');
  }
}
