<div class="container-fluid bg-white p-4" [@open]="'opened'">
  <h1>{{ 'CLAIM:FILLING' | translate }}</h1>
  <div class="hint">
    <p>{{ 'brokerage_additional_consent' | translate }}</p>
  </div>
  <div class="row">
    <ui-step-view
      class="col-12 mt-3"
      [total]="3"
      [current]="cfService.stepKey + 1"
      [stepValues]="['CLAIM_STEP:CREATION', 'CLAIM_STEP:CONDITION_OF_ACCEPTATE', 'CLAIM_STEP:SIGN']">
    </ui-step-view>
    <div class="order-container">
      <div class="form-data col-12">
        <ng-container *ngIf="cfService.stepKey === 0">
          <div class="order-title">
            <h2>{{ 'BS:ADDITIONAL_CONSENT_TITLE' | translate }}</h2>
            <mat-checkbox [formControl]="formDepoContract" color="primary">{{
              'BS:FORM_ADDITIONAL_DEPO_CONSENT' | translate
            }}</mat-checkbox>
          </div>
          <h4>{{ 'ADDITIONAL_CONSENT:CHOOSE_TARIFF' | translate }}</h4>
          <div class="hint">
            <p class="mt-2"><mat-icon>info_outline</mat-icon></p>
            <p>{{'ADDITIONAL_CONSENT:CHOOSE_TARIFF_HINT' | translate}}</p>
          </div>
          <ng-container *ngIf="!this.form.get('tariff')?.value; else tariffIsNotNull">
            <div class="tariffs">
              <ng-container *ngFor="let tariff of tariffs">
                <div class="tariff col-12">
                  <div class="tariff__logo">
                    <img [src]="'./assets/logos/' + tariff + '.svg'" alt="logo" />
                  </div>
                  <div class="tariff__description">
                    <span>{{ 'TARIFF:' + tariff | translate }}</span>
                  </div>
                  <div class="tariff__footer">
                    <button mat-button class="filled" type="button" (click)="chooseTariff(tariff)">
                      {{ 'CHOOSE' | translate }}
                    </button>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>
          <ng-template #tariffIsNotNull>
            <div class="tariffs">
              <ng-container *ngFor="let tariff of tariffs">
                <div class="tariff col-12">
                  <div class="tariff__logo">
                    <img [src]="'./assets/logos/' + tariff + '.svg'" alt="logo" />
                  </div>
                  <div class="tariff__description">
                    <span>{{ 'TARIFF:' + tariff | translate }}</span>
                  </div>
                  <div class="tariff__footer">
                    <ng-container *ngIf="this.form.get('tariff')?.value !== tariff;else chosen">
                      <button mat-button class="filled" type="button" (click)="chooseTariff(tariff)">
                        {{ 'CHOOSE' | translate }}
                      </button>
                    </ng-container>
                    <ng-template #chosen>
                      <button mat-button class="filled chosen" type="button" disabled>
                        {{ 'CHOSEN_TARIFF' | translate }}
                      </button>
                    </ng-template>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="d-flex justify-content-end mt-3">
              <button mat-button class="filled" type="button" (click)="next()">
                {{ 'NEXT' | translate }}
              </button>
            </div>
          </ng-template>
          
        </ng-container>
        <ng-container *ngIf="cfService.stepKey === 1">
          <form [formGroup]="form">
            <div class="order-content">
              <p>{{ 'BS:CONDITION_OF_ACCEPTATE' | translate }}</p>
              <mat-radio-group
                formControlName="broker_can_use_client_money"
                (change)="onChangeBrokerCanUseClientMoney($event)">
                <mat-radio-button color="primary" [value]="true">
                  {{ 'BS:CONDITION_OF_ACCEPTATE_AGREE' | translate }}
                </mat-radio-button>
                <mat-radio-button color="primary" [value]="false">
                  {{ 'BS:CONDITION_OF_ACCEPTATE_DISAGREE' | translate }}
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="order-content col-12">
              <p>{{ 'BS:INCOME_EARNING_OPTION' | translate }}</p>
              <mat-radio-group formControlName="income_earning_option" (change)="onChangeIncomeEarningOption($event)">
                <mat-radio-button color="primary" [value]="'bank_account'">
                  {{ 'BS:INCOME_EARNING_OPTION_BANK_ACCOUNT' | translate }}
                </mat-radio-button>
                <mat-radio-button color="primary" [value]="'brokerage_account'">
                  {{ 'BS:INCOME_EARNING_OPTION_BROKERAGE_ACCOUNT' | translate }}
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="order-content">
              <mat-checkbox formControlName="refusal_of_transactions_with_incomplete_coverage" color="primary">{{
                'BS:REFUSAL_OF_TRANSACTIONS_WITH_INCOMPLETE_COVERAGE' | translate
              }}</mat-checkbox>
            </div>
            <div class="order-content">
              <mat-checkbox formControlName="trade_on_spb_stock" color="primary">{{
                'BS:TRADE_ON_SPB_STOCK' | translate
              }}</mat-checkbox>
              <div class="hint mt-1">
                <p>{{ 'BS:TRADE_ON_SPB_STOCK_TIP' | translate }}</p>
              </div>
            </div>
          </form>
          <div class="d-flex justify-content-between align-items-center">
            <button class="back" (click)="back()" mat-button type="button">
              {{ 'BACK' | translate }}
            </button>
            <button [disabled]="form.invalid" mat-button class="filled" type="button" (click)="next()">
              {{ 'NEXT' | translate }}
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="cfService.stepKey === 2">
          <div class="col-12">
            <ai-ui-documents
              [claimTypeName]="'brokerage_additional_consent' | translate"
              [attachments]="attachmentsToSign"
              [isCreatingOrder]="true"
              [tradeOnSpbStock]="
                form.get('trade_on_spb_stock')?.value === true && currentAgreementData.trade_on_spb_stock === false
              "></ai-ui-documents>
          </div>
          <div class="col-10 confirmation">
            <h4>{{ 'CLAIM_SIGNATURE_TITLE' | translate }}</h4>
            <div class="hint">
              <p>{{ 'CLAIM_SIGNATURE_INFORMATION' | translate }}</p>
            </div>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <button class="back" (click)="back()" mat-button type="button">
              {{ 'BACK' | translate }}
            </button>
            <button mat-button class="filled" type="button" (click)="sendToSign(claimId)">
              {{ 'SIGN' | translate }}
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
