<div class="container-fluid bg-white p-4" [@open]="'opened'">
  <h1>{{ 'CLAIM:FILLING' | translate }}</h1>
  <div class="hint">
    <p>{{ claimType === 'manual_assignment' ? ('APPEAL' | translate) : ('ARBITRARY_ORDER' | translate) }}</p>
  </div>
  <div class="row">
    <ui-step-view
      class="col-12 mt-3"
      [total]="2"
      [current]="cfService.stepKey + 1"
      [stepValues]="['CLAIM_STEP:CREATION', 'CLAIM_STEP:SIGN']">
    </ui-step-view>
    <div class="order-container">
      <div class="form-data col-12">
        <ng-container *ngIf="cfService.stepKey === 0">
          <div class="order-content">
            <p>
              {{
                claimType === 'manual_assignment'
                  ? ('APPEAL:CONTENT' | translate)
                  : ('ARBITRARY_ORDER:CONTENT' | translate)
              }}
            </p>
          </div>
          <form [formGroup]="form">
            <mat-form-field appearance="outline">
              <mat-label>{{
                claimType === 'manual_assignment' ? ('FIELD:APPEAL' | translate) : ('FIELD:ARBITRARY_ORDER' | translate)
              }}</mat-label>
              <textarea matInput cdkTextareaAutosize formControlName="text"></textarea>
              <mat-error *ngIf="form.get('text').errors?.required">
                {{ 'ERROR:REQUIRED' | translate }}
              </mat-error>
            </mat-form-field>
          </form>

          <div class="row attachments">
            <ng-container *ngFor="let attachment of claimAttachments">
              <div class="col-xl-3 col-lg-4 col-md-5 col-sm-6 col-12" *ngIf="attachment">
                <div class="attachment mat-elevation-z2" [@open]="'opened'">
                  <div class="close" (click)="onDelete(attachment.id)">
                    <mat-icon>close</mat-icon>
                  </div>
                  <ng-container *ngIf="attachment.isImage; else document">
                    <img [src]="attachment.url" [alt]="attachment.file_name" (click)="open(attachment.url)" />
                  </ng-container>
                  <ng-template #document>
                    <div class="document">
                      <img src="./assets/images/document.svg" alt="logo" />
                      <p>{{ attachment.file_name }}</p>
                      <div class="actions">
                        <div class="open">
                          <a [href]="attachment.url" target="_blank">
                            <div>
                              <mat-icon>visibility</mat-icon>
                            </div>
                            {{ 'OPEN' | translate }}
                          </a>
                        </div>
                        <div class="download">
                          <a [href]="attachment.url" [download]="attachment.file_name" target="_blank">
                            <!-- <button mat-button (click)="download('test.docx','blob:http://localhost:4300/ed2a7872-2c21-4cf1-b7db-e66d4ec9710e')"> -->
                            <div>
                              <mat-icon>download</mat-icon>
                            </div>
                            {{ 'DOWNLOAD' | translate }}
                            <!-- </button> -->
                          </a>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </div>
            </ng-container>
          </div>

          <div class="actions">
            <div class="d-flex justify-content-start mb-3">
              <input class="file-input" type="file" [accept]="acceptedFormates" (change)="fileSelected($event)" #file />
              <button type="button" class="attach btn btn-outline-dark" (click)="file.click()">
                {{ 'ATTACHMENT:UPLOAD' | translate }}
              </button>
            </div>
            <div class="hint">
              <p>{{ 'ATTACHMENT:HINT_ONLY_PDF' | translate }}</p>
            </div>
            <div class="d-flex justify-content-end mb-3">
              <button [disabled]="form.invalid" class="filled" mat-flat-button (click)="next()">
                {{ 'NEXT' | translate }}
              </button>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="cfService.stepKey === 1">
          <div class="col-12">
            <ai-ui-documents [attachments]="attachmentsToSign" [isCreatingOrder]="true"></ai-ui-documents>
          </div>
          <div class="col-10 confirmation">
            <ng-container *ngIf="claimType === 'arbitrary_order'">
              <mat-checkbox [formControl]="theFirstConfirmation" color="primary">
                {{ 'BS:CONFIRMATION_4' | translate }}
                <a href="https://www.aigenis.ru/info12" target="_blank" class="risk-link">{{
                  'BS:CONFIRMATION_4_1' | translate
                }}</a>
                {{ 'BS:CONFIRMATION_4_2' | translate }}
              </mat-checkbox>
            </ng-container>
            <h4>{{ 'CLAIM_SIGNATURE_TITLE' | translate }}</h4>
            <div class="hint">
              <p>{{ 'CLAIM_SIGNATURE_INFORMATION' | translate }}</p>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <button class="back" (click)="back()" mat-button type="button">
              {{ 'BACK' | translate }}
            </button>
            <button
              mat-button
              [disabled]="claimType === 'arbitrary_order' && !theFirstConfirmation.value"
              class="filled"
              type="button"
              (click)="sendToSign()">
              {{ 'SIGN' | translate }}
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
