import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Observable, debounceTime, distinctUntilChanged, map, of, startWith, switchMap } from 'rxjs';
import { ParamsType } from 'src/app/interfaces/api/common/common-types.interface';

@Component({
  selector: 'ai-ui-select',
  templateUrl: './ai-ui-select.component.html',
  styleUrls: ['./ai-ui-select.component.scss'],
})
export class AiUiSelectComponent implements OnInit, AfterViewInit {
  @ViewChild(MatAutocompleteTrigger) trigger: MatAutocompleteTrigger;

  @Input() formControlObj: FormControl = new FormControl(null);

  @Input() label: string;

  @Input() additionalParams: { [key: string]: string };

  @Input() request: (params: ParamsType, additionalParams: { [key: string]: string }) => Observable<any[]>;

  @Input() showAtOnce: boolean = false;

  @Output() selected: EventEmitter<any | null> = new EventEmitter();

  @Output() notSelected: EventEmitter<void> = new EventEmitter();

  filteredItems: Observable<any[]>;

  constructor() {}

  ngOnInit(): void {
    this.filteredItems = this.formControlObj.valueChanges.pipe(
      startWith(''),
      debounceTime(150),
      map((value) => (typeof value === 'string' ? value : value?.name)),
      switchMap((value) => this.filterItems(value))
    );
  }

  ngAfterViewInit() {
    this.trigger.panelClosingActions.subscribe((e) => {
      if (!(e && e.source)) {
        // this.filterItems('');
        this.trigger.closePanel();
        this.notSelected.emit();
      }
    });
  }

  filterItems(value): Observable<any[]> {
    return this.showAtOnce || value.length > 2 ? this.request(value || '', this.additionalParams) : of([]);
  }

  displayFn(item: any): string {
    return item && item.name ? item.name : '';
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent) {
    this.selected.emit(event.option.value || null);
  }
}
