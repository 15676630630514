import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiUrlWithPrefix } from '@env';
import { BehaviorSubject, map } from 'rxjs';
import { ExchangeRateResponse } from 'src/app/interfaces/api/exchange-rate';
import { LoaderService } from '../loader/loader.service';

@Injectable({
  providedIn: 'root',
})
export class ExchangeRateService {
  static readonly EXCHANGE_RATES = `${apiUrlWithPrefix}/exchange-rate/`;

  public exchangeRates$: BehaviorSubject<ExchangeRateResponse[]> = new BehaviorSubject<ExchangeRateResponse[]>(null);

  constructor(private http: HttpClient, private loader: LoaderService) {}

  actualExchageRates() {
    const httpParams = new HttpParams({
      fromObject: {
        date_of_use: new Date().toISOString().split('T')[0],
      },
    });
    this.loader.showLoader();
    return this.http
      .get(ExchangeRateService.EXCHANGE_RATES, {
        params: httpParams,
      })
      .pipe(
        map((res: any) => {
          return res.results as ExchangeRateResponse[];
        })
      )
      .subscribe({
        next: (data) => {
          this.exchangeRates$.next(data);
          this.loader.hideLoader();
        },
        error: () => {
          this.loader.hideLoader();
        },
      });
  }
}
