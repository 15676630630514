import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ClaimsBatchResponse, DepositaryAccountReference } from 'src/app/interfaces/api/claims.interface';
import { animationOptions } from 'src/app/utils/animation';
import {
  DepoType,
  OperationInitiator,
  StatementType,
  getFormatDate,
  getISOFormatDateTimeUTC,
} from 'src/app/utils/utils';
import { ClaimBaseComponent } from '../claim-base/claim-base.component';
import { formatDate } from '@angular/common';
import { MatRadioChange } from '@angular/material/radio';
import { mergeMap } from 'rxjs';

@Component({
  selector: 'app-depo-reference',
  templateUrl: './depo-reference.component.html',
  styleUrls: ['./depo-reference.component.scss'],
  animations: animationOptions,
})
export class DepoReferenceComponent extends ClaimBaseComponent implements OnInit, OnDestroy {
  yesterday = new Date();

  statementTypes = Array.from(new Set(Object.keys(StatementType)));

  timeControl = new FormControl(null);

  ngOnInit(): void {
    this.yesterday.setDate(this.yesterday.getDate() - 1);
    this.form = this.initForm();
    this.getDepoAccounts();
  }

  initForm() {
    return this.fb.group({
      depositary_account: new FormControl(null, Validators.required),
      operation_initiator: new FormControl(
        { value: OperationInitiator.depositor, disabled: true },
        Validators.required
      ),
      depositary_statements: this.fb.array([
        this.fb.group({
          type: new FormControl(null, Validators.required),
          report_date: new FormControl(null),
          report_datetime: new FormControl(null),
          report_date_from: new FormControl(null),
          report_date_to: new FormControl(null),
        }),
      ]),
      issuer: new FormControl(null),
      security: new FormControl(null),
      additional_terms: new FormControl(null),
    });
  }

  onChangeStatementType(event: MatRadioChange, formGroup: FormGroup) {
    this.timeControl.setValue(null);
    formGroup.patchValue({
      report_date: null,
      report_datetime: null,
      report_date_from: null,
      report_date_to: null,
    });
    if (event.value === StatementType.depositary_account_reference) {
      formGroup.get('report_datetime').setValidators(Validators.required);
      this.timeControl.setValidators(Validators.required);
      formGroup.get('report_date_from').clearValidators();
      formGroup.get('report_date_to').clearValidators();
      formGroup.get('report_date').clearValidators();
    } else if (event.value === StatementType.securities_statement) {
      formGroup.get('report_datetime').clearValidators();
      this.timeControl.clearValidators();
      formGroup.get('report_date_from').setValidators(Validators.required);
      formGroup.get('report_date_to').setValidators(Validators.required);
      formGroup.get('report_date').clearValidators();
    } else if (event.value === StatementType.depositary_account_statement) {
      formGroup.get('report_datetime').clearValidators();
      this.timeControl.clearValidators();
      formGroup.get('report_date_from').clearValidators();
      formGroup.get('report_date_to').clearValidators();
      formGroup.get('report_date').setValidators(Validators.required);
    } else {
      // empty
    }
    this.timeControl.updateValueAndValidity({ emitEvent: false });
    formGroup.get('report_datetime').updateValueAndValidity({ emitEvent: false });
    formGroup.get('report_date_from').updateValueAndValidity({ emitEvent: false });
    formGroup.get('report_date_to').updateValueAndValidity({ emitEvent: false });
    formGroup.get('report_date').updateValueAndValidity({ emitEvent: false });
    this.cd.markForCheck();
  }

  next() {
    if (this.form.invalid) {
      return;
    }
    const payload = this.form.getRawValue() as DepositaryAccountReference;
    payload.type = DepoType.depositary_account_reference;
    payload.depositary_statements.map((d) => {
      d.report_date = getFormatDate(d.report_date);
      if (d.report_datetime && this.timeControl.value) {
        d.report_datetime = getISOFormatDateTimeUTC(d.report_datetime, this.timeControl.value);
      }
      d.report_date_from = getFormatDate(d.report_date_from);
      d.report_date_to = getFormatDate(d.report_date_to);
    });
    this.loader.showLoader();
    this.claimsService
      .addClaimsBatch([payload])
      .pipe(
        mergeMap((res: ClaimsBatchResponse) => {
          this.claimBatchId = res.id;
          return this.documentService.claimsBatchDocumentsProcessing(res.id);
        })
      )
      .subscribe({
        next: (data) => {
          this.attachmentsToSign = data;
          this.cfService.goToStep(this.cfService.stepKey + 1);
          this.loader.hideLoader();
          this.cd.markForCheck();
        },
        error: () => {
          this.loader.hideLoader();
        },
      });
  }
}
