import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiUrlWithPrefix } from '@env';
import { ChangePasswordRequest, PasswordRecoveryRequest } from 'src/app/interfaces/api/password-recovery.interface';

@Injectable({
  providedIn: 'root',
})
export class PasswordService {
  static readonly RESET_PASSWORD = `${apiUrlWithPrefix}/user/reset-password/`;
  static readonly CHANGE_PASSWORD = `${apiUrlWithPrefix}/user/change-password/`;
  static readonly RESET_PASSWORD_CONFIRMATION = `${apiUrlWithPrefix}/user/reset-password-confirm/`;

  constructor(private http: HttpClient) {}

  resetPassword(email: string) {
    return this.http.post(PasswordService.RESET_PASSWORD, {
      email,
    });
  }

  resetPasswordConfirmation(payload: PasswordRecoveryRequest) {
    return this.http.post(PasswordService.RESET_PASSWORD_CONFIRMATION, {
      ...payload,
    });
  }

  changePassword(payload: ChangePasswordRequest) {
    return this.http.post(PasswordService.CHANGE_PASSWORD, {
      ...payload,
    });
  }
}
