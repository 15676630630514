import { Injectable } from '@angular/core';
import { apiUrlWithPrefix } from '@env';
import { forkJoin, map, mergeMap, of } from 'rxjs';
import { EDM_FILE_NAME, PERSONAL_DATA_FILE_NAME } from 'src/app/constants/constants';
import { ProfileResponse } from 'src/app/interfaces/api/profile.interface';
import { ClaimsService } from '../claims/claims.service';
import { LoaderService } from '../loader/loader.service';
import { ProfileService } from '../profile/profile.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  url = (id: number): string => `${apiUrlWithPrefix}/protected-file/${id}/preview/`;
  claimAttachmentUrl = (id: number): string => `${apiUrlWithPrefix}/claim-attachment/${id}/preview/`;
  claimDocumentAttachmentUrl = (id: number): string => `${apiUrlWithPrefix}/claim-document/${id}/preview/`;
  passportScanUrl = (id: number): string => `${apiUrlWithPrefix}/user/passport-scan/${id}/preview/`;

  constructor(
    private claimService: ClaimsService,
    private profileService: ProfileService,
    private loader: LoaderService
  ) {}

  claimDocumentsProcessing(claimId: number) {
    const emittedAttachments = [];
    return forkJoin({
      claimAttachments: this.claimService.claimAttachmentsById(claimId),
      documents: this.claimService.claimDocumentsById(claimId),
      claimReasonsDocuments: this.claimService.claimReasonDocumentsById(claimId),
    }).pipe(
      mergeMap((res) => {
        emittedAttachments.push(...res.claimAttachments, ...res.documents, ...res.claimReasonsDocuments);
        if (!emittedAttachments.length) {
          this.loader.hideLoader();
        }
        const claim_attachments_payload = res.claimAttachments.map((obj) => {
          obj.is_attachment = true;
          return this.claimService.fileToBlob(this.claimAttachmentUrl(obj.id));
        });
        const documents_payload = res.documents.map((obj) =>
          this.claimService.fileToBlob(this.claimDocumentAttachmentUrl(obj.id))
        );
        const claim_reason_document_payload = res.claimReasonsDocuments.map((obj) =>
          this.claimService.fileToBlob(this.claimService.claimReasonDocumentUrl(obj.id))
        );
        const payload = [...claim_attachments_payload, ...documents_payload, ...claim_reason_document_payload];
        return forkJoin(payload);
      }),
      map((data) => {
        return emittedAttachments.map((attachment, index) => ({
          id: attachment.id,
          file_name: attachment.file_name,
          url: data[index],
          type: attachment?.type,
          doc_signed: attachment?.doc_signed,
          sign_datetime: attachment?.sign_datetime,
          is_attachment: attachment.is_attachment,
          isImage: ['jpeg', 'jpg', 'png']?.indexOf(attachment.file_name?.split('.')?.pop()?.toLowerCase()) !== -1,
        }));
      })
    );
  }

  claimAgreementsProcessing(claimId: number) {
    const emittedAttachments = [];
    return this.claimService.claimDocumentsById(claimId).pipe(
      mergeMap((res) => {
        emittedAttachments.push(...res.filter((d) => d.need_sign_stamp));
        if (!emittedAttachments.length) {
          this.loader.hideLoader();
        }
        const documents_payload = res.map((obj) =>
          this.claimService.fileToBlob(this.claimDocumentAttachmentUrl(obj.id))
        );
        const payload = [...documents_payload];
        return forkJoin(payload);
      }),
      map((data) => {
        return emittedAttachments.map((attachment, index) => ({
          id: attachment.id,
          file_name: attachment.file_name,
          url: data[index],
          type: attachment?.type,
          doc_signed: attachment?.doc_signed,
          isImage: ['jpeg', 'jpg', 'png']?.indexOf(attachment.file_name?.split('.')?.pop()?.toLowerCase()) !== -1,
        }));
      })
    );
  }

  claimReasonsDocumentsProcessing(claimId: number) {
    const emittedAttachments = [];
    return this.claimService.claimDocumentsById(claimId).pipe(
      mergeMap((res) => {
        emittedAttachments.push(...res);
        if (!emittedAttachments.length) {
          this.loader.hideLoader();
        }
        const claim_reason_document_payload = res.map((obj) =>
          this.claimService.fileToBlob(this.claimService.claimReasonDocumentUrl(obj.id))
        );
        const payload = [...claim_reason_document_payload];
        return forkJoin(payload);
      }),
      map((data) => {
        return emittedAttachments.map((attachment, index) => ({
          id: attachment.id,
          file_name: attachment.file_name,
          url: data[index],
        }));
      })
    );
  }

  additionalConsentDocumentProcessing() {
    return this.claimService.additionalConsent().pipe(
      mergeMap((res) => {
        if (!res?.id) {
          return null;
        }
        return this.claimService.fileToBlob(this.claimAttachmentUrl(res.id)).pipe(
          map((data) => {
            return {
              id: res.id,
              file_name: res.file_name,
              url: data,
              type: res?.type,
              doc_signed: res?.doc_signed,
              is_attachment: true,
              isImage: ['jpeg', 'jpg', 'png']?.indexOf(res.file_name?.split('.')?.pop()?.toLowerCase()) !== -1,
            };
          })
        );
      })
    );
  }

  profileDocumentsProcessing(user: ProfileResponse) {
    const emittedAttachments = [];
    return forkJoin({
      edm_consent: of(user.edm_consent),
      personal_data_consent: of(user.personal_data_consent),
    }).pipe(
      mergeMap((res) => {
        emittedAttachments.push(
          {
            id: res.edm_consent,
            file_name: EDM_FILE_NAME,
          },
          {
            id: res.personal_data_consent,
            file_name: PERSONAL_DATA_FILE_NAME,
          }
        );
        const payload = emittedAttachments
          .filter((a) => !!a.id)
          .map((obj) => this.profileService.fileToBlob(this.url(obj.id)));
        return forkJoin(payload);
      }),
      map((data) => {
        return emittedAttachments.map((attachment, index) => ({
          id: attachment.id,
          file_name: attachment.file_name,
          url: data[index],
        }));
      })
    );
  }

  passportScanProcessing() {
    const emittedAttachments = [];
    return this.profileService.getPassportScans().pipe(
      mergeMap((res) => {
        emittedAttachments.push(...res.results);
        if (!emittedAttachments.length) {
          this.loader.hideLoader();
        }
        const payload = emittedAttachments.map((obj) => this.profileService.fileToBlob(this.passportScanUrl(obj.id)));
        return forkJoin(payload);
      }),
      map((data) => {
        return emittedAttachments.map((scan, index) => ({
          id: scan.id,
          file_name: scan.file_name,
          url: data[index],
          isImage: ['jpeg', 'jpg', 'png']?.indexOf(scan.file_name?.split('.')?.pop()?.toLowerCase()) !== -1,
        }));
      })
    );
  }

  claimsBatchDocumentsProcessing(claimBatchId: number, showSignature: boolean = false) {
    const emittedAttachments = [];
    return this.claimService.claimsBatchDocumentsById(claimBatchId).pipe(
      mergeMap((res) => {
        emittedAttachments.push(...res);
        if (!emittedAttachments.length) {
          this.loader.hideLoader();
        }
        const payload = res.map((obj) => this.claimService.fileToBlob(this.claimDocumentAttachmentUrl(obj.id)));
        this.loader.hideLoader();
        return forkJoin(payload);
      }),
      map((data) => {
        return emittedAttachments.map((attachment, index) => ({
          id: attachment.id,
          file_name: attachment.file_name,
          type: attachment.type,
          url: data[index],
          doc_signed: showSignature && attachment?.doc_signed,
        }));
      })
    );
  }
}
