import { Injectable } from '@angular/core';
import { BehaviorSubject, debounce, debounceTime, Subscription, timer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private _isLoaderShowing$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public isLoaderShowing$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {
    this._isLoaderShowing$.pipe(debounceTime(50)).subscribe((value: boolean) => {
      this.isLoaderShowing$.next(value);
    });
  }

  showLoader() {
    this._isLoaderShowing$.next(true);
  }

  hideLoader() {
    this._isLoaderShowing$.next(false);
  }
}
