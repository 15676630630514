<div class="close" mat-dialog-close>
  <mat-icon>close</mat-icon>
</div>
<div class="container">
  <div class="title mb-4">
    <h3>{{ data.type | translate }}</h3>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="sub-title">
        <mat-icon>info</mat-icon>
        <h5>{{ 'CLAIM_MAIN_DATA' | translate }}</h5>
      </div>
      <div class="table-responsive">
        <table class="table borderless">
          <tbody>
            <tr>
              <th>{{ 'CLAIM:NUMBER' | translate }}</th>
              <td>{{ data?.number }}</td>
            </tr>
            <tr>
              <th>{{ 'CLAIM:STATUS' | translate }}</th>
              <td>
                <div class="status">
                  <mat-icon class="status" [ngClass]="data.status">{{ data.status | claimStatusIcon }}</mat-icon>
                  <span>{{ data.status | translate }}</span>
                </div>
              </td>
            </tr>
            <ng-container *ngIf="data.status === 'rejected' && data?.rejection_reason">
              <tr>
                <th>{{ 'CLAIM:REJECTION_REASON' | translate }}</th>
                <td>{{ data?.rejection_reason | translate }}</td>
              </tr>
            </ng-container>
            <tr>
              <th>{{ 'CLAIM:CREATED_AT' | translate }}</th>
              <td>{{ data.created_at + 'Z' | date : 'dd.MM.yyyy HH:mm' }}</td>
            </tr>

            <!--BROKER AGREEMENT TYPE-->
            <ng-container *ngIf="data.type === 'broker_agreement'">
              <tr>
                <th>{{ 'BROKING:RISK_STATUS_LABEL' | translate }}</th>
                <td>{{ data?.agreement?.risk_level | translate }}</td>
              </tr>
            </ng-container>

            <!--DEPOSITARY AGREEMENT TYPE-->
            <ng-container *ngIf="data.type === 'depositary_agreement'">
              <tr>
                <th>{{ 'CLAIM_DETAILS' | translate }}</th>
                <td>Счет ДЕПО владельца</td>
              </tr>
              <tr>
                <th>{{ 'BS:INCOME_EARNING_OPTION' | translate }}</th>
                <td>{{ 'BS:INCOME_EARNING_OPTION_' + (data?.income_earning_option | uppercase) | translate }}</td>
              </tr>
            </ng-container>

            <!--TERMINATE AGREEMENT TYPE-->
            <ng-container *ngIf="data.type === 'terminate_agreement'">
              <tr>
                <th>{{ 'CLAIM_TERMINATION_TYPE' | translate }}</th>
                <td>{{ data?.termination_type | translate }}</td>
              </tr>
            </ng-container>

            <!--WITHDRAW MONEY TYPE-->
            <ng-container *ngIf="data.type === 'withdraw_money'">
              <tr>
                <th>{{ 'BANK:CURRENCY_CODE' | translate }}</th>
                <td>{{ data?.withdrawal_currency }}</td>
              </tr>
              <tr>
                <th>{{ 'WITHDRAWAL:IN_THE_AMOUNT_OF_FREE_BALANCE' | translate }}</th>
                <td>{{ (data?.withdraw_all_money ? 'YES' : 'NO') | translate }}</td>
              </tr>
              <ng-container *ngIf="!data?.withdraw_all_money">
                <tr>
                  <th>{{ 'FIELD:AMOUNT' | translate }}</th>
                  <td>{{ data?.withdrawal_amount }} {{ data?.withdrawal_currency }}</td>
                </tr>
              </ng-container>
            </ng-container>

            <!--WITHDRAW MONEY FORM EXCHANGE TYPE-->
            <ng-container *ngIf="data.type === 'withdraw_exchange_money'">
              <tr>
                <th>{{ 'CLAIM:ADDITIONAL_CONDITION' | translate }}</th>
                <td>{{ data?.additional_terms ? data?.additional_terms : '-' }}</td>
              </tr>
              <ng-container *ngFor="let withdrawal of data?.withdraw_from_exchanges">
                <h6 class="mt-4">
                  {{ getExchangeName(withdrawal.exchange) }}
                </h6>
                <tr>
                  <th>{{ 'BANK:CURRENCY_CODE' | translate }}</th>
                  <td>{{ withdrawal?.currency }}</td>
                </tr>
                <tr>
                  <th>{{ 'WITHDRAWAL:IN_THE_AMOUNT_OF_FREE_BALANCE' | translate }}</th>
                  <td>{{ (withdrawal?.withdraw_all_money ? 'YES' : 'NO') | translate }}</td>
                </tr>
                <ng-container *ngIf="!withdrawal?.withdraw_all_money">
                  <tr>
                    <th>{{ 'FIELD:AMOUNT' | translate }}</th>
                    <td>{{ withdrawal?.amount }} {{ withdrawal?.currency }}</td>
                  </tr>
                </ng-container>
                <tr *ngIf="withdrawal?.platform_name">
                  <th>{{ 'WITHDRAWAL:AREA_NAME' | translate }}</th>
                  <td>{{ withdrawal?.platform_name }}</td>
                </tr>
              </ng-container>
            </ng-container>

            <!--MONEY TRANSFER TYPE-->
            <ng-container *ngIf="data.type === 'money_transfer'">
              <tr>
                <th>{{ 'CLAIM:TRANSFER_TYPE' | translate }}</th>
                <td>{{ data?.transfer_type | translate }}</td>
              </tr>
              <tr>
                <th>{{ 'BANK:CURRENCY_CODE' | translate }}</th>
                <td>{{ data?.transfer_currency }}</td>
              </tr>
              <tr>
                <th>{{ 'WITHDRAWAL:IN_THE_AMOUNT_OF_FREE_BALANCE' | translate }}</th>
                <td>{{ (data?.transfer_all_money ? 'YES' : 'NO') | translate }}</td>
              </tr>
              <ng-container *ngIf="!data?.transfer_all_money">
                <tr>
                  <th>{{ 'FIELD:AMOUNT' | translate }}</th>
                  <td>{{ data?.transfer_amount }} {{ data?.transfer_currency }}</td>
                </tr>
              </ng-container>
              <ng-container *ngIf="data?.transfer_type === 'agreement'">
                <tr>
                  <th>{{ 'CLAIM:AGREEMENT_FROM' | translate }}</th>
                  <!-- TODO change after receiving rhe endpoint -->
                  <td>{{ (auth.brokerageService$ | async)?.number }}</td>
                </tr>
              </ng-container>
              <tr>
                <th>{{ 'CLAIM:EXCHANGE_FROM' | translate }}</th>
                <td>{{ getExchangeName(data?.transfer_from_exchange) }}</td>
              </tr>
              <ng-container *ngIf="data?.transfer_type === 'agreement'">
                <tr>
                  <th>{{ 'CLAIM:AGREEMENT_TO' | translate }}</th>
                  <!-- TODO change after receiving rhe endpoint -->
                  <td>{{ (auth.brokerageService$ | async)?.number }}</td>
                </tr>
              </ng-container>
              <tr>
                <th>{{ 'CLAIM:EXCHANGE_TO' | translate }}</th>
                <td>{{ getExchangeName(data?.transfer_to_exchange) }}</td>
              </tr>
            </ng-container>

            <!--TRANSACTION REFERENCE REGISTRY TYPE-->
            <ng-container *ngIf="data?.type === 'transaction_reference_registry'">
              <tr>
                <th>{{ 'CLAIM:TRANSACTION_TYPE' | translate }}</th>
                <td>{{ data?.transaction_type | translate }}</td>
              </tr>
              <tr>
                <th>{{ 'CLAIM:OPERATION_INITIATOR' | translate }}</th>
                <td>{{ data?.operation_initiator | translate }}</td>
              </tr>
              <ng-container *ngIf="data?.place_of_storage !== 'other'">
                <tr>
                  <th>{{ 'CLAIM:PLACE_OF_STORAGE' | translate }}</th>
                  <td>{{ data?.place_of_storage | translate }}</td>
                </tr>
              </ng-container>
              <ng-container *ngIf="data?.place_of_storage === 'other'">
                <tr>
                  <th>{{ 'CLAIM:PLACE_OF_STORAGE' | translate }}</th>
                  <td>{{ data?.place_of_storage_arbitrary }}</td>
                </tr>
              </ng-container>
              <div class="col-12">
                <div class="sub-title col-12">
                  <mat-icon>info</mat-icon>
                  <h5>
                    {{
                      (data?.participant_type === 'sender' ? 'CLAIM:INFO_ABOUT_SENDER' : 'CLAIM:INFO_ABOUT_RECIPIENT')
                        | translate
                    }}
                  </h5>
                </div>
              </div>
              <tr>
                <th>{{ 'CLAIM:ACCOUNT_NUMBER' | translate }}</th>
                <td>{{ data?.account_number }}</td>
              </tr>
              <tr>
                <th>{{ 'CLAIM:ACCOUNT_TYPE' | translate }}</th>
                <td>{{ data?.account_type | translate }}</td>
              </tr>
              <tr>
                <th>
                  {{
                    (data?.entity_type === 'legal_entity' ? 'CLAIM:LEGAL_ENTITY_NAME' : 'CLAIM:INDIVIDUAL_ENTITY_NAME')
                      | translate
                  }}
                </th>
                <td>{{ data?.entity_name }}</td>
              </tr>
              <ng-container *ngIf="data?.entity_type === 'legal_entity'">
                <tr>
                  <th>{{ 'CLAIM:OGRN' | translate }}</th>
                  <td>{{ data?.ogrn }}</td>
                </tr>
              </ng-container>
              <ng-container *ngIf="data?.entity_type === 'individual_entity'">
                <tr>
                  <th>{{ 'CLAIM:AUTHORIZED_DOCUMENT' | translate }}</th>
                  <td>{{ 'AUTHORIZED_DOCUMENT:' + data?.authorized_document | translate }}</td>
                </tr>
                <tr>
                  <th>{{ 'CLAIM:SERIES_AND_NUMBER' | translate }}</th>
                  <td>{{ data?.passport_number }}</td>
                </tr>
                <tr>
                  <th>{{ 'CLAIM:DATE_OF_ISSUE' | translate }}</th>
                  <td>{{ data?.date_of_issue + 'Z' | date : 'dd.MM.yyyy' }}</td>
                </tr>
              </ng-container>
              <tr>
                <th>{{ 'CLAIM:PLACE_OF_ISSUE' | translate }}</th>
                <td>{{ data?.place_of_issue }}</td>
              </tr>
              <tr>
                <th>{{ 'CLAIM:REGISTRATION_BY_ENTITY_NAME' | translate }}</th>
                <td>{{ data?.registered_by_entity_name }}</td>
              </tr>
              <tr>
                <th>{{ 'CLAIM:REGISTRAR' | translate }}</th>
                <td>{{ data?.registrar }}</td>
              </tr>
              <tr>
                <th>{{ 'CLAIM:IDENTIFIER' | translate }}</th>
                <td>{{ data?.identifier }}</td>
              </tr>
              <tr>
                <th>{{ 'CLAIM:DATE_OF_CALCULATION' | translate }}</th>
                <td>{{ data?.calculation_date + 'Z' | date : 'dd.MM.yyyy' }}</td>
              </tr>
              <tr>
                <th>{{ 'CLAIM:DEAL_DATE' | translate }}</th>
                <td>{{ data?.deal_date + 'Z' | date : 'dd.MM.yyyy' }}</td>
              </tr>
              <tr>
                <th>{{ 'CLAIM:REFERENCE' | translate }}</th>
                <td>{{ data?.reference }}</td>
              </tr>
            </ng-container>

            <!--TRANSACTION REFERENCE TYPE-->
            <ng-container *ngIf="data?.type === 'transaction_reference'">
              <tr>
                <th>{{ 'CLAIM:TRANSACTION_TYPE' | translate }}</th>
                <td>{{ data?.transaction_type | translate }}</td>
              </tr>
              <tr>
                <th>{{ 'CLAIM:OPERATION_INITIATOR' | translate }}</th>
                <td>{{ data?.operation_initiator | translate }}</td>
              </tr>
              <ng-container *ngIf="data?.place_of_storage !== 'other'">
                <tr>
                  <th>{{ 'CLAIM:PLACE_OF_STORAGE' | translate }}</th>
                  <td>{{ data?.place_of_storage | translate }}</td>
                </tr>
              </ng-container>
              <ng-container *ngIf="data?.place_of_storage === 'other'">
                <tr>
                  <th>{{ 'CLAIM:PLACE_OF_STORAGE' | translate }}</th>
                  <td>{{ data?.place_of_storage_arbitrary }}</td>
                </tr>
              </ng-container>
              <div class="col-12">
                <div class="sub-title col-12">
                  <mat-icon>info</mat-icon>
                  <h5>
                    {{
                      (data?.participant_type === 'sender' ? 'CLAIM:INFO_ABOUT_SENDER' : 'CLAIM:INFO_ABOUT_RECIPIENT')
                        | translate
                    }}
                  </h5>
                </div>
              </div>
              <tr>
                <th>{{ 'CLAIM:IDENTIFIER' | translate }}</th>
                <td>{{ data?.identifier }}</td>
              </tr>
              <tr>
                <th>{{ 'CLAIM:CONTRACTOR_NAME' | translate }}</th>
                <td>
                  {{ data?.contractor_name }} ({{
                    (data?.contractor_entity_type === 'legal_entity' ? 'LEGAL_ENTITY' : 'INDIVIDUAL_ENTITY')
                      | translate
                  }})
                </td>
              </tr>
              <tr>
                <th>{{ 'CLAIM:CONTRACTOR_DEPOSITARY' | translate }}</th>
                <td>{{ data?.contractor_depositary }}</td>
              </tr>
              <tr>
                <th>{{ 'CLAIM:ACCOUNT_NUMBER_IN_THE_CALCULATED_DEPOSITARY' | translate }}</th>
                <td>{{ data?.settlement_depo_account_number }}</td>
              </tr>
              <tr>
                <th>{{ 'CLAIM:CHAPTER_NUMBER_IN_THE_CALCULATED_DEPOSITARY' | translate }}</th>
                <td>{{ data?.settlement_depo_account_chapter }}</td>
              </tr>
              <tr>
                <th>{{ 'CLAIM:DATE_OF_CALCULATION' | translate }}</th>
                <td>{{ data?.calculation_date + 'Z' | date : 'dd.MM.yyyy' }}</td>
              </tr>
              <tr>
                <th>{{ 'CLAIM:DEAL_DATE' | translate }}</th>
                <td>{{ data?.deal_date + 'Z' | date : 'dd.MM.yyyy' }}</td>
              </tr>
            </ng-container>

            <!--TRANSFER REFERENCE TYPE-->
            <ng-container *ngIf="data?.type === 'transfer_reference'">
              <tr>
                <th>{{ 'CLAIM:TRANSFER_TYPE' | translate }}</th>
                <td>{{ data?.transfer_reference_type | translate }}</td>
              </tr>
              <tr>
                <th>{{ 'CLAIM:DATE_OF_CALCULATION' | translate }}</th>
                <td>{{ data?.calculation_date + 'Z' | date : 'dd.MM.yyyy' }}</td>
              </tr>
              <tr>
                <th>{{ 'CLAIM:DEAL_DATE' | translate }}</th>
                <td>{{ data?.deal_date + 'Z' | date : 'dd.MM.yyyy' }}</td>
              </tr>
              <ng-container *ngIf="data?.sender">
                <div class="col-12">
                  <div class="sub-title">
                    <mat-icon>info</mat-icon>
                    <h5>{{ 'CLAIM:INFO_ABOUT_SENDER' | translate }}</h5>
                  </div>
                </div>
                <tr>
                  <th>{{ 'CLAIM:DEPOSITOR_FULL_NAME' | translate }}</th>
                  <td>{{ data?.sender?.depositor_full_name }}</td>
                </tr>
                <tr>
                  <th>{{ 'CLAIM:DEPO_NUMBER' | translate }}</th>
                  <td>{{ data?.sender?.depositary_account }}</td>
                </tr>
                <tr>
                  <th>{{ 'CLAIM:DEPO_CHAPTER' | translate }}</th>
                  <td>{{ data?.sender?.depositary_chapter }}</td>
                </tr>
                <!-- <tr>
                  <th>{{ 'CLAIM:IS_INTERNAL_CLIENT' | translate }}</th>
                  <td>{{ (data?.sender?.is_external_client ? 'NO' : 'YES') | translate }}</td>
                </tr> -->
                <tr>
                  <th>{{ 'CLAIM:OPERATION_INITIATOR' | translate }}</th>
                  <td>{{ data?.sender?.operation_initiator | translate }}</td>
                </tr>
              </ng-container>
              <ng-container *ngIf="data?.recipient">
                <div class="col-12">
                  <div class="sub-title">
                    <mat-icon>info</mat-icon>
                    <h5>{{ 'CLAIM:INFO_ABOUT_RECIPIENT' | translate }}</h5>
                  </div>
                </div>
                <tr>
                  <th>{{ 'CLAIM:DEPOSITOR_FULL_NAME' | translate }}</th>
                  <td>{{ data?.recipient?.depositor_full_name }}</td>
                </tr>
                <tr>
                  <th>{{ 'CLAIM:DEPO_NUMBER' | translate }}</th>
                  <td>{{ data?.recipient?.depositary_account }}</td>
                </tr>
                <tr>
                  <th>{{ 'CLAIM:DEPO_CHAPTER' | translate }}</th>
                  <td>{{ data?.recipient?.depositary_chapter }}</td>
                </tr>
                <!-- <tr>
                  <th>{{ 'CLAIM:IS_INTERNAL_CLIENT' | translate }}</th>
                  <td>{{ (data?.recipient?.is_external_client ? 'NO' : 'YES') | translate }}</td>
                </tr> -->
                <tr>
                  <th>{{ 'CLAIM:OPERATION_INITIATOR' | translate }}</th>
                  <td>{{ data?.recipient?.operation_initiator | translate }}</td>
                </tr>
              </ng-container>
            </ng-container>

            <!--DEPOSITARY ACCOUNT REFERENCE TYPE-->
            <ng-container *ngIf="data?.type === 'depositary_account_reference'">
              <tr>
                <th>{{ 'CLAIM:OPERATION_INITIATOR' | translate }}</th>
                <td>{{ data?.operation_initiator | translate }}</td>
              </tr>
              <ng-container *ngIf="data?.depositary_statements">
                <div class="col-12">
                  <div class="sub-title">
                    <mat-icon>info</mat-icon>
                    <h5>{{ 'CLAIM:DEPOSITORY_STATEMENT' | translate }}</h5>
                  </div>
                </div>
                <ng-container *ngFor="let statement of data?.depositary_statements; let i = index">
                  <tr>
                    <th>{{ 'CLAIM:DEPOSITORY_STATEMENT_TYPE' | translate }}</th>
                    <td>{{ statement?.type | translate }}</td>
                  </tr>
                  <ng-container *ngIf="statement?.report_date">
                    <tr>
                      <th>{{ 'CLAIM:GET_DEPOSITORY_STATEMENT_BY_DATE' | translate }}</th>
                      <td>{{ statement?.report_date + 'Z' | date : 'dd.MM.yyy' }}</td>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="statement?.report_date_from && statement?.report_date_to">
                    <tr>
                      <th>{{ 'CLAIM:GET_DEPOSITORY_STATEMENT_BY_RANGE' | translate }}</th>
                      <td>
                        {{
                          'RANGE_FROM_TO'
                            | translate
                              : [
                                  statement?.report_date_from + 'Z' | date : 'dd.MM.yyy',
                                  statement?.report_date_to + 'Z' | date : 'dd.MM.yyy'
                                ]
                        }}
                      </td>
                    </tr>
                  </ng-container>
                  <mat-divider *ngIf="i !== data.depositary_statements.length - 1"></mat-divider>
                </ng-container>
              </ng-container>
              <div class="col-12">
                <div class="sub-title">
                  <mat-icon>info</mat-icon>
                  <h5>{{ 'REFERENCE_DETALIZATION' | translate }}</h5>
                </div>
              </div>
              <tr>
                <th>{{ 'CLAIM:ISSUER_REFERENCE' | translate }}</th>
                <td>{{ data?.issuer_statement ? (data?.issuer_statement | translate) : '-' }}</td>
              </tr>
              <tr>
                <th>{{ 'CLAIM:RELEASE_REFERENCE' | translate }}</th>
                <td>{{ data?.release_statement ? (data?.release_statement | translate) : '-' }}</td>
              </tr>
              <tr>
                <th>{{ 'CLAIM:ADDITIONAL_INFORMATION' | translate }}</th>
                <td>{{ data?.additional_terms ? (data?.additional_terms | translate) : '-' }}</td>
              </tr>
            </ng-container>

            <!--DEPOSITARY ACCOUNT INFO-->
            <ng-container *ngIf="data?.depositary_account">
              <div class="col-12">
                <div class="sub-title">
                  <mat-icon>info</mat-icon>
                  <h5>{{ 'CLAIM:DEPO_ACCOUNT' | translate }}</h5>
                </div>
              </div>
              <tr>
                <th>{{ 'CLAIM:DEPO_NUMBER' | translate }}</th>
                <td>{{ depositaryAccount?.account }}</td>
              </tr>
              <tr>
                <th>{{ 'CLAIM:DEPO_CHAPTER' | translate }}</th>
                <td>{{ depositaryAccount?.chapter }}</td>
              </tr>
            </ng-container>

            <!--SECURITIES INFO-->
            <ng-container *ngIf="data.claim_securities">
              <div class="col-12">
                <div class="sub-title">
                  <mat-icon>list_alt</mat-icon>
                  <h5>{{ 'CLAIM:INFO_ABOUT_DEFINITIONS' | translate }}</h5>
                </div>
              </div>
              <ng-container *ngFor="let claim_security of data.claim_securities; let i = index">
                <!-- <tr>
                  <th>{{ 'SECURITY:ISSUER' | translate }}</th>
                  <td>{{ claim_security.security?.issuer }}</td>
                </tr>
                <tr *ngIf="claim_security.security.instr_type">
                  <th>{{ 'SECURITY:TYPE' | translate }}</th>
                  <td>{{ claim_security.security?.instr_type | translate }}</td>
                </tr>
                <tr *ngIf="claim_security.security.instr_category">
                  <th>{{ 'SECURITY:CATEGORY' | translate }}</th>
                  <td>{{ claim_security.security?.instr_category | translate }}</td>
                </tr>
                <tr *ngIf="claim_security.security.state_reg_number">
                  <th>{{ 'SECURITY:REGISTRATION_NUMBER' | translate }}</th>
                  <td>{{ claim_security.security?.state_reg_number }}</td>
                </tr>
                <tr *ngIf="claim_security.security.issue_number">
                  <th>{{ 'SECURITY:ISSUE_NUMBER' | translate }}</th>
                  <td>{{ claim_security.security?.issue_number }}</td>
                </tr>
                <tr *ngIf="claim_security.security.isin">
                  <th>{{ 'SECURITY:ISIN_CODE' | translate }}</th>
                  <td>{{ claim_security.security?.isin }}</td>
                </tr>
                <tr *ngIf="claim_security.security.nominal_price">
                  <th>{{ 'SECURITY:NOMINAL_PRICE' | translate }}</th>
                  <td>{{ claim_security.security?.nominal_price }}</td>
                </tr>
                <tr *ngIf="claim_security.security.nominal_price_currency">
                  <th>{{ 'SECURITY:NOMINAL_PRICE_CURRENCY' | translate }}</th>
                  <td>{{ claim_security.security?.nominal_price_currency }}</td>
                </tr> -->
                <tr *ngIf="claim_security.quik_type">
                  <th>{{ 'SECURITY:QUIK_TYPE' | translate }}</th>
                  <td>{{ 'CLAIM_QUIK_TYPE:' + claim_security?.quik_type | translate }}</td>
                </tr>
                <tr *ngIf="claim_security.quantity">
                  <th>{{ 'SECURITY:AMOUNT' | translate }}</th>
                  <td>{{ claim_security.quantity }}</td>
                </tr>
                <tr *ngIf="claim_security.deal_amount">
                  <th>{{ 'SECURITY:DEAL_AMOUNT' | translate }}</th>
                  <td>{{ claim_security.deal_amount }}</td>
                </tr>

                <tr *ngIf="claim_security.deal_type">
                  <th>{{ 'SECURITY:TRADE_TYPE' | translate }}</th>
                  <td>{{ claim_security.deal_type | translate }}</td>
                </tr>
                <tr *ngIf="claim_security.deal_price_type">
                  <th>{{ 'SECURITY:PRICE_TRADE_TYPE' | translate }}</th>
                  <td>{{ 'CLAIM_DEAL_PRICE_TYPE:' + claim_security.deal_price_type | translate }}</td>
                </tr>
                <tr *ngIf="claim_security.max_security_price">
                  <th>{{ 'SECURITY:PRICE_TRESHOLD' | translate }}</th>
                  <td>{{ claim_security.max_security_price }}</td>
                </tr>
                <tr *ngIf="claim_security.fixed_security_price">
                  <th>{{ 'SECURITY:TRADE_PRICE' | translate }}</th>
                  <td>{{ claim_security.fixed_security_price }}</td>
                </tr>
                <tr *ngIf="claim_security.exchange">
                  <th>{{ 'SECURITY:EXECUTION_PLACE' | translate }}</th>
                  <td>{{ getExchangeName(claim_security.exchange) }}</td>
                </tr>
                <tr *ngIf="claim_security.exchange_custom">
                  <th>{{ 'SECURITY:EXECUTION_PLACE' | translate }}</th>
                  <td>{{ claim_security.exchange_custom }}</td>
                </tr>
                <tr *ngIf="claim_security.counterparty">
                  <th>{{ 'SECURITY:CONTRACTOR' | translate }}</th>
                  <td>{{ claim_security.counterparty }}</td>
                </tr>
                <tr *ngIf="claim_security.security_obligations_info">
                  <th>{{ 'SECURITY:INFO_ABOUT_ENCUMBRANCE' | translate }}</th>
                  <td>{{ claim_security.security_obligations_info }}</td>
                </tr>
                <tr *ngIf="claim_security.price_currency">
                  <th>{{ 'SECURITY:CURRENCY' | translate }}</th>
                  <td>{{ claim_security.price_currency }}</td>
                </tr>
                <tr *ngIf="claim_security.repo_rate">
                  <th>{{ 'SECURITY:REPO_RATE' | translate }}</th>
                  <td>{{ claim_security.repo_rate + 'Z' | date : 'dd.MM.yyyy' }}</td>
                </tr>
                <tr *ngIf="claim_security.pair_deal_amount">
                  <th>{{ 'SECURITY:PAIR_TRADE_AMOUNT' | translate }}</th>
                  <td>{{ claim_security.pair_deal_amount }}</td>
                </tr>
                <tr *ngIf="claim_security.repo_deal_term">
                  <th>{{ 'SECURITY:REPO_DEAL_TERM' | translate }}</th>
                  <td>{{ claim_security.repo_deal_term }}</td>
                </tr>
                <tr *ngIf="claim_security.additional_info">
                  <th>{{ 'SECURITY:INFORMATION' | translate }}</th>
                  <td>{{ claim_security.additional_info }}</td>
                </tr>
                <mat-divider *ngIf="i !== data.claim_securities.length - 1"></mat-divider>
              </ng-container>
            </ng-container>

            <!--CLAIM REASONS INFO-->
            <ng-container *ngIf="data.reasons">
              <div class="col-12">
                <div class="sub-title">
                  <mat-icon>list_alt</mat-icon>
                  <h5>{{ 'CLAIM:REASON' | translate }}</h5>
                </div>
              </div>
              <ng-container *ngFor="let reason of data.reasons; let i = index">
                <tr>
                  <th>{{ 'CLAIM:REASON' | translate }}</th>
                  <td>{{ reason.type | translate }}</td>
                </tr>
                <ng-container *ngIf="reason.type === 'other_agreement'">
                  <tr>
                    <th>{{ 'CLAIM:AGREEMENT_NAME' | translate }}</th>
                    <td>{{ reason.contract_name }}</td>
                  </tr>
                </ng-container>
                <tr>
                  <th>{{ 'CLAIM:AGREEMENT_NUMBER' | translate }}</th>
                  <td>{{ reason.contract_number }}</td>
                </tr>
                <tr>
                  <th>{{ 'CLAIM:AGREEMENT_NAME' | translate }}</th>
                  <td>{{ reason.contract_date + 'Z' | date : 'dd.MM.yyyy' }}</td>
                </tr>
                <ng-container *ngIf="['agreement_of_sale', 'repo_agreement'].includes(reason.type)">
                  <tr>
                    <th>{{ 'SECURITY:DEAL_AMOUNT' | translate }}</th>
                    <td>{{ reason?.deal_amount }}</td>
                  </tr>
                </ng-container>
                <mat-divider *ngIf="i !== data.reasons.length - 1"></mat-divider>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>

    <!--BANK ACCOUNT INFO-->
    <ng-container *ngIf="data.bank_account">
      <div class="col-12">
        <div class="sub-title">
          <mat-icon>list_alt</mat-icon>
          <h5>{{ 'CLAIM_BANK_REQUISITED' | translate }}</h5>
        </div>
        <div class="table-responsive">
          <table class="table borderless">
            <tbody>
              <tr>
                <th>{{ 'BANK:COUNTRY_CODE' | translate }}</th>
                <td>{{ bankAccount?.bank?.country | translate }}</td>
              </tr>
              <tr>
                <th>{{ 'BANK:CURRENCY_CODE' | translate }}</th>
                <td>{{ bankAccount?.currency_code }}</td>
              </tr>
              <!-- <tr>
              <th>Название счета</th>
              <td></td>
            </tr> -->
              <tr>
                <th>{{ 'BANK:BIC' | translate }}</th>
                <td>{{ bankAccount?.bank?.bic }}</td>
              </tr>
              <tr>
                <th>{{ 'BANK:NAME' | translate }}</th>
                <td>{{ bankAccount?.bank?.name }}</td>
              </tr>
              <tr>
                <th>{{ 'BANK:CORRESPONDENT_ACCOUNT' | translate }}</th>
                <td>{{ bankAccount?.bank?.correspondent_account }}</td>
              </tr>
              <tr>
                <th>{{ 'BANK:CHECKING_ACCOUNT' | translate }}</th>
                <td>{{ bankAccount?.checking_account }}</td>
              </tr>
              <tr>
                <th>{{ 'BANK:ADDRESS' | translate }}</th>
                <td>{{ bankAccount?.bank?.address }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </ng-container>

    <!--CURRENCY INTRUMENTS TRADE TYPE-->
    <ng-container *ngIf="data?.type === 'currency_instruments_trade'">
      <div class="col-12">
        <div class="sub-title">
          <mat-icon>info_outline</mat-icon>
          <h5>{{ 'CLAIM_DETAILS' | translate }}</h5>
        </div>
        <div class="table-responsive">
          <table class="table borderless">
            <tbody>
              <tr>
                <th>{{ 'CLAIM:TRADE_TYPE' | translate }}</th>
                <td>{{ data?.trade_type | translate }}</td>
              </tr>
              <tr>
                <th>{{ 'CLAIM:OUTCOME_CURRENCY' | translate }}</th>
                <td>{{ data?.outcome_currency }}</td>
              </tr>
              <tr>
                <th>{{ 'CLAIM:RATE_TYPE' | translate }}</th>
                <td>{{ data?.exchange_rate_type | translate }}</td>
              </tr>
              <tr>
                <th>{{ 'CLAIM:INCOME_AMOUNT' | translate }}</th>
                <td>{{ data?.income_amount }}</td>
              </tr>
              <tr>
                <th>{{ 'CLAIM:EXCHANGE_RATE' | translate }}</th>
                <td>{{ data?.exchange_rate ? data.exchange_rate : '-' }}</td>
              </tr>
              <tr>
                <th>{{ 'CLAIM:INCOME_CURRENCY' | translate }}</th>
                <td>{{ data?.income_currency }}</td>
              </tr>
              <tr>
                <th>{{ 'CLAIM:ADDITIONAL_CONDITION' | translate }}</th>
                <td>{{ data?.additional_terms ? data?.additional_terms : '-' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </ng-container>

    <!--EDIT PROFILE TYPE-->
    <ng-container *ngIf="data?.type === 'edit_profile'">
      <div class="changed-data">
        <ng-container *ngFor="let data of profileService.diffData">
          <div class="diff">
            <div *ngIf="data.objectName" class="diff__caption">
              <h4>{{ 'PROFILE:' + data.objectName | uppercase | translate }}</h4>
            </div>
            <div class="diff__title">
              {{ 'FIELD:' + data.fieldName | uppercase | translate }}
            </div>
            <div class="diff__content">
              {{ data.current | translate }} {{ data?.current_name_value ? '(' + data?.current_name_value + ')' : '' }}
              <mat-icon class="arrow-right">arrow_right_alt</mat-icon>
              <mat-icon class="arrow-downward">arrow_downward</mat-icon>
              {{ data.updated | translate }} {{ data?.updated_name_value ? '(' + data?.updated_name_value + ')' : '' }}
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="profileService?.deletedPassportScansDiffData.length > 0">
          <div class="diff">
            <div class="diff__caption">
              <h4>{{ 'PASSPORT:DELETED_SCANS' | translate }}</h4>
            </div>
            <ng-container *ngFor="let attachment of profileService.deletedPassportScansDiffData">
              <div class="row attachments">
                <div class="attachment col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                  <ng-container *ngIf="attachment.isImage; else document">
                    <img [src]="attachment.url" [alt]="attachment.file_name" (click)="open(attachment.url)" />
                  </ng-container>
                  <ng-template #document>
                    <div class="document">
                      <img src="./assets/images/document.svg" alt="logo" />
                      <p>{{ attachment.file_name }}</p>
                      <div class="actions">
                        <div class="open">
                          <a [href]="attachment.url" target="_blank">
                            <div>
                              <mat-icon>visibility</mat-icon>
                            </div>
                            {{ 'OPEN' | translate }}
                          </a>
                        </div>
                        <div class="download">
                          <a [href]="attachment.url" [download]="attachment.file_name" target="_blank">
                            <div>
                              <mat-icon>download</mat-icon>
                            </div>
                            {{ 'DOWNLOAD' | translate }}
                          </a>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="profileService?.uploadedPassportScansDiffData.length > 0">
          <div class="diff">
            <div class="diff__caption">
              <h4>{{ 'PASSPORT:UPLOADED_SCANS' | translate }}</h4>
            </div>
            <ng-container *ngFor="let attachment of profileService.uploadedPassportScansDiffData">
              <div class="row attachments">
                <div class="attachment col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                  <ng-container *ngIf="attachment.isImage; else document">
                    <img [src]="attachment.url" [alt]="attachment.file_name" (click)="open(attachment.url)" />
                  </ng-container>
                  <ng-template #document>
                    <div class="document">
                      <img src="./assets/images/document.svg" alt="logo" />
                      <p>{{ attachment.file_name }}</p>
                      <div class="actions">
                        <div class="open">
                          <a [href]="attachment.url" target="_blank">
                            <div>
                              <mat-icon>visibility</mat-icon>
                            </div>
                            {{ 'OPEN' | translate }}
                          </a>
                        </div>
                        <div class="download">
                          <a [href]="attachment.url" [download]="attachment.file_name" target="_blank">
                            <div>
                              <mat-icon>download</mat-icon>
                            </div>
                            {{ 'DOWNLOAD' | translate }}
                          </a>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>
