<div class="container-fluid bg-white p-4" [@open]="'opened'">
  <h1>{{ 'CLAIM:FILLING' | translate }}</h1>
  <div class="row">
    <ui-step-view
      class="col-12 mt-3"
      [total]="5"
      [current]="bs.brokerageServiceStepKey + 1"
      [stepValues]="[
        'CLAIM_STEP:BROKERAGE_AGREEMENT',
        'CLAIM_STEP:REQUISITES',
        'CLAIM_STEP:CONDITION_OF_ACCEPTATE',
        'CLAIM_STEP:ADDITIONALLY',
        'CLAIM_STEP:SIGN'
      ]">
    </ui-step-view>
    <div class="order-container">
      <div class="form-data">
        <ng-container *ngIf="bs.brokerageServiceStepKey === 0">
          <div class="order-title">
            <h2>{{ 'BS:TITLE' | translate }}</h2>
            <p>{{ 'BS:SUBTITLE' | translate }}</p>
            <mat-checkbox [formControl]="formDepoContract" color="primary">{{
              'BS:FORM_DEPO' | translate
            }}</mat-checkbox>
          </div>

          <h4>{{ 'BS:CHOOSE_TARIFF' | translate }}</h4>
          <div class="tariffs col-12">
            <ng-container *ngFor="let tariff of tariffs">
              <div class="tariff col-12">
                <div class="tariff__logo">
                  <img [src]="'./assets/logos/'+tariff+'.svg'" alt="logo" />
                </div>
                <div class="tariff__description">
                  <span>{{ 'TARIFF:' + tariff | translate }}</span>
                </div>
                <div class="tariff__footer">
                  <button mat-button class="filled" type="button" (click)="chooseTariff(tariff)">
                    {{ 'CHOOSE' | translate }}
                  </button>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="bs.brokerageServiceStepKey === 1">
          <ng-container *ngIf="bankAccounts.length === 0; else bankAccountForm">
            <ai-ui-bank-base [emitEvent]="true" (afterAddBankAccount)="setBankAccountId($event)"></ai-ui-bank-base>
          </ng-container>
          <ng-template #bankAccountForm>
            <div class="bank-container">
              <div class="bank-info-title">
                <h3>{{ 'BANK_INFO:TITLE' | translate }}</h3>
                <div class="hint">
                  <p>{{ 'BANK_INFO:HINT' | translate }}</p>
                </div>
              </div>
              <div class="add-action">
                <button mat-button type="button" (click)="onAddBankAccount()">
                  <mat-icon>add</mat-icon>
                  {{ 'ADD_BANK_ACCOUNT' | translate }}
                </button>
              </div>
            </div>
            <mat-radio-group [formControl]="bank_account">
              <ng-container *ngFor="let bankAccount of bankAccounts">
                <div class="edit-action text-align-right">
                  <button mat-button type="button" (click)="onEditBankAccount(bankAccount.id)">
                    <mat-icon>edit</mat-icon>
                    {{ 'CHANGE' | translate }}
                  </button>
                </div>
                <div class="bank-card">
                  <mat-radio-button class="bank-card-radio-button" color="primary" [value]="bankAccount.id">
                  </mat-radio-button>
                  <ai-ui-bank-account-card [bankAccount]="bankAccount"></ai-ui-bank-account-card>
                </div>
              </ng-container>
            </mat-radio-group>
          </ng-template>
          <div class="d-flex align-items-center justify-content-between">
            <button class="back" (click)="back()" mat-button type="button">
              {{ 'BACK' | translate }}
            </button>
            <ng-container *ngIf="bankAccounts.length !== 0">
              <button
                *ngIf="bankAccounts.length !== 0"
                [disabled]="!bank_account.value"
                mat-button
                class="filled"
                type="button"
                (click)="next()">
                {{ 'NEXT' | translate }}
              </button>
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="bs.brokerageServiceStepKey === 2">
          <div class="actions">
            <div class="order-content">
              <p>{{ 'BS:CONDITION_OF_ACCEPTATE' | translate }}</p>
              <mat-radio-group
                [formControl]="broker_can_use_client_money"
                (change)="onChangeBrokerCanUseClientMoney($event)">
                <mat-radio-button color="primary" [value]="true">
                  {{ 'BS:CONDITION_OF_ACCEPTATE_AGREE' | translate }}
                </mat-radio-button>
                <mat-radio-button color="primary" [value]="false">
                  {{ 'BS:CONDITION_OF_ACCEPTATE_DISAGREE' | translate }}
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <ng-container *ngIf="formDepoContract.value">
              <div class="order-content col-12">
                <p>{{ 'BS:INCOME_EARNING_OPTION' | translate }}</p>
                <mat-radio-group [formControl]="income_earning_option" (change)="onChangeIncomeEarningOption($event)">
                  <mat-radio-button color="primary" [value]="'bank_account'">
                    {{ 'BS:INCOME_EARNING_OPTION_BANK_ACCOUNT' | translate }}
                  </mat-radio-button>
                  <mat-radio-button color="primary" [value]="'brokerage_account'">
                    {{ 'BS:INCOME_EARNING_OPTION_BROKERAGE_ACCOUNT' | translate }}
                  </mat-radio-button>
                </mat-radio-group>
              </div>
            </ng-container>
            <div class="order-content">
              <mat-checkbox [formControl]="refusal_of_transactions_with_incomplete_coverage" color="primary">{{
                'BS:REFUSAL_OF_TRANSACTIONS_WITH_INCOMPLETE_COVERAGE' | translate
              }}</mat-checkbox>
            </div>
            <div class="order-content">
              <mat-checkbox [formControl]="trade_on_spb_stock" color="primary">{{
                'BS:TRADE_ON_SPB_STOCK' | translate
              }}</mat-checkbox>
              <div class="hint mt-1">
                <p>{{ 'BS:TRADE_ON_SPB_STOCK_TIP' | translate }}</p>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <button class="back" (click)="back()" mat-button type="button">
                {{ 'BACK' | translate }}
              </button>
              <button mat-button class="filled" type="button" (click)="next()">
                {{ 'NEXT' | translate }}
              </button>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="bs.brokerageServiceStepKey === 3">
          <form [formGroup]="additionalForm">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'BS:TAX_BENEFITS_PRESENCE' | translate }}</mat-label>
              <input matInput formControlName="tax_benefits_presence" />
              <mat-error *ngIf="additionalForm.get('tax_benefits_presence').errors?.required">
                {{ 'ERROR:REQUIRED' | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>{{ 'BS:BUSINESS_RELATIONSHIP_PURPOSES' | translate }}</mat-label>
              <mat-select
                formControlName="business_relationship_purposes_list"
                multiple
                (selectionChange)="onChangeRelationshipPurpose($event)">
                <mat-option *ngFor="let purpose of relationshipPurposes" [value]="purpose">
                  {{ 'RELATIONSHIP_PURPOSE:' + purpose | translate }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="additionalForm.get('business_relationship_purposes_list').errors?.required">
                {{ 'ERROR:REQUIRED' | translate }}
              </mat-error>
            </mat-form-field>
            <ng-container *ngIf="additionalForm.get('business_relationship_purposes_list')?.value?.includes('other')">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'BS:BUSINESS_RELATIONSHIP_PURPOSE_ARBITRARY' | translate }}</mat-label>
                <input matInput formControlName="business_relationship_purpose_arbitrary" />
              </mat-form-field>
            </ng-container>
            <mat-form-field appearance="outline">
              <mat-label>{{ 'BS:BUSINESS_RELATIONSHIP_CHARACTER' | translate }}</mat-label>
              <mat-select formControlName="business_relationship_character">
                <mat-option *ngFor="let character of relationshipCharacters" [value]="character">
                  {{ 'RELATIONSHIP_CHARACTER:' + character | translate }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="additionalForm.get('business_relationship_character').errors?.required">
                {{ 'ERROR:REQUIRED' | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>{{ 'BS:FINANCIAL_AND_ECONOMIC_TRANSACTION_PURPOSES' | translate }}</mat-label>
              <mat-select
                formControlName="financial_and_economic_transaction_purposes_list"
                multiple
                (selectionChange)="onEconomicTransactionChange($event)">
                <mat-option *ngFor="let transaction of economicTransactions" [value]="transaction">
                  {{ 'ECONOMIC_TRANSACTION:' + transaction | translate }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="additionalForm.get('financial_and_economic_transaction_purposes_list').errors?.required">
                {{ 'ERROR:REQUIRED' | translate }}
              </mat-error>
            </mat-form-field>
            <ng-container
              *ngIf="additionalForm.get('financial_and_economic_transaction_purposes_list')?.value?.includes('other')">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'BS:FINANCIAL_AND_ECONOMIC_TRANSACTION_PURPOSE_ARBITRARY' | translate }}</mat-label>
                <input matInput formControlName="financial_and_economic_transaction_purpose_arbitrary" />
              </mat-form-field>
            </ng-container>
            <mat-form-field appearance="outline">
              <mat-label>{{ 'BS:FINANCIAL_POSITION' | translate }}</mat-label>
              <mat-select formControlName="financial_position">
                <mat-option *ngFor="let position of financialPositions" [value]="position">
                  {{ 'FINANCIAL_POSITION:' + position | translate }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="additionalForm.get('financial_position').errors?.required">
                {{ 'ERROR:REQUIRED' | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>{{ 'BS:INFO_ABOUT_BUSINESS_REPUTATION' | translate }}</mat-label>
              <input matInput formControlName="info_about_business_reputation" />
              <mat-error *ngIf="additionalForm.get('info_about_business_reputation').errors?.required">
                {{ 'ERROR:REQUIRED' | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>{{ 'BS:ORIGIN_FUNDS_SOURCES' | translate }}</mat-label>
              <mat-select
                formControlName="origin_funds_sources_list"
                multiple
                (selectionChange)="onOriginFundsSourceChange($event)">
                <mat-option *ngFor="let source of sources" [value]="source">
                  {{ 'ORIGIN_FUNDS_SOURCES:' + source | translate }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="additionalForm.get('origin_funds_sources_list').errors?.required">
                {{ 'ERROR:REQUIRED' | translate }}
              </mat-error>
            </mat-form-field>
            <ng-container *ngIf="additionalForm.get('origin_funds_sources_list')?.value?.includes('other')">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'BS:ORIGIN_FUNDS_SOURCE_ARBITRARY' | translate }}</mat-label>
                <input matInput formControlName="origin_funds_sources_arbitrary" />
              </mat-form-field>
            </ng-container>
            <div class="d-flex align-items-center justify-content-between">
              <button class="back" (click)="back()" mat-button type="button">
                {{ 'BACK' | translate }}
              </button>
              <button mat-button [disabled]="additionalForm.invalid" class="filled" type="button" (click)="next()">
                {{ 'NEXT' | translate }}
              </button>
            </div>
          </form>
        </ng-container>
        <ng-container *ngIf="bs.brokerageServiceStepKey === 4">
          <div class="col-12">
            <ai-ui-documents
              [claimTypeName]="'broker_agreement' | translate"
              [attachments]="attachmentsToSign"
              [isCreatingOrder]="true"
              [tradeOnSpbStock]="trade_on_spb_stock.value === true"></ai-ui-documents>
          </div>
          <div class="col-10 confirmation">
            <mat-checkbox [formControl]="theMainConfirmation" color="primary">
              {{ 'BS:CONFIRMATION_2' | translate }}
              <a [href]="mainInfoLink" target="_blank" class="risk-link">{{ 'BS:MAIN_CONFIRMATION' | translate }}</a>
            </mat-checkbox>
            <mat-checkbox [formControl]="theFirstConfirmation" color="primary">{{
              'BS:CONFIRMATION_1' | translate
            }}</mat-checkbox>
            <mat-checkbox [formControl]="theSecondConfirmation" color="primary">
              {{ 'BS:CONFIRMATION_2' | translate }}
              <a [href]="riskStatementLink" target="_blank" class="risk-link">{{
                'BS:CONFIRMATION_2_1' | translate
              }}</a>
            </mat-checkbox>
            <mat-checkbox [formControl]="theThirdConfirmation" color="primary">
              {{ 'BS:CONFIRMATION_2' | translate }}
              <a [href]="riskNoticeLink" target="_blank" class="risk-link">{{ 'BS:CONFIRMATION_2_2' | translate }}</a>
            </mat-checkbox>
            <mat-checkbox [formControl]="theFourthConfirmation" color="primary">
              {{ 'BS:CONFIRMATION_4' | translate }}
              <a href="https://www.aigenis.ru/info12" target="_blank" class="risk-link">{{
                'BS:CONFIRMATION_4_1' | translate
              }}</a>
              {{ 'BS:CONFIRMATION_4_2' | translate }}
            </mat-checkbox>
            <h4>{{ 'CLAIM_SIGNATURE_TITLE' | translate }}</h4>
            <div class="hint">
              <p>{{ 'CLAIM_SIGNATURE_INFORMATION' | translate }}</p>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <button class="back" (click)="back()" mat-button type="button">
              {{ 'BACK' | translate }}
            </button>
            <button
              [disabled]="
                !theMainConfirmation.value ||
                !theFirstConfirmation.value ||
                !theSecondConfirmation.value ||
                !theThirdConfirmation.value ||
                !theFourthConfirmation.value
              "
              mat-button
              class="filled"
              type="button"
              (click)="sendToSign()">
              {{ 'SIGN' | translate }}
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
