import { Component, OnDestroy, OnInit } from '@angular/core';
import { ClaimBaseComponent } from '../claim-base/claim-base.component';
import { animationOptions } from 'src/app/utils/animation';
import { forkJoin, mergeMap } from 'rxjs';
import { Attachment, AttachmentBase } from 'src/app/interfaces/api/attachment.interface';
import { apiUrlWithPrefix } from '@env';
import { OtherType } from 'src/app/utils/utils';
import { ClaimsBatchResponse } from 'src/app/interfaces/api/claims.interface';
import { AiUiImageDialogComponent } from 'src/app/shared/shared-components/ai-ui-image-dialog/ai-ui-image-dialog.component';

@Component({
  selector: 'app-change-personal-data',
  templateUrl: './change-personal-data.component.html',
  styleUrls: ['./change-personal-data.component.scss'],
  animations: animationOptions,
})
export class ChangePersonalDataComponent extends ClaimBaseComponent implements OnInit, OnDestroy {
  passportScans: AttachmentBase[];

  scans: Attachment[] = [];

  attachmentEditUrl = (id: number): string => `${apiUrlWithPrefix}/user/profile-edit/passport-scan/${id}/preview/`;

  ngOnInit(): void {
    this.profileService.diffData = [];
    this.profileService.deletedPassportScansDiffData = [];
    this.profileService.uploadedPassportScansDiffData = [];
    this.loader.showLoader();
    this.profileService.getDiff().subscribe({
      next: (data) => {
        this.profileService.getObjectData(data);
        this.loader.hideLoader();
      },
      error: () => {
        this.loader.hideLoader();
      },
    });
  }

  private getUploadedAttachments(): void {
    this.loader.showLoader();
    this.profileService
      .getPassportScans()
      .pipe(
        mergeMap((res) => {
          this.passportScans = res.results;
          const payload = res.results.map((att) => this.profileService.fileToBlob(this.attachmentEditUrl(att.id)));
          return forkJoin(payload);
        })
      )
      .subscribe({
        next: (data) => {
          this.scans = this.passportScans.map((scan, index) => ({
            id: scan.id,
            file_name: scan.file_name,
            url: data[index],
            isImage: ['jpeg', 'jpg', 'png']?.indexOf(scan.file_name?.split('.')?.pop()?.toLowerCase()) !== -1,
          }));
          this.cd.markForCheck();
          this.loader.hideLoader();
        },
        error: () => {
          this.loader.hideLoader();
        },
      });
  }

  next() {
    this.claimsService
      .addClaimsBatch([
        {
          type: OtherType.edit_profile,
        },
      ])
      .pipe(
        mergeMap((res: ClaimsBatchResponse) => {
          this.claimBatchId = res.id;
          return this.documentService.claimsBatchDocumentsProcessing(res.id);
        })
      )
      .subscribe({
        next: (data) => {
          this.attachmentsToSign = data;
          this.cfService.goToStep(this.cfService.stepKey + 1);
          this.loader.hideLoader();
          this.cd.markForCheck();
        },
        error: () => {
          this.loader.hideLoader();
        },
      });
  }

  open(url: string) {
    this.dialog.open(AiUiImageDialogComponent, {
      panelClass: 'attachment-container',
      data: {
        url,
      },
    });
  }

  rollback() {
    this.loader.showLoader();
    this.profileService.rollBackChanges().subscribe({
      next: () => {
        this.cfService.goToStep(0);
        this.router.navigate(['profile', 'edit']);
        this.loader.hideLoader();
      },
      error: () => {
        this.loader.hideLoader();
      },
    });
  }

  customSendToSign = () => this.claimsService.sendToSign(this.claimBatchId, true);
}
