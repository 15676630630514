import { AfterContentChecked, Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSelectChange } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { ClaimSecurity, ClaimsBatchResponse, SecuritiesTrade } from 'src/app/interfaces/api/claims.interface';
import { DepositaryAccountResponse } from 'src/app/interfaces/api/depositary-account.interface';
import { ExchangeResponse } from 'src/app/interfaces/api/exchange.interface';
import { animationOptions } from 'src/app/utils/animation';
import { AreaType, BsType, ClaimTradeType, DealPriceType, QuikType } from 'src/app/utils/utils';
import { ClaimBaseComponent } from '../claim-base/claim-base.component';
import { filter } from 'd3';
import { mergeMap } from 'rxjs';

@Component({
  selector: 'app-bo-nrd-papers-deal-new',
  templateUrl: './bo-nrd-papers-deal-new.component.html',
  styleUrls: ['./bo-nrd-papers-deal-new.component.scss'],
  animations: animationOptions,
})
export class BoNrdPapersDealNewComponent extends ClaimBaseComponent implements OnInit, OnDestroy {
  areas = Array.from(new Set(Object.keys(AreaType)));

  dealTypes = Array.from(new Set(Object.keys(ClaimTradeType)));

  dealPriceTypes = Array.from(new Set(Object.keys(DealPriceType)));

  theFirstConfirmation: FormControl = new FormControl(true, [Validators.required]);

  securityOrders: ClaimSecurity[] = [];

  displayedColumns: string[] = ['ISSUER', 'TYPE', 'TRADE_TYPE', 'AMOUNT', 'CURRENCY', 'PRICE', 'REMOVE_ACTION'];

  dataSource = new MatTableDataSource<ClaimSecurity>([]);

  exchanges: ExchangeResponse[] = [];

  mainDepositaryAccount: DepositaryAccountResponse = null;

  exchangeId: number = 0;

  ngOnInit(): void {
    this.form = this.initSecurityForm();
    this.getMainDepoAccounts();
    this.getExchanges();
  }

  getMainDepoAccounts() {
    this.loader.showLoader();
    this.depositaryAccountService.depoAccountsByAgreement(this.auth.depositaryService$.value.id).subscribe({
      next: (data) => {
        this.mainDepositaryAccount = data?.filter((n) => n.type === 'main')[0];
        this.form.patchValue({
          depositary_account: this.mainDepositaryAccount?.id,
        });
        this.loader.hideLoader();
      },
      error: () => {
        this.loader.hideLoader();
      },
    });
  }

  getExchanges() {
    this.exchanges = this.exchangeService.exchanges$?.value?.filter((e) => e.code === AreaType.offe);
  }

  initSecurityForm() {
    return new FormGroup({
      issuer: new FormControl(null, Validators.required),
      issuerName: new FormControl({ value: null, disabled: true }),
      securityName: new FormControl({ value: null, disabled: true }),
      instr_type: new FormControl(null, Validators.required),
      instr_category: new FormControl(null),
      state_reg_number: new FormControl({ value: null, disabled: true }),
      issue_number: new FormControl({ value: null, disabled: true }),
      isin: new FormControl(null, Validators.required),
      code_nsd: new FormControl(null),
      quantity: new FormControl(null, Validators.required),
      security: new FormControl({ value: null, disabled: true }, Validators.required),
      quik_type: new FormControl(QuikType.limit, Validators.required),
      nominal_price: new FormControl({ value: null, disabled: true }),
      nominal_price_currency: new FormControl({ value: null, disabled: true }),
      deal_amount: new FormControl({ value: null, disabled: true }, Validators.required),
      depositary_account: new FormControl(null, Validators.required),
      deal_type: new FormControl(null, Validators.required),
      deal_price_type: new FormControl(null, Validators.required),
      max_security_price: new FormControl(null),
      fixed_security_price: new FormControl(null),
      exchange: new FormControl(null, Validators.required),
      exchange_custom: new FormControl(null),
      counterparty: new FormControl(null),
      security_obligations_info: new FormControl(null),
      price_currency: new FormControl(null, Validators.required),
      calculation_currency: new FormControl(null, Validators.required),
      is_repo: new FormControl(false),
      repo_rate: new FormControl(null),
      pair_deal_amount: new FormControl(null),
      repo_deal_term: new FormControl(null),
      additional_info: new FormControl(null),
    });
  }

  onTradeTypeChanged() {
    this.form.patchValue({
      price_deal_type: null,
      max_security_price: null,
      nominal_price: null,
      quantity: null,
    });
  }

  onShowRepoToggle(event: MatCheckboxChange, form: FormGroup) {
    if (!event.checked) {
      form.patchValue({
        repo_rate: null,
        pair_deal_amount: null,
        repo_deal_term: null,
      });
    }
  }

  onPriceDealTypeChanged(event: MatSelectChange) {
    if (event.value === DealPriceType.not_greater) {
      this.form.patchValue({
        fixed_security_price: null,
        nominal_price: null,
        quik_type: QuikType.limit,
      });
      this.form.get('max_security_price').setValidators(Validators.required);
      this.form.get('fixed_security_price').clearValidators();
      this.form.get('nominal_price').clearValidators();
    } else if (event.value === DealPriceType.fixed) {
      this.form.patchValue({
        max_security_price: null,
        nominal_price: null,
        quik_type: QuikType.limit,
      });
      this.form.get('fixed_security_price').setValidators(Validators.required);
      this.form.get('max_security_price').clearValidators();
      this.form.get('nominal_price').clearValidators();
    } else {
      this.form.patchValue({
        fixed_security_price: null,
        max_security_price: null,
        quik_type: QuikType.market,
      });
      this.form.get('nominal_price').setValidators(Validators.required);
      this.form.get('max_security_price').clearValidators();
      this.form.get('fixed_security_price').clearValidators();
    }
    this.form.patchValue({
      quantity: null,
    });
    this.form.get('fixed_security_price').updateValueAndValidity({ emitEvent: false });
    this.form.get('max_security_price').updateValueAndValidity({ emitEvent: false });
    this.form.get('nominal_price').updateValueAndValidity({ emitEvent: false });
    this.cd.markForCheck();
  }

  onQuantityKeyDown(event: any) {
    if (event.key === '.') {
      event.preventDefault();
    }
  }

  onExecutionPlaceChanged() {
    this.resetData(this.form, false);
  }

  addSecurity() {
    if (this.form.invalid) {
      return;
    }
    this.securityOrders.push(this.form.getRawValue());
    this.securityOrders.forEach((s) => {
      if (s.exchange_custom) {
        s.exchange = null;
      }
    });
    this.dataSource.data = this.securityOrders;
    this.form.reset();
    this.cd.markForCheck();
  }

  removeSecurity(item: ClaimSecurity) {
    this.securityOrders = this.securityOrders.filter((o) => o !== item);
    this.dataSource.data = this.securityOrders;
    this.cd.markForCheck();
  }

  next() {
    if (!this.securityOrders) {
      return;
    }
    const payload: SecuritiesTrade = {
      additional_terms: null,
      type: BsType.securities_trade,
      claim_securities: this.securityOrders,
    };
    this.loader.showLoader();
    this.claimsService
      .addClaimsBatch([payload])
      .pipe(
        mergeMap((res: ClaimsBatchResponse) => {
          this.claimBatchId = res.id;
          return this.documentService.claimsBatchDocumentsProcessing(res.id);
        })
      )
      .subscribe({
        next: (data) => {
          this.attachmentsToSign = data;
          this.cfService.goToStep(this.cfService.stepKey + 1);
          this.loader.hideLoader();
          this.cd.markForCheck();
        },
        error: () => {
          this.loader.hideLoader();
        },
      });
  }
}
