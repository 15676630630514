import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { mergeMap } from 'rxjs';
import { ClaimsBatchResponse, DepositaryTransferReference } from 'src/app/interfaces/api/claims.interface';
import { animationOptions } from 'src/app/utils/animation';
import {
  CalculationPlace,
  DepoType,
  ReferenceType,
  TransactionReason,
  convertByteToMegaBytes,
  getFormatDate,
  maxFileSize,
} from 'src/app/utils/utils';
import { ClaimBaseComponent } from '../claim-base/claim-base.component';
import { MatSelectChange } from '@angular/material/select';
import { Attachment, AttachmentBase } from 'src/app/interfaces/api/attachment.interface';

@Component({
  selector: 'app-depo-transaction-transfer-reference',
  templateUrl: './depo-transaction-transfer-reference.component.html',
  styleUrls: ['./depo-transaction-transfer-reference.component.scss'],
  animations: animationOptions,
})
export class DepoTransactionTransferReferenceComponent extends ClaimBaseComponent implements OnInit, OnDestroy {
  acceptedFormates = '.pdf';

  claimReasonDocuments: Attachment[] = [];

  referenceTypes = Array.from(new Set(Object.keys(ReferenceType)));

  transactionReasons = [TransactionReason.depo_agreement, TransactionReason.other_agreement];

  caculationPlaces = Array.from(new Set(Object.keys(CalculationPlace)));

  // participant_type = new FormControl(ParticipantType.sender, Validators.required);

  senderDepoAccountType = new FormControl('own');

  recipientDepoAccountType = new FormControl('own');

  ngOnInit(): void {
    this.form = this.initForm();
    this.getDepoAccounts(false);
  }

  initForm() {
    return this.fb.group(
      {
        transfer_reference_type: new FormControl(ReferenceType.movement, Validators.required),
        sender: this.initDepositorForm(),
        recipient: this.initDepositorForm(),
        claim_securities: this.fb.array([this.initSecurity()]),
        reasons: this.fb.array([this.initReason()]),
        deal_date: new FormControl(null, Validators.required),
        calculation_date: new FormControl(null, Validators.required),
      },
      {
        validators: [placeOfStorageMatchValidator],
      }
    );
  }

  onChangeSenderDepoAccountType(value: string) {
    this.form.get('sender').patchValue({
      depositary_account: null,
      depositary_chapter: null,
    });

    if (value !== 'other') {
      this.form.get('sender').get('depositor_full_name').disable();
      this.form.get('sender').patchValue({
        depositor_full_name: this.auth.fullName,
      });
    } else {
      this.form.get('sender').patchValue({
        depositor_full_name: null,
      });
      this.form.get('sender').get('depositor_full_name').enable();
    }
  }

  onChangeRecipientDepoAccountType(value: string) {
    this.form.get('recipient').patchValue({
      depositary_account: null,
      depositary_chapter: null,
    });

    if (value !== 'other') {
      this.form.get('recipient').get('depositor_full_name').disable();
      this.form.get('recipient').patchValue({
        depositor_full_name: this.auth.fullName,
      });
    } else {
      this.form.get('recipient').patchValue({
        depositor_full_name: null,
      });
      this.form.get('recipient').get('depositor_full_name').enable();
    }
  }

  onChangeSenderDepoAccount(account: string, emitEvent: boolean = true) {
    if (!emitEvent) {
      this.form.get('sender').patchValue({
        depositary_account: account,
      });
    }
    this.form.get('sender').updateValueAndValidity({ emitEvent: false });
    this.cd.markForCheck();
    if (this.form.get('transfer_reference_type')?.value === ReferenceType.movement && emitEvent) {
      this.onChangeRecipientDepoAccount(account, false);
    }
  }

  reasonTypeChanged(event: MatSelectChange, form: FormGroup) {
    form.patchValue({
      contract_name: null,
      contract_number: null,
      contract_date: null,
      document: null,
      documentObject: null,
    });
    if (event.value === TransactionReason.other_agreement) {
      form.get('contract_name').setValidators(Validators.required);
      form.get('contract_number').enable();
      form.get('contract_date').enable();
    } else if (event.value === TransactionReason.depo_agreement) {
      form.patchValue({
        contract_number: this.auth.depositaryService$.value.number,
        contract_date: this.auth.depositaryService$.value.activation_datetime,
      });
      form.get('contract_number').disable();
      form.get('contract_date').disable();
      form.get('contract_name').clearValidators();
    }
    form.get('contract_number').updateValueAndValidity({ emitEvent: false });
    form.get('contract_date').updateValueAndValidity({ emitEvent: false });
    form.get('contract_name').updateValueAndValidity({ emitEvent: false });
    this.cd.markForCheck();
  }

  onChangeRecipientDepoAccount(account: string, emitEvent: boolean = true) {
    if (!emitEvent) {
      this.form.get('recipient').patchValue({
        depositary_account: account,
      });
    }
    this.form.get('recipient').updateValueAndValidity({ emitEvent: false });
    this.cd.markForCheck();
    if (this.form.get('transfer_reference_type')?.value === ReferenceType.movement && emitEvent) {
      this.onChangeSenderDepoAccount(account, false);
    }
  }

  onChangeTransferReferenceType(event: MatRadioChange) {
    if (event.value === ReferenceType.movement) {
      this.senderDepoAccountType.setValue('own');
      this.recipientDepoAccountType.setValue('own');
    }

    this.form.get('sender').patchValue({
      depositary_account: null,
      depositary_chapter: null,
    });
    this.form.get('recipient').patchValue({
      depositary_account: null,
      depositary_chapter: null,
    });
    this.form.get('sender').updateValueAndValidity({ emitEvent: false });
    this.form.get('recipient').updateValueAndValidity({ emitEvent: false });

    this.onChangeSenderDepoAccountType(this.senderDepoAccountType.value);
    this.onChangeRecipientDepoAccountType(this.recipientDepoAccountType.value);
    this.cd.markForCheck();
  }

  fileSelected(event, reasonForm: FormGroup) {
    const file: File = event.target.files[0];
    let formData: FormData = new FormData();
    formData.append('file', file, file.name);
    if (file.size > maxFileSize) {
      this.toaster.showError(this.translation.translate('FILE_SIZE_INCORRECT', [convertByteToMegaBytes(maxFileSize)]));
      return;
    }
    this.loader.showLoader();
    this.claimsService.addClaimReasonDocument(formData).subscribe({
      next: (data: AttachmentBase) => {
        reasonForm.controls.document.setValue(data.id);
        this.updateAttachments(reasonForm, data);
        event.target.value = null;
        this.cd.markForCheck();
        this.loader.hideLoader();
      },
      error: () => {
        this.loader.hideLoader();
      },
    });
  }

  private updateAttachments(reasonForm: FormGroup, data: AttachmentBase): void {
    this.loader.showLoader();
    this.claimsService.fileToBlob(this.claimsService.claimReasonDocumentUrl(data.id)).subscribe({
      next: (url: string) => {
        reasonForm.controls.documentObject.setValue({
          id: data.id,
          file_name: data.file_name,
          url,
          isImage: false,
        });
        this.toaster.showSuccess(this.translation.translate('ATTACHMENT_HAS_BEEN_ADDED'));
        this.cd.markForCheck();
        this.loader.hideLoader();
      },
      error: () => {
        this.loader.hideLoader();
      },
    });
  }

  onDeleteReasonDocument(document_id: number, reasonForm: FormGroup) {
    this.loader.showLoader();
    this.claimsService.deleteClaimReasonDocument(document_id).subscribe({
      next: () => {
        this.toaster.showInfo(this.translation.translate('ATTACHMENT_HAS_BEEN_REMOVED'));
        reasonForm.patchValue({
          document: null,
          documentObject: null,
        });
        this.cd.markForCheck();
        this.loader.hideLoader();
      },
      error: () => {
        this.loader.hideLoader();
      },
    });
  }

  next() {
    if (this.form.invalid) {
      return;
    }

    if (
      this.form.get('transfer_reference_type')?.value === ReferenceType.transfer &&
      this.senderDepoAccountType.value === 'other' &&
      this.recipientDepoAccountType.value === 'other'
    ) {
      this.toaster.showError(this.translation.translate('CLAIM_ERROR:ONE_OF_THE_DEPO_ACCOUNT_TYPE_MUST_BE_OWN'));
      return;
    }

    const payload = this.form.getRawValue() as DepositaryTransferReference;
    payload.type = DepoType.transfer_reference;
    payload.deal_date = getFormatDate(payload.deal_date);
    payload.calculation_date = getFormatDate(payload.calculation_date);
    payload.reasons.map((r) => {
      r.contract_date = getFormatDate(new Date(r.contract_date));
    });

    if (this.senderDepoAccountType.value === 'own') {
      const splitted_sender_account = payload.sender.depositary_account.split(';');
      payload.sender.depositary_account = splitted_sender_account[0];
      payload.sender.depositary_chapter =
        splitted_sender_account[1] === 'null' ? undefined : splitted_sender_account[1];
    }
    if (this.recipientDepoAccountType.value === 'own') {
      const splitted_recipient_account = payload.recipient.depositary_account.split(';');
      payload.recipient.depositary_account = splitted_recipient_account[0];
      payload.recipient.depositary_chapter =
        splitted_recipient_account[1] === 'null' ? undefined : splitted_recipient_account[1];
    }

    this.loader.showLoader();
    this.claimsService
      .addClaimsBatch([payload])
      .pipe(
        mergeMap((res: ClaimsBatchResponse) => {
          this.claimBatchId = res.id;
          return this.documentService.claimsBatchDocumentsProcessing(res.id);
        })
      )
      .subscribe({
        next: (data) => {
          this.attachmentsToSign = data;
          this.cfService.goToStep(this.cfService.stepKey + 1);
          this.loader.hideLoader();
          this.cd.markForCheck();
        },
        error: () => {
          this.loader.hideLoader();
        },
      });
  }
}

function placeOfStorageMatchValidator(form: FormGroup) {
  const sender = form.get('sender');
  const recipient = form.get('recipient');
  const mismatch =
    form.get('transfer_reference_type').value === ReferenceType.transfer &&
    (sender.get('place_of_storage').value !== recipient.get('place_of_storage').value ||
      sender.get('place_of_storage_arbitrary').value !== recipient.get('place_of_storage_arbitrary').value);
  mismatch
    ? sender.get('place_of_storage').setErrors({ mismatch: true })
    : sender.get('place_of_storage').setErrors(null);
  return sender.get('place_of_storage').errors;
}
