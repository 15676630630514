import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TicketResponse } from 'src/app/interfaces/api/messages.interface';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { MessagesService } from 'src/app/services/messages/messages.service';
import { TicketStatus } from 'src/app/utils/utils';

export interface Parameters {
  category: number;
  status: TicketStatus;
  hideEmpty: boolean;
}

@Component({
  selector: 'app-conversations',
  templateUrl: './conversations.component.html',
  styleUrls: ['./conversations.component.scss'],
})
export class ConversationsComponent implements OnInit {
  tickets: TicketResponse[] = [];

  filteredTickets: TicketResponse[] = [];

  statuses = Array.from(new Set(Object.keys(TicketStatus)));

  categoryIds: number[];

  filterForm: FormGroup;

  constructor(
    public messagesService: MessagesService,
    private loader: LoaderService,
    private router: Router,
    private cd: ChangeDetectorRef
  ) {}

  displayedColumns: string[] = ['CATEGORY', 'STATUS', 'CREATED_AT', 'CLOSED_AT'];

  ngOnInit(): void {
    this.messagesService.chatCategories$.subscribe((data) => {
      this.categoryIds = data?.map((c) => c.id);
    });
    this.loader.showLoader();
    this.messagesService.getTickets().subscribe({
      next: (data) => {
        this.tickets = data;
        this.filteredTickets = this.tickets.filter((t) => t.is_empty === false);
        this.loader.hideLoader();
      },
      error: () => {
        this.loader.hideLoader();
      },
    });
    this.filterForm = this.initFilterForm();
    this.filterFormListener();
  }

  filterFormListener() {
    this.filterForm.valueChanges.subscribe({
      next: (params: Parameters) => {
        this.filteredTickets = this.tickets.filter(
          (c) =>
            (params.category ? [params.category] : this.categoryIds).includes(c.category) &&
            (params.status ? [params.status] : this.statuses).includes(c.status) &&
            (params.hideEmpty ? c.is_empty === false : true)
        );
        this.cd.markForCheck();
      },
    });
  }

  initFilterForm() {
    return new FormGroup({
      category: new FormControl(null),
      status: new FormControl(null),
      hideEmpty: new FormControl(true),
    });
  }

  getCategoryName(category_id) {
    return this.messagesService.chatCategories$?.value?.filter((c) => c.id === category_id)[0]?.name;
  }

  goToChat(chat_id: number) {
    this.router.navigate(['/profile/messages', chat_id]);
  }
}
