import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { apiUrlWithPrefix, environment } from '@env';
import { BehaviorSubject, Subscription, interval, map, of, switchMap, tap, zip } from 'rxjs';
import { JWT, SignInRequest, SignInResponse } from 'src/app/interfaces/api/auth.interface';
import { ServiceContract } from 'src/app/interfaces/api/claims.interface';
import { AllowedSignInterface, ProfileResponse } from 'src/app/interfaces/api/profile.interface';
import { ClaimCategory, ProfileStatus } from 'src/app/utils/utils';
import { EdmModalComponent } from '../../shared/shared-components/standalone/edm-modal/edm-modal.component';
import { LoaderService } from '../loader/loader.service';
import { ProfileService } from '../profile/profile.service';
import { AuthInterceptor } from './auth.interceptor';

export const parseJwt = (token): JWT | null => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  static readonly ACCESS_TOKEN_KEY = 'access_token';
  static readonly REFRESH_TOKEN_KEY = 'refresh_token';

  static readonly AUTH_SIGN_IN = `${apiUrlWithPrefix}/user/sign-in/`;
  static readonly AUTH_LOG_OUT = `${apiUrlWithPrefix}/user/log-out/`;
  static readonly AUTH_REFRESH_TOKEN = `${apiUrlWithPrefix}/user/refresh-token/`;
  static readonly ACCEPT_CONSENT = `${apiUrlWithPrefix}/user/profile/accept-edm-consent/`;

  public readonly userProfile$: BehaviorSubject<ProfileResponse | null> = new BehaviorSubject<ProfileResponse>(null);

  public readonly brokerageService$: BehaviorSubject<ServiceContract | null> = new BehaviorSubject<ServiceContract>(
    null
  );

  public readonly depositaryService$: BehaviorSubject<ServiceContract | null> = new BehaviorSubject<ServiceContract>(
    null
  );

  public readonly isAllowedToChangeData$: BehaviorSubject<AllowedSignInterface> =
    new BehaviorSubject<AllowedSignInterface>(null);

  private interval: Subscription;

  get access_token(): string {
    return localStorage.getItem(AuthService.ACCESS_TOKEN_KEY) || null;
  }

  private set access_token(value: string) {
    localStorage.setItem(AuthService.ACCESS_TOKEN_KEY, value);
  }

  get fullName(): string {
    const user = this.userProfile$.value;
    return user?.last_name + ' ' + user?.first_name + (user?.middle_name ? ' ' + user?.middle_name : '');
  }

  get isAuthenticated(): boolean {
    return !!this.access_token;
  }

  private edmModalRef: MatDialogRef<any> = null;

  constructor(
    private http: HttpClient,
    private router: Router,
    private loader: LoaderService,
    private matDialog: MatDialog
  ) {}

  getAccessToken() {
    return new Promise((resolve) => {
      const access_token = localStorage.getItem(AuthService.ACCESS_TOKEN_KEY);
      const access_token_info = parseJwt(access_token);
      if (
        access_token &&
        access_token_info &&
        access_token_info.token_type === 'access' &&
        access_token_info.exp * 1000 - 10000 > Date.now()
      ) {
        resolve(access_token);
      } else if (access_token_info?.user_id) {
        const csrftoken = getCookie('csrftoken');

        const req = new XMLHttpRequest();
        req.open('POST', AuthService.AUTH_REFRESH_TOKEN);
        req.setRequestHeader('Content-Type', 'application/json;charset=utf-8');
        req.setRequestHeader('X-CSRFTOKEN', csrftoken);
        req.withCredentials = true;
        req.responseType = 'json';
        req.onload = () => {
          const access = req.response.access;
          localStorage.setItem(AuthService.ACCESS_TOKEN_KEY, access);
          resolve(access);
        };
        req.onerror = () => {
          resolve(null);
        };
        req.send();
      } else {
        resolve(null);
      }
    });
  }

  userProfileListener() {
    this.interval = interval(environment.userProfileRefreshInterval)
      .pipe(switchMap(() => this.http.get<ProfileResponse>(ProfileService.PROFILE_INFO)))
      .subscribe({
        next: (data: ProfileResponse) => {
          this.userProfile$.next(data);
          if (data.agreements) {
            this.brokerageService$.next(data.agreements.filter((c) => c.type === ClaimCategory.broker_service)[0]);
            this.depositaryService$.next(data.agreements.filter((c) => c.type === ClaimCategory.depositary_service)[0]);
          }
        },
        error: () => {
          this.userProfile$.next(null);
          this.brokerageService$.next(null);
          this.depositaryService$.next(null);
        },
      });
  }

  getAllowedSignToChangePersonalData() {
    return this.http.get(ProfileService.PROFILE_EDIT_IS_ALLOWED).pipe(map((res: any) => res as AllowedSignInterface));
  }

  getUserProfile() {
    return this.http
      .get<ProfileResponse>(ProfileService.PROFILE_INFO)
      .pipe(switchMap((profileResponse) => zip(of(profileResponse), this.getAllowedSignToChangePersonalData())))
      .subscribe(([profileResponse, allowedSignReposnse]) => {
        this.userProfile$.next(profileResponse);
        if (!profileResponse.edm_consent_accepted && !this.edmModalRef) {
          this.edmModalRef = this.matDialog.open(EdmModalComponent, {
            disableClose: true,
          });
          this.edmModalRef.afterClosed().subscribe({
            next: () => {
              this.confirmConsents();
            },
          });
        }
        if (profileResponse.agreements) {
          this.brokerageService$.next(
            profileResponse.agreements.filter((c) => c.type === ClaimCategory.broker_service)[0]
          );
          this.depositaryService$.next(
            profileResponse.agreements.filter((c) => c.type === ClaimCategory.depositary_service)[0]
          );
        }
        if (this.userProfile$?.value?.status !== ProfileStatus.pii_editing) {
          this.isAllowedToChangeData$.next({
            result: true,
            reason: null,
          });
        } else {
          this.isAllowedToChangeData$.next(allowedSignReposnse);
          this.loader.hideLoader();
        }
      });
  }

  getProfileInitial(): Promise<void> {
    const headers = new HttpHeaders({
      [AuthInterceptor.SKIP_AUTH_INTERCEPT]: 'true',
    });
    return new Promise((resolve) => {
      this.http
        .get<ProfileResponse>(ProfileService.PROFILE_INFO, { headers })
        .pipe(switchMap((profileResponse) => zip(of(profileResponse), this.getAllowedSignToChangePersonalData())))
        .subscribe({
          next: ([profileResponse, allowedSignReposnse]) => {
            this.userProfile$.next(profileResponse);
            if (profileResponse.agreements) {
              this.brokerageService$.next(
                profileResponse.agreements.filter((c) => c.type === ClaimCategory.broker_service)[0]
              );
              this.depositaryService$.next(
                profileResponse.agreements.filter((c) => c.type === ClaimCategory.depositary_service)[0]
              );
            }
            if (this.userProfile$?.value?.status !== ProfileStatus.pii_editing) {
              this.isAllowedToChangeData$.next({
                result: true,
                reason: null,
              });
            } else {
              this.isAllowedToChangeData$.next(allowedSignReposnse);
              this.loader.hideLoader();
            }
            resolve();
          },
          error: () => {
            resolve();
          },
        });
    });
  }

  setAccessToken(token: string) {
    this.access_token = token;
  }

  signIn(request: SignInRequest) {
    return this.http
      .post<SignInResponse>(AuthService.AUTH_SIGN_IN, request, {
        withCredentials: true,
      })
      .pipe(
        tap((response) => {
          this.setAccessToken(response.access);
        })
      );
  }

  logOut() {
    this.loader.showLoader();
    this.http.post(AuthService.AUTH_LOG_OUT, {}).subscribe({
      next: () => {
        this.removeToken();
        this.userProfile$.next(null);
        this.loader.hideLoader();
        this.router.navigate(['users', 'sign_in']);
      },
      error: () => {
        this.loader.hideLoader();
      },
    });
  }

  removeToken() {
    localStorage.removeItem(AuthService.ACCESS_TOKEN_KEY);
  }

  public resetProfileListener() {
    this.interval && this.interval.unsubscribe();
  }

  private confirmConsents(): void {
    this.http.get(AuthService.ACCEPT_CONSENT).subscribe({});
  }
}

function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === name + '=') {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}
