<div class="d-flex align-items-center justify-content-between">
  <ng-container *ngFor="let step of totalSteps; let first = first">
      <div [class.completed]="step <= currentStep"
        [class.completed-line]="step + 1 <= currentStep"
        [class.big]="stepValues?.length"
        class="step">
        <div [class.completed-value]="step <= currentStep" class="mt-4 d-none d-xl-block" *ngIf="stepValues?.length">{{stepValues[step - 1] | translate}}</div>
      </div>
  </ng-container>
</div>
