import { Component } from '@angular/core';

@Component({
  selector: 'app-bo-manual-assignment',
  templateUrl: './bo-manual-assignment.component.html',
  styleUrls: ['./bo-manual-assignment.component.scss'],
})
export class BoManualAssignmentComponent {
  constructor() {}
}
