<div class="container-fluid bg-white p-4"
  [@open]="'opened'">
  <h1>{{ 'CLAIM:FILLING' | translate }}</h1>
  <div class="row">
    <ui-step-view class="col-12 mt-3"
      [total]="3"
      [current]="ds.stepKey + 1"
      [stepValues]="['CLAIM_STEP:REQUISITES', 'CLAIM_STEP:CONDITION_OF_ACCEPTATE', 'CLAIM_STEP:SIGN']">
    </ui-step-view>
    <div class="order-container">
      <div class="form-data">
        <ng-container *ngIf="ds.stepKey===0">
          <ng-container *ngIf="bankAccounts.length===0; else bankAccountForm">
            <ai-ui-bank-base [emitEvent]="true"
              (afterAddBankAccount)="setBankAccountId($event)"></ai-ui-bank-base>
          </ng-container>
          <ng-template #bankAccountForm>
            <div class="bank-container">
              <div class="bank-info-title">
                <h3>{{ 'BANK_INFO:TITLE' | translate }}</h3>
                <div class="hint">
                  <p>{{ 'BANK_INFO:HINT' | translate }}</p>
                </div>
              </div>
              <div class="add-action">
                <button mat-button
                  type="button"
                  (click)="onAddBankAccount()">
                  <mat-icon>add</mat-icon>
                  {{ 'ADD_BANK_ACCOUNT' | translate }}
                </button>
              </div>
            </div>
            <mat-radio-group [formControl]="bank_account">
              <ng-container *ngFor="let bankAccount of bankAccounts">
                <div class="edit-action text-align-right">
                  <button mat-button
                    type="button"
                    (click)="onEditBankAccount(bankAccount.id)">
                    <mat-icon>edit</mat-icon>
                    {{ 'CHANGE' | translate }}
                  </button>
                </div>
                <div class="bank-card">
                  <mat-radio-button class="bank-card-radio-button"
                    color="primary"
                    [value]="bankAccount.id">
                  </mat-radio-button>
                  <ai-ui-bank-account-card [bankAccount]="bankAccount"></ai-ui-bank-account-card>
                </div>
              </ng-container>
            </mat-radio-group>
          </ng-template>
          <div class="d-flex justify-content-end">
            <button *ngIf="bankAccounts.length!==0"
              [disabled]="!bank_account.value"
              mat-button
              class="filled"
              type="button"
              (click)="next()">
              {{ 'NEXT' | translate }}
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="ds.stepKey===1">
          <div class="actions">
            <div class="order-content  col-12">
              <p>{{ 'BS:INCOME_EARNING_OPTION' | translate }}</p>
              <mat-radio-group [formControl]="income_earning_option">
                <mat-radio-button color="primary"
                  [value]="'bank_account'">
                  {{ 'BS:INCOME_EARNING_OPTION_BANK_ACCOUNT' | translate }}
                </mat-radio-button>
                <mat-radio-button color="primary"
                  [value]="'brokerage_account'">
                  {{ 'BS:INCOME_EARNING_OPTION_BROKERAGE_ACCOUNT' | translate }}
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <button class="back"
                (click)="back()"
                mat-button
                type="button">
                {{ 'BACK' | translate }}
              </button>
              <button mat-button
                class="filled"
                type="button"
                (click)="next()">
                {{ 'NEXT' | translate }}
              </button>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="ds.stepKey===2">
          <div class="col-12">
            <ai-ui-documents [attachments]="attachmentsToSign"
              [isCreatingOrder]="true"></ai-ui-documents>
          </div>
          <div class="col-10 confirmation">
            <mat-checkbox [formControl]="confirmation"
              color="primary">{{ 'DEPO:CONFIRMATION' | translate
              }}</mat-checkbox>
            <h4>{{ 'CLAIM_SIGNATURE_TITLE' | translate }}</h4>
            <div class="hint">
              <p>{{ 'CLAIM_SIGNATURE_INFORMATION' | translate }}
              </p>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <button class="back"
              (click)="back()"
              mat-button
              type="button">
              {{ 'BACK' | translate }}
            </button>
            <button [disabled]="!confirmation.value"
              mat-button
              class="filled"
              type="button"
              (click)="sendToSign()">
              {{ 'SIGN' | translate }}
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>