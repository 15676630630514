import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { BankAccountResponse } from 'src/app/interfaces/api/bank.interface';
import { Area, ClaimsBatchResponse, WithdrawMoneyFromAreaRequest } from 'src/app/interfaces/api/claims.interface';
import { AiUiAddBankAccountComponent } from 'src/app/shared/shared-components/ai-ui-add-bank-account/ai-ui-add-bank-account.component';
import { AiUiEditBankAccountComponent } from 'src/app/shared/shared-components/ai-ui-edit-bank-account/ai-ui-edit-bank-account.component';
import { animationOptions } from 'src/app/utils/animation';
import { AreaType, BsType } from 'src/app/utils/utils';
import { ClaimBaseComponent } from '../claim-base/claim-base.component';
import { Attachment } from 'src/app/interfaces/api/attachment.interface';
import { mergeMap } from 'rxjs';

@Component({
  selector: 'app-bo-withdraw-area-money-new',
  templateUrl: './bo-withdraw-area-money-new.component.html',
  styleUrls: ['./bo-withdraw-area-money-new.component.scss'],
  animations: animationOptions,
})
export class BoWithdrawAreaMoneyNewComponent extends ClaimBaseComponent implements OnInit, OnDestroy {
  bankAccounts: BankAccountResponse[] = [];

  ngOnInit(): void {
    this.form = this.initForm();
    this.getBankAccounts();
    this.formListener();
  }

  initForm() {
    return this.fb.group({
      msk_stock_market: this.fb.group({
        exchange: new FormControl(null),
        amount: new FormControl(null, Validators.required),
        currency: new FormControl(null, Validators.required),
        withdraw_all_money: new FormControl(false),
      }),
      msk_currency_market: this.fb.group({
        exchange: new FormControl(null),
        amount: new FormControl(null, Validators.required),
        currency: new FormControl(null, Validators.required),
        withdraw_all_money: new FormControl(false),
      }),
      foreign_market: this.fb.group({
        exchange: new FormControl(null),
        amount: new FormControl(null, Validators.required),
        currency: new FormControl(null, Validators.required),
        withdraw_all_money: new FormControl(false),
        platform_name: new FormControl(null),
      }),
      additional_terms: new FormControl(null),
      bank_account: new FormControl(null, Validators.required),
    });
  }

  formListener() {
    this.form.valueChanges.subscribe((data) => {
      const values: Area[] = [data.msk_stock_market, data.msk_currency_market, data.foreign_market];
      const forms = [
        this.form.controls.msk_stock_market,
        this.form.controls.msk_currency_market,
        this.form.controls.foreign_market,
      ] as FormGroup[];
      if (
        values.find((f) => f.currency !== null && ((f.amount > 0 && !f.withdraw_all_money) || f.withdraw_all_money))
      ) {
        forms.forEach((f) => {
          f.controls.currency.clearValidators();
          f.controls.amount.clearValidators();
          f.controls.currency.updateValueAndValidity({ emitEvent: false });
          f.controls.amount.updateValueAndValidity({ emitEvent: false });
        });
      } else {
        forms.forEach((f) => {
          f.controls.currency.setValidators(Validators.required);
          f.controls.amount.setValidators(Validators.required);
          f.controls.currency.updateValueAndValidity({ emitEvent: false });
          f.controls.amount.updateValueAndValidity({ emitEvent: false });
        });
      }
    });
  }

  onAddBankAccount() {
    this.dialog
      .open(AiUiAddBankAccountComponent, {
        panelClass: 'popup-container',
        width: '800px',
      })
      .afterClosed()
      .subscribe((res: boolean) => {
        if (res) {
          this.getBankAccounts();
        }
      });
  }

  onEditBankAccount(id: number) {
    this.dialog
      .open(AiUiEditBankAccountComponent, {
        panelClass: 'popup-container',
        width: '800px',
        data: {
          bankAccountId: id,
        },
      })
      .afterClosed()
      .subscribe((res: boolean) => {
        if (res) {
          this.getBankAccounts();
        }
      });
  }

  getBankAccounts() {
    this.loader.showLoader();
    this.bankService.bankAccounts().subscribe({
      next: (res) => {
        if (res.length) {
          this.bankAccounts = res;
          if (this.form.controls.bank_account.value === null) {
            this.form.controls.bank_account.setValue(this.bankAccounts[0].id);
          }
          this.cd.detectChanges();
          this.cd.markForCheck();
        }
        this.loader.hideLoader();
      },
      error: () => {
        this.loader.hideLoader();
      },
    });
  }

  onCheckboxChanged(event: MatCheckboxChange, formGroupName: string) {
    if (event.checked) {
      this.form.get(formGroupName).get('amount').disable();
      this.form.get(formGroupName).patchValue({
        amount: null,
      });
      this.form.get(formGroupName).get('amount').clearValidators();
    } else {
      this.form.get(formGroupName).get('amount').setValidators(Validators.required);
      this.form.get(formGroupName).get('amount').enable();
    }
    this.form.get(formGroupName).get('amount').updateValueAndValidity({ emitEvent: false });
  }

  next() {
    if (this.form.invalid) {
      return;
    }
    const rawValue = this.form.getRawValue();
    rawValue.msk_currency_market.exchange = this.getExchangeId(AreaType.mstock);
    rawValue.msk_stock_market.exchange = this.getExchangeId(AreaType.mcur);
    rawValue.foreign_market.exchange = this.getExchangeId(AreaType.offe);
    const exchangesData = (
      [rawValue.msk_stock_market, rawValue.msk_currency_market, rawValue.foreign_market] as Area[]
    ).filter(
      (e) => e.exchange > 0 && e.currency !== null && ((e.amount > 0 && !e.withdraw_all_money) || e.withdraw_all_money)
    );
    const payload: WithdrawMoneyFromAreaRequest = {
      type: BsType.withdraw_exchange_money,
      additional_terms: rawValue.additional_terms,
      bank_account: rawValue.bank_account,
      withdraw_from_exchanges: exchangesData,
    };
    this.loader.showLoader();
    this.claimsService
      .addClaimsBatch([payload])
      .pipe(
        mergeMap((res: ClaimsBatchResponse) => {
          this.claimBatchId = res.id;
          return this.documentService.claimsBatchDocumentsProcessing(res.id);
        })
      )
      .subscribe({
        next: (data) => {
          this.attachmentsToSign = data;
          this.cfService.goToStep(this.cfService.stepKey + 1);
          this.loader.hideLoader();
          this.cd.markForCheck();
        },
        error: () => {
          this.loader.hideLoader();
        },
      });
  }

  getExchangeId(type: AreaType) {
    switch (type) {
      case AreaType.mstock:
        return this.exchangeService.exchanges$.value.filter((e) => e.code == AreaType.mstock)[0]?.id;
      case AreaType.mcur:
        return this.exchangeService.exchanges$.value.filter((e) => e.code == AreaType.mcur)[0]?.id;
      case AreaType.offe:
        return this.exchangeService.exchanges$.value.filter((e) => e.code == AreaType.offe)[0]?.id;
      default:
        return 0;
    }
  }
}
