import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { Locale } from './locale.enum';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  set locale(value: Locale) {
    this._locale = value || null;
    this.updateLocale();
  }

  get locale(): Locale {
    return this._locale;
  }

  private _locale: Locale = Locale.RU;

  private _$locale: BehaviorSubject<Locale> = new BehaviorSubject(Locale.RU);

  $locale: Observable<Locale> = this._$locale.asObservable();

  private translations: { [key: string]: string } = {};

  constructor(private http: HttpClient) {}

  async getTranslations(locale?: Locale): Promise<void> {
    this._locale = locale || this._locale;
    return firstValueFrom(this.http.get(`assets/localization/${this._locale}.json`)).then(
      (res: { [key: string]: string }) => {
        this.translations = res || {};
        this._$locale.next(this._locale);
      }
    );
  }

  translate(key: string, interpolateParams?: Array<string | number>): string {
    if (interpolateParams && this.translations[key]) {
      return this.interpolateParams(this.translations[key], interpolateParams);
    }
    return this.translations[key] || key;
  }

  private interpolateParams(key: string, params: Array<string | number>) {
    return key.replace(/{\d+}/g, (value: any) => {
      const index = +value.toString().replace('{', '').replace('}', '');
      return params[index] || value;
    });
  }

  private async updateLocale(): Promise<void> {
    await this.getTranslations();
  }
}
