<div class="container-fluid bg-white p-4"
  [@open]="'opened'">
  <div class="title">
    <h1>{{ 'CLAIM:FILLING' | translate }}</h1>
    <div class="hint">
      <p>{{ 'terminate_agreement' | translate }}</p>
    </div>
  </div>
  <div class="row">
    <ui-step-view class="col-12 mt-3"
      [total]="2"
      [current]="cfService.stepKey + 1"
      [stepValues]="['CLAIM_STEP:CREATION', 'CLAIM_STEP:SIGN']">
    </ui-step-view>
    <div class="order-container">
      <div class="form-data  col-12">
        <ng-container *ngIf="cfService.stepKey===0">
          <div class="order-content">
            <p>{{ 'CONTRACT_TERMINATION:CONTENT' | translate }}</p>
          </div>
          <form [formGroup]="form">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'FIELD:CONTRACT_TERMINATION' | translate }}</mat-label>
              <mat-select formControlName="termination_type">
                <mat-option *ngFor="let type of terminationTypes"
                  [value]="type">
                  {{ type | translate }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="form.get('termination_type').errors?.required">
                {{'ERROR:REQUIRED' | translate }}
              </mat-error>
            </mat-form-field>
          </form>
          <div class="actions">
            <div class="d-flex justify-content-end mb-3">
              <button [disabled]="form.invalid"
                class="filled"
                mat-flat-button
                (click)="next()">
                {{ 'NEXT' | translate }}
              </button>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="cfService.stepKey===1">
          <div class="col-12">
            <ai-ui-documents [attachments]="attachmentsToSign"
              [isCreatingOrder]="true"></ai-ui-documents>
          </div>
          <div class="col-10 confirmation">
            <mat-checkbox [formControl]="theFirstConfirmation"
              color="primary">
              {{ 'BS:CONFIRMATION_4' | translate }}
              <a href="https://www.aigenis.ru/info12"
                target="_blank"
                class="risk-link">{{ 'BS:CONFIRMATION_4_1' | translate }}</a>
                {{ 'BS:CONFIRMATION_4_2' | translate }}
            </mat-checkbox>
            <h4>{{ 'CLAIM_SIGNATURE_TITLE' | translate }}</h4>
            <div class="hint">
              <p>{{ 'CLAIM_SIGNATURE_INFORMATION' | translate }}
              </p>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <button class="back"
              (click)="back()"
              mat-button
              type="button">
              {{ 'BACK' | translate }}
            </button>
            <button mat-button
              [disabled]="!theFirstConfirmation.value"
              class="filled"
              type="button"
              (click)="sendToSign()">
              {{ 'SIGN' | translate }}
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>