import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslationService } from '@localization';
import { Subject, forkJoin, takeUntil } from 'rxjs';
import { ServiceContract } from 'src/app/interfaces/api/claims.interface';
import { ClientInvestment, ClientPortfolio, Estimate } from 'src/app/interfaces/api/portfolio.interface';
import { CustomBrokingBreakpoints } from 'src/app/interfaces/internal/main.interface';
import { PieData } from 'src/app/interfaces/internal/pie.interface';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ExchangeRateService } from 'src/app/services/exchange-rate/exchange-rate.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { PortfolioService } from 'src/app/services/portfolio/portfolio.service';
import { AiUiRefillAccountMethodComponent } from 'src/app/shared/shared-components/ai-ui-refill-account-method/ai-ui-refill-account-method.component';
import { animationOptions } from 'src/app/utils/animation';
import { BsType, Currency, InvestmentType, SecurityType, substractDaysFromDate } from 'src/app/utils/utils';

@Component({
  selector: 'app-broking',
  templateUrl: './broking.component.html',
  styleUrls: ['./broking.component.scss'],
  animations: animationOptions,
})
export class BrokingComponent implements OnInit, OnDestroy {
  today: Date = new Date();

  twoDaysAgo: Date = substractDaysFromDate(this.today, 2);

  assetsData: PieData[];

  areaData: PieData[];

  currenciesData: PieData[];

  mstock: Estimate;

  mcur: Estimate;

  offe: Estimate;

  isSmallScreen: boolean;

  destroyed$: Subject<void> = new Subject();

  readonly currenciesDict: { [code: string]: string } = {
    RUB: '₽',
    USD: '$',
    EUR: '€',
  };

  currencyToShow = Currency.RUB;

  tabToShow: 'stocks' | 'venues' | 'currencies' = 'stocks';

  bsTypes = [
    {
      name: this.translation.translate(BsType.securities_trade),
      routerLink: '/claims/bo_nrd_papers_deal_new',
    },
    {
      name: this.translation.translate(BsType.currency_instruments_trade),
      routerLink: '/claims/bo_currency_instruments_new',
    },
    {
      name: this.translation.translate('CLAIM_TYPE:CASH_WITHDRAWAL'),
      routerLink: '/claims/bo_withdraw_money_new',
    },
    {
      name: this.translation.translate('CLAIM_TYPE:CASH_TRANSFER'),
      routerLink: '/claims/bo_transfer_money_new',
    },
    {
      name: this.translation.translate(BsType.terminate_agreement),
      routerLink: '/claims/bo_termination_new',
    },
    {
      name: this.translation.translate('CLAIM_TYPE:ARBITRARY_ORDER'),
      routerLink: '/claims/bo_manual_assignment_new',
    },
  ];

  portfolios: ClientPortfolio[] = [];

  portfolioMoneyCaption: number = 0;

  portfolioMoney: number = 0;

  groupedAccounts: { [key: string]: number } = {
    [InvestmentType.checking_account.toString()]: 0,
    [InvestmentType.brokerage_account.toString()]: 0,
  };

  stocks: ClientPortfolio[] = [];

  bonds: ClientPortfolio[] = [];

  investments: ClientInvestment[] = [];

  brokerage: ServiceContract = null;

  displayedColumns: string[] = [
    'SECURITY_NAME',
    'PLACE_OF_STORAGE',
    'AMOUNT',
    'BALANCE_SUM',
    'NKD',
    'MARKET_PRICE_RUB',
  ];

  constructor(
    public auth: AuthService,
    public portfolioService: PortfolioService,
    private translation: TranslationService,
    private exchangeRateService: ExchangeRateService,
    private loader: LoaderService,
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit(): void {
    this.auth.brokerageService$.subscribe((data) => {
      this.brokerage = data;
    });
    this.loader.showLoader();
    forkJoin({
      portfolios: this.portfolioService.clientPortfolio(),
      investments: this.portfolioService.clientInvestment(),
    }).subscribe({
      next: (data) => {
        this.portfolios = data.portfolios;
        this.investments = data.investments;
        this.portfolioMoneyCaption = this.portfolioMoney = this.portfolioService.calculatePortfolio(
          this.portfolios,
          this.investments
        );
        // this.portfolioService.calculatePortfolioAccounts(this.investments, this.groupedAccounts);
        this.stocks = this.portfolios.filter((p) => !!p.security && p.security.instr_type === SecurityType.shares);
        this.bonds = this.portfolios.filter((p) => !!p.security && p.security.instr_type === SecurityType.bonds);
        this.initPieData();
        this.loader.hideLoader();
      },
      error: () => {
        this.loader.hideLoader();
      },
    });

    this.breakpointObserver
      .observe([CustomBrokingBreakpoints.SMALL, CustomBrokingBreakpoints.HIGH])
      .pipe(takeUntil(this.destroyed$))
      .subscribe((result) => {
        if (result.breakpoints[CustomBrokingBreakpoints.SMALL]) {
          this.isSmallScreen = true;
        } else if (result.breakpoints[CustomBrokingBreakpoints.HIGH]) {
          this.isSmallScreen = false;
        }
      });
  }

  initPieData() {
    // TODO the percentage will be calculated dynamically after receiving all necessary data
    this.assetsData = [
      {
        name: this.translation.translate('BROKING:ASSETS_TAB:STOCKS'),
        color: '#F2C94C',
        money: this.portfolioService.calculateKindOfSecurity(this.stocks),
      },
      {
        name: this.translation.translate('BROKING:ASSETS_TAB:BONDS'),
        color: '#6FBBCF',
        money: this.portfolioService.calculateKindOfSecurity(this.bonds),
      },
      {
        name: this.translation.translate('BROKING:ASSETS_TAB:FREE_MONEY'),
        color: '#6FCF97',
        money: this.portfolioService.calculateAccount(this.investments),
      },
      {
        name: this.translation.translate('BROKING:ASSETS_TAB:NKD'),
        color: '#BB6BD9',
        money: this.portfolioService.calculateNkd(this.investments),
      },
    ];
    this.areaData = [
      {
        name: this.translation.translate('AREA:MSK_STOCK_MARKET'),
        color: '#6FCF97',
        money: this.mstock?.money,
      },
      {
        name: this.translation.translate('AREA:FOREIGN_MARKET'),
        color: '#BB6BD9',
        money: this.offe?.money,
      },
    ];
    this.currenciesData = [
      {
        name: this.translation.translate('BROKING:CURRENCIES_TAB:RUB'),
        color: '#F2C94C',
        money: this.mstock?.money,
      },
      {
        name: this.translation.translate('BROKING:CURRENCIES_TAB:EUR'),
        color: '#6FCF97',
        money: '0',
      },
      {
        name: this.translation.translate('BROKING:CURRENCIES_TAB:USD'),
        color: '#BB6BD9',
        money: '0',
      },
    ];

    this.percentageCalculation();
  }

  toggleCurrency(event: any) {
    if (event.target.id) {
      this.currencyToShow = event.target.id;
      if (this.currencyToShow === Currency.RUB) {
        this.portfolioMoneyCaption = this.portfolioMoney;
      } else {
        const rate = this.exchangeRateService.exchangeRates$.value.filter((e) => {
          return e.currency_from === Currency.RUB && e.currency_to === this.currencyToShow;
        })[0]?.rate;
        this.portfolioMoneyCaption = this.portfolioMoney * rate;
      }
    }
  }

  percentageCalculation() {
    this.assetsData.map((item) => {
      item.percent = item.money === undefined ? 0 : +((+item.money / this.sum(this.assetsData)) * 100).toFixed(2);
    });
    this.areaData.map((item) => {
      item.percent = item.money === undefined ? 0 : +((+item.money / this.sum(this.areaData)) * 100).toFixed(2);
    });
    this.currenciesData.map((item) => {
      item.percent = item.money === undefined ? 0 : +((+item.money / this.sum(this.currenciesData)) * 100).toFixed(2);
    });
  }

  sum(data: PieData[]) {
    return data.map((n) => +n.money).reduce((acc, curr) => (isNaN(acc) ? 0 : acc) + (isNaN(curr) ? 0 : curr));
  }

  toggleTab(event: any) {
    if (event.target.id) {
      this.tabToShow = event.target.id;
    }
  }

  refillAccount() {
    this.dialog.open(AiUiRefillAccountMethodComponent, {
      panelClass: 'popup-container',
      width: '800px',
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
