<div class="container">
  <div class="close" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </div>
  <div class="title mb-2">
    <h3>{{ 'ENTER_AT_LEAST_THREE_SYMBOLS_ISIN' | translate }}</h3>
  </div>
  <mat-form-field appearance="outline" class="search-field mb-3">
    <input matInput placeholder="{{ 'SEARCH' | translate }}" (input)="onIsinCodeInputted($event)">
  </mat-form-field>
  <ng-container *ngIf="!data.securities.length;else choice">
    <mat-option [disabled]="true">{{ 'NOT_FOUND' | translate }}</mat-option>
  </ng-container>
  <ng-template #choice>
    <ng-container *ngIf="data.securities.length">
      <table mat-table [dataSource]="data.securities">
        <ng-container matColumnDef="ISIN">
          <th mat-header-cell *matHeaderCellDef>{{ 'SECURITY:ISIN_CODE' | translate }}</th>
          <td mat-cell *matCellDef="let security"><b>{{ security?.isin }}</b></td>
        </ng-container>
        <ng-container matColumnDef="FULL_NAME">
          <th mat-header-cell *matHeaderCellDef>{{ 'SECURITY:SHORT_NAME' | translate }}</th>
          <td mat-cell *matCellDef="let security">{{ security?.full_name }}</td>
        </ng-container>
        <ng-container matColumnDef="INSTR_TYPE">
          <th mat-header-cell *matHeaderCellDef>{{ 'SECURITY:TYPE' | translate }}</th>
          <td mat-cell *matCellDef="let security">{{ security?.instr_type | translate }}</td>
        </ng-container>
        <ng-container matColumnDef="INSTR_CATEGORY">
          <th mat-header-cell *matHeaderCellDef>{{ 'SECURITY:CATEGORY' | translate }}</th>
          <td mat-cell *matCellDef="let security">{{ security?.instr_category ? ('SECURITY_CATEGORY:'+security?.instr_category |
            translate) : '-' }}</td>
        </ng-container>
        <ng-container matColumnDef="STATE_REG_NUMBER">
          <th mat-header-cell *matHeaderCellDef>{{ 'SECURITY:REGISTRATION_NUMBER' | translate }}</th>
          <td mat-cell *matCellDef="let security">{{ security?.state_reg_number ? security?.state_reg_number : '-' |
            translate }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr (click)="onIsinSelected(security)" mat-row *matRowDef="let security; columns: displayedColumns"></tr>
      </table>
    </ng-container>
  </ng-template>
</div>