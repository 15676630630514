<div [ngClass]="{ 'registration-footer' : background==='dark', 'main-footer' : background==='light' }">
  <footer>
    <div class="footer-content" [ngClass]="background">
      <section>
        <div class="container-fluid text-start text-md-start">
          <div class="row">

            <div class="col-lg-4">
              <img src="/assets/logos/aigenis_light.svg"
                class="logo"
                alt="logo">
              <div class="pre">{{'FOOTER:ADDRESS' | translate}}</div>
            </div>
            <div class="col-lg-5">

            </div>
            <div class="col-lg-3">
              <div class="d-flex align-items-center mb-2">
                <img src="/assets/images/phone.svg"
                  height="40"
                  alt="phone">
                <div class="contacts">
                  <a class="phone" href="tel:+74952121101">{{ 'FOOTER:CONTACT_NUMBER' | translate }}</a>
                  <div>{{ 'FOOTER:WORK_TIME' | translate }}</div>
                </div>
              </div>
              
              <div class="pre">{{ 'FOOTER:INFORMATION' | translate }}</div>
              <div class="link">
                <a href="https://aigenis.ru/" target="_blank">{{ 'FOOTER:NAVIGATE_TO_THE_OFFICIAL_WEBSITE' | translate }}</a>
                <img src="/assets/images/arrow-right.svg"
                  class="ms-4"
                  height="12"
                  alt="arrow">
              </div>
            </div>

            <div class="col-12 divider mb-4"></div>
            <div class="col-lg-6">
              <p>{{ 'FOOTER:COPYRIGHT' | translate : [year] }}</p>
            </div>
            <div class="col-lg-6 developed">
              <a href="https://aigenis.ru/" target="_blank">{{ 'FOOTER:DEVELOPED_BY' | translate : [year] }}</a>
            </div>
          </div>
        </div>
      </section>
    </div>
  </footer>
</div>