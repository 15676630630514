<div class="container-fluid bg-white p-4"
  [@open]="'opened'">
  <h1>{{ 'CLAIM:FILLING' | translate }}</h1>
  <div class="hint">
    <p>{{ 'CLAIM_TYPE:CASH_WITHDRAWAL' | translate }}</p>
  </div>
  <div class="row">
    <ui-step-view class="col-12 mt-3"
      [total]="2"
      [current]="cfService.stepKey + 1"
      [stepValues]="['CLAIM_STEP:CREATION', 'CLAIM_STEP:SIGN']">
    </ui-step-view>
    <div class="order-container">
      <div class="form-data col-12">
        <ng-container *ngIf="cfService.stepKey===0">
          <form [formGroup]="form">
            <div class="row">
              <div class="col">
                <mat-form-field appearance="outline">
                  <mat-label>{{ 'BANK:CURRENCY_CODE' | translate }}</mat-label>
                  <mat-select formControlName="withdrawal_currency">
                    <mat-option *ngFor="let code of currencies"
                      [value]="code">
                      {{ code }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="form.get('withdrawal_currency').errors?.required">
                    {{'ERROR:REQUIRED' | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field appearance="outline">
                  <mat-label>{{ 'FIELD:AMOUNT' | translate }}</mat-label>
                  <input amount
                    [decimalPlace]="2"
                    matInput
                    type="text"
                    formControlName="withdrawal_amount">
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <mat-checkbox (change)="onCheckboxChanged($event)"
                class="mb-4"
                color="primary"
                formControlName="withdraw_all_money">{{
                  'WITHDRAWAL:IN_THE_AMOUNT_OF_FREE_BALANCE' | translate }}</mat-checkbox>
              <ng-container *ngIf="form.get('withdrawal_currency')?.value!=='RUB'">
                <mat-checkbox color="primary"
                  [checked]="showCorrespondentBank"
                  class="mb-4"
                  (change)="onShowCorrespondentToggle($event)">
                  {{ 'CLAIM:INFO_ABOUT_CORRESPONDENT' | translate }}
                </mat-checkbox>
                <ng-container *ngIf="showCorrespondentBank">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ 'CLAIM:WITHDRAWAL_CORRESPONDENT_BANK_NAME' | translate }}</mat-label>
                    <input matInput
                      formControlName="withdrawal_correspondent_bank_name">
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                    <mat-label>{{ 'CLAIM:WITHDRAWAL_CORRESPONDENT_BANK_ACCOUNT' | translate }}</mat-label>
                    <input matInput
                      formControlName="withdrawal_correspondent_bank_account">
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                    <mat-label>{{ 'CLAIM:WITHDRAWAL_CORRESPONDENT_BANK_SWIFT' | translate }}</mat-label>
                    <input matInput
                      formControlName="withdrawal_correspondent_bank_swift">
                  </mat-form-field>
                </ng-container>
              </ng-container>
            </div>
            <mat-form-field appearance="outline">
              <mat-label>{{ 'CLAIM:ADDITIONAL_CONDITION' | translate }}</mat-label>
              <input matInput
                formControlName="additional_terms">
            </mat-form-field>
            <div class="bank-container">
              <div class="bank-info-title">
                <h3>{{ 'BANK_INFO:TITLE' | translate }}</h3>
                <div class="hint">
                  <p>{{ 'BANK_INFO:WITHDRAWAL_HINT' | translate }}</p>
                </div>
              </div>
              <div class="add-action">
                <button mat-button
                  type="button"
                  (click)="onAddBankAccount()">
                  <mat-icon>add</mat-icon>
                  {{ 'ADD_BANK_ACCOUNT' | translate }}
                </button>
              </div>
            </div>
            <mat-radio-group formControlName="bank_account">
              <ng-container *ngFor="let bankAccount of bankAccounts">
                <div class="edit-action text-align-right">
                  <button mat-button
                    type="button"
                    (click)="onEditBankAccount(bankAccount.id)">
                    <mat-icon>edit</mat-icon>
                    {{ 'CHANGE' | translate }}
                  </button>
                </div>
                <div class="bank-card">
                  <mat-radio-button class="bank-card-radio-button"
                    color="primary"
                    [value]="bankAccount.id">
                  </mat-radio-button>
                  <ai-ui-bank-account-card [bankAccount]="bankAccount"></ai-ui-bank-account-card>
                </div>
              </ng-container>
            </mat-radio-group>
          </form>
          <div class="actions">
            <div class="d-flex justify-content-end mb-3">
              <button [disabled]="form.invalid"
                class="filled"
                mat-flat-button
                (click)="next()">
                {{ 'NEXT' | translate }}
              </button>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="cfService.stepKey===1">
          <div class="col-12">
            <ai-ui-documents [attachments]="attachmentsToSign"
              [isCreatingOrder]="true"></ai-ui-documents>
          </div>
          <div class="col-10 confirmation">
            <mat-checkbox [formControl]="theFirstConfirmation"
              color="primary">
              {{ 'BS:CONFIRMATION_4' | translate }}
              <a href="https://www.aigenis.ru/info12"
                target="_blank"
                class="risk-link">{{ 'BS:CONFIRMATION_4_1' | translate }}</a>
                {{ 'BS:CONFIRMATION_4_2' | translate }}
            </mat-checkbox>
            <h4>{{ 'CLAIM_SIGNATURE_TITLE' | translate }}</h4>
            <div class="hint">
              <p>{{ 'CLAIM_SIGNATURE_INFORMATION' | translate }}
              </p>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <button class="back"
              (click)="back()"
              mat-button
              type="button">
              {{ 'BACK' | translate }}
            </button>
            <button mat-button
              [disabled]="!theFirstConfirmation.value"
              class="filled"
              type="button"
              (click)="sendToSign()">
              {{ 'SIGN' | translate }}
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>