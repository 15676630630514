import { Component, OnInit } from '@angular/core';
import { animationOptions } from 'src/app/utils/animation';

@Component({
  selector: 'app-ai-ui-server-error',
  templateUrl: './ai-ui-server-error.component.html',
  styleUrls: ['./ai-ui-server-error.component.scss'],
  animations: animationOptions,
})
export class AiUiServerErrorComponent {
  constructor() {}

  reload() {
    window.location.reload();
  }
}
