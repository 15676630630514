import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { TranslationService } from '@localization';
import { Subject, filter, forkJoin, takeUntil } from 'rxjs';
import {
  Address,
  AddressInfo,
  Passport,
  PassportTaxInfo,
  TaxCompliance,
} from 'src/app/interfaces/api/profile.interface';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { ToasterService } from 'src/app/shared/shared-services/toaster/toaster.service';
import { animationOptions } from 'src/app/utils/animation';
import { CountryCode, PassportType, formatDate } from 'src/app/utils/utils';

@Component({
  selector: 'app-profile-info',
  templateUrl: './profile-info.component.html',
  styleUrls: ['./profile-info.component.scss'],
  animations: animationOptions,
})
export class ProfileInfoComponent implements OnInit, OnDestroy {
  destroyed$: Subject<void> = new Subject();

  personalData: PassportTaxInfo;

  contactData: AddressInfo;

  extraData: TaxCompliance;

  is_forbidden: boolean = true;

  constructor(
    public auth: AuthService,
    public profileService: ProfileService,
    private cd: ChangeDetectorRef,
    private loader: LoaderService,
    private toaster: ToasterService,
    private translation: TranslationService
  ) {}

  ngOnInit(): void {
    this.loader.showLoader();
    forkJoin([
      this.profileService.getPassportTaxInfo(false),
      this.profileService.getAddress(false),
      this.profileService.getTaxCompliance(false),
    ]).subscribe({
      next: (results: [PassportTaxInfo, AddressInfo, TaxCompliance]) => {
        this.personalData = results[0];
        this.contactData = results[1];
        this.extraData = results[2];
        this.cd.detectChanges();
        this.cd.markForCheck();
        this.loader.hideLoader();
      },
      error: () => {
        this.loader.hideLoader();
      },
    });

    this.auth.isAllowedToChangeData$
      .pipe(
        takeUntil(this.destroyed$),
        filter((n) => !!this.auth.userProfile$.value)
      )
      .subscribe((data) => {
        if (data?.result === false) {
          this.is_forbidden = true;
          this.toaster.showError(this.translation.translate(data.reason));
        } else {
          this.is_forbidden = false;
        }
      });
  }

  getPassportInfoStr(passport: Passport) {
    switch (passport?.type) {
      case PassportType.passport:
        return this.translation.translate('PASSPORT_INFO', [
          'РБ',
          passport?.id_number ? passport?.id_number : this.translation.translate('NOT_FOUND'),
          passport?.number ? passport?.number : this.translation.translate('NOT_FOUND'),
          passport?.date_of_issue ? formatDate(passport?.date_of_issue?.toLocaleString()) : '-',
          passport?.date_of_expiration ? formatDate(passport?.date_of_expiration?.toLocaleString()) : '-',
          passport?.issued_by || '-',
        ]);
      case PassportType.id_card:
        return this.translation.translate('ID_CARD_INFO_V2', [
          passport?.id_number || this.translation.translate('NOT_FOUND'),
          passport?.number || this.translation.translate('NOT_FOUND'),
          passport?.date_of_issue ? formatDate(passport?.date_of_issue?.toLocaleString()) : '-',
          passport?.date_of_expiration ? formatDate(passport?.date_of_expiration?.toLocaleString()) : '-',
          passport?.biometric_number || this.translation.translate('NOT_FOUND'),
          passport?.biometric_date_of_issue ? formatDate(passport?.biometric_date_of_issue?.toLocaleString()) : '-',
          passport?.biometric_date_of_expiration
            ? formatDate(passport?.biometric_date_of_expiration?.toLocaleString())
            : '-',
          passport?.issued_by_code || '-',
        ]);
      case PassportType.rf_passport:
        return this.translation.translate('RF_PASSPORT_INFO', [
          passport?.number ? passport?.number : this.translation.translate('NOT_FOUND'),
          passport?.date_of_issue ? formatDate(passport?.date_of_issue?.toLocaleString()) : '-',
          passport?.issued_by || '-',
          passport?.issued_by_code || '-',
        ]);
      default:
        return '-';
    }
  }

  getAddressStr(address: Address) {
    const notIndicatedStr = this.translation.translate('NOT_INDICATED');
    switch (address?.country) {
      case CountryCode.BY:
        return this.translation.translate('ADDRESS_INFO_2', [
          address?.country ? this.translation.translate('COUNTRY:' + address?.country) : notIndicatedStr,
          address?.locality || notIndicatedStr,
          address?.street || notIndicatedStr,
          address?.zip_code || notIndicatedStr,
          address?.house_number || notIndicatedStr,
          address?.house_unit || notIndicatedStr,
          address?.apartment_number || notIndicatedStr,
        ]);
      case CountryCode.RU:
        return this.translation.translate('RF_ADDRESS_INFO', [
          address?.country ? this.translation.translate('COUNTRY:' + address?.country) : notIndicatedStr,
          address?.region_rus?.name || notIndicatedStr,
          address?.district_rus?.name || notIndicatedStr,
          address?.city_rus?.name || notIndicatedStr,
          address?.locality_rus?.name || notIndicatedStr,
          address?.street_rus?.name || notIndicatedStr,
          address?.zip_code || notIndicatedStr,
          address?.house_number || notIndicatedStr,
          address?.house_unit || notIndicatedStr,
          address?.apartment_number || notIndicatedStr,
        ]);
      default:
        return '-';
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
