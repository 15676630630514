<div class="error-container" [@open]="'opened'">
  <div class="image">
    <img src="./assets/images/500_error.svg" alt="logo">
  </div>
  <div class="content">
    <p>{{ 'INTERNAL_SERVER_ERROR_CONTENT' | translate }}</p>
  </div>
  <div class="reload">
    <button mat-button class="filled w-100" type="button" (click)="reload()">
      {{ 'RELOAD' | translate }}
    </button>
  </div>
</div>