import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { apiUrlWithPrefix } from '@env';
import { TranslationService } from '@localization';
import { BehaviorSubject, Observable, forkJoin, map, mergeMap } from 'rxjs';
import { Attachment, AttachmentBase } from 'src/app/interfaces/api/attachment.interface';
import { ClaimBase, ClaimResponse, ClaimsBatchResponse } from 'src/app/interfaces/api/claims.interface';
import { AiUiConfirmDialogComponent } from 'src/app/shared/shared-components/ai-ui-confirm-dialog/ai-ui-confirm-dialog.component';
import { ToasterService } from 'src/app/shared/shared-services/toaster/toaster.service';
import { ClaimCategory, ConfirmOperation } from 'src/app/utils/utils';
import { LoaderService } from '../loader/loader.service';

@Injectable({
  providedIn: 'root',
})
export class ClaimsService {
  static readonly CLAIMS = `${apiUrlWithPrefix}/claim/`;
  static readonly CLAIMS_DOCUMENT = `${apiUrlWithPrefix}/claim-document/`;
  static readonly CLAIM_ATTACH_W8 = `${apiUrlWithPrefix}/claim-document/w8-scan/`;
  static readonly CLAIMS_BATCH = `${apiUrlWithPrefix}/claims-batch/`;
  static readonly CLAIM_ATTACHMENT = `${apiUrlWithPrefix}/claim-attachment/`;
  static readonly CLAIM_REASON_DOCUMENT = `${apiUrlWithPrefix}/claim-reason-document/`;
  static readonly ADDITIONAL_CONSENT = `${apiUrlWithPrefix}/claim-attachment/additional-consent/`;

  claimAttachmentUrl = (id: number): string => `${apiUrlWithPrefix}/claim-attachment/${id}/preview/`;
  claimDocumentAttachmentUrl = (id: number): string => `${apiUrlWithPrefix}/claim-document/${id}/preview/`;
  claimReasonDocumentUrl = (id: number): string => `${apiUrlWithPrefix}/claim-reason-document/${id}/preview/`;
  claimDocumentComplexDownloadUrl = (id: number): string =>
    `${apiUrlWithPrefix}/claim-document/${id}/complex-download/`;

  claimAttachmentComplexDownloadUrl = (id: number): string =>
    `${apiUrlWithPrefix}/claim-attachment/${id}/complex-download/`;

  claimSignAttachmentRequest = (id: number): string => `${apiUrlWithPrefix}/claim-attachment/${id}/sign/`;
  claimSignAttachmentConfirm = (id: number): string => `${apiUrlWithPrefix}/claim-attachment/${id}/sign-confirm/`;

  public attachments$: BehaviorSubject<Attachment[]> = new BehaviorSubject<Attachment[]>(null);

  constructor(
    private http: HttpClient,
    private toaster: ToasterService,
    private translation: TranslationService,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private loader: LoaderService,
    private router: Router
  ) {}

  claims(type: string) {
    const httpParams = type
      ? new HttpParams({
          fromObject: {
            category: type,
          },
        })
      : null;
    return this.http
      .get(ClaimsService.CLAIMS, {
        params: httpParams,
      })
      .pipe(
        map((res: any) => {
          return res.results as ClaimResponse[];
        })
      );
  }

  cancelClaim(id: number) {
    return this.http.put(`${ClaimsService.CLAIMS}${id}/cancel/`, {});
  }

  claimAttachmentsById(claimId: number) {
    const httpParams = new HttpParams({
      fromObject: {
        claim: claimId,
      },
    });
    return this.http
      .get(ClaimsService.CLAIM_ATTACHMENT, {
        params: httpParams,
      })
      .pipe(
        map((res: any) => {
          return res.results as AttachmentBase[];
        })
      );
  }

  claimsByBrokerageService() {
    const httpParams = new HttpParams({
      fromObject: {
        category: ClaimCategory.broker_service,
      },
    });
    return this.http
      .get(ClaimsService.CLAIMS, {
        params: httpParams,
      })
      .pipe(
        map((res: any) => {
          return res.results as ClaimResponse[];
        })
      );
  }

  claimById(id: number) {
    return this.http.get(`${ClaimsService.CLAIMS}${id}/`).pipe(
      map((res: any) => {
        return res as ClaimResponse;
      })
    );
  }

  claimsBatchDocumentsById(claimsBatchId: number) {
    const httpParams = new HttpParams({
      fromObject: {
        claims_batch: claimsBatchId,
      },
    });
    return this.getDocuments(httpParams);
  }

  claimDocumentsById(claimId: number) {
    const httpParams = new HttpParams({
      fromObject: {
        claim: claimId,
      },
    });
    return this.getDocuments(httpParams);
  }

  claimReasonDocumentsById(claimId: number) {
    const httpParams = new HttpParams({
      fromObject: {
        claim: claimId,
      },
    });
    return this.getClaimReasonsDocuments(httpParams);
  }

  additionalConsent() {
    return this.http.get(ClaimsService.ADDITIONAL_CONSENT).pipe(map((res: any) => res as AttachmentBase));
  }

  private getDocuments(httpParams: HttpParams) {
    return this.http
      .get(ClaimsService.CLAIMS_DOCUMENT, {
        params: httpParams,
      })
      .pipe(
        map((res: any) => {
          return res.results as AttachmentBase[];
        })
      );
  }

  private getClaimReasonsDocuments(httpParams: HttpParams) {
    return this.http
      .get(ClaimsService.CLAIM_REASON_DOCUMENT, {
        params: httpParams,
      })
      .pipe(
        map((res: any) => {
          return res.results as AttachmentBase[];
        })
      );
  }

  deleteDocument(id: number) {
    return this.http.delete(`${ClaimsService.CLAIMS_DOCUMENT}${id}/`);
  }

  fileToBlob(url: string): Observable<any> {
    return this.http.get(url, { responseType: 'blob' }).pipe(
      map((res) => {
        return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(res));
      })
    );
  }

  addClaimsBatch(data: ClaimBase[]) {
    return this.http
      .post(ClaimsService.CLAIMS_BATCH, {
        claims: data,
      })
      .pipe(
        map((res: ClaimsBatchResponse) => {
          return res as ClaimsBatchResponse;
        })
      );
  }

  attachW8BEN(file: FormData): Observable<AttachmentBase> {
    return this.http.post<AttachmentBase>(ClaimsService.CLAIM_ATTACH_W8, file);
  }

  addClaimAttachment(file: FormData): Observable<AttachmentBase> {
    return this.http.post<AttachmentBase>(ClaimsService.CLAIM_ATTACHMENT, file);
  }

  addClaimReasonDocument(file: FormData): Observable<AttachmentBase> {
    return this.http.post<AttachmentBase>(ClaimsService.CLAIM_REASON_DOCUMENT, file);
  }

  deleteClaimReasonDocument(id: number) {
    return this.http.delete(`${apiUrlWithPrefix}/claim-reason-document/${id}/`);
  }

  deleteAttachment(id: number) {
    return this.http.delete(`${apiUrlWithPrefix}/claim-attachment/${id}/`);
  }

  sendToSignAttachment(id: number) {
    return this.http.get(this.claimSignAttachmentRequest(id));
  }

  confirmAttachentSign(id: number, code: string) {
    return this.http.post(this.claimSignAttachmentConfirm(id), code);
  }

  sendToSign(claimBatchId: number, updatedAllowedSign: boolean = false, claimIdForRedirection: number = 0) {
    this.http.get(`${ClaimsService.CLAIMS_BATCH}${claimBatchId}/sign/`).subscribe({
      next: (data: any) => {
        this.dialog
          .open(AiUiConfirmDialogComponent, {
            data: {
              operation: ConfirmOperation.phone,
              updatedAllowedSign: updatedAllowedSign,
              callback: this.claimsBatchConfirm.bind(this, claimBatchId),
            },
          })
          .afterClosed()
          .subscribe({
            next: (res: boolean) => {
              if (res) {
                if (claimIdForRedirection > 0) {
                  this.router.navigate(['claims', claimIdForRedirection]);
                } else {
                  this.router.navigate(['claims']);
                }
                this.toaster.showSuccess(this.translation.translate('BS_HAS_BEEN_CREATED'));
              }
            },
          });
        this.loader.hideLoader();
      },
      error: () => {
        this.loader.hideLoader();
      },
    });
  }

  claimsBatchConfirm(id: number, code: string) {
    return this.http.post(`${ClaimsService.CLAIMS_BATCH}${id}/sign-confirm/`, code);
  }
}
