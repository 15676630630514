import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiUrlWithPrefix } from '@env';
import { map } from 'rxjs';
import { NotificationSettings } from 'src/app/interfaces/api/notification';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  static readonly NOTIFICATION_SETTINGS = `${apiUrlWithPrefix}/user/notification-settings/`;

  constructor(private http: HttpClient) {}

  getSettings() {
    return this.http.get(NotificationService.NOTIFICATION_SETTINGS).pipe(
      map((res) => {
        return res as NotificationSettings;
      })
    );
  }

  updateSettings(data: NotificationSettings) {
    return this.http.put(NotificationService.NOTIFICATION_SETTINGS, {
      ...data,
    });
  }
}
