<div class="requisites">
  <div class="pair">
    <span class="key">
      {{ 'REFILL_ACCOUNT:RECEIVER' | translate }}&nbsp;
    </span>
    <span class="value">
      {{ refill_method.payment_receiver }}
    </span>
    <div class="copy">
      <mat-icon class="material-icons-outlined"
        (click)="copyValue(refill_method.payment_receiver)">file_copy</mat-icon>
    </div>
  </div>
  <div class="pair">
    <span class="key">
      {{ 'REFILL_ACCOUNT:INN' | translate }}&nbsp;
    </span>
    <span class="value">
      {{ refill_method.inn }}
    </span>
    <div class="copy">
      <mat-icon class="material-icons-outlined"
        (click)="copyValue(refill_method.inn)">file_copy</mat-icon>
    </div>
  </div>
  <div class="pair">
    <span class="key">
      {{ 'REFILL_ACCOUNT:BANK_NAME' | translate }}&nbsp;
    </span>
    <span class="value">
      {{ refill_method.bank_name }}
    </span>
    <div class="copy">
      <mat-icon class="material-icons-outlined"
        (click)="copyValue(refill_method.bank_name)">file_copy</mat-icon>
    </div>
  </div>
  <div class="pair">
    <span class="key">
      {{ 'REFILL_ACCOUNT:BANK_ADDRESS' | translate }}&nbsp;
    </span>
    <span class="value">
      {{ refill_method.bank_address }}
    </span>
    <div class="copy">
      <mat-icon class="material-icons-outlined"
        (click)="copyValue(refill_method.bank_address)">file_copy</mat-icon>
    </div>
  </div>
  <div class="pair">
    <span class="key">
      {{ 'REFILL_ACCOUNT:SWIFT' | translate }}&nbsp;
    </span>
    <span class="value">
      {{ refill_method.swift_code }}
    </span>
    <div class="copy">
      <mat-icon class="material-icons-outlined"
        (click)="copyValue(refill_method.swift_code)">file_copy</mat-icon>
    </div>
  </div>
  <div class="pair">
    <span class="key">
      {{ 'REFILL_ACCOUNT:CHECKING_ACCOUNT' | translate }}&nbsp;
    </span>
    <span class="value">
      {{ refill_method.checking_account }}
    </span>
    <div class="copy">
      <mat-icon class="material-icons-outlined"
        (click)="copyValue(refill_method.checking_account)">file_copy</mat-icon>
    </div>
  </div>
  <div class="pair" *ngIf="refill_method.currency==='RUB'">
    <span class="key">
      {{ 'REFILL_ACCOUNT:CORRESPONDENT_ACCOUNT' | translate }}&nbsp;
    </span>
    <span class="value">
      {{ refill_method.correspondent_account }}
    </span>
    <div class="copy">
      <mat-icon class="material-icons-outlined"
        (click)="copyValue(refill_method.correspondent_account)">file_copy</mat-icon>
    </div>
  </div>
  <div class="pair">
    <span class="key">
      {{ 'REFILL_ACCOUNT:PURPOSE_OF_PAYMENT' | translate }}&nbsp;
    </span>
    <span class="value">
      {{ purposeOfPayment }}
    </span>
    <div class="copy">
      <mat-icon class="material-icons-outlined"
        (click)="copyValue(purposeOfPayment)">file_copy</mat-icon>
    </div>
  </div>
</div>