<div class="container">
  <div class="row align-items-center">
      <div class="img col-sm-2 col-12">
        <img width="48px"
          src="https://lk.aigenis.ru/assets/young_ui/banks/default-90075b0ffccd5279ba01827c7bf4c08c501167dba7c7573b86a013143341b6d7.png"
          alt="logo">
      </div>
      <div class="bank-info col-sm-4 col-12">
        <span>{{ 'BANK' | translate }}</span>
        <p>{{ bankAccount?.bank?.name }}</p>
      </div>
      <div class="bank-info col-sm-4 col-12">
        <span>{{ 'BANK:ACCOUNT_NUMBER' | translate }}</span>
        <p>{{ bankAccount.checking_account }}</p>
      </div>
      <div class="bank-info col-sm-2 col-12">
        <span>{{ 'BANK:CURRENCY' | translate }}</span>
        <p>{{ bankAccount.currency_code }}</p>
      </div>
      <!-- <div class="col-sm-1">
        <mat-icon click="onEditBankAccount(bankAccount.id)">edit</mat-icon>
      </div> -->
  </div>
</div>