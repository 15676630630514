import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslationService } from '@localization';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { ConfirmOperation, EMAIL_REGEXP } from 'src/app/utils/utils';
import { ToasterService } from '../../shared-services/toaster/toaster.service';
import { AiUiConfirmDialogComponent } from '../ai-ui-confirm-dialog/ai-ui-confirm-dialog.component';

@Component({
  selector: 'app-ai-ui-change-email',
  templateUrl: './ai-ui-change-email.component.html',
  styleUrls: ['./ai-ui-change-email.component.scss'],
})
export class AiUiChangeEmailComponent implements OnInit {
  form: FormGroup;

  constructor(
    private authService: AuthService,
    private profileService: ProfileService,
    private dialogRef: MatDialogRef<AiUiChangeEmailComponent>,
    private dialog: MatDialog,
    private loader: LoaderService,
    private translation: TranslationService,
    private toaster: ToasterService
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.form = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.pattern(EMAIL_REGEXP)]),
    });
  }

  sendCode() {
    if (this.form.invalid) {
      return;
    }
    this.dialogRef.close();
    const email = this.form.value.email;
    this.loader.showLoader();
    this.profileService.changeEmail(email).subscribe({
      next: (data: any) => {
        this.dialog
          .open(AiUiConfirmDialogComponent, {
            data: {
              attempt_id: data.attempt_id,
              email,
              operation: ConfirmOperation.email,
              callback: this.profileService.changeEmailConfirm.bind(this.profileService),
            },
          })
          .afterClosed()
          .subscribe({
            next: (res: boolean) => {
              if (res) {
                this.authService.getUserProfile();
                this.toaster.showSuccess(this.translation.translate('EMAIL_HAS_BEEN_CHANGED'));
              }
            },
          });
        this.loader.hideLoader();
      },
      error: () => {
        this.form.enable();
        this.loader.hideLoader();
      },
    });
  }
}
