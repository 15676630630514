export const PHONE_CONFIRM_DUMMY_CODE: number = 1234;
export const PHONE_CONFIRM_CHECK_CODE_ATTEMPTS_LIMIT: number = 3;
export const PHONE_CONFIRM_ATTEMPT_IN_SECONDS: number = 30;
export const BROKER_AGREEMENT_DEFAULT_FILE_NAME: string = 'Broker Agreement.pdf';
export const DEPOSITARY_AGREEMENT_DEFAULT_FILE_NAME: string = 'Depositary Agreement.pdf';
export const FOREIGN_TAXPAYER_DEFAULT_FILE_NAME: string = 'Foreign Taxpayer Form.pdf';
export const CLIENT_QUESTIONNAIRE_DEFAULT_FILE_NAME: string = 'Client Questionnaire.pdf';
export const CLIENT_QUESTIONNAIRE_EDIT_DEFAULT_FILE_NAME: string = 'Client Questionnaire (edit).pdf';
export const CURRENCY_INSTRUMENTS_DEFAULT_FILE_NAME: string = 'Currency Instruments Trade.pdf';
export const MANUAL_ASSIGNMENT_FILE_NAME: string = 'Manual assignment.pdf';
export const CONSENT_TO_ELECTRONIC_DOCUMENT_MANAGEMENT_FILE_NAME: string =
  'Consent to electronic document management.pdf';
export const CONSENT_TO_PERSONAL_DATA_PROCESSING_FILE_NAME: string = 'Consent to personal data processing.pdf';
export const WITHDRAWAL_MONEY_FILE_NAME: string = 'Withdraw Money.pdf';
export const FATCA_FILE_NAME: string = 'fatca.pdf';
export const PROFILE_FILE_NAME: string = 'profile.pdf';
export const EDM_FILE_NAME: string = 'edm.pdf';
export const PERSONAL_DATA_FILE_NAME: string = 'personal_data.pdf';
export const DEPOSITARY_ACCOUNT_REFERENCE_FILE_NAME: string = 'Depositary account reference.pdf';
export const DEPOSITARY_CREATE_ACCOUNT_ORDER_FILE_NAME: string = 'Depositary Create Account Order.pdf';
export const TRANSACTION_REFERENCE_REGISTRY_FILE_NAME: string = 'Transaction reference registry.pdf';
export const TERMINATE_AGREEMENT_FILE_NAME: string = 'Terminate Agreement.pdf';
export const MONEY_TRANSFER_FILE_NAME: string = 'Money Transfer.pdf';
export const WITHDRAW_EXCHANGE_MONEY_FILE_NAME: string = 'Withdraw Exchange Money.pdf';
export const BROKERAGE_ADDITIONAL_CONSENT_FILE_NAME: string = 'Brokerage additional consent.pdf';
export const DEPOSITARY_ADDITIONAL_CONSENT_FILE_NAME: string = 'Depositary additional consent.pdf';
export const OPENING_DEPO_ACCOUNT_FILE_NAME: string = 'Instruction to open DEPO account.pdf';
export const APPOINTMENT_OF_AUTHORIZED_PERSON_FILE_NAME: string = 'Appointment of authorized person.pdf';
export const POWER_OF_ATTORNEY_FILE_NAME: string = 'Power of attorney.pdf';

export const SECURITIES_TRADE_PREFIX_FILE_NAME: string = 'Securities Trade';
