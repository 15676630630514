import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslationService } from '@localization';
import { ChangePasswordRequest } from 'src/app/interfaces/api/password-recovery.interface';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { PasswordService } from 'src/app/services/password-recovery/password-recovery.service';
import {
  DIGITS_REGEXP,
  getPasswordValidationMessage,
  LOWERCASE_REGEXP,
  passwordPatternValidator,
  SPECIAL_CHARACTERS_REGEXP,
  UPPERCASE_REGEXP,
} from 'src/app/utils/utils';
import { ToasterService } from '../../shared-services/toaster/toaster.service';

@Component({
  selector: 'app-ai-ui-change-password',
  templateUrl: './ai-ui-change-password.component.html',
  styleUrls: ['./ai-ui-change-password.component.scss'],
})
export class AiUiChangePasswordComponent implements OnInit {
  passwordForm: FormGroup;

  readonly getPasswordValidationMessage = getPasswordValidationMessage;

  constructor(
    private passwordService: PasswordService,
    private loader: LoaderService,
    private toaster: ToasterService,
    private translation: TranslationService,
    private dialogRef: MatDialogRef<AiUiChangePasswordComponent>
  ) {}

  ngOnInit(): void {
    this.passwordForm = this.initPasswordForm();
  }

  initPasswordForm() {
    return new FormGroup({
      old_password: new FormControl(null, [Validators.required]),
      new_password: new FormControl(null, [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(LOWERCASE_REGEXP),
        Validators.pattern(UPPERCASE_REGEXP),
        Validators.pattern(DIGITS_REGEXP),
        Validators.pattern(SPECIAL_CHARACTERS_REGEXP),
        passwordPatternValidator,
      ]),
    });
  }

  submit() {
    if (this.passwordForm.invalid) {
      return;
    }
    const payload = this.passwordForm.value as ChangePasswordRequest;
    this.loader.showLoader();
    this.passwordService.changePassword(payload).subscribe({
      next: () => {
        this.dialogRef.close();
        this.toaster.showSuccess(this.translation.translate('PASSWORD_HAS_BEEN_CHANGED'));
        this.loader.hideLoader();
      },
      error: (err: HttpErrorResponse) => {
        const code = err.error[0]?.code || err.error.code;
        if (code === 'old_password_is_incorrect') {
          this.toaster.showError(this.translation.translate('ERROR:old_password_is_incorrect'));
        }
        this.loader.hideLoader();
      },
    });
  }
}
