import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProfileComponent } from './profile.component';
import { ProfileInfoComponent } from './profile-info/profile-info.component';
import { ProfileEditComponent } from './profile-edit/profile-edit.component';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslationModule } from '@localization';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { SharedPipesModule } from '../../../shared/shared-pipes/shared-pipes.module';
import { NgxMaskModule } from 'ngx-mask';
import { SharedModule } from 'src/app/shared/shared-components/shared.module';
import { QualificationsComponent } from './qualifications/qualifications.component';
import { SettingsComponent } from './settings/settings.component';
import { SharedDirectivesModule } from 'src/app/shared/shared-directives/shared-directives.module';
import { DocumentsComponent } from './documents/documents.component';
import { MessagesComponent } from './messages/messages.component';
import { PreliminaryComponent } from './messages/preliminary/preliminary.component';
import { ConversationsComponent } from './conversations/conversations.component';

const routes: Routes = [
  {
    path: '',
    component: ProfileComponent,
    children: [
      {
        path: '',
        redirectTo: 'info',
        pathMatch: 'full',
      },
      {
        path: 'info',
        component: ProfileInfoComponent,
        pathMatch: 'full',
      },
      {
        path: 'edit',
        component: ProfileEditComponent,
      },
      {
        path: 'documents',
        component: DocumentsComponent,
      },
      {
        path: 'tickets',
        component: ConversationsComponent,
      },
      {
        path: 'messages_new',
        component: PreliminaryComponent,
      },
      {
        path: 'messages/:id',
        component: MessagesComponent,
      },
      {
        path: 'qualifications',
        component: QualificationsComponent,
      },
      {
        path: 'settings',
        component: SettingsComponent,
      },
    ],
  },
];

const ProfileRouterModule: ModuleWithProviders<RouterModule> = RouterModule.forChild(routes);

@NgModule({
  declarations: [
    ProfileComponent,
    ProfileInfoComponent,
    ProfileEditComponent,
    QualificationsComponent,
    SettingsComponent,
    DocumentsComponent,
    MessagesComponent,
    PreliminaryComponent,
    ConversationsComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    TranslationModule,
    NgxMatIntlTelInputComponent,
    ProfileRouterModule,
    SharedModule,
    SharedPipesModule,
    SharedDirectivesModule,
    NgxMaskModule,
  ],
  providers: [],
})
export class ProfileModule {}
