<div class="close"
  mat-dialog-close>
  <mat-icon>close</mat-icon>
</div>
<ng-container *ngIf="refillPaymentService.stepKey===0">
  <div class="container-fluid bg-white p-4">
    <div class="title mb-2">
      <h3>{{ 'REFILL_ACCOUNT:CHOOSE_MARKET' | translate }}</h3>
    </div>
    <div class="row">
      <div class="categories">
        <div class="category-cards">
          <div class="col-12"
            *ngFor="let category of categories">
            <div class="category-card"
            (click)="next(category)">
              <img src="./assets/logos/currency_deal.svg"
                alt="logo">
              <span>{{ category | translate }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="refillPaymentService.stepKey===1">
  <div class="container">
    <div class="title mb-2">
      <h3>{{ 'REFILL_ACCOUNT:TITLE' | translate }}</h3>
    </div>
    <div class="payment-methods mt-3">
      <ng-container *ngIf="auth.brokerageService$ | async as brokerage_agreement">
        <ng-container
          *ngIf="paymentMethodsWithConsent && brokerage_agreement?.created_by_claim.broker_can_use_client_money">
          <mat-expansion-panel class="mat-elevation-z0"
            expanded>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span>{{ 'REFILL_ACCOUNT:BANK_REQUISITES_WITH_CONSENT' | translate }}</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-tab-group mat-stretch-tabs="true">
              <div *ngFor="let refill_method of paymentMethodsWithConsent"
                class="method">
                <mat-tab [label]="refill_method.currency">
                  <ai-ui-refill-account-method-info [refill_method]="refill_method" [purposeOfPayment]="purposeOfPayment"></ai-ui-refill-account-method-info>
                </mat-tab>
              </div>
            </mat-tab-group>
          </mat-expansion-panel>
        </ng-container>

        <ng-container
          *ngIf="paymentMethodsWithoutConsent  && brokerage_agreement?.created_by_claim.broker_can_use_client_money===false">
          <mat-expansion-panel class="mat-elevation-z0"
            expanded>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span>{{ 'REFILL_ACCOUNT:BANK_REQUISITES_WITHOUT_CONSENT' | translate }}</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-tab-group mat-stretch-tabs="true">
              <div *ngFor="let refill_method of paymentMethodsWithoutConsent"
                class="method">
                <mat-tab [label]="refill_method.currency">
                  <ai-ui-refill-account-method-info [refill_method]="refill_method" [purposeOfPayment]="purposeOfPayment"></ai-ui-refill-account-method-info>
                </mat-tab>
              </div>
            </mat-tab-group>
          </mat-expansion-panel>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-container>