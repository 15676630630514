<div class="container-fluid bg-white p-4" [@open]="'opened'">
  <h1>{{ 'QUALIFICATIONS:TITLE' | translate }}</h1>
  <div class="subtitle">
    <p>{{ 'QUALIFICATIONS:SUBTITLE' | translate }}</p>
  </div>
  <div class="row indent">
    <div class="d-flex justify-content-start mb-3">
      <button class="filled" mat-flat-button routerLink="/claims/qualified_investor_new">
        {{ 'ADD_ORDER' | translate }}
      </button>
    </div>
  </div>
  <div class="row indent">
    <div class="title">
      <h3 class="mt-3">{{ 'QUALIFICATIONS:CONFITION_TITLE' | translate }}</h3>
    </div>
    <div class="condition col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12" *ngFor="let condition of conditions">
      <div class="condition__logo">
        <img [src]="condition.logo" alt="logo">
      </div>
      <div class="condition__title">
        <p>{{ condition.title }}</p>
      </div>
      <div class="condition__content">
        <p>{{ condition.content }}</p>
      </div>
    </div>
  </div>
</div>