import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslationService } from '@localization';
import { PasswordRecoveryRequest } from 'src/app/interfaces/api/password-recovery.interface';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { PasswordService } from 'src/app/services/password-recovery/password-recovery.service';
import { AiUiInfoDialogComponent } from 'src/app/shared/shared-components/ai-ui-info-dialog/ai-ui-info-dialog.component';
import { ToasterService } from 'src/app/shared/shared-services/toaster/toaster.service';
import { animationOptions } from 'src/app/utils/animation';
import {
  DIGITS_REGEXP,
  getPasswordValidationMessage,
  LOWERCASE_REGEXP,
  passwordPatternValidator,
  SPECIAL_CHARACTERS_REGEXP,
  UPPERCASE_REGEXP,
} from 'src/app/utils/utils';

@Component({
  selector: 'app-password-recovery-confirmation',
  templateUrl: './password-recovery-confirmation.component.html',
  styleUrls: ['./password-recovery-confirmation.component.scss'],
  animations: animationOptions,
})
export class PasswordRecoveryConfirmationComponent implements OnInit {
  passwordRecoveryRequest: PasswordRecoveryRequest;

  passwordForm: FormGroup;

  protected readonly getPasswordValidationMessage = getPasswordValidationMessage;

  constructor(
    public prService: PasswordService,
    private router: Router,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private toasterService: ToasterService,
    private translation: TranslationService,
    private loader: LoaderService
  ) {}

  ngOnInit(): void {
    this.initPasswordForm();
  }

  initPasswordForm() {
    this.passwordForm = new FormGroup(
      {
        new_password1: new FormControl(null, [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(LOWERCASE_REGEXP),
          Validators.pattern(UPPERCASE_REGEXP),
          Validators.pattern(DIGITS_REGEXP),
          Validators.pattern(SPECIAL_CHARACTERS_REGEXP),
          passwordPatternValidator,
        ]),
        new_password2: new FormControl(null, Validators.required),
      },
      { validators: [passwordMatchValidator] }
    );
  }

  submit() {
    if (this.passwordForm.invalid) {
      return;
    }
    const payolad: PasswordRecoveryRequest = {
      new_password1: this.passwordForm.value.new_password1,
      new_password2: this.passwordForm.value.new_password2,
      uid: this.activatedRoute.snapshot.paramMap.get('uid'),
      token: this.activatedRoute.snapshot.paramMap.get('token'),
    };
    this.loader.showLoader();
    this.prService.resetPasswordConfirmation(payolad).subscribe({
      complete: () => {
        this.dialog
          .open(AiUiInfoDialogComponent, {
            disableClose: true,
            data: {
              content: this.translation.translate('SUCCESS:PASSWORD_HAS_RECOVERED'),
            },
          })
          .afterClosed()
          .subscribe({
            next: () => {
              this.router.navigate(['users', 'sign_in']);
            },
          });
        this.loader.hideLoader();
      },
      error: (err: HttpErrorResponse) => {
        const passwordMismatch = err.error[0]?.code === 'password_mismatch';
        if (passwordMismatch) {
          this.toasterService.showError(this.translation.translate('ERROR:PASSWORD_MISMATCH'));
        }
        const incorrectUidOrToken = err.error[0]?.code === 'non_field_errors_incorrect_uid_or_token';
        if (incorrectUidOrToken) {
          this.toasterService.showError(this.translation.translate('ERROR:incorrect_uid_or_token'));
        }
        const passwordIsCommon = ['new_password1_invalid', 'new_password2_invalid'].indexOf(err.error[0]?.code) !== -1;
        if (passwordIsCommon) {
          this.toasterService.showError(this.translation.translate('ERROR:password_is_common'));
        }
        this.loader.hideLoader();
      },
    });
  }

  backToSign() {
    this.router.navigate(['users', 'sign_in']);
  }
}

function passwordMatchValidator(form: FormGroup) {
  const isMatch = form.get('new_password1').value === form.get('new_password2').value;
  isMatch
    ? delete form.get('new_password2')?.errors?.passwordMismatch
    : form.get('new_password2').setErrors({ passwordMismatch: true });
  return form.get('new_password2')?.errors;
}
