import { Pipe, PipeTransform } from '@angular/core';
import { FaqResponse } from 'src/app/interfaces/api/faq.interface';

@Pipe({
  name: 'faqFilter',
})
export class FaqFilterPipe implements PipeTransform {
  transform(items: FaqResponse[], status: string): FaqResponse[] {
    if (!items || !status) {
      return items;
    }
    return items.filter((i) => i.type === status);
  }
}
