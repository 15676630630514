import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslationService } from '@localization';
import { ChangeLoginRequest } from 'src/app/interfaces/api/profile.interface';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { ToasterService } from '../../shared-services/toaster/toaster.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-ai-ui-change-login',
  templateUrl: './ai-ui-change-login.component.html',
  styleUrls: ['./ai-ui-change-login.component.scss'],
})
export class AiUiChangeLoginComponent implements OnInit {
  form: FormGroup;

  constructor(
    private profileService: ProfileService,
    private loader: LoaderService,
    private toaster: ToasterService,
    private translation: TranslationService,
    private dialogRef: MatDialogRef<AiUiChangeLoginComponent>
  ) {}

  ngOnInit(): void {
    this.form = this.initForm();
  }

  initForm() {
    return new FormGroup({
      username: new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.required),
    });
  }

  submit() {
    if (this.form.invalid) {
      return;
    }
    this.loader.showLoader();
    const payload = this.form.value as ChangeLoginRequest;
    this.profileService.changeLogin(payload).subscribe({
      next: () => {
        this.toaster.showSuccess(this.translation.translate('LOGIN_HAS_BEEN_CHANGED'));
        this.dialogRef.close();
        this.loader.hideLoader();
      },
      error: () => {
        this.loader.hideLoader();
      },
    });
  }
}
