<div class="container">
	<h3>{{ 'SMS_CONFIRMATION' | translate }}</h3>
	<div class="sms-section text-start">
		<div class="send-code-info">
			<p *ngIf="data.operation==='phone'">{{ 'REGISTRATION:CODE_HAS_BEEN_SENT' |
				translate: [phoneNumber] }}</p>
			<p *ngIf="data.operation==='email'">{{
				'REGISTRATION:CODE_HAS_BEEN_SENT_TO_EMAIL' | translate: [data?.email] }}</p>
		</div>
		<mat-form-field appearance="outline">
			<mat-label>{{ 'REGISTRATION:CONFIRM_CODE' | translate }}</mat-label>
			<input (input)="confirm()"
				[showMaskTyped]="true"
				[formControl]="code"
				mask="0000"
				matInput
				required />
			<mat-error *ngIf="code.errors?.required">
				{{'ERROR:REQUIRED' | translate }}
			</mat-error>
		</mat-form-field>
	</div>
</div>