import { Component, Input } from '@angular/core';
import { RefillAccountResponse } from 'src/app/interfaces/api/refill-account.interface';
import { ToasterService } from '../../shared-services/toaster/toaster.service';
import { TranslationService } from '@localization';

@Component({
  selector: 'ai-ui-refill-account-method-info',
  templateUrl: './ai-ui-refill-account-method-info.component.html',
  styleUrls: ['./ai-ui-refill-account-method-info.component.scss'],
})
export class AiUiRefillAccountMethodInfoComponent {
  @Input() refill_method: RefillAccountResponse;
  @Input() purposeOfPayment: string;
  constructor(private toaster: ToasterService, private translation: TranslationService) {}

  copyValue(value: string) {
    navigator.clipboard.writeText(value);
    this.toaster.showInfo(this.translation.translate('REFILL_ACCOUNT:COPIED'));
  }
}
