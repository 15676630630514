import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[dateFormat]',
})
export class DateDirective {
  private _allowedKeys: string[] = [
    'Backspace',
    'Tab',
    'Enter',
    'Escape',
    'ArrowLeft',
    'ArrowRight',
    'Delete',
    'PrintScreen',
  ];

  pattern: RegExp = new RegExp('^[0-9.]+$');

  element: HTMLInputElement;

  constructor(element: ElementRef) {
    this.element = element.nativeElement;
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: any) {
    if (this._allowedKeys.includes(event.key)) {
      return true;
    }
    if (
      !this.pattern.test(event.key) ||
      (event.key === '.' && !this.element.value) ||
      (event.key === '.' && ![2, 5].includes(event.target.value.length)) ||
      event.target.value.length + event.key.length === 11
    ) {
      return false;
    }
    if ([2, 5].includes(event.target.value.length) && event.key !== '.') {
      event.target.value = event.target.value + '.';
    }
    return true;
  }

  @HostListener('paste', ['$event'])
  onPaste(event: any) {
    return false;
  }
}
