import { Component, OnInit } from '@angular/core';
import { TranslationService } from '@localization';
import { AuthService } from 'src/app/services/auth/auth.service';
import { animationOptions } from 'src/app/utils/animation';
import { BsType, ProfileStatus } from 'src/app/utils/utils';

export interface OperationType {
  logo: string;
  name: string;
  url: string;
}

@Component({
  selector: 'app-claim-selection',
  templateUrl: './claim-selection.component.html',
  styleUrls: ['./claim-selection.component.scss'],
  animations: animationOptions,
})
export class ClaimSelectionComponent implements OnInit {
  bs_operations: OperationType[];

  depo_operations: OperationType[];

  constructor(public auth: AuthService, private translation: TranslationService) {}

  ngOnInit(): void {
    if (this.auth.userProfile$.value?.status === ProfileStatus.pii_not_filled) {
      return;
    }
    this.bs_operations = [
      {
        logo: './assets/logos/cash_transfer.svg',
        name: this.translation.translate(BsType.securities_trade),
        url: '/claims/bo_nrd_papers_deal_new',
      },
      {
        logo: './assets/logos/currency_deal.svg',
        name: this.translation.translate(BsType.currency_instruments_trade),
        url: '/claims/bo_currency_instruments_new',
      },
      // {
      //   logo: './assets/logos/income_statement.svg',
      //   name: this.translation.translate('CLAIM_TYPE:INCOME_STATEMENT'),
      //   url: '',
      // },
      {
        logo: './assets/logos/cash_withdrawal.svg',
        name: this.translation.translate('CLAIM_TYPE:CASH_WITHDRAWAL'),
        url: '/claims/bo_withdraw_money_new',
      },
      // {
      //   logo: './assets/logos/cash_withdrawal.svg',
      //   name: this.translation.translate('CLAIM_TYPE:CASH_WITHDRAWAL_FROM_AREA'),
      //   url: '/claims/bo_withdraw_area_money_new',
      // },
      {
        logo: './assets/logos/cash_transfer.svg',
        name: this.translation.translate('CLAIM_TYPE:CASH_TRANSFER'),
        url: '/claims/bo_transfer_money_new',
      },
      {
        logo: './assets/logos/contract_termination.svg',
        name: this.translation.translate(BsType.terminate_agreement),
        url: '/claims/bo_termination_new',
      },
      {
        logo: './assets/logos/arbitrary_order.svg',
        name: this.translation.translate('CLAIM_TYPE:ARBITRARY_ORDER'),
        url: '/claims/bo_manual_assignment_new',
      },
    ];
    this.depo_operations = [
      {
        logo: './assets/logos/income_statement.svg',
        name: this.translation.translate('CLAIM_TYPE:PROVISION'),
        url: '/claims/depo_reference_new',
      },
      {
        logo: './assets/logos/cash_withdrawal.svg',
        name: this.translation.translate('CLAIM_TYPE:TRANSACTION_REFERENCE_REGISTRY'),
        url: '/claims/depo_transaction_reference_registry_new',
      },
      {
        logo: './assets/logos/cash_withdrawal.svg',
        name: this.translation.translate('CLAIM_TYPE:TRANSACTION_REFERENCE'),
        url: '/claims/depo_transaction_reference_new',
      },
      {
        logo: './assets/logos/cash_transfer.svg',
        name: this.translation.translate('CLAIM_TYPE:TRANSACTION_TRANSFER_REFERENCE'),
        url: '/claims/depo_transaction_transfer_reference_new',
      },
      {
        logo: './assets/logos/arbitrary_order.svg',
        name: this.translation.translate('CLAIM_TYPE:ARBITRARY_ORDER'),
        url: '/claims/depo_manual_assignment_new',
      },
    ];
  }
}
