<div class="container-fluid bg-white p-4"
  [@open]="'opened'">
  <h1>{{ 'CLAIM:FILLING' | translate }}</h1>
  <div class="hint">
    <p>{{ 'reference_provision' | translate }}</p>
  </div>
  <div class="row">
    <ui-step-view class="col-12 mt-3"
      [total]="2"
      [current]="cfService.stepKey + 1"
      [stepValues]="['CLAIM_STEP:CREATION', 'CLAIM_STEP:SIGN']">
    </ui-step-view>
    <div class="order-container">
      <div class="form-data  col-12">
        <ng-container *ngIf="cfService.stepKey===0">
          <form [formGroup]="form">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'CLAIM:OPERATION_INITIATOR' | translate }}</mat-label>
              <mat-select formControlName="operation_initiator">
                <mat-option *ngFor="let initiator of initiators"
                  [value]="initiator">
                  {{ initiator | translate }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="form.get('operation_initiator').errors?.required">
                {{'ERROR:REQUIRED' | translate }}
              </mat-error>
            </mat-form-field>
            <div>
              <mat-form-field appearance="outline">
                <mat-label>{{ 'CLAIM:DEPO_ACCOUNT' | translate }}</mat-label>
                <mat-select formControlName="depositary_account">
                  <ng-container *ngIf="!depositaryAccounts.length;else accountsChoice">
                    <mat-option [disabled]="true">{{ 'NOTHING_FOUND' | translate }}</mat-option>
                  </ng-container>
                  <ng-template #accountsChoice>
                    <mat-option *ngFor="let depo of depositaryAccounts"
                      [value]="depo.id">
                      {{ depo.chapter ? ('CLAIM:DEPO_ACCOUNT_INFO' | translate: [depo.account, depo.chapter]) :
                      depo.account }}
                    </mat-option>
                  </ng-template>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="group"
              formArrayName="depositary_statements">
              <ng-container
                *ngFor="let depositary_statement of form.get('depositary_statements')['controls'];let i=index"
                [formGroupName]="i">
                <mat-radio-group class="row"
                  formControlName="type"
                  (change)="onChangeStatementType($event, depositary_statement)">
                  <ng-container *ngFor="let statementType of statementTypes">
                    <mat-radio-button color="primary"
                      [value]="statementType">
                      {{ statementType | translate }}
                    </mat-radio-button>
                  </ng-container>
                </mat-radio-group>
                <div class="report-datetime extra-field col-sm-6 col-12"
                  *ngIf="depositary_statement.get('type')?.value==='depositary_account_reference'">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ 'CLAIM:REFERENCE_DATE' | translate }}</mat-label>
                    <input [max]="yesterday"
                      dateFormat
                      matInput
                      formControlName="report_datetime"
                      [matDatepicker]="report_datetime">
                    <mat-datepicker-toggle matSuffix
                      [for]="report_datetime"></mat-datepicker-toggle>
                    <mat-datepicker #report_datetime></mat-datepicker>
                    <mat-error *ngIf="depositary_statement.get('report_datetime').errors?.required">
                      {{'ERROR:REQUIRED' | translate }}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                    <mat-label>{{ 'TIME' | translate }}</mat-label>
                    <div class="d-flex">
                      <input time
                        matInput
                        [formControl]="timeControl">
                    </div>
                    <mat-error *ngIf="timeControl.errors?.required">
                      {{'ERROR:REQUIRED' | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-sm-8 col-12"
                  *ngIf="depositary_statement.get('type')?.value==='securities_statement'">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ 'CLAIM:REFERENCE_RANGE' | translate }}</mat-label>
                    <mat-date-range-input [rangePicker]="get_definition_reference_range"
                      [max]="yesterday">
                      <input matStartDate
                        formControlName="report_date_from">
                      <input matEndDate
                        formControlName="report_date_to">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix
                      [for]="get_definition_reference_range"></mat-datepicker-toggle>
                    <mat-date-range-picker #get_definition_reference_range></mat-date-range-picker>
                    <mat-error *ngIf="depositary_statement.get('report_date_from').errors?.required || depositary_statement.get('report_date_to').errors?.required">
                      {{'ERROR:REQUIRED' | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-sm-5 col-12"
                  *ngIf="depositary_statement.get('type')?.value==='depositary_account_statement'">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ 'CLAIM:REFERENCE_DATE' | translate }}</mat-label>
                    <input [max]="yesterday"
                      dateFormat
                      matInput
                      formControlName="report_date"
                      [matDatepicker]="report_date">
                    <mat-datepicker-toggle matSuffix
                      [for]="report_date"></mat-datepicker-toggle>
                    <mat-datepicker #report_date></mat-datepicker>
                    <mat-error *ngIf="depositary_statement.get('report_date').errors?.required">
                      {{'ERROR:REQUIRED' | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </ng-container>
            </div>
            <h5 class="mb-3">{{ 'REFERENCE_DETALIZATION' | translate }}</h5>
            <mat-form-field appearance="outline">
              <mat-label>{{ 'CLAIM:ISSUER_REFERENCE' | translate }}</mat-label>
              <mat-select formControlName="issuer"
                (selectionChange)="onFullNameSelected($event, form, true)">
                <mat-form-field appearance="outline"
                  class="search-field">
                  <input matInput
                    placeholder="{{ 'SEARCH' | translate }}"
                    (input)="onFullNameInputted($event, form)">
                </mat-form-field>
                <ng-container *ngIf="!issuers.length">
                  <mat-option [disabled]="true">{{ 'ENTER_AT_LEAST_THREE_SYMBOLS_ISSUER' | translate }}</mat-option>
                </ng-container>
                <mat-option *ngFor="let issuer of issuers"
                  [value]="issuer.id">
                  {{ issuer.full_name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>{{ 'SECURITY:NAME' | translate }}</mat-label>
              <input matInput
                [formControl]="securityName"
                [readonly]="true">
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>{{ 'CLAIM:RELEASE_REFERENCE' | translate }}</mat-label>
              <input matInput
                formControlName="security"
                (click)="searchSecurity(form)"
                [readonly]="true">
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>{{ 'CLAIM:ADDITIONAL_INFORMATION' | translate }}</mat-label>
              <input matInput
                formControlName="additional_terms">
            </mat-form-field>
          </form>
          <div class="d-flex align-items-center justify-content-end">
            <button [disabled]="form.invalid"
              class="filled"
              mat-flat-button
              (click)="next()">
              {{ 'NEXT' | translate }}
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="cfService.stepKey===1">
          <div class="col-12">
            <ai-ui-documents [attachments]="attachmentsToSign"
              [isCreatingOrder]="true"></ai-ui-documents>
          </div>
          <div class="col-10 confirmation">
            <h4>{{ 'CLAIM_SIGNATURE_TITLE' | translate }}</h4>
            <div class="hint">
              <p>{{ 'CLAIM_SIGNATURE_INFORMATION' | translate }}
              </p>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <button class="back"
              (click)="back()"
              mat-button
              type="button">
              {{ 'BACK' | translate }}
            </button>
            <button mat-button
              class="filled"
              type="button"
              (click)="sendToSign()">
              {{ 'SIGN' | translate }}
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>