import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Attachment } from 'src/app/interfaces/api/attachment.interface';
import { BankAccountResponse } from 'src/app/interfaces/api/bank.interface';
import { ClaimsBatchResponse, WithdrawMoneyRequest } from 'src/app/interfaces/api/claims.interface';
import { AiUiAddBankAccountComponent } from 'src/app/shared/shared-components/ai-ui-add-bank-account/ai-ui-add-bank-account.component';
import { AiUiEditBankAccountComponent } from 'src/app/shared/shared-components/ai-ui-edit-bank-account/ai-ui-edit-bank-account.component';
import { animationOptions } from 'src/app/utils/animation';
import { BsType, Currency } from 'src/app/utils/utils';
import { ClaimBaseComponent } from '../claim-base/claim-base.component';
import { mergeMap } from 'rxjs';

@Component({
  selector: 'app-bo-withdraw-money-new',
  templateUrl: './bo-withdraw-money-new.component.html',
  styleUrls: ['./bo-withdraw-money-new.component.scss'],
  animations: animationOptions,
})
export class BoWithdrawMoneyNewComponent extends ClaimBaseComponent implements OnInit, OnDestroy {
  bankAccounts: BankAccountResponse[] = [];

  showCorrespondentBank: boolean = false;

  theFirstConfirmation: FormControl = new FormControl(true, [Validators.required]);

  ngOnInit(): void {
    this.form = this.initForm();
    this.getBankAccounts();
  }

  initForm() {
    return new FormGroup({
      withdrawal_currency: new FormControl(Currency.RUB, Validators.required),
      withdrawal_amount: new FormControl({ value: null, disabled: true }),
      withdraw_all_money: new FormControl(true),
      bank_account: new FormControl(null, Validators.required),
      withdrawal_correspondent_bank_name: new FormControl(null),
      withdrawal_correspondent_bank_account: new FormControl(null),
      withdrawal_correspondent_bank_swift: new FormControl(null),
      additional_terms: new FormControl(null),
    });
  }

  onAddBankAccount() {
    this.dialog
      .open(AiUiAddBankAccountComponent, {
        panelClass: 'popup-container',
        width: '800px',
      })
      .afterClosed()
      .subscribe((res: boolean) => {
        if (res) {
          this.getBankAccounts();
        }
      });
  }

  onEditBankAccount(id: number) {
    this.dialog
      .open(AiUiEditBankAccountComponent, {
        panelClass: 'popup-container',
        width: '800px',
        data: {
          bankAccountId: id,
        },
      })
      .afterClosed()
      .subscribe((res: boolean) => {
        if (res) {
          this.getBankAccounts();
        }
      });
  }

  getBankAccounts() {
    this.loader.showLoader();
    this.bankService.bankAccounts().subscribe({
      next: (res) => {
        if (res.length) {
          this.bankAccounts = res;
          if (this.form.controls.bank_account.value === null) {
            this.form.controls.bank_account.setValue(this.bankAccounts[0].id);
          }
          this.cd.detectChanges();
          this.cd.markForCheck();
        }
        this.loader.hideLoader();
      },
      error: () => {
        this.loader.hideLoader();
      },
    });
  }

  onShowCorrespondentToggle(event: MatCheckboxChange) {
    this.showCorrespondentBank = event.checked;
    if (!event.checked) {
      this.form.patchValue({
        withdrawal_correspondent_bank_name: null,
        withdrawal_correspondent_bank_account: null,
        withdrawal_correspondent_bank_swift: null,
      });
    }
  }

  onCheckboxChanged(event: MatCheckboxChange) {
    if (event.checked) {
      this.form.controls.withdrawal_amount.disable();
      this.form.patchValue({
        withdrawal_amount: null,
      });
      this.form.controls.withdrawal_amount.clearValidators();
    } else {
      this.form.controls.withdrawal_amount.setValidators(Validators.required);
      this.form.controls.withdrawal_amount.enable();
    }
    this.form.controls.withdrawal_amount.updateValueAndValidity({ emitEvent: false });
  }

  next() {
    if (this.form.invalid) {
      return;
    }
    const payload = this.form.getRawValue() as WithdrawMoneyRequest;
    payload.type = BsType.withdraw_money;
    this.loader.showLoader();
    this.claimsService
      .addClaimsBatch([payload])
      .pipe(
        mergeMap((res: ClaimsBatchResponse) => {
          this.claimBatchId = res.id;
          return this.documentService.claimsBatchDocumentsProcessing(res.id);
        })
      )
      .subscribe({
        next: (data) => {
          this.attachmentsToSign = data;
          this.cfService.goToStep(this.cfService.stepKey + 1);
          this.loader.hideLoader();
          this.cd.markForCheck();
        },
        error: () => {
          this.loader.hideLoader();
        },
      });
  }
}
