import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { MatSelectChange } from '@angular/material/select';
import { mergeMap } from 'rxjs';
import { ClaimsBatchResponse, TransactionReferenceRegistry } from 'src/app/interfaces/api/claims.interface';
import { animationOptions } from 'src/app/utils/animation';
import {
  AccountType,
  AuthorizedDocument,
  DepoType,
  EntityType,
  OperationInitiator,
  ParticipantType,
  TransactionReason,
  TransactionType,
  convertByteToMegaBytes,
  getFormatDate,
  maxFileSize,
} from 'src/app/utils/utils';
import { ClaimBaseComponent } from '../claim-base/claim-base.component';
import { Attachment, AttachmentBase } from 'src/app/interfaces/api/attachment.interface';

@Component({
  selector: 'app-depo-transaction-reference-registry',
  templateUrl: './depo-transaction-reference-registry.component.html',
  styleUrls: ['./depo-transaction-reference-registry.component.scss'],
  animations: animationOptions,
})
export class DepoTransactionReferenceRegistryComponent extends ClaimBaseComponent implements OnInit, OnDestroy {
  acceptedFormates = '.pdf';

  claimReasonDocuments: Attachment[] = [];

  transactionReasons = Array.from(new Set(Object.keys(TransactionReason)));

  accountTypes = Array.from(new Set(Object.keys(AccountType)));

  authorizedDocuments = Array.from(new Set(Object.keys(AuthorizedDocument)));

  default_contract_number: FormControl;

  default_contract_date: FormControl;

  ngOnInit(): void {
    this.form = this.initForm();
    this.getDepoAccounts(false);
    this.default_contract_number = new FormControl({
      value: this.auth.depositaryService$.value.number,
      disabled: true,
    });
    this.default_contract_date = new FormControl({
      value: this.auth.depositaryService$.value.activation_datetime,
      disabled: true,
    });
    this.cd.markForCheck();
  }

  initForm() {
    return this.fb.group({
      transaction_type: new FormControl(TransactionType.refill, Validators.required),
      operation_initiator: new FormControl(
        { value: OperationInitiator.depositor, disabled: true },
        Validators.required
      ),
      depositary_account: new FormControl(null, Validators.required),
      place_of_storage: new FormControl(null, Validators.required),
      place_of_storage_arbitrary: new FormControl(null),
      claim_securities: this.fb.array([this.initSecurity()]),
      reasons: this.fb.array([this.initReason()]),
      participant_type: new FormControl(ParticipantType.sender, Validators.required),
      account_number: new FormControl(null, Validators.required),
      account_type: new FormControl(AccountType.owner_depo_account, Validators.required),
      entity_type: new FormControl(EntityType.individual_entity, Validators.required),
      entity_name: new FormControl(null, Validators.required),
      ogrn: new FormControl(null),
      authorized_document: new FormControl(null, Validators.required),
      passport_number: new FormControl(null, Validators.required),
      date_of_issue: new FormControl(null, Validators.required),
      place_of_issue: new FormControl(null, Validators.required),
      registered_by_entity_name: new FormControl(null, Validators.required),
      registrar: new FormControl(null, Validators.required),
      identifier: new FormControl(null, Validators.required),
      calculation_date: new FormControl(null, Validators.required),
      deal_date: new FormControl(null, Validators.required),
      reference: new FormControl(null, Validators.required),
    });
  }

  onChangeEntityType(event: MatRadioChange) {
    if (event.value === EntityType.legal_entity) {
      this.form.patchValue({
        authorized_document: null,
        passport_number: null,
        date_of_issue: null,
      });
      this.form.get('authorized_document').clearValidators();
      this.form.get('passport_number').clearValidators();
      this.form.get('date_of_issue').clearValidators();
      this.form.get('ogrn').setValidators(Validators.required);
    } else {
      this.form.patchValue({
        ogrn: null,
      });
      this.form.get('ogrn').clearValidators();
      this.form.get('authorized_document').setValidators(Validators.required);
      this.form.get('passport_number').setValidators(Validators.required);
      this.form.get('date_of_issue').setValidators(Validators.required);
    }
    this.form.get('ogrn').updateValueAndValidity({ emitEvent: false });
    this.form.get('authorized_document').updateValueAndValidity({ emitEvent: false });
    this.form.get('passport_number').updateValueAndValidity({ emitEvent: false });
    this.form.get('date_of_issue').updateValueAndValidity({ emitEvent: false });
    this.cd.markForCheck();
  }

  fileSelected(event, reasonForm: FormGroup) {
    const file: File = event.target.files[0];
    let formData: FormData = new FormData();
    formData.append('file', file, file.name);
    if (file.size > maxFileSize) {
      this.toaster.showError(this.translation.translate('FILE_SIZE_INCORRECT', [convertByteToMegaBytes(maxFileSize)]));
      return;
    }
    this.loader.showLoader();
    this.claimsService.addClaimReasonDocument(formData).subscribe({
      next: (data: AttachmentBase) => {
        reasonForm.controls.document.setValue(data.id);
        this.updateAttachments(reasonForm, data);
        event.target.value = null;
        this.cd.markForCheck();
        this.loader.hideLoader();
      },
      error: () => {
        this.loader.hideLoader();
      },
    });
  }

  private updateAttachments(reasonForm: FormGroup, data: AttachmentBase): void {
    this.loader.showLoader();
    this.claimsService.fileToBlob(this.claimsService.claimReasonDocumentUrl(data.id)).subscribe({
      next: (url: string) => {
        reasonForm.controls.documentObject.setValue({
          id: data.id,
          file_name: data.file_name,
          url,
          isImage: false,
        });
        this.toaster.showSuccess(this.translation.translate('ATTACHMENT_HAS_BEEN_ADDED'));
        this.cd.markForCheck();
        this.loader.hideLoader();
      },
      error: () => {
        this.loader.hideLoader();
      },
    });
  }

  onDeleteReasonDocument(document_id: number, reasonForm: FormGroup) {
    this.loader.showLoader();
    this.claimsService.deleteClaimReasonDocument(document_id).subscribe({
      next: () => {
        this.toaster.showInfo(this.translation.translate('ATTACHMENT_HAS_BEEN_REMOVED'));
        reasonForm.patchValue({
          document: null,
          documentObject: null,
        });
        this.cd.markForCheck();
        this.loader.hideLoader();
      },
      error: () => {
        this.loader.hideLoader();
      },
    });
  }

  next() {
    if (this.form.invalid) {
      return;
    }
    const payload = this.form.getRawValue() as TransactionReferenceRegistry;
    payload.type = DepoType.transaction_reference_registry;
    payload.date_of_issue = getFormatDate(payload.date_of_issue);
    payload.deal_date = getFormatDate(payload.deal_date);
    payload.calculation_date = getFormatDate(payload.calculation_date);
    payload.reasons.map((r) => {
      r.contract_date = getFormatDate(r.contract_date);
    });
    this.loader.showLoader();
    this.claimsService
      .addClaimsBatch([payload])
      .pipe(
        mergeMap((res: ClaimsBatchResponse) => {
          this.claimBatchId = res.id;
          return this.documentService.claimsBatchDocumentsProcessing(res.id);
        })
      )
      .subscribe({
        next: (data) => {
          this.attachmentsToSign = data;
          this.cfService.goToStep(this.cfService.stepKey + 1);
          this.loader.hideLoader();
          this.cd.markForCheck();
        },
        error: () => {
          this.loader.hideLoader();
        },
      });
  }
}
