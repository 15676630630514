<div class="container-fluid bg-white p-4"
  [@open]="'opened'">
  <h1>{{ 'CLAIM:FILLING' | translate }}</h1>
  <div class="hint">
    <p>{{ 'transfer_reference' | translate }}</p>
  </div>
  <div class="row">
    <ui-step-view class="col-12 mt-3"
      [total]="2"
      [current]="cfService.stepKey + 1"
      [stepValues]="['CLAIM_STEP:CREATION', 'CLAIM_STEP:SIGN']">
    </ui-step-view>
    <div class="order-container">
      <div class="form-data  col-12">
        <ng-container *ngIf="cfService.stepKey===0">
          <form [formGroup]="form">
            <div class="radio-group">
              <mat-radio-group (change)="onChangeTransferReferenceType($event)"
                formControlName="transfer_reference_type">
                <mat-radio-button color="primary"
                  value="transfer">
                  {{ 'transfer' | translate }}
                </mat-radio-button>
                <mat-radio-button color="primary"
                  value="movement">
                  {{ 'movement' | translate }}
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <h5 class="mb-3">{{ 'CLAIM:DEFINITION_SENDER_TITLE' | translate }}</h5>
            <div formGroupName="sender">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'CLAIM:DEPOSITOR_FULL_NAME' | translate }}</mat-label>
                <input formControlName="depositor_full_name"
                  matInput>
                <mat-error *ngIf="form.get('sender').get('depositor_full_name').errors?.required">
                  {{'ERROR:REQUIRED' | translate }}
                </mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>{{ 'CLAIM:OPERATION_INITIATOR' | translate }}</mat-label>
                <mat-select formControlName="operation_initiator">
                  <mat-option *ngFor="let initiator of initiators"
                    [value]="initiator">
                    {{ initiator | translate }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="form.get('sender').get('operation_initiator').errors?.required">
                  {{'ERROR:REQUIRED' | translate }}
                </mat-error>
              </mat-form-field>
              <div class="radio-group"
                *ngIf="form.get('transfer_reference_type').value==='transfer'">
                <mat-radio-group [formControl]="senderDepoAccountType"
                  (change)="onChangeSenderDepoAccountType($event.value)">
                  <mat-radio-button color="primary"
                    value="own">
                    {{ 'CLAIM:OWN_DEPO_ACCOUNT' | translate }}
                  </mat-radio-button>
                  <mat-radio-button color="primary"
                    value="other">
                    {{ 'CLAIM:OTHER_DEPO_ACCOUNT' | translate }}
                  </mat-radio-button>
                </mat-radio-group>
              </div>
              <ng-container *ngIf="senderDepoAccountType.value!=='other';else otherSender">
                <mat-form-field appearance="outline">
                  <mat-label>{{ 'CLAIM:DEPO_ACCOUNT' | translate }}</mat-label>
                  <mat-select formControlName="depositary_account"
                    (selectionChange)="onChangeSenderDepoAccount($event.value)">
                    <ng-container *ngIf="!depositaryAccounts.length;else accountsChoiceForSender">
                      <mat-option [disabled]="true">{{ 'NOTHING_FOUND' | translate }}</mat-option>
                    </ng-container>
                    <ng-template #accountsChoiceForSender>
                      <mat-option *ngFor="let depo of depositaryAccounts"
                        [value]="depo.account+';'+depo.chapter">
                        {{ depo.chapter ? ('CLAIM:DEPO_ACCOUNT_INFO' | translate: [depo.account, depo.chapter]) :
                        depo.account }}
                      </mat-option>
                    </ng-template>
                  </mat-select>
                </mat-form-field>
              </ng-container>
              <ng-template #otherSender>
                <div class="row">
                  <mat-form-field class="col"
                    appearance="outline">
                    <mat-label>{{ 'CLAIM:DEPO_NUMBER' | translate }}</mat-label>
                    <input formControlName="depositary_account"
                      matInput>
                  </mat-form-field>
                  <mat-form-field class="col"
                    appearance="outline">
                    <mat-label>{{ 'CLAIM:DEPO_CHAPTER' | translate }}</mat-label>
                    <input formControlName="depositary_chapter"
                      matInput>
                  </mat-form-field>
                </div>
              </ng-template>
              <mat-form-field appearance="outline">
                <mat-label>{{ 'CLAIM:PLACE_OF_STORAGE' | translate }}</mat-label>
                <mat-select formControlName="place_of_storage"
                  (selectionChange)="onChangePlaceOfStorage($event, form.get('sender'))">
                  <mat-option *ngFor="let place of placeOfStorages"
                    [value]="place">
                    {{ place | translate }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="form.get('sender').get('place_of_storage').errors?.required">
                  {{'ERROR:REQUIRED' | translate }}
                </mat-error>
                <mat-error *ngIf="form.get('sender').get('place_of_storage').errors?.mismatch">
                  {{ 'ERROR:PLACE_OF_STORAGE_MISMATCH' | translate }}
                </mat-error>
              </mat-form-field>
              <div class="extra-field"
                *ngIf="form.get('sender').get('place_of_storage')?.value==='other'">
                <mat-form-field appearance="outline">
                  <mat-label>{{ 'CLAIM:PLACE_OF_STORAGE' | translate }}</mat-label>
                  <input formControlName="place_of_storage_arbitrary"
                    matInput>
                  <mat-error *ngIf="form.get('sender').get('place_of_storage_arbitrary').errors?.required">
                    {{'ERROR:REQUIRED' | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <h5 class="mb-3">{{ 'CLAIM:DEFINITION_RECIPIENT_TITLE' | translate }}</h5>
            <div formGroupName="recipient">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'CLAIM:DEPOSITOR_FULL_NAME' | translate }}</mat-label>
                <input formControlName="depositor_full_name"
                  matInput>
                <mat-error *ngIf="form.get('recipient').get('depositor_full_name').errors?.required">
                  {{'ERROR:REQUIRED' | translate }}
                </mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>{{ 'CLAIM:OPERATION_INITIATOR' | translate }}</mat-label>
                <mat-select formControlName="operation_initiator">
                  <mat-option *ngFor="let initiator of initiators"
                    [value]="initiator">
                    {{ initiator | translate }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="form.get('recipient').get('operation_initiator').errors?.required">
                  {{'ERROR:REQUIRED' | translate }}
                </mat-error>
              </mat-form-field>
              <div class="radio-group"
                *ngIf="form.get('transfer_reference_type').value==='transfer'">
                <mat-radio-group [formControl]="recipientDepoAccountType"
                  (change)="onChangeRecipientDepoAccountType($event.value)">
                  <mat-radio-button color="primary"
                    value="own">
                    {{ 'CLAIM:OWN_DEPO_ACCOUNT' | translate }}
                  </mat-radio-button>
                  <mat-radio-button color="primary"
                    value="other">
                    {{ 'CLAIM:OTHER_DEPO_ACCOUNT' | translate }}
                  </mat-radio-button>
                </mat-radio-group>
              </div>
              <ng-container *ngIf="recipientDepoAccountType.value!=='other';else otherRecipient">
                <mat-form-field appearance="outline">
                  <mat-label>{{ 'CLAIM:DEPO_ACCOUNT' | translate }}</mat-label>
                  <mat-select formControlName="depositary_account"
                    (selectionChange)="onChangeRecipientDepoAccount($event.value)">
                    <ng-container *ngIf="!depositaryAccounts.length;else accountsChoiceForRecipient">
                      <mat-option [disabled]="true">{{ 'NOTHING_FOUND' | translate }}</mat-option>
                    </ng-container>
                    <ng-template #accountsChoiceForRecipient>
                      <mat-option *ngFor="let depo of depositaryAccounts"
                        [value]="depo.account+';'+depo.chapter">
                        {{ depo.chapter ? ('CLAIM:DEPO_ACCOUNT_INFO' | translate: [depo.account, depo.chapter]) :
                        depo.account }}
                      </mat-option>
                    </ng-template>
                  </mat-select>
                </mat-form-field>
              </ng-container>
              <ng-template #otherRecipient>
                <div class="row">
                  <mat-form-field class="col"
                    appearance="outline">
                    <mat-label>{{ 'CLAIM:DEPO_NUMBER' | translate }}</mat-label>
                    <input formControlName="depositary_account"
                      matInput>
                  </mat-form-field>
                  <mat-form-field class="col"
                    appearance="outline">
                    <mat-label>{{ 'CLAIM:DEPO_CHAPTER' | translate }}</mat-label>
                    <input formControlName="depositary_chapter"
                      matInput>
                  </mat-form-field>
                </div>
              </ng-template>
              <mat-form-field appearance="outline">
                <mat-label>{{ 'CLAIM:PLACE_OF_STORAGE' | translate }}</mat-label>
                <mat-select formControlName="place_of_storage"
                  (selectionChange)="onChangePlaceOfStorage($event, form.get('recipient'))">
                  <mat-option *ngFor="let place of placeOfStorages"
                    [value]="place">
                    {{ place | translate }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="form.get('recipient').get('place_of_storage').errors?.required">
                  {{'ERROR:REQUIRED' | translate }}
                </mat-error>
                <!-- <mat-error *ngIf="form.get('sender').get('place_of_storage').errors?.mismatch">
                  {{ 'ERROR:PLACE_OF_STORAGE_MISMATCH' | translate }}
                </mat-error> -->
              </mat-form-field>
              <div class="extra-field"
                *ngIf="form.get('recipient').get('place_of_storage')?.value==='other'">
                <mat-form-field appearance="outline">
                  <mat-label>{{ 'CLAIM:PLACE_OF_STORAGE' | translate }}</mat-label>
                  <input formControlName="place_of_storage_arbitrary"
                    matInput>
                  <mat-error *ngIf="form.get('recipient').get('place_of_storage_arbitrary').errors?.required">
                    {{'ERROR:REQUIRED' | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <h5 class="mb-3">{{ 'CLAIM:INFO_ABOUT_DEFINITIONS' | translate }}</h5>
            <div formArrayName="claim_securities">
              <ng-container *ngFor="let claim_security of form.get('claim_securities')['controls']; let i = index"
                [formGroupName]="i">
                <button class="reset"
                  (click)="resetData(claim_security)"
                  mat-button
                  type="button">
                  <mat-icon>clear</mat-icon>
                  <span>{{ 'RESET' | translate }}</span>
                </button>
                <mat-form-field appearance="outline">
                  <mat-label>{{ 'SECURITY:NAME' | translate }}</mat-label>
                  <input matInput
                    [formControl]="securityName"
                    [readonly]="true">
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>{{ 'SECURITY:ISIN_CODE' | translate }}</mat-label>
                  <input matInput
                    formControlName="isin"
                    (click)="searchSecurity(claim_security)"
                    [readonly]="true">
                  <mat-error *ngIf="claim_security.get('isin').errors?.required">
                    {{'ERROR:REQUIRED' | translate }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>{{ 'SECURITY:TYPE' | translate }}</mat-label>
                  <mat-select formControlName="instr_type">
                    <mat-option *ngFor="let type of securityTypes"
                      [value]="type">
                      {{ type | translate }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="claim_security.get('instr_type').errors?.required">
                    {{'ERROR:REQUIRED' | translate }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>{{ 'SECURITY:CATEGORY' | translate }}</mat-label>
                  <mat-select formControlName="instr_category">
                    <ng-container *ngIf="!securityCategories.length;else categories">
                      <mat-option [disabled]="true">{{ 'NOT_FOUND' | translate }}</mat-option>
                    </ng-container>
                    <ng-template #categories>
                      <mat-option *ngFor="let type of securityCategories"
                        [value]="type">
                        {{ 'SECURITY_CATEGORY:' + type | translate }}
                      </mat-option>
                    </ng-template>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>{{ 'SECURITY:ISSUER' | translate }}</mat-label>
                  <mat-select formControlName="issuer"
                    (selectionChange)="onFullNameSelected($event, claim_security)">
                    <mat-form-field appearance="outline"
                      class="search-field">
                      <input matInput
                        placeholder="{{ 'SEARCH' | translate }}"
                        (input)="onFullNameInputted($event, claim_security)">
                    </mat-form-field>
                    <ng-container *ngIf="!issuers.length">
                      <mat-option [disabled]="true">{{ 'ENTER_AT_LEAST_THREE_SYMBOLS_ISSUER' | translate }}</mat-option>
                    </ng-container>
                    <mat-option *ngFor="let issuer of issuers"
                      [value]="issuer.id">
                      {{ issuer.full_name }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="claim_security.get('issuer').errors?.required">
                    {{'ERROR:REQUIRED' | translate }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>{{ 'SECURITY:REGISTRATION_NUMBER' | translate }}</mat-label>
                  <input formControlName="state_reg_number"
                    matInput>
                </mat-form-field>
                <mat-form-field class="col"
                  appearance="outline">
                  <mat-label>{{ 'SECURITY:NOMINAL_PRICE' | translate }}</mat-label>
                  <input amount
                    [decimalPlace]="2"
                    type="text"
                    formControlName="nominal_price"
                    matInput>
                  <mat-error *ngIf="claim_security.get('nominal_price').errors?.required">
                    {{'ERROR:REQUIRED' | translate }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="col"
                  appearance="outline">
                  <mat-label>{{ 'SECURITY:DEAL_AMOUNT' | translate }}</mat-label>
                  <input type="number"
                    min="0"
                    formControlName="deal_amount"
                    matInput
                    [ngModel]="(claim_security.get('quantity')?.value * claim_security.get('nominal_price')?.value).toFixed(2)">
                  <mat-error *ngIf="claim_security.get('deal_amount').errors?.required">
                    {{'ERROR:REQUIRED' | translate }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>{{ 'SECURITY:AMOUNT' | translate }}</mat-label>
                  <ng-container
                    *ngIf="!!claim_security.get('code_nsd').value && claim_security.get('code_nsd').value?.endsWith('/DR');else integer">
                    <input amount
                      [decimalPlace]="2"
                      type="text"
                      formControlName="quantity"
                      matInput>
                  </ng-container>
                  <ng-template #integer>
                    <input integer
                      type="text"
                      formControlName="quantity"
                      matInput>
                  </ng-template>
                  <mat-error *ngIf="claim_security.get('quantity').errors?.required">
                    {{'ERROR:REQUIRED' | translate }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>{{ 'SECURITY:CURRENCY' | translate }}</mat-label>
                  <mat-select formControlName="price_currency">
                    <mat-option *ngFor="let currency of currencies"
                      [value]="currency">
                      {{ currency }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="claim_security.get('price_currency').errors?.required">
                    {{'ERROR:REQUIRED' | translate }}
                  </mat-error>
                </mat-form-field>
              </ng-container>
            </div>
            <div class="d-flex justify-content-between">
              <h5 class="mb-3">{{ 'CLAIM:REASON' | translate }}</h5>
              <mat-icon style="cursor: pointer;"
                (click)="onAddReason()">add
              </mat-icon>
            </div>
            <div formArrayName="reasons">
              <ng-container *ngFor="let reasonForm of form.get('reasons')['controls']; let i = index"
                [formGroupName]="i">
                <div class="pb-3"
                  *ngIf="i>0">
                  <mat-divider></mat-divider>
                </div>
                <mat-icon style="cursor: pointer; float: right;"
                  *ngIf="i>0"
                  (click)="onRemoveReason(i)">clear
                </mat-icon>

                <ng-container *ngIf="reasonForm.get('type')?.value!=='depo_agreement'">

                  <ng-container *ngIf="reasonForm.get('documentObject')?.value as documentObj">
                    <div class="d-flex reason-document align-items-center justify-content-between">
                      <img src="./assets/images/document-gray.svg"
                        alt="logo"
                        class="me-3">
                      <div class="flex-grow-1">
                        <p>{{ documentObj.file_name }}</p>
                      </div>
                      <div class="actions d-flex">
                        <a mat-button
                          class="d-flex align-items-center me-2"
                          [href]="documentObj.url"
                          target="_blank">
                          {{ 'OPEN' | translate }}
                          <mat-icon class="ms-2">visibility</mat-icon>
                        </a>
                        <a mat-button
                          class="d-flex align-items-center"
                          [href]="documentObj.url"
                          [download]="documentObj.file_name"
                          target="_blank">
                          {{ 'DOWNLOAD' | translate }}
                          <mat-icon class="ms-2">download</mat-icon>
                        </a>
                        <a mat-button
                        class="d-flex align-items-center"
                        (click)="onDeleteReasonDocument(documentObj.id, reasonForm)"
                        target="_blank">
                        {{ 'DELETE' | translate }}
                        <mat-icon class="ms-2">delete</mat-icon>
                      </a>
                      </div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="!reasonForm.get('documentObject')?.value && reasonForm.get('type')?.value==='other_agreement'">
                    <div class="d-flex justify-content-start mb-3">
                      <input class="file-input"
                        type="file"
                        [accept]="acceptedFormates"
                        (change)="fileSelected($event, reasonForm)"
                        #file>
                      <button type="button"
                        class="attach btn btn-outline-dark"
                        (click)="file.click()">
                        {{ 'ATTACHMENT:UPLOAD_REASON_DOCUMENT' | translate }}
                      </button>
                    </div>
                    <div class="hint">
                      <p>{{ 'ATTACHMENT:HINT_ONLY_PDF' | translate }}</p>
                    </div>
                  </ng-container>

                </ng-container>

                <mat-form-field appearance="outline">
                  <mat-label>{{ 'CLAIM:REASON' | translate }}</mat-label>
                  <mat-select formControlName="type"
                    (selectionChange)="reasonTypeChanged($event, reasonForm)">
                    <mat-option *ngFor="let reason of transactionReasons"
                      [value]="reason">
                      {{ reason | translate }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="reasonForm.get('type').errors?.required">
                    {{'ERROR:REQUIRED' | translate }}
                  </mat-error>
                </mat-form-field>
                <ng-container *ngIf="reasonForm.get('type')?.value==='other_agreement'">
                  <mat-form-field class="col"
                    appearance="outline">
                    <mat-label>{{ 'CLAIM:AGREEMENT_NAME' | translate }}</mat-label>
                    <input formControlName="contract_name"
                      matInput>
                    <mat-error *ngIf="reasonForm.get('contract_name').errors?.required">
                      {{'ERROR:REQUIRED' | translate }}
                    </mat-error>
                  </mat-form-field>
                </ng-container>
                <div class="row">
                  <mat-form-field class="col"
                    appearance="outline">
                    <mat-label>{{ 'CLAIM:AGREEMENT_NUMBER' | translate }}</mat-label>
                    <input formControlName="contract_number"
                      matInput>
                    <mat-error *ngIf="reasonForm.get('contract_number').errors?.required">
                      {{'ERROR:REQUIRED' | translate }}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="col"
                    appearance="outline">
                    <mat-label>{{ 'CLAIM:AGREEMENT_DATE' | translate }}</mat-label>
                    <input dateFormat
                      formControlName="contract_date"
                      matInput
                      [matDatepicker]="agreementDate">
                    <mat-datepicker-toggle matSuffix
                      [for]="agreementDate"></mat-datepicker-toggle>
                    <mat-datepicker #agreementDate></mat-datepicker>
                    <mat-error *ngIf="reasonForm.get('contract_date').errors?.required">
                      {{'ERROR:REQUIRED' | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </ng-container>
            </div>
            <div class="row">
              <mat-form-field class="col"
                appearance="outline">
                <mat-label>{{ 'CLAIM:DATE_OF_TRANSACTION' | translate }}</mat-label>
                <input dateFormat
                  matInput
                  formControlName="deal_date"
                  [matDatepicker]="dateOfTransaction">
                <mat-datepicker-toggle matSuffix
                  [for]="dateOfTransaction"></mat-datepicker-toggle>
                <mat-datepicker #dateOfTransaction></mat-datepicker>
              </mat-form-field>
              <mat-form-field class="col"
                appearance="outline">
                <mat-label>{{ 'CLAIM:DATE_OF_CALCULATION' | translate }}</mat-label>
                <input dateFormat
                  matInput
                  formControlName="calculation_date"
                  [matDatepicker]="calculationDate">
                <mat-datepicker-toggle matSuffix
                  [for]="calculationDate"></mat-datepicker-toggle>
                <mat-datepicker #calculationDate></mat-datepicker>
              </mat-form-field>
            </div>
          </form>
          <div class="d-flex justify-content-end mb-3">
            <button [disabled]="form.invalid"
              class="filled"
              mat-flat-button
              (click)="next()">
              {{ 'NEXT' | translate }}
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="cfService.stepKey===1">
          <div class="col-12">
            <ai-ui-documents [attachments]="attachmentsToSign"
              [isCreatingOrder]="true"></ai-ui-documents>
          </div>
          <div class="col-10 confirmation">
            <h4>{{ 'CLAIM_SIGNATURE_TITLE' | translate }}</h4>
            <div class="hint">
              <p>{{ 'CLAIM_SIGNATURE_INFORMATION' | translate }}
              </p>
            </div>
          </div>
          <div class="actions">
            <button class="back"
              (click)="back()"
              mat-button
              type="button">
              {{ 'BACK' | translate }}
            </button>
            <button mat-button
              class="filled"
              type="button"
              (click)="sendToSign()">
              {{ 'SIGN' | translate }}
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>