import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ai-ui-risk-information',
  templateUrl: './ai-ui-risk-information.component.html',
  styleUrls: ['./ai-ui-risk-information.component.scss'],
})
export class AiUiRiskInformationComponent {
  constructor() {}
}
