import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiUrlWithPrefix, environment } from '@env';
import { BehaviorSubject, Observable, Subscription, interval, map, startWith, switchMap } from 'rxjs';
import { ClientInvestment, ClientPortfolio, Estimate } from 'src/app/interfaces/api/portfolio.interface';
import { AreaType, InvestmentType } from 'src/app/utils/utils';

@Injectable({
  providedIn: 'root',
})
export class PortfolioService {
  static readonly PORTFOLIO_MONEY = `${apiUrlWithPrefix}/portfolio/money/`;

  static readonly MOCK_PORTFOLIO_MONEY = './assets/staticfiles/portfolio.json';

  static readonly CLIENT_PORTFOLIO = `${apiUrlWithPrefix}/client-portfolio/money/`;
  static readonly CLIENT_INVESTMENT = `${apiUrlWithPrefix}/client-investment/money/`;

  static readonly MOCK_CLIENT_PORTFOLIO = './assets/staticfiles/portfolio_2.json';
  static readonly MOCK_CLIENT_INVESTMENT = './assets/staticfiles/investment_2.json';

  private observer: Subscription;

  mstock$: BehaviorSubject<Estimate> = new BehaviorSubject<Estimate>(null);
  mcur$: BehaviorSubject<Estimate> = new BehaviorSubject<Estimate>(null);
  offe$: BehaviorSubject<Estimate> = new BehaviorSubject<Estimate>(null);

  constructor(private http: HttpClient) {}

  clientPortfolio() {
    return this.http.get<ClientPortfolio[]>(PortfolioService.CLIENT_PORTFOLIO);
  }

  clientInvestment() {
    return this.http.get<ClientInvestment[]>(PortfolioService.CLIENT_INVESTMENT);
  }

  portfolioMoney(): Observable<Estimate[]> {
    return this.http.get<Estimate[]>(PortfolioService.PORTFOLIO_MONEY).pipe(
      map((res) => {
        res.forEach((r) => {
          r.money = Number(r.money).toFixed(2);
          r.money_long = Number(r.money_long).toFixed(2);
          r.money_short = Number(r.money_short).toFixed(2);
          r.free_money_sur = Number(r.free_money_sur).toFixed(2);
        });
        return res;
      })
    );
  }

  portfolioMoneyListener() {
    this.observer = interval(environment.portfolioRefreshInterval)
      .pipe(
        startWith(0),
        switchMap(() => this.http.get(PortfolioService.MOCK_PORTFOLIO_MONEY))
      )
      .pipe(
        map((res: Estimate[]) => {
          res.forEach((r) => {
            r.money = Number(r.money).toFixed(2);
            r.money_long = Number(r.money_long).toFixed(2);
            r.money_short = Number(r.money_short).toFixed(2);
            r.free_money_sur = Number(r.free_money_sur).toFixed(2);
          });
          return res;
        })
      )
      .subscribe({
        next: (data) => {
          this.mstock$.next(data.find((e) => e.exchange_code === AreaType.mstock));
          this.mcur$.next(data.find((e) => e.exchange_code === AreaType.mcur));
          this.offe$.next(data.find((e) => e.exchange_code === AreaType.offe));
        },
        error: () => {
          this.mstock$.next(null);
          this.mcur$.next(null);
          this.offe$.next(null);
        },
      });
  }

  calculatePortfolio(portfolios: ClientPortfolio[], investments: ClientInvestment[]): number {
    let checkedSecurityIds = [];
    let result = 0;
    portfolios.map((p) => {
      result += Number(p.rub_rate);
      if (!checkedSecurityIds.includes(investments, p.security.id)) {
        result += this.calculateNkdBySecurity(p.security.id, investments);
      }
      checkedSecurityIds.push(p.security.id);
    });
    result += this.calculateAccount(investments);
    return result;
  }

  calculateNkdBySecurity(securityId: number, investments: ClientInvestment[]): number {
    const NKDs = investments
      .filter((i) => !!i.security && i.security.id === securityId && i.type === InvestmentType.nkd)
      ?.map((i) => Number(i.rub_rate));
    if (!NKDs.length) {
      return 0;
    }
    return NKDs.reduce((prev, acc) => prev + acc);
  }

  calculateNkd(investments: ClientInvestment[]): number {
    const NKDs = investments.filter((i) => i.type === InvestmentType.nkd)?.map((i) => Number(i.rub_rate));
    if (!NKDs.length) {
      return 0;
    }
    return NKDs.reduce((prev, acc) => prev + acc);
  }

  calculateAccount(investments: ClientInvestment[]): number {
    const accounts = investments
      .filter((i) =>
        [InvestmentType.checking_account.toString(), InvestmentType.brokerage_account.toString()].includes(i.type)
      )
      ?.map((i) => Number(i.rub_rate));
    if (!accounts.length) {
      return 0;
    }
    return accounts.reduce((prev, acc) => prev + acc);
  }

  calculatePortfolioAccounts(investments: ClientInvestment[], groupedAccounts: { [key: string]: number }) {
    const accounts = investments.filter((i) =>
      [InvestmentType.checking_account.toString(), InvestmentType.brokerage_account.toString()].includes(i.type)
    );
    if (!accounts.length) {
      return;
    }
    accounts.map((i) => {
      groupedAccounts[i.type] += Number(i.rub_rate);
    });
  }

  calculateComission(investments: ClientInvestment[]): number {
    const comissions = investments.filter((i) => i.type === InvestmentType.other_comission)?.map((i) => i.rub_rate);
    if (!comissions.length) {
      return 0;
    }
    return comissions.reduce((prev, acc) => prev + acc);
  }

  calculateAigenisComission(investments: ClientInvestment[]): number {
    const comissions = investments.filter((i) => i.type === InvestmentType.aigenis_comission)?.map((i) => i.rub_rate);
    if (!comissions.length) {
      return 0;
    }
    return comissions.reduce((prev, acc) => prev + acc);
  }

  calculateKindOfSecurity(portfolios: ClientPortfolio[]): number {
    const rates = portfolios?.map((i) => Number(i.rub_rate));
    if (!rates.length) {
      return 0;
    }
    return Number(rates.reduce((prev, acc) => prev + acc));
  }
}
