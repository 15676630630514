<ng-container *ngIf="!this.emitEvent">
  <div class="close" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </div>
</ng-container>
<div class="container">
  <div class="title mb-2">
    <h3>{{ (operation === 'add' ? 'ADDING_BANK_ACCOUNT' : 'CHANGING_BANK_ACCOUNT') | translate }}</h3>
  </div>
  <div class="row">
    <div class="col-12">
      <form [formGroup]="form" (ngSubmit)="submit()">
        <div class="sub-title">
          <h5>{{ 'BANK:COUNTRY_CODE' | translate }}</h5>
        </div>
        <mat-radio-group [formControl]="country_code" (change)="onCountryCodeChange()">
          <mat-radio-button color="primary" [value]="'BY'">
            {{ 'COUNTRY:BY' | translate }}
          </mat-radio-button>
          <mat-radio-button color="primary" [value]="'RU'">
            {{ 'COUNTRY:RU' | translate }}
          </mat-radio-button>
        </mat-radio-group>
        <div class="sub-title">
          <h5>{{ 'BANK:CURRENCY_CODE_SUBTITLE' | translate }}</h5>
        </div>
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'BANK:CURRENCY_CODE' | translate }}
          </mat-label>
          <mat-select formControlName="currency_code">
            <mat-option *ngFor="let currency of currencies" [value]="currency">
              {{ currency }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('currency_code').errors?.required">
            {{'ERROR:REQUIRED' | translate }}
          </mat-error>
        </mat-form-field>
        <div class="sub-title">
          <h5>{{ 'BANK:BIC_SUBTITLE' | translate }}</h5>
        </div>

        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'BANK:BIC' | translate }}
          </mat-label>
          <mat-select [formControl]="bic" (selectionChange)="onBicSelected($event)">
            <mat-form-field appearance="outline" class="search-field">
              <input matInput placeholder="{{ 'SEARCH' | translate }}" (input)="onBicInputted($event)">
            </mat-form-field>
            <ng-container *ngIf="!banks.length">
              <mat-option [disabled]="true">{{ 'ENTER_AT_LEAST_THREE_SYMBOLS_BIC' | translate }}</mat-option>
            </ng-container>
            <mat-option *ngFor="let bank of banks" [value]="bank.id">
              {{ bank.bic }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="bic.errors?.required">
            {{'ERROR:REQUIRED' | translate }}
          </mat-error>
        </mat-form-field>

        <div class="sub-title">
          <h5>{{ 'BANK:NAME_SUBTITLE' | translate }}</h5>
        </div>
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'BANK:NAME' | translate }}
          </mat-label>
          <mat-select [formControl]="name" (selectionChange)="onBankNameSelected($event)">
            <mat-form-field appearance="outline" class="search-field">
              <input matInput placeholder="{{ 'SEARCH' | translate }}" (input)="onBankNameInputted($event)">
            </mat-form-field>
            <ng-container *ngIf="!banks.length">
              <mat-option [disabled]="true">{{ 'ENTER_AT_LEAST_THREE_SYMBOLS_BANK' | translate }}</mat-option>
            </ng-container>
            <mat-option *ngFor="let bank of banks" [value]="bank.id">
              {{ bank.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="name.errors?.required">
            {{'ERROR:REQUIRED' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'BANK:ADDRESS' | translate }}
          </mat-label>
          <input matInput [formControl]="address">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'BANK:CHECKING_ACCOUNT' | translate }}
          </mat-label>
          <input uppercase matInput formControlName="checking_account"
            [prefix]="(country_code?.value==='BY') ? 'BY' : ''"
            [mask]="(country_code?.value==='BY') ? checkingAccountMaskBY : checkingAccountMaskRU">
          <mat-error *ngIf="form.get('checking_account').errors?.required">
            {{'ERROR:REQUIRED' | translate }}
          </mat-error>
        </mat-form-field>
        <ng-container *ngIf="(country_code?.value==='RU')">
          <mat-form-field appearance="outline">
            <mat-label>
              {{ 'BANK:CORRESPONDENT_ACCOUNT' | translate }}
            </mat-label>
            <input matInput [formControl]="correspondent_account">
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>
              {{ 'BANK:PERSONAL_ACCOUNT' | translate }}
            </mat-label>
            <input matInput formControlName="personal_account">
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>
              {{ 'BANK:INTERNAL_ACCOUNT_NAME' | translate }}
            </mat-label>
            <input matInput formControlName="internal_account_name">
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>
              {{ 'BANK:PHONE' | translate }}
            </mat-label>
            <input matInput [formControl]="phone">
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>
              {{ 'BANK:INDEX' | translate }}
            </mat-label>
            <input matInput [formControl]="index">
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>
              {{ 'BANK:OKATO' | translate }}
            </mat-label>
            <input matInput [formControl]="okato">
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>
              {{ 'BANK:OKPO' | translate }}
            </mat-label>
            <input matInput [formControl]="okpo">
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>
              {{ 'BANK:REG_NUM' | translate }}
            </mat-label>
            <input matInput [formControl]="regnum">
          </mat-form-field>
        </ng-container>
        <ng-container *ngIf="(country_code?.value==='BY')">
          <mat-form-field appearance="outline">
            <mat-label>
              {{ 'BANK:NUMBER' | translate }}
            </mat-label>
            <input matInput [formControl]="number">
          </mat-form-field>
        </ng-container>
        <div class="sub-title">
          <h5>{{ 'BANK:ACCOUNT_NAME_SUBTITLE' | translate }}</h5>
        </div>
        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'BANK:ACCOUNT_NAME' | translate }}
          </mat-label>
          <input matInput formControlName="name">
          <mat-error *ngIf="form.get('name').errors?.required">
            {{'ERROR:REQUIRED' | translate }}
          </mat-error>
          <mat-error *ngIf="form.get('name').errors?.pattern">
            {{'ERROR:ONLY_LETTERS_OR_DIGITS_AND_SPECIAL_CHARACTERS' | translate }}
          </mat-error>
        </mat-form-field>
        <button mat-button class="btn btn-outline-dark filled" [disabled]="form.invalid" type="submit">{{ emitEvent ?
          ('NEXT' | translate) : (operation === 'add' ? 'ADD' : 'CHANGE') | translate
          }}</button>
      </form>
    </div>
  </div>
</div>