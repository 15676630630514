<div class="container-fluid bg-white p-4"
  [@open]="'opened'">
  <h1>{{ 'CLAIM:FILLING' | translate }}</h1>
  <div class="hint">
    <p>{{ 'edit_profile' | translate }}</p>
  </div>
  <div class="row">
    <ui-step-view class="col-12 mt-3"
      [total]="2"
      [current]="cfService.stepKey + 1"
      [stepValues]="['CLAIM_STEP:CREATION', 'CLAIM_STEP:SIGN']">
    </ui-step-view>
    <div class="order-container">
      <div class="form-data  col-12">
        <ng-container *ngIf="cfService.stepKey===0">
          <div class="restriction  col-12">
            <div class="content">
              <div class="content__title">
                <span>{{ 'CHANGE_PERSONAL_DATA:CONTENT_TITLE' | translate }}</span>
              </div>
              <div class="content__description">
                <span>{{ 'CHANGE_PERSONAL_DATA:CONTENT' | translate }}</span>
              </div>
            </div>
          </div>
          <div class="changed-data">
            <ng-container *ngFor="let data of profileService.diffData">
              <div class="diff">
                <div *ngIf="data.objectName"
                  class="diff__caption">
                  <h4>{{ ('PROFILE:' + data.objectName | uppercase) | translate }}</h4>
                </div>
                <div class="diff__title">
                  {{ ('FIELD:' + data.fieldName | uppercase) | translate }}
                </div>
                <div class="diff__content">
                  {{ data.current | translate }} {{ data?.current_name_value ? '('+data?.current_name_value+')' : '' }}
                  <mat-icon>arrow_right_alt</mat-icon>
                  {{ data.updated | translate }} {{ data?.updated_name_value ? '('+data?.updated_name_value+')' : '' }}
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="profileService?.deletedPassportScansDiffData.length > 0">
              <div class="diff">
                <div class="diff__caption">
                  <h4>{{ 'PASSPORT:DELETED_SCANS' | translate }}</h4>
                </div>
                <ng-container *ngFor="let attachment of profileService.deletedPassportScansDiffData">
                  <div class="row attachments">
    <div class="attachment col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                      <ng-container *ngIf="attachment.isImage; else document">
                        <img [src]="attachment.url" [alt]="attachment.file_name" (click)="open(attachment.url)" />
                      </ng-container>
                      <ng-template #document>
                        <div class="document">
                          <img src="./assets/images/document.svg" alt="logo" />
                          <p>{{ attachment.file_name }}</p>
                          <div class="actions">
                            <div class="open">
                              <a [href]="attachment.url" target="_blank">
                                <div>
                                  <mat-icon>visibility</mat-icon>
                                </div>
                                {{ 'OPEN' | translate }}
                              </a>
                            </div>
                            <div class="download">
                              <a [href]="attachment.url" [download]="attachment.file_name" target="_blank">
                                <div>
                                  <mat-icon>download</mat-icon>
                                </div>
                                {{ 'DOWNLOAD' | translate }}
                              </a>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </ng-container>
              </div>
            </ng-container>
            <ng-container *ngIf="profileService?.uploadedPassportScansDiffData.length > 0">
              <div class="diff">
                <div class="diff__caption">
                  <h4>{{ 'PASSPORT:UPLOADED_SCANS' | translate }}</h4>
                </div>
                <ng-container *ngFor="let attachment of profileService.uploadedPassportScansDiffData">
                  <div class="row attachments">
    <div class="attachment col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                      <ng-container *ngIf="attachment.isImage; else document">
                        <img [src]="attachment.url" [alt]="attachment.file_name" (click)="open(attachment.url)" />
                      </ng-container>
                      <ng-template #document>
                        <div class="document">
                          <img src="./assets/images/document.svg" alt="logo" />
                          <p>{{ attachment.file_name }}</p>
                          <div class="actions">
                            <div class="open">
                              <a [href]="attachment.url" target="_blank">
                                <div>
                                  <mat-icon>visibility</mat-icon>
                                </div>
                                {{ 'OPEN' | translate }}
                              </a>
                            </div>
                            <div class="download">
                              <a [href]="attachment.url" [download]="attachment.file_name" target="_blank">
                                <div>
                                  <mat-icon>download</mat-icon>
                                </div>
                                {{ 'DOWNLOAD' | translate }}
                              </a>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <button class="back"
              (click)="rollback()"
              mat-button
              type="button">
              {{ 'BACK' | translate }}
            </button>
            <button class="filled"
              mat-flat-button
              (click)="next()">
              {{ 'NEXT' | translate }}
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="cfService.stepKey===1">
          <div class="col-12">
            <ai-ui-documents [attachments]="attachmentsToSign"
              [isCreatingOrder]="true"></ai-ui-documents>
          </div>
          <div class="col-10 confirmation">
            <h4>{{ 'CLAIM_SIGNATURE_TITLE' | translate }}</h4>
            <div class="hint">
              <p>{{ 'CLAIM_SIGNATURE_INFORMATION' | translate }}
              </p>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <button class="back"
              (click)="back()"
              mat-button
              type="button">
              {{ 'BACK' | translate }}
            </button>
            <button mat-button
              class="filled"
              type="button"
              (click)="customSendToSign()">
              {{ 'SIGN' | translate }}
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>