import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AgreementTariff } from 'src/app/utils/utils';
import { AiUiInfoDialogComponent } from '../ai-ui-info-dialog/ai-ui-info-dialog.component';
import { TranslationService } from '@localization';

@Component({
  selector: 'ai-ui-tariff-choice',
  templateUrl: './ai-ui-tariff-choice.component.html',
  styleUrls: ['./ai-ui-tariff-choice.component.scss'],
})
export class AiUiTariffChoiceComponent implements OnInit {
  tariffs = Array.from(new Set(Object.keys(AgreementTariff)));

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private translation: TranslationService,
    public dialogRef: MatDialogRef<AiUiTariffChoiceComponent>
  ) {}

  ngOnInit(): void {
    if (this.data.selectedTariff) {
      this.tariffs = this.tariffs.filter((i) => i != this.data.selectedTariff);
    }
  }

  chooseTariff(tariff: string) {
    this.dialogRef.close(tariff);
  }
}
