import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { apiUrlWithPrefix } from '@env';
import { TranslationService } from '@localization';
import { BehaviorSubject, Observable, forkJoin, map, mergeMap, of } from 'rxjs';
import { AttachmentBase } from 'src/app/interfaces/api/attachment.interface';
import {
  AddressInfo,
  ChangeLoginRequest,
  ConfirmContact,
  DeletedPassportScanDiffInterface,
  PassportScanDiffInterface,
  PassportTaxInfo,
  TaxCompliance,
  ValueDiffInterface,
} from 'src/app/interfaces/api/profile.interface';
import { ClaimStatus, ProfileStatus } from 'src/app/utils/utils';
import { Pagination } from '../../interfaces/api/pagination.interface';
import { AuthService } from '../auth/auth.service';
import { GeoService } from '../geo/geo.service';
import { LoaderService } from '../loader/loader.service';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  static readonly AIGENIS_INVEST_BINDING = `${apiUrlWithPrefix}/user/aigenis-oauth/initiate/`;

  static readonly PROFILE_CHANGE_LOGIN = `${apiUrlWithPrefix}/user/set-username/`;
  static readonly PROFILE_CHANGE_PHONE = `${apiUrlWithPrefix}/user/change-phone/`;
  static readonly PROFILE_CHANGE_EMAIL = `${apiUrlWithPrefix}/user/change-email/`;
  static readonly PROFILE_EMAIL_CONFIRMATION_REQUEST = `${apiUrlWithPrefix}/user/confirm-email-request/`;
  static readonly PROFILE_EMAIL_CONFIRMATION_CHECK = `${apiUrlWithPrefix}/user/confirm-email-check/`;
  static readonly PROFILE_CHANGE_PHONE_CONFIRM = `${apiUrlWithPrefix}/user/change-phone-confirm/`;
  static readonly PROFILE_CHANGE_EMAIL_CONFIRM = `${apiUrlWithPrefix}/user/change-email-confirm/`;
  static readonly PROFILE_INFO = `${apiUrlWithPrefix}/user/profile/`;

  static readonly PROFILE_PASSPORT_TAX_INFO = `${apiUrlWithPrefix}/user/passport-tax-info/`;
  static readonly PROFILE_PASSPORT_SCAN = `${apiUrlWithPrefix}/user/passport-scan/`;
  static readonly PROFILE_ADDRESS = `${apiUrlWithPrefix}/user/address/`;
  static readonly PROFILE_TAX_COMPLIANCE = `${apiUrlWithPrefix}/user/tax-compliance/`;

  static readonly PROFILE_EDIT_PASSPORT_TAX_INFO = `${apiUrlWithPrefix}/user/profile-edit/passport-tax-info/`;
  static readonly PROFILE_EDIT_PASSPORT_SCAN = `${apiUrlWithPrefix}/user/profile-edit/passport-scan/`;
  static readonly PROFILE_EDIT_ADDRESS = `${apiUrlWithPrefix}/user/profile-edit/address/`;
  static readonly PROFILE_EDIT_TAX_COMPLIANCE = `${apiUrlWithPrefix}/user/profile-edit/tax-compliance/`;
  static readonly PROFILE_EDIT_DIFFERENCE = `${apiUrlWithPrefix}/user/profile-edit/diff/`;
  static readonly PROFILE_EDIT_IS_ALLOWED = `${apiUrlWithPrefix}/user/profile-edit/is-allowed/`;
  static readonly PROFILE_EDIT_ROLLBACK_CHANGES = `${apiUrlWithPrefix}/user/profile-edit/rollback-changes/`;

  static readonly SIGN_CONSENTS_SEND_CODE = `${apiUrlWithPrefix}/user/profile/consents/send-code/`;
  static readonly SIGN_CONSENTS_CHECK_CODE = `${apiUrlWithPrefix}/user/profile/consents/check-code/`;

  static readonly profileEditDifferenceByClaimId = (claimId: number) =>
    `${apiUrlWithPrefix}/user/profile-edit/diff/${claimId}/`;
  static readonly downloadScanById = (id: number) => `${apiUrlWithPrefix}/user/passport-scan/${id}/download/`;
  static readonly getEditablePassportScanById = (id: number) =>
    `${apiUrlWithPrefix}/user/profile-edit/passport-scan/${id}/`;
  static readonly getPassportScanById = (id: number) => `${apiUrlWithPrefix}/user/passport-scan/${id}/`;
  static readonly attachmentEditUrl = (id: number): string =>
    `${apiUrlWithPrefix}/user/profile-edit/passport-scan/${id}/preview/`;

  public passportTaxInfo$: BehaviorSubject<PassportTaxInfo> = new BehaviorSubject<PassportTaxInfo>(null);

  static readonly _profileSteps: [number, string][] = [
    [0, 'personalData'],
    [1, 'contactData'],
    [2, 'extraData'],
  ];

  private _profileStepsMap = new Map(ProfileService._profileSteps);

  private _profileStep: number = 0;

  set profileCurrentStep(step: number) {
    this._profileStep = step;
  }

  get profileCurrentStepKey(): number {
    return this._profileStep;
  }

  get profileCurrentStepValue() {
    return this._profileStepsMap.get(this._profileStep);
  }

  get profileStepsCount() {
    return ProfileService._profileSteps.length;
  }

  public goToStep(step: number) {
    this.profileCurrentStep = step;
  }

  diffData: ValueDiffInterface[] = [];

  deletedPassportScansDiffData: DeletedPassportScanDiffInterface[] = [];

  uploadedPassportScansDiffData: DeletedPassportScanDiffInterface[] = [];

  fieldNames = [
    'last_name',
    'first_name',
    'middle_name',
    'sex',
    'birth_date',
    'tax_number_inn',
    'tax_number_unp',
    'insurance_number',
    'is_living_address_as_reg',
    'is_postal_address_as_living',
  ];

  excludedFieldNames = ['current_scans', 'deleted_scans'];

  objectNames = [
    'passport_scans', // ?
    'tax_compliance',
    'passport',
    'registration_address',
    'living_address',
    'postal_address',
  ];

  constructor(
    private auth: AuthService,
    private http: HttpClient,
    private geoService: GeoService,
    private translation: TranslationService,
    private sanitizer: DomSanitizer
  ) {
    this.diffData = [];
    this.deletedPassportScansDiffData = [];
    this.uploadedPassportScansDiffData = [];
  }

  sendCodeToSignConsents() {
    return this.http.get(ProfileService.SIGN_CONSENTS_SEND_CODE);
  }

  checkCodeToSignConsents(params: any) {
    return this.http.post(ProfileService.SIGN_CONSENTS_CHECK_CODE, {
      ...params,
    });
  }

  getPassportTaxInfo(isEdit: boolean = true) {
    const userStatus = this.auth.userProfile$?.value?.status;
    return this.http.get(
      userStatus === ProfileStatus.pii_approved
        ? ProfileService.PROFILE_EDIT_PASSPORT_TAX_INFO
        : ProfileService.PROFILE_PASSPORT_TAX_INFO
    );
  }

  sendPassportTaxInfo(data: PassportTaxInfo) {
    const userStatus = this.auth.userProfile$?.value?.status;
    const httpRequest =
      userStatus === ProfileStatus.pii_approved
        ? this.http.patch(ProfileService.PROFILE_EDIT_PASSPORT_TAX_INFO, {
            ...data,
          })
        : this.http.put(ProfileService.PROFILE_PASSPORT_TAX_INFO, {
            ...data,
          });
    return httpRequest;
  }

  getPassportScans(): Observable<Pagination<AttachmentBase>> {
    return this.http.get<Pagination<AttachmentBase>>(ProfileService.PROFILE_PASSPORT_SCAN);
  }

  getUploadedPassportScans(): Observable<Pagination<AttachmentBase>> {
    return this.http.get<Pagination<AttachmentBase>>(ProfileService.PROFILE_EDIT_PASSPORT_SCAN);
  }

  sendPassportScan(file: FormData): Observable<AttachmentBase> {
    const userStatus = this.auth.userProfile$?.value?.status;
    return this.http.post<AttachmentBase>(
      userStatus === ProfileStatus.pii_approved
        ? ProfileService.PROFILE_EDIT_PASSPORT_SCAN
        : ProfileService.PROFILE_PASSPORT_SCAN,
      file
    );
  }

  getPassportScanById(id: number) {
    const userStatus = this.auth.userProfile$?.value?.status;
    return this.http.get(
      userStatus === ProfileStatus.pii_approved
        ? ProfileService.getEditablePassportScanById(id)
        : ProfileService.getPassportScanById(id)
    );
  }

  download(id: number) {
    return this.http.get(ProfileService.downloadScanById(id), {
      reportProgress: true,
      responseType: 'blob',
      observe: 'events',
    });
  }

  deletePassportScan(id: number): Observable<void> {
    const userStatus = this.auth.userProfile$?.value?.status;
    return this.http.delete<void>(
      userStatus === ProfileStatus.pii_approved
        ? ProfileService.getEditablePassportScanById(id)
        : ProfileService.getPassportScanById(id)
    );
  }

  fileToBlob(url: string): Observable<any> {
    return this.http.get(url, { responseType: 'blob' }).pipe(
      map((res) => {
        return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(res));
      })
    );
  }

  getAddress(isEdit: boolean = true) {
    const userStatus = this.auth.userProfile$?.value?.status;
    return this.http.get(
      userStatus === ProfileStatus.pii_approved && isEdit
        ? ProfileService.PROFILE_EDIT_ADDRESS
        : ProfileService.PROFILE_ADDRESS
    );
  }

  sendAddress(data: AddressInfo) {
    const userStatus = this.auth.userProfile$?.value?.status;
    const httpRequest =
      userStatus === ProfileStatus.pii_approved
        ? this.http.patch(ProfileService.PROFILE_EDIT_ADDRESS, data)
        : this.http.put(ProfileService.PROFILE_ADDRESS, data);
    return httpRequest;
  }

  changeLogin(data: ChangeLoginRequest) {
    return this.http.post(ProfileService.PROFILE_CHANGE_LOGIN, data);
  }

  changeEmail(email: string) {
    return this.http.post(ProfileService.PROFILE_CHANGE_EMAIL, {
      email,
    });
  }

  emailConfirmationRequest() {
    return this.http.post(ProfileService.PROFILE_EMAIL_CONFIRMATION_REQUEST, null);
  }

  emailConfirmationCheck(data: ConfirmContact) {
    return this.http.post(ProfileService.PROFILE_EMAIL_CONFIRMATION_CHECK, data);
  }

  changeEmailConfirm(data: ConfirmContact) {
    return this.http.post(ProfileService.PROFILE_CHANGE_EMAIL_CONFIRM, data);
  }

  changePhone(phone: string) {
    return this.http.post(ProfileService.PROFILE_CHANGE_PHONE, {
      phone,
    });
  }

  changePhoneConfirm(data: ConfirmContact) {
    return this.http.post(ProfileService.PROFILE_CHANGE_PHONE_CONFIRM, {
      ...data,
    });
  }

  getTaxCompliance(isEdit: boolean = true) {
    const userStatus = this.auth.userProfile$?.value?.status;
    return this.http.get(
      userStatus === ProfileStatus.pii_approved && isEdit
        ? ProfileService.PROFILE_EDIT_TAX_COMPLIANCE
        : ProfileService.PROFILE_TAX_COMPLIANCE
    );
  }

  sendTaxCompliance(data: TaxCompliance) {
    const userStatus = this.auth.userProfile$?.value?.status;
    const httpRequest =
      userStatus === ProfileStatus.pii_approved
        ? this.http.patch(ProfileService.PROFILE_EDIT_TAX_COMPLIANCE, {
            ...data,
          })
        : this.http.put(ProfileService.PROFILE_TAX_COMPLIANCE, {
            ...data,
          });
    return httpRequest;
  }

  getDiff() {
    return this.http.get(ProfileService.PROFILE_EDIT_DIFFERENCE).pipe(map((res: any) => res));
  }

  getDiffByClaimId(claimid: number) {
    return this.http.get(ProfileService.profileEditDifferenceByClaimId(claimid)).pipe(map((res: any) => res));
  }

  rollBackChanges() {
    return this.http.post(ProfileService.PROFILE_EDIT_ROLLBACK_CHANGES, null);
  }

  getData(data: any, objectName: string = null, claimStatus: string = null) {
    const results = Object.entries(data).filter((r) => !this.objectNames.includes(r[0]) && !!r[1]);
    this.pushDiffData(results, objectName, claimStatus);
    return this.diffData;
  }

  pushDiffData(values: [string, unknown][], objectName: string, claimStatus: string = null) {
    values.map((result, i) => {
      if (claimStatus !== ClaimStatus.satisfied && result[0] === 'deleted_scans' && (result[1] as []).length > 0) {
        this.pushPassportScans(<number[]>result[1], this.deletedPassportScansDiffData);
      } else if (result[0] === 'uploaded_scans' && (result[1] as []).length > 0) {
        this.pushPassportScans(<number[]>result[1], this.uploadedPassportScansDiffData);
      } else if (!this.excludedFieldNames.includes(result[0])) {
        let current = (result[1] as any).current;
        let updated = (result[1] as any).updated;
        let current_name_value = (result[1] as any).current_name_value;
        let updated_name_value = (result[1] as any).updated_name_value;
        if (result[0] === 'locality_type_id') {
          const locality_types = this.geoService.localityTypes$.value;
          current = locality_types?.find((n) => n.id === current)?.name;
          updated = locality_types?.find((n) => n.id === updated)?.name;
        } else if (result[0] === 'street_type_id') {
          const street_types = this.geoService.streetTypes$.value;
          current = street_types?.find((n) => n.id === current)?.name;
          updated = street_types?.find((n) => n.id === updated)?.name;
        }
        this.diffData.push({
          objectName: i === 0 && !!objectName ? objectName : null,
          fieldName: result[0],
          current: typeof current === 'boolean' ? this._get_bool_value(current) : current || '-',
          updated: typeof updated === 'boolean' ? this._get_bool_value(updated) : updated || '-',
          current_name_value: current_name_value,
          updated_name_value: updated_name_value,
        });
      }
    });
  }

  private pushPassportScans(scanIds: number[], scans: PassportScanDiffInterface[]) {
    scanIds.map((id) => {
      let file_name = null;
      this.getPassportScanById(id)
        .pipe(
          mergeMap((data: any) => {
            file_name = data.file_name;
            return this.fileToBlob(ProfileService.attachmentEditUrl(data.id));
          })
        )
        .subscribe((item) => {
          scans.push({
            file_name: file_name,
            url: item,
            isImage: ['jpeg', 'jpg', 'png']?.indexOf(file_name?.split('.')?.pop()?.toLowerCase()) !== -1,
          });
        });
    });
  }

  aigenisInvestBinding() {
    this.http.post(ProfileService.AIGENIS_INVEST_BINDING, null).subscribe({
      next: (data: any) => {
        window.location.assign(data.redirect_uri);
      },
    });
  }

  getObjectData(data: any, claimStatus: string = null) {
    this.pushDiffData(
      Object.entries(data).filter((r) => this.fieldNames.includes(r[0]) && !!r[1]),
      this.translation.translate('PERSONAL_DATA'),
      claimStatus
    );

    const results = Object.entries(data).filter((r) => this.objectNames.includes(r[0]) && !!r[1]);
    for (let result of results) {
      this.getData(result[1], result[0], claimStatus);
    }
  }

  private _get_bool_value(value: boolean) {
    return value ? this.translation.translate('YES') : this.translation.translate('NO');
  }
}
