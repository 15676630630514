<div class="container-fluid bg-white p-4"
  [@open]="'opened'">
  <h1>{{ 'SETTINGS' | translate }}</h1>
  <div class="row">
    <div class="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12 form-data">
      <ng-container *ngIf="step === 0">
        <div class="settings-title">
          <h3>{{ 'SETTINGS:SECUTIRY_TITLE' | translate }}</h3>
        </div>
        <div class="security-content">
          <div class="shadowed-box d-flex justify-content-between align-items-center"
            (click)="onLoginChange()">
            <span>{{ 'CHANGE_CURRENT_LOGIN' | translate }}</span>
            <mat-icon>
              edit
            </mat-icon>
          </div>
          <div class="shadowed-box d-flex justify-content-between align-items-center"
            (click)="onPasswordChange()">
            <span>{{ 'CHANGE_CURRENT_PASSWORD' | translate }}</span>
            <mat-icon>
              edit
            </mat-icon>
          </div>
        </div>
        <div class="settings-title">
          <h3>{{ 'SETTINGS:CODEWORD_TITLE' | translate }}</h3>
        </div>
        <div class="security-content">
          <div class="shadowed-box d-flex justify-content-between align-items-center"
            (click)="onKeywordChange()">
            <span>{{ 'SETTINGS:APPLY_FOR_CODEWORD_ADDING_OR_CHANGING' | translate }}</span>
          </div>
        </div>
        <ng-container *ngIf="(auth.userProfile$ | async)?.citizenship==='BY'">
          <div class="settings-title">
            <h3>{{ 'SETTINGS:ADDITIONAL' | translate }}</h3>
          </div>
          <div class="security-content">
            <ng-container *ngIf="(auth.userProfile$ | async)?.user_aigenis_id===null;else bound">
              <div [matTooltip]="'SETTINGS:AIGENIS_BINDING_TOOLTIP' | translate"
                matTooltipPosition="above"
                matTooltipClass="custom-tooltip"
                class="shadowed-box d-flex justify-content-between align-items-center"
                (click)="bindAccount()">
                <span>{{ 'SETTINGS:AIGENIS_BINDING' | translate }}</span>
                <img src="./assets/images/aigenis_invest.svg"
                  alt="logo">
              </div>
              <div class="hint">
                <p>{{ 'SETTINGS:AIGENIS_BINDING_TIP' | translate }}</p>
              </div>
            </ng-container>
            <ng-template #bound>
              <div class="shadowed-box d-flex justify-content-between align-items-center">
                <span>{{ 'SETTINGS:AIGENIS_ACCOUNT_BOUND' | translate }}</span>
                <img src="./assets/images/aigenis_invest.svg"
                  alt="logo">
              </div>
            </ng-template>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="step===1">
        <div class="settings-title-mailing">
          <h3>{{ 'SETTINGS:NOTIFICATIONS_AND_MAILING_TITLE' | translate }}</h3>
        </div>
        <div class="mailing-content">
          <form [formGroup]="mailingSettingsForm">
            <mat-slide-toggle formControlName="main"
              color="primary"
              labelPosition="before">
              <div class="header">
                <span>{{ 'SETTINGS:NOTIFICATION_TYPE_1_TITLE' | translate }}</span>
              </div>
              <div class="hint">
                <p>{{ 'SETTINGS:NOTIFICATION_TYPE_1_DESCRIPTION' | translate }}</p>
              </div>
            </mat-slide-toggle>
            <mat-slide-toggle formControlName="claim"
              color="primary"
              labelPosition="before">
              <div class="header">
                <span>{{ 'SETTINGS:NOTIFICATION_TYPE_2_TITLE' | translate }}</span>
              </div>
              <div class="hint">
                <p>{{ 'SETTINGS:NOTIFICATION_TYPE_2_DESCRIPTION' | translate }}</p>
              </div>
            </mat-slide-toggle>
            <mat-slide-toggle formControlName="news"
              color="primary"
              labelPosition="before">
              <div class="header">
                <span>{{ 'SETTINGS:NOTIFICATION_TYPE_3_TITLE' | translate }}</span>
              </div>
              <div class="hint">
                <p>{{ 'SETTINGS:NOTIFICATION_TYPE_3_DESCRIPTION' | translate }}</p>
              </div>
            </mat-slide-toggle>
          </form>
          <button mat-button
            class="filled"
            type="button"
            (click)="saveSettings()">
            {{ 'SAVE' | translate }}
          </button>
        </div>
      </ng-container>
      <ng-container *ngIf="step === 2">
        <div class="settings-title">
          <h3>{{ 'SETTINGS:CONTACT_DATA_TAB' | translate }}</h3>
        </div>
        <form [formGroup]="contactDataForm">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'FIELD:PHONE_NUMBER' | translate }}</mat-label>
            <div class="d-flex contact-edit">
              <input matInput
                formControlName="phone" />
              <mat-icon (click)="onPhoneChange()">
                edit
              </mat-icon>
            </div>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{ 'FIELD:EMAIL' | translate }}</mat-label>
            <div class="d-flex contact-edit">
              <input formControlName="email"
                matInput>
              <mat-icon (click)="onEmailChange()">
                edit
              </mat-icon>
            </div>
          </mat-form-field>
          <ng-container *ngIf="(auth.userProfile$ | async)?.email_confirmed===false">
            <div class="d-flex justify-content-end">
              <button class="filled"
                mat-button
                type="button"
                (click)="onEmailConfirm()">
                {{ 'EMAIL:CONFIRM' | translate }}
              </button>
            </div>
          </ng-container>
        </form>
      </ng-container>
      <ng-container *ngIf="step===3">
        <div class="settings-title">
          <h3>{{ 'SETTINGS:BANK_ACCOUNTS_TAB' | translate }}</h3>
        </div>
        <div class="d-flex justify-content-end mb-2">
          <button mat-button
            class="filled"
            (click)="onAddBankAccount()">
            {{'ADD' | translate}}
            <mat-icon class="ms-2 material-icons-outlined">library_add</mat-icon>
          </button>
        </div>
        <div class="bank-account"
          *ngFor="let bankAccount of bankAccounts">
          <ai-ui-bank-account-card [bankAccount]="bankAccount"></ai-ui-bank-account-card>
          <button mat-icon-button
            [matMenuTriggerFor]="menu"
            aria-label="Example icon-button with a menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item
              (click)="onEditBankAccount(bankAccount.id)">
              <mat-icon>edit</mat-icon>
              <span>{{ 'EDIT' | translate }}</span>
            </button>
            <button mat-menu-item
              (click)="onDeleteBankAccount(bankAccount.id)">
              <mat-icon>close</mat-icon>
              <span>{{ 'DELETE' | translate }}</span>
            </button>
          </mat-menu>
        </div>
      </ng-container>
    </div>
    <div class="col charts">
      <mat-button-toggle-group vertical="true"
        name="favoriteColor">
        <mat-button-toggle [ngClass]="{ 'checked' : step===0 }"
          (click)="step=0">
          {{ 'SETTINGS:SECUTIRY_TAB' | translate }}
        </mat-button-toggle>
        <mat-button-toggle [ngClass]="{ 'checked' : step===1 }"
          (click)="step=1">
          {{ 'SETTINGS:NOTIFICATIONS_AND_MAILING_TAB' | translate }}
        </mat-button-toggle>
        <mat-button-toggle [ngClass]="{ 'checked' : step===2 }"
          (click)="step=2">
          {{ 'SETTINGS:CONTACT_DATA_TAB' | translate }}
        </mat-button-toggle>
        <mat-button-toggle [ngClass]="{ 'checked' : step===3 }"
          (click)="step=3">
          {{ 'SETTINGS:BANK_ACCOUNTS_TAB' | translate }}
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>
</div>