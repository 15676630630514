<div class="close" mat-dialog-close>
  <mat-icon>close</mat-icon>
</div>
<div>
  <div class="content">
    <h2>{{ 'RISK_DIALOG:TITLE' | translate }}</h2>
    <p>{{ 'RISK_DIALOG:CONTENT' | translate }}</p>
    <h3>{{ 'RISK_DIALOG:PART_TITLE_1' | translate }}</h3>
    <p>{{ 'RISK_DIALOG:PART_CONTENT_1' | translate }}</p>
    <h3>{{ 'RISK_DIALOG:PART_TITLE_2' | translate }}</h3>
    <p>{{ 'RISK_DIALOG:PART_CONTENT_2' | translate }}</p>
    <h3>{{ 'RISK_DIALOG:PART_TITLE_3' | translate }}</h3>
    <p>{{ 'RISK_DIALOG:PART_CONTENT_3' | translate }}</p>
    <p>{{ 'RISK_DIALOG:PART_CONTENT_4' | translate }}</p>
  </div>
</div>