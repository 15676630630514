import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { apiUrlWithPrefix } from '@env';
import { TranslationService } from '@localization';
import { map, Observable, tap } from 'rxjs';
import { AuthSettings, Payload, PersonalInfo } from 'src/app/interfaces/api/registration.interface';
import { ToasterService } from 'src/app/shared/shared-services/toaster/toaster.service';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class RegistrationService {
  static readonly REGISTRATION_INITIATE = `${apiUrlWithPrefix}/user/sign-up/initiate/`;
  static readonly REGISTRATION_RESEND_CODE = `${apiUrlWithPrefix}/user/sign-up/resend-code/`;
  static readonly REGISTRATION_CHECK_CODE = `${apiUrlWithPrefix}/user/sign-up/check-code/`;
  static readonly REGISTRATION_NEXT_STEP = `${apiUrlWithPrefix}/user/sign-up/next-step/`;
  static readonly REGISTRATION_PERSONAL_INFO = `${apiUrlWithPrefix}/user/sign-up/personal-info/`;
  static readonly REGISTRATION_CHECK_SIGN_CONSENTS_CODE = `${apiUrlWithPrefix}/user/sign-up/consents/check-code/`;
  static readonly REGISTRATION_AUTH_SETTINGS = `${apiUrlWithPrefix}/user/sign-up/auth-settings/`;

  static readonly _registrationSteps: [number, string][] = [
    [0, 'enter_phone'],
    [1, 'confirm_phone'],
    [2, 'personal_info'],
    [3, 'auth_settings'],
  ];

  private _registrationStepsMap = new Map(RegistrationService._registrationSteps);

  private _registrationStep: number = 0;

  set registrationCurrentStep(step: number) {
    this._registrationStep = step;
  }

  get registrationCurrentStepKey(): number {
    return this._registrationStep;
  }

  get registrationCurrentStepValue() {
    return this._registrationStepsMap.get(this._registrationStep);
  }

  get registrationStepsCount() {
    return RegistrationService._registrationSteps.length;
  }

  public goToStep(step: number) {
    this.registrationCurrentStep = step;
  }

  readonly REGISTRATION_UUID_KEY = 'registraion_uuid';

  readonly PAYLOAD_KEY = 'payload';

  get registration_uuid_value(): string {
    return JSON.parse(sessionStorage.getItem(this.REGISTRATION_UUID_KEY)).uuid;
  }

  get registration_uuid(): { registration_uuid: string } {
    return JSON.parse(sessionStorage.getItem(this.REGISTRATION_UUID_KEY)) || null;
  }

  set registration_uuid(value: { registration_uuid: string }) {
    sessionStorage.setItem(this.REGISTRATION_UUID_KEY, JSON.stringify(value));
  }

  get payload(): Payload {
    return JSON.parse(sessionStorage.getItem(this.PAYLOAD_KEY)) || null;
  }

  set payload(data: Payload) {
    sessionStorage.setItem(this.PAYLOAD_KEY, JSON.stringify(data));
  }

  getRegistrationStepByValue(stepName: string) {
    return RegistrationService._registrationSteps.find((v) => v[1] === stepName)[0];
  }

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private authService: AuthService,
    private toasterService: ToasterService,
    private translation: TranslationService
  ) {}

  getEdmConsent() {
    return this.fileToBlob(`${apiUrlWithPrefix}/user/sign-up/${this.registration_uuid_value}/edm-consent/preview/`);
  }

  getPersonalDataConsent() {
    return this.fileToBlob(
      `${apiUrlWithPrefix}/user/sign-up/${this.registration_uuid_value}/personal-data-consent/preview/`
    );
  }

  fileToBlob(url: string): Observable<any> {
    return this.http.get(url, { responseType: 'blob' }).pipe(
      map((res) => {
        return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(res));
      })
    );
  }

  lastStep() {
    return this.http.post<string>(RegistrationService.REGISTRATION_NEXT_STEP, {
      ...this.registration_uuid,
    });
  }

  initiate(phone: string) {
    return this.http
      .post<{ registration_uuid: string }>(RegistrationService.REGISTRATION_INITIATE, {
        phone,
      })
      .pipe(
        map((res) => {
          this.registration_uuid = res;
          return res;
        })
      );
  }

  checkCode(code: string) {
    return this.http.post(RegistrationService.REGISTRATION_CHECK_CODE, {
      ...this.registration_uuid,
      code: code,
    });
  }

  resendCode() {
    return this.http.post(RegistrationService.REGISTRATION_RESEND_CODE, {
      ...this.registration_uuid,
    });
  }

  personalInfo(personalInfo: PersonalInfo) {
    return this.http.post(RegistrationService.REGISTRATION_PERSONAL_INFO, {
      ...this.registration_uuid,
      ...personalInfo,
    });
  }

  checkSignConsentsCode(params: any) {
    return this.http.post(RegistrationService.REGISTRATION_CHECK_SIGN_CONSENTS_CODE, {
      ...this.registration_uuid,
      ...params,
    });
  }

  authSettings(settings: AuthSettings) {
    return this.http
      .post<string>(RegistrationService.REGISTRATION_AUTH_SETTINGS, {
        ...this.registration_uuid,
        ...settings,
      })
      .pipe(tap((access) => this.authService.setAccessToken(access)));
  }
}
