import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { MatSelectChange } from '@angular/material/select';
import { Router } from '@angular/router';
import { apiUrlWithPrefix } from '@env';
import { TranslationService } from '@localization';
import { Observable, Subject, Subscription, filter, forkJoin, mergeMap, takeUntil } from 'rxjs';
import { Attachment, AttachmentBase } from 'src/app/interfaces/api/attachment.interface';
import { RegionRuResponse } from 'src/app/interfaces/api/geo.interface';
import { Address, AddressInfo, PassportTaxInfo, TaxCompliance } from 'src/app/interfaces/api/profile.interface';
import { AuthService } from 'src/app/services/auth/auth.service';
import { GeoService } from 'src/app/services/geo/geo.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { AiUiImageDialogComponent } from 'src/app/shared/shared-components/ai-ui-image-dialog/ai-ui-image-dialog.component';
import { AiUiPersonalDataEditableContentComponent } from 'src/app/shared/shared-components/ai-ui-personal-data-editable-content/ai-ui-personal-data-editable-content.component';
import { AiUiSelectComponent } from 'src/app/shared/shared-components/ai-ui-select/ai-ui-select.component';
import { ToasterService } from 'src/app/shared/shared-services/toaster/toaster.service';
import { animationOptions } from 'src/app/utils/animation';
import {
  CountryCode,
  NAME_REGEXP,
  HasNoForeignTaxNumberReason,
  PassportType,
  ProfileStatus,
  Sex,
  TaxResidentStatus,
  acceptedFormates,
  convertByteToMegaBytes,
  dateOfBirthValidator,
  getFormatDate,
  maxFileSize,
} from 'src/app/utils/utils';

@Component({
  selector: 'app-profile-edit',
  templateUrl: './profile-edit.component.html',
  styleUrls: ['./profile-edit.component.scss'],
  animations: animationOptions,
})
export class ProfileEditComponent implements OnInit, OnDestroy {
  @ViewChild('regAddressDistrict') regAddressDistrict: AiUiSelectComponent;

  today: Date = new Date();

  destroyed$: Subject<void> = new Subject();

  countries = Array.from(new Set(Object.keys(CountryCode)));

  male_female = Array.from(new Set(Object.keys(Sex)));

  taxResidentStatuses = Array.from(new Set(Object.keys(TaxResidentStatus)));

  passportScans: AttachmentBase[];

  attachments: Attachment[] = [];

  regRegionRuItems: RegionRuResponse[] = [];

  efficiencies = ['full', 'diminished'];

  acceptedFormates = acceptedFormates;

  personalDataForm: FormGroup;

  contactDataForm: FormGroup;

  extraDataForm: FormGroup;

  file: FormControl;

  extraDataProcessing: FormControl = new FormControl(false);

  images: Attachment[] = [];

  isAllowedCheckobserver: Subscription = new Subscription();

  isTheFirstStepSaved: boolean;

  isTheSecondStepSaved: boolean;

  oldPersonalData: PassportTaxInfo;

  oldContactData: AddressInfo;

  oldExtraData: TaxCompliance;

  has_residence_permit_in_country = new FormControl(false, Validators.required);

  attachmentUrl = (id: number): string => `${apiUrlWithPrefix}/user/passport-scan/${id}/preview/`;

  attachmentEditUrl = (id: number): string => `${apiUrlWithPrefix}/user/profile-edit/passport-scan/${id}/preview/`;

  filteredRegions: Observable<RegionRuResponse[]>;

  constructor(
    public profileService: ProfileService,
    public authService: AuthService,
    public geoService: GeoService,
    private fb: FormBuilder,
    private toaster: ToasterService,
    private translation: TranslationService,
    private loader: LoaderService,
    private cd: ChangeDetectorRef,
    private dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.personalDataForm = this.initPersonalDataForm();
    this.personalDataForm.markAllAsTouched();
    this.contactDataForm = this.initContactDataForm();
    this.contactDataForm.markAllAsTouched();
    this.extraDataForm = this.initExtraDataForm();
    this.extraDataForm.markAllAsTouched();
    this.file = new FormControl(null);
    this.cd.markForCheck();
    this.authService.isAllowedToChangeData$
      .pipe(
        takeUntil(this.destroyed$),
        filter((n) => !!this.authService.userProfile$.value)
      )
      .subscribe((data) => {
        if (data?.result === false) {
          this.toaster.showError(this.translation.translate(data.reason));
          this.router.navigate(['profile', 'info']);
        } else if (data?.result && this.authService.userProfile$?.value?.status === ProfileStatus.pii_approved) {
          this.loader.showLoader();
          this.profileService.getDiff().subscribe({
            next: (data) => {
              if (Object.values(data).filter((n) => Object.keys(n).length > 0).length === 0) {
                this.initData();
              } else {
                this.profileService.diffData = [];
                this.profileService.deletedPassportScansDiffData = [];
                this.profileService.uploadedPassportScansDiffData = [];
                this.profileService.getObjectData(data);
                this.dialog
                  .open(AiUiPersonalDataEditableContentComponent, {
                    width: '800px',
                    disableClose: true,
                    panelClass: 'popup-container',
                    data: {
                      diffData: this.profileService.diffData,
                      deletedPassportScansDiffData: this.profileService.deletedPassportScansDiffData,
                      uploadedPassportScansDiffData: this.profileService.uploadedPassportScansDiffData,
                    },
                  })
                  .afterClosed()
                  .subscribe({
                    next: () => {
                      this.initData();
                    },
                    error: () => {},
                  });
              }
              this.loader.hideLoader();
            },
            error: () => {
              this.loader.hideLoader();
            },
          });
        } else {
          this.initData();
        }
      });
  }

  converter(f: AbstractControl) {
    return f as FormControl;
  }

  initData() {
    this.getPersonalData();
    this.getContactData();
    this.getExtraData();
    this.getAttachments();
  }

  initPersonalDataForm() {
    return this.fb.group({
      last_name: new FormControl(null, [
        Validators.required,
        Validators.pattern(NAME_REGEXP),
        Validators.maxLength(15),
      ]),
      first_name: new FormControl(null, [
        Validators.required,
        Validators.pattern(NAME_REGEXP),
        Validators.maxLength(15),
      ]),
      middle_name: new FormControl(null, [Validators.pattern(NAME_REGEXP), Validators.maxLength(15)]),
      sex: new FormControl(null, Validators.required),
      birth_date: new FormControl(new Date(), [Validators.required, dateOfBirthValidator()]),
      tax_number_unp: new FormControl(null, [Validators.minLength(9), Validators.maxLength(9)]),
      tax_number_inn: new FormControl(null, [Validators.maxLength(30)]),
      insurance_number: new FormControl(null),
      passport: this.fb.group({
        type: new FormControl(PassportType.passport, [Validators.required]),
        id_number: new FormControl(null, [Validators.required, Validators.maxLength(30)]),
        full_name: new FormControl(null, [Validators.required]),
        citizenship: new FormControl(null, [Validators.required]),
        number: new FormControl(null, [Validators.required, Validators.maxLength(15)]),
        biometric_number: new FormControl(null),
        biometric_date_of_issue: new FormControl(null),
        biometric_date_of_expiration: new FormControl(null),
        date_of_issue: new FormControl(null, Validators.required),
        date_of_expiration: new FormControl(null, Validators.required),
        issued_by_code: new FormControl(null, Validators.required),
        issued_by: new FormControl(null, Validators.required),
        birth_place: new FormControl(null, Validators.required),
      }),
    });
  }

  getPersonalData() {
    this.loader.showLoader();
    this.profileService.getPassportTaxInfo().subscribe((data: PassportTaxInfo) => {
      this.oldPersonalData = data;
      this.personalDataForm.patchValue(data);
      this.onPassportTypeChange();
      this.cd.markForCheck();
      this.loader.hideLoader();
    });
  }

  getContactData() {
    this.loader.showLoader();
    this.profileService.getAddress().subscribe((data: AddressInfo) => {
      this.oldContactData = data;
      this.contactDataForm.patchValue({
        ...data,
      });
      this.onRegistrationAddressToggleChange()
      this.onLivingAddressToggleChange(true);
      this.onPostalAddressToggleChange(true);
      this.cd.markForCheck();
      this.loader.hideLoader();
    });
  }

  getExtraData() {
    this.loader.showLoader();
    this.profileService.getTaxCompliance().subscribe((data: TaxCompliance) => {
      this.oldExtraData = data;
      this.has_residence_permit_in_country.setValue(data.has_residence_permit_in_countries_list?.length > 0);
      this.extraDataForm.patchValue({
        ...data,
      });
      this.updateTaxCountryNameValidators(data.tax_resident_type === TaxResidentStatus.tax_resident_of_another_country);
      if (
        data.is_foreign_person_authorized_to_manage_account === 'other_country_person' &&
        data.foreign_person_authorized_to_manage_account_countries_list?.length > 0
      ) {
        this.addCountry(data.foreign_person_authorized_to_manage_account_countries_list);
      }
      if (
        data.has_order_to_transfer_funds_to_foreign_country === 'other_country' &&
        data.has_order_to_transfer_funds_to_foreign_countries_list?.length > 0
      ) {
        this.addOrderToTransferFunds(data.has_order_to_transfer_funds_to_foreign_countries_list);
      }
      if (
        data.has_address_in_foreign_country === 'other_country' &&
        data.has_address_in_foreign_countries_list?.length > 0
      ) {
        this.addAddressInForeignCountry(data.has_address_in_foreign_countries_list);
      }
      if (data.has_residence_permit_in_countries_list?.length > 0) {
        this.addResidencePermit(data.has_residence_permit_in_countries_list);
      }
      this.onChangeUsaTaxResident(data.is_usa_tax_resident);

      this.onChangeHasForeignTaxNumber(data.has_foreign_tax_number);
      this.onChangeHasNoForeignTaxNumberReason(data.has_no_foreign_tax_number_reason);

      this.onRadioButtonChange(data.is_foreign_public_official, 'foreign_public_official_info', 255);
      this.onRadioButtonChange(data.is_official_of_int_org, 'official_of_int_org_info', 255);
      this.onRadioButtonChange(data.is_other_country_tax_resident, 'other_country_tax_resident_info', 255);
      this.onRadioButtonChange(
        data.has_permanent_instructions_for_funds_transfer_to_foreign_accs,
        'permanent_instructions_for_funds_transfer_to_foreign_accs_info',
        255
      );
      this.onRadioButtonChange(data.is_relative_of_official, 'relative_of_official_info', 255);

      this.cd.markForCheck();
      this.loader.hideLoader();
    });
  }

  initContactDataForm() {
    return this.fb.group({
      is_living_address_as_reg: new FormControl(false),
      is_postal_address_as_living: new FormControl(false),
      registration_address: this.address_form(),
      living_address: this.address_form(),
      postal_address: this.address_form(),
    });
  }

  private address_form() {
    return this.fb.group({
      country: new FormControl(CountryCode.RU, Validators.required),
      region: new FormControl(null),
      region_en: new FormControl(null),
      locality_type_id: new FormControl(null),
      locality: new FormControl(null),
      locality_en: new FormControl(null),
      street_type_id: new FormControl(null),
      street: new FormControl(null),
      street_en: new FormControl(null),
      region_rus: new FormControl(null),
      region_rus_id: new FormControl(null),
      district_rus: new FormControl(null),
      district_rus_id: new FormControl(null),
      district_en: new FormControl(null),
      city_rus: new FormControl(null),
      city_rus_id: new FormControl(null),
      city_en: new FormControl(null),
      locality_rus: new FormControl(null),
      locality_rus_id: new FormControl(null),
      street_rus: new FormControl(null),
      street_rus_id: new FormControl(null),
      house_number: new FormControl(null, [Validators.required, Validators.maxLength(20)]),
      house_unit: new FormControl(null),
      apartment_number: new FormControl(null, [Validators.maxLength(20)]),
      zip_code: new FormControl(null, [Validators.required, Validators.maxLength(6)]),
    });
  }

  initExtraDataForm() {
    return this.fb.group({
      tax_resident_type: new FormControl(null, [Validators.required]),
      tax_resident_country_name: new FormControl(null),
      work_capacity: new FormControl(false, Validators.required),
      is_protector: new FormControl(false, Validators.required),
      is_public_official: new FormControl(false, Validators.required),
      is_foreign_public_official: new FormControl(false, Validators.required),
      foreign_public_official_info: new FormControl(null),
      is_official_of_int_org: new FormControl(false, Validators.required),
      official_of_int_org_info: new FormControl(null),
      is_relative_of_official: new FormControl(false, Validators.required),
      relative_of_official_info: new FormControl(null),
      is_usa_tax_resident: new FormControl(false, Validators.required),
      usa_tax_number: new FormControl(null),
      has_foreign_tax_number: new FormControl(false, Validators.required),
      foreign_tax_number: new FormControl(null),
      has_no_foreign_tax_number_reason: new FormControl(null),
      individual_cannot_obtain_tin_due_to_other_reasons: new FormControl(null),
      is_other_country_tax_resident: new FormControl(false, Validators.required),
      other_country_tax_resident_info: new FormControl(null),
      is_usa_citizen: new FormControl(false, Validators.required),
      is_birth_place_usa_and_not_citizen: new FormControl(false, Validators.required),
      is_foreign_person_authorized_to_manage_account: new FormControl(null, Validators.required),
      foreign_person_authorized_to_manage_account_countries_list: this.fb.array([]),
      has_order_to_transfer_funds_to_foreign_country: new FormControl(null, Validators.required),
      has_order_to_transfer_funds_to_foreign_countries_list: this.fb.array([]),
      has_permanent_instructions_for_funds_transfer_to_foreign_accs: new FormControl(false),
      permanent_instructions_for_funds_transfer_to_foreign_accs_info: new FormControl(null),
      has_green_card: new FormControl(false, Validators.required),
      has_address_in_foreign_country: new FormControl(null, Validators.required),
      has_address_in_foreign_countries_list: this.fb.array([]),
      has_usa_phone: new FormControl(false, Validators.required),
      has_foreign_phone: new FormControl(false),
      foreign_phone_number: new FormControl(null),
      has_residence_permit_in_countries_list: this.fb.array([]),
    });
  }

  onRegionSelected(regionFormControl: AbstractControl, value: any) {
    const parentFormControl = this.converter(regionFormControl.parent);
    if (!!parentFormControl) {
      parentFormControl.patchValue(
        {
          district_rus_id: null,
          city_rus_id: null,
          locality_rus_id: null,
          street_rus: '',
          street_rus_id: null,
        },
        { emitEvent: false }
      );
      regionFormControl.patchValue(value?.id);
      parentFormControl.get('district_rus').reset();
      parentFormControl.get('city_rus').reset();
      parentFormControl.get('locality_rus').reset();
    }
    parentFormControl.get('district_rus').enable();
    parentFormControl.get('city_rus').enable();
    parentFormControl.get('locality_rus').enable();
    parentFormControl.get('street_rus').enable();
  }

  onDistrictSelected(districtFormControl: AbstractControl, value: any) {
    const parentFormControl = this.converter(districtFormControl.parent);
    if (!!parentFormControl) {
      parentFormControl.patchValue(
        {
          city_rus_id: null,
          locality_rus_id: null,
          street_rus: '',
          street_rus_id: null,
        },
        { emitEvent: false }
      );
      districtFormControl.patchValue(value?.id);
      parentFormControl.get('city_rus').reset('');
      parentFormControl.get('locality_rus').reset('');
      parentFormControl.get('city_rus').updateValueAndValidity();
      parentFormControl.get('locality_rus').updateValueAndValidity();
    }
  }

  onCitySelected(cityFormControl: AbstractControl, value: any) {
    const parentFormControl = this.converter(cityFormControl.parent);
    if (!!parentFormControl) {
      parentFormControl.patchValue({
        street_rus: '',
        street_rus_id: null,
      });
      cityFormControl.patchValue(value?.id);
      // parentFormControl.get('locality_rus').reset();
      // parentFormControl.get('street_rus').reset();
    }
  }

  onLocalitySelected(localityFormControl: AbstractControl, value: any) {
    const parentFormControl = this.converter(localityFormControl.parent);
    if (!!parentFormControl) {
      parentFormControl.patchValue({
        street_rus: '',
        street_rus_id: null,
      });
      localityFormControl.patchValue(value?.id);
      // parentFormControl.get('street_rus').reset();
    }
  }

  onStreetSelected(streetFormControl: AbstractControl, value: any) {
    const parentFormControl = this.converter(streetFormControl.parent);
    if (!!parentFormControl) {
      parentFormControl.patchValue({
        zip_code: value?.zip_code,
      });
    }
    streetFormControl.patchValue(value?.id);
  }

  onNotRegionSelected(formControl: AbstractControl) {
    formControl.patchValue({
      region_rus: '',
      region_rus_id: null,
      district_rus: '',
      district_rus_id: null,
      city_rus: '',
      city_rus_id: null,
      locality_rus: '',
      locality_rus_id: null,
      street_rus: '',
      street_rus_id: null,
    });
    formControl.get('district_rus').disable();
    formControl.get('city_rus').disable();
    formControl.get('locality_rus').disable();
    formControl.get('street_rus').disable();
  }

  onNotDistrictSelected(formControl: AbstractControl) {
    formControl.patchValue({
      district_rus: '',
      district_rus_id: null,
      city_rus_id: null,
      locality_rus_id: null,
      street_rus: '',
      street_rus_id: null,
    });
    // formControl.get('city_rus').reset();
    // formControl.get('locality_rus').reset();
    formControl.get('district_rus').setValue('', { emitEvent: true });
    formControl.get('city_rus').reset('');
    formControl.get('locality_rus').reset('');
    formControl.get('city_rus').updateValueAndValidity();
    formControl.get('locality_rus').updateValueAndValidity();
  }

  onNotCitySelected(formControl: AbstractControl) {
    formControl.patchValue({
      city_rus: '',
      city_rus_id: null,
      locality_rus: '',
      locality_rus_id: null,
      street_rus: '',
      street_rus_id: null,
    });
    formControl.get('city_rus').setValue(null, { emitEvent: true });
  }

  onNotLocalitySelected(formControl: AbstractControl) {
    formControl.patchValue({
      locality_rus: '',
      locality_rus_id: null,
      street_rus: '',
      street_rus_id: null,
    });
    formControl.get('locality_rus').setValue(null, { emitEvent: true });
  }

  onNotStreetSelected(formControl: AbstractControl) {
    formControl.patchValue({
      street_rus: '',
      street_rus_id: null,
      zip_code: null,
    });
    formControl.get('street_rus').setValue(null, { emitEvent: true });
  }

  getAdditionalParamsForCity(formControl: AbstractControl) {
    let params = {};
    if (!!formControl.get('region_rus_id')?.value) {
      params['region'] = formControl.get('region_rus_id')?.value;
    }
    if (!!formControl.get('district_rus_id')?.value) {
      params['district'] = formControl.get('district_rus_id')?.value;
    }
    return params;
  }

  getAdditionalParamsForLocality(formControl: AbstractControl) {
    let params = {};
    if (!!formControl.get('region_rus_id')?.value) {
      params['region'] = formControl.get('region_rus_id')?.value;
    }
    if (!!formControl.get('district_rus_id')?.value) {
      params['district'] = formControl.get('district_rus_id')?.value;
    }
    if (!!formControl.get('city_rus_id')?.value) {
      params['city'] = formControl.get('city_rus_id')?.value;
    }
    return params;
  }

  getAdditionalParamsForStreet(formControl: AbstractControl) {
    let params = {};
    if (!!formControl.get('region_rus_id')?.value) {
      params['region'] = formControl.get('region_rus_id')?.value;
    }
    if (!!formControl.get('district_rus_id')?.value) {
      params['district'] = formControl.get('district_rus_id')?.value;
    }
    if (!!formControl.get('city_rus_id')?.value) {
      params['city'] = formControl.get('city_rus_id')?.value;
    }
    if (!!formControl.get('locality_rus_id')?.value) {
      params['locality'] = formControl.get('locality_rus_id')?.value;
    }
    return params;
  }

  fileSelected(event) {
    const file: File = event.target.files[0];
    let formData: FormData = new FormData();
    formData.append('file', file, file.name);
    if (file.size > maxFileSize) {
      this.toaster.showError(this.translation.translate('FILE_SIZE_INCORRECT', [convertByteToMegaBytes(maxFileSize)]));
      return;
    }
    this.file.setValue(file);
    this.loader.showLoader();
    this.profileService.sendPassportScan(formData).subscribe({
      next: (data: AttachmentBase) => {
        this.passportScans.push(data);
        this.updateAttachments(data);
        event.target.value = null;
        this.cd.markForCheck();
        this.loader.hideLoader();
      },
      error: () => {
        this.loader.hideLoader();
      },
    });
  }

  open(url: string) {
    this.dialog.open(AiUiImageDialogComponent, {
      panelClass: 'attachment-container',
      data: {
        url,
      },
    });
  }

  onDelete(id: number) {
    this.loader.showLoader();
    this.profileService.deletePassportScan(id).subscribe({
      next: () => {
        this.toaster.showInfo(this.translation.translate('ATTACHMENT_HAS_BEEN_REMOVED'));
        this.attachments = this.attachments.filter((att) => att.id !== id);
        this.cd.markForCheck();
        this.loader.hideLoader();
      },
      error: () => {
        this.loader.hideLoader();
      },
    });
  }

  private getAttachments(): void {
    this.loader.showLoader();
    this.profileService
      .getPassportScans()
      .pipe(
        mergeMap((res) => {
          this.passportScans = res.results;
          const payload = res.results.map((att) => this.profileService.fileToBlob(this.attachmentUrl(att.id)));
          return forkJoin(payload);
        })
      )
      .subscribe({
        next: (data) => {
          this.attachments = this.passportScans.map((scan, index) => ({
            id: scan.id,
            file_name: scan.file_name,
            url: data[index],
            isImage: ['jpeg', 'jpg', 'png']?.indexOf(scan.file_name?.split('.')?.pop()?.toLowerCase()) !== -1,
          }));
          this.cd.markForCheck();
          this.loader.hideLoader();
        },
        error: () => {
          this.loader.hideLoader();
        },
      });
  }

  private updateAttachments(data: AttachmentBase): void {
    this.loader.showLoader();
    const attachmentUrl =
      this.authService.userProfile$?.value?.status === ProfileStatus.pii_approved
        ? this.attachmentEditUrl(data.id)
        : this.attachmentUrl(data.id);
    this.profileService.fileToBlob(attachmentUrl).subscribe({
      next: (url: string) => {
        this.attachments.push({
          id: data.id,
          file_name: data.file_name,
          url,
          isImage: ['jpeg', 'jpg', 'png']?.indexOf(data.file_name?.split('.')?.pop()?.toLowerCase()) !== -1,
        });
        this.toaster.showSuccess(this.translation.translate('ATTACHMENT_HAS_BEEN_ADDED'));
        this.cd.markForCheck();
        this.loader.hideLoader();
      },
      error: () => {
        this.loader.hideLoader();
      },
    });
  }

  onCitizenshipChange(value: string) {
    this.personalDataForm.patchValue({
      passport: {
        type: value === CountryCode.RU ? PassportType.rf_passport : null,
        id_number: null,
        number: null,
        date_of_issue: null,
        issued_by: null,
        issued_by_code: null,
        birth_place: null,
      },
    });
    this.onPassportTypeChange();
  }

  onPassportTypeChange() {
    const passportForm = this.personalDataForm.get('passport');
    const passportType = passportForm.get('type').value;
    console.log(passportType);
    if (passportType === PassportType.id_card) {
      this.personalDataForm.patchValue({
        passport: {
          issued_by: '',
          // issued_by_code: '',
        },
      });
      passportForm.get('issued_by').clearValidators();
      passportForm.get('biometric_number').setValidators([Validators.required, Validators.maxLength(15)]);
      passportForm.get('biometric_date_of_issue').setValidators(Validators.required);
      passportForm.get('biometric_date_of_expiration').setValidators(Validators.required);
      passportForm.get('id_number').setValidators(Validators.required);
      passportForm.get('date_of_expiration').setValidators(Validators.required);
      passportForm.get('issued_by_code').setValidators(Validators.required);
    } else if (passportType === PassportType.passport) {
      this.personalDataForm.patchValue({
        passport: {
          biometric_number: null,
          biometric_date_of_issue: null,
          biometric_date_of_expiration: null,
          issued_by_code: null,
        },
      });
      passportForm.get('biometric_number').clearValidators();
      passportForm.get('biometric_date_of_issue').clearValidators();
      passportForm.get('biometric_date_of_expiration').clearValidators();
      passportForm.get('issued_by_code').clearValidators();
      passportForm.get('id_number').setValidators(Validators.required);
      passportForm.get('date_of_expiration').setValidators(Validators.required);
      passportForm.get('issued_by').setValidators(Validators.required);
    } else if (passportType === PassportType.rf_passport) {
      this.personalDataForm.patchValue({
        passport: {
          biometric_number: null,
          biometric_date_of_issue: null,
          biometric_date_of_expiration: null,
          id_number: null,
          date_of_expiration: null,
          // issued_by_code: '',
        },
      });
      passportForm.get('biometric_number').clearValidators();
      passportForm.get('biometric_date_of_issue').clearValidators();
      passportForm.get('biometric_date_of_expiration').clearValidators();
      passportForm.get('id_number').clearValidators();
      passportForm.get('date_of_expiration').clearValidators();
      passportForm.get('issued_by').setValidators(Validators.required);
      passportForm.get('issued_by_code').setValidators(Validators.required);
    }
    passportForm.get('id_number').updateValueAndValidity({ emitEvent: false });
    passportForm.get('date_of_expiration').updateValueAndValidity({ emitEvent: false });
    passportForm.get('biometric_number').updateValueAndValidity({ emitEvent: false });
    passportForm.get('biometric_date_of_issue').updateValueAndValidity({ emitEvent: false });
    passportForm.get('biometric_date_of_expiration').updateValueAndValidity({ emitEvent: false });
    passportForm.get('issued_by').updateValueAndValidity({ emitEvent: false });
    passportForm.get('issued_by_code').updateValueAndValidity({ emitEvent: false });
    this.cd.markForCheck();
  }

  onAddressCountryChange(formControl: AbstractControl, value: string) {
    this.resetAddressForm(formControl);
    this.setValidationForAddressForm(formControl, value);
    this.cd.markForCheck();
  }

  onRegistrationAddressToggleChange() {
    const regAddressForm = this.contactDataForm.get('registration_address');
    this.setValidationForAddressForm(regAddressForm, regAddressForm.get('country').value);
  }

  onLivingAddressToggleChange(isInit: boolean = false) {
    const isLivingAddressAsReg = this.contactDataForm.get('is_living_address_as_reg').value;
    const livingAddressForm = this.contactDataForm.get('living_address');
    if (isLivingAddressAsReg) {
      this.resetValidationForAddressForm(livingAddressForm);
      this.resetAddressForm(livingAddressForm);
    } else {
      if (isInit) {
        this.setValidationForAddressForm(livingAddressForm, livingAddressForm.get('country').value);
        return;
      }
      this.onAddressCountryChange(livingAddressForm, livingAddressForm.get('country').value);
    }
  }

  onPostalAddressToggleChange(isInit: boolean = false) {
    const postalAddressForm = this.contactDataForm.get('postal_address');
    const isPostalAddressAsLiving = this.contactDataForm.get('is_postal_address_as_living').value;
    if (isPostalAddressAsLiving) {
      this.resetValidationForAddressForm(postalAddressForm);
      this.resetAddressForm(postalAddressForm);
    } else {
      if (isInit) {
        this.setValidationForAddressForm(postalAddressForm, postalAddressForm.get('country').value);
        return;
      }
      this.onAddressCountryChange(postalAddressForm, postalAddressForm.get('country').value);
    }
  }

  resetAddressForm(formControl: AbstractControl) {
    formControl.patchValue({
      region: null,
      region_en: null,
      locality: null,
      locality_en: null,
      locality_type_id: null,
      street: null,
      street_type_id: null,
      street_en: null,
      region_rus_id: null,
      region_rus: null,
      district_rus_id: null,
      district_rus: null,
      district_en: null,
      city_rus_id: null,
      city_rus: null,
      city_en: null,
      locality_rus_id: null,
      locality_rus: null,
      street_rus_id: null,
      street_rus: null,
      house_number: null,
      house_unit: null,
      apartment_number: null,
      zip_code: null,
    });
    this.cd.markForCheck();
  }

  resetValidationForAddressForm(addressFormControl: AbstractControl) {
    addressFormControl.get('region_rus_id').clearValidators();
    addressFormControl.get('region_rus').clearValidators();
    addressFormControl.get('street_rus_id').clearValidators();
    addressFormControl.get('street_rus').clearValidators();
    addressFormControl.get('region').clearValidators();
    addressFormControl.get('locality').clearValidators();
    addressFormControl.get('locality_type_id').clearValidators();
    addressFormControl.get('street').clearValidators();
    addressFormControl.get('street_type_id').clearValidators();

    addressFormControl.get('region_rus_id').updateValueAndValidity({ emitEvent: false });
    addressFormControl.get('region_rus').updateValueAndValidity({ emitEvent: false });
    addressFormControl.get('street_rus_id').updateValueAndValidity({ emitEvent: false });
    addressFormControl.get('street_rus').updateValueAndValidity({ emitEvent: false });
    addressFormControl.get('region').updateValueAndValidity({ emitEvent: false });
    addressFormControl.get('locality').updateValueAndValidity({ emitEvent: false });
    addressFormControl.get('locality_type_id').updateValueAndValidity({ emitEvent: false });
    addressFormControl.get('street').updateValueAndValidity({ emitEvent: false });
    addressFormControl.get('street_type_id').updateValueAndValidity({ emitEvent: false });
    this.cd.markForCheck();
  }

  setValidationForAddressForm(addressFormControl: AbstractControl, countryCode: string) {
    if (countryCode === CountryCode.BY) {
      addressFormControl.get('region').setValidators(Validators.required);
      addressFormControl.get('locality').setValidators(Validators.required);
      addressFormControl.get('locality_type_id').setValidators(Validators.required);
      addressFormControl.get('street').setValidators(Validators.required);
      addressFormControl.get('street_type_id').setValidators(Validators.required);

      addressFormControl.get('region_rus_id').clearValidators();
      addressFormControl.get('region_rus').clearValidators();
      addressFormControl.get('street_rus_id').clearValidators();
      addressFormControl.get('street_rus').clearValidators();
    } else if (countryCode === CountryCode.RU) {
      addressFormControl.get('region_rus_id').setValidators(Validators.required);
      addressFormControl.get('region_rus').setValidators(Validators.required);
      addressFormControl.get('street_rus_id').setValidators(Validators.required);
      addressFormControl.get('street_rus').setValidators(Validators.required);

      addressFormControl.get('region').clearValidators();
      addressFormControl.get('locality').clearValidators();
      addressFormControl.get('locality_type_id').clearValidators();
      addressFormControl.get('street').clearValidators();
      addressFormControl.get('street_type_id').clearValidators();
    } else {
      // empty
    }
    addressFormControl.get('region').updateValueAndValidity({ emitEvent: false });
    addressFormControl.get('locality').updateValueAndValidity({ emitEvent: false });
    addressFormControl.get('locality_type_id').updateValueAndValidity({ emitEvent: false });
    addressFormControl.get('street_type_id').updateValueAndValidity({ emitEvent: false });
    addressFormControl.get('street').updateValueAndValidity({ emitEvent: false });
    addressFormControl.get('region_rus_id').updateValueAndValidity({ emitEvent: false });
    addressFormControl.get('region_rus').updateValueAndValidity({ emitEvent: false });
    addressFormControl.get('street_rus_id').updateValueAndValidity({ emitEvent: false });
    addressFormControl.get('street_rus').updateValueAndValidity({ emitEvent: false });
    this.cd.markForCheck();
  }

  next() {
    if (this.profileService.profileCurrentStepKey === 0) {
      this.saveTheFirstStep();
    } else if (this.profileService.profileCurrentStepKey === 1) {
      this.saveTheSecondStep();
    } else if (this.profileService.profileCurrentStepKey === 2) {
      this.saveTheThirdStep();
    }
  }

  submit() {
    if (!this.isTheFirstStepSaved) {
      this.saveTheFirstStep();
    }
    if (!this.isTheSecondStepSaved) {
      this.saveTheSecondStep();
    }
    this.saveTheThirdStep();
    this.clearForm();
    // TODO update user profile
  }

  saveTheFirstStep() {
    if (!this.authService.isAllowedToChangeData$.value.result) {
      return;
    }
    let payload = this.personalDataForm.value as PassportTaxInfo;
    if (payload.birth_date.toString()?.split('-')?.length <= 1) {
      payload.birth_date = getFormatDate(payload.birth_date);
    }
    if (payload.passport.date_of_issue.toString()?.split('-')?.length <= 1) {
      payload.passport.date_of_issue = getFormatDate(payload.passport.date_of_issue);
    }
    if (
      !!payload?.passport?.date_of_expiration &&
      payload.passport.date_of_expiration.toString()?.split('-')?.length <= 1
    ) {
      payload.passport.date_of_expiration = getFormatDate(payload.passport.date_of_expiration);
    }
    if (
      !!payload?.passport?.biometric_date_of_issue &&
      payload.passport.biometric_date_of_issue.toString()?.split('-')?.length <= 1
    ) {
      payload.passport.biometric_date_of_issue = getFormatDate(payload.passport.biometric_date_of_issue);
    }
    if (
      !!payload?.passport?.biometric_date_of_expiration &&
      payload.passport.biometric_date_of_expiration.toString()?.split('-')?.length <= 1
    ) {
      payload.passport.biometric_date_of_expiration = getFormatDate(payload.passport.biometric_date_of_expiration);
    }
    if (this.authService.userProfile$?.value?.status === ProfileStatus.pii_approved) {
      payload = this.getObjectDiff(payload, this.oldPersonalData) as PassportTaxInfo;
    }
    this.loader.showLoader();
    this.profileService.sendPassportTaxInfo(payload).subscribe({
      complete: () => {
        this.profileService.goToStep(this.profileService.profileCurrentStepKey + 1);
        this.isTheFirstStepSaved = true;
        this.cd.markForCheck();
        this.loader.hideLoader();
      },
      error: () => {
        this.loader.hideLoader();
      },
    });
  }

  saveTheSecondStep() {
    const isLivingAddressAsReg = this.contactDataForm.get('is_living_address_as_reg').value;
    if (isLivingAddressAsReg) {
      this.contactDataForm.patchValue({
        living_address: this.contactDataForm.get('registration_address').value,
      });
    } else {
      this.contactDataForm.patchValue({
        living_address: this.contactDataForm.get('living_address').value,
      });
    }
    const isPostalAddressAsLiving = this.contactDataForm.get('is_postal_address_as_living').value;
    if (isPostalAddressAsLiving) {
      this.contactDataForm.patchValue({
        postal_address: this.contactDataForm.get('registration_address').value,
      });
    } else {
      this.contactDataForm.patchValue({
        postal_address: this.contactDataForm.get('postal_address').value,
      });
    }
    let payload = this.contactDataForm.value as AddressInfo;
    if (this.authService.userProfile$?.value?.status === ProfileStatus.pii_approved) {
      if (!payload.is_postal_address_as_living && this.oldContactData.is_postal_address_as_living) {
        this.oldContactData.postal_address = this.initAddress();
      } else if (payload.is_postal_address_as_living && !this.oldContactData.is_postal_address_as_living) {
        payload.postal_address = null;
      }

      if (!payload.is_living_address_as_reg && this.oldContactData.is_living_address_as_reg) {
        this.oldContactData.living_address = this.initAddress();
      } else if (payload.is_living_address_as_reg && !this.oldContactData.is_living_address_as_reg) {
        payload.living_address = null;
      }

      payload = this.getObjectDiff(payload, this.oldContactData) as AddressInfo;
    }
    this.loader.showLoader();
    this.profileService.sendAddress(payload).subscribe({
      complete: () => {
        this.profileService.goToStep(this.profileService.profileCurrentStepKey + 1);
        this.isTheSecondStepSaved = true;
        this.loader.hideLoader();
      },
      error: () => {
        this.loader.hideLoader();
      },
    });
  }

  saveTheThirdStep() {
    let payload = this.extraDataForm.value as TaxCompliance;
    payload.foreign_person_authorized_to_manage_account_countries_list =
      this.extraDataForm.value.foreign_person_authorized_to_manage_account_countries_list.map((n) => n.name);
    payload.has_order_to_transfer_funds_to_foreign_countries_list =
      this.extraDataForm.value.has_order_to_transfer_funds_to_foreign_countries_list.map((n) => n.name);
    payload.has_address_in_foreign_countries_list = this.extraDataForm.value.has_address_in_foreign_countries_list.map(
      (n) => n.name
    );
    payload.has_residence_permit_in_countries_list =
      this.extraDataForm.value.has_residence_permit_in_countries_list.map((n) => n.name);

    const userStatus = this.authService.userProfile$?.value?.status;
    if (userStatus === ProfileStatus.pii_approved) {
      payload = this.getObjectDiff(payload, this.oldExtraData) as TaxCompliance;
    }
    this.loader.showLoader();
    this.profileService.sendTaxCompliance(payload).subscribe({
      complete: () => {
        if (userStatus === ProfileStatus.pii_approved) {
          this.router.navigate(['claims', 'edit_profile']);
        } else {
          this.authService.getUserProfile();
          this.personalDataForm.reset();
          this.contactDataForm.reset();
          this.extraDataForm.reset();
          this.profileService.profileCurrentStep = 0;
          this.toaster.showInfo(this.translation.translate('PROFILE_DATA_HAS_BEEN_CHANGED'));
          this.router.navigate(['profile', 'info']);
        }
        this.loader.hideLoader();
      },
      error: () => {
        this.loader.hideLoader();
      },
    });
  }

  onChangeResidentStatus(event: MatSelectChange) {
    this.updateTaxCountryNameValidators(event.value === TaxResidentStatus.tax_resident_of_another_country);
    this.extraDataForm.patchValue({
      tax_resident_country_name: '',
    });
  }

  updateTaxCountryNameValidators(value: boolean) {
    if (value) {
      this.extraDataForm.controls.tax_resident_country_name.setValidators([Validators.required]);
      this.extraDataForm.controls.tax_resident_country_name.updateValueAndValidity({ emitEvent: false });
    } else {
      this.extraDataForm.controls.tax_resident_country_name.clearValidators();
      this.extraDataForm.controls.tax_resident_country_name.updateValueAndValidity({ emitEvent: false });
    }
  }

  onRadioButtonChange(checked: boolean, fieldName: string, max_length: number) {
    if (!checked) {
      this.extraDataForm.get(fieldName).patchValue('');
      this.extraDataForm.get(fieldName).clearValidators();
    } else {
      this.extraDataForm.get(fieldName).setValidators([Validators.required, Validators.maxLength(max_length)]);
    }
    this.extraDataForm.get(fieldName).updateValueAndValidity({ emitEvent: false });
    this.cd.markForCheck();
  }

  onChangeUsaTaxResident(checked: boolean) {
    if (!checked) {
      this.extraDataForm.get('usa_tax_number').patchValue('');
      this.extraDataForm.get('usa_tax_number').clearValidators();
    } else {
      this.extraDataForm.get('usa_tax_number').setValidators([Validators.required, Validators.maxLength(30)]);
    }
    this.extraDataForm.get('usa_tax_number').updateValueAndValidity({ emitEvent: false });
    this.cd.markForCheck();
    this.cd.detectChanges();
  }

  onChangeHasForeignTaxNumber(checked: boolean) {
    console.log(checked);
    if (!checked) {
      this.extraDataForm.get('foreign_tax_number').patchValue('');
      this.extraDataForm.get('foreign_tax_number').clearValidators();

      this.extraDataForm.get('has_no_foreign_tax_number_reason').setValidators(Validators.required);
      this.extraDataForm
        .get('individual_cannot_obtain_tin_due_to_other_reasons')
        .setValidators([Validators.required, Validators.maxLength(255)]);
    } else {
      this.extraDataForm.get('has_no_foreign_tax_number_reason').patchValue('');
      this.extraDataForm.get('has_no_foreign_tax_number_reason').clearValidators();
      this.extraDataForm.get('individual_cannot_obtain_tin_due_to_other_reasons').patchValue('');
      this.extraDataForm.get('individual_cannot_obtain_tin_due_to_other_reasons').clearValidators();

      this.extraDataForm.get('foreign_tax_number').setValidators(Validators.required);

      // this.extraDataForm.get('usa_tax_number').setValidators([Validators.required, Validators.maxLength(30)]);
    }
    this.extraDataForm.get('foreign_tax_number').updateValueAndValidity({ emitEvent: false });
    this.extraDataForm.get('has_no_foreign_tax_number_reason').updateValueAndValidity({ emitEvent: false });
    this.extraDataForm
      .get('individual_cannot_obtain_tin_due_to_other_reasons')
      .updateValueAndValidity({ emitEvent: false });
    this.cd.markForCheck();
  }

  onChangeHasNoForeignTaxNumberReason(value: string) {
    console.log(value);
    if (value === HasNoForeignTaxNumberReason.individual_cannot_obtain_tin_due_to_other_reasons) {
      this.extraDataForm
        .get('individual_cannot_obtain_tin_due_to_other_reasons')
        .setValidators([Validators.required, Validators.maxLength(255)]);
    } else {
      this.extraDataForm.get('individual_cannot_obtain_tin_due_to_other_reasons').patchValue('');
      this.extraDataForm.get('individual_cannot_obtain_tin_due_to_other_reasons').clearValidators();
    }
    this.extraDataForm
      .get('individual_cannot_obtain_tin_due_to_other_reasons')
      .updateValueAndValidity({ emitEvent: false });
    this.cd.markForCheck();
  }

  onChangeForeignPerson(event: MatRadioChange) {
    if (event.value !== 'other_country_person') {
      const formArray = this.extraDataForm.controls
        .foreign_person_authorized_to_manage_account_countries_list as FormArray;
      formArray.clear();
    } else {
      this.addCountry();
    }
  }

  addCountry(items?: string[]) {
    const formArray = this.extraDataForm.controls
      .foreign_person_authorized_to_manage_account_countries_list as FormArray;
    if (items?.length > 0) {
      formArray.clear();
      items.forEach((n) => {
        formArray.push(
          this.fb.group({
            name: new FormControl(n, Validators.required),
          })
        );
      });
    } else {
      formArray.push(
        this.fb.group({
          name: new FormControl(null, Validators.required),
        })
      );
    }
  }

  removeCountry(index: number) {
    (this.extraDataForm.controls.foreign_person_authorized_to_manage_account_countries_list as FormArray).removeAt(
      index
    );
  }

  onChangeOrderToTransferFunds(event: MatRadioChange) {
    if (event.value !== 'other_country') {
      const formArray = this.extraDataForm.controls.has_order_to_transfer_funds_to_foreign_countries_list as FormArray;
      formArray.clear();
    } else {
      this.addOrderToTransferFunds();
    }
  }

  addOrderToTransferFunds(items?: string[]) {
    const formArray = this.extraDataForm.controls.has_order_to_transfer_funds_to_foreign_countries_list as FormArray;
    if (items?.length > 0) {
      formArray.clear();
      items.forEach((n) => {
        formArray.push(
          this.fb.group({
            name: new FormControl(n, Validators.required),
          })
        );
      });
    } else {
      formArray.push(
        this.fb.group({
          name: new FormControl(null, Validators.required),
        })
      );
    }
  }

  removeOrderToTransferFunds(index: number) {
    (this.extraDataForm.controls.has_order_to_transfer_funds_to_foreign_countries_list as FormArray).removeAt(index);
  }

  onChangeAddressInForeignCountry(event: MatRadioChange) {
    if (event.value !== 'other_country') {
      const formArray = this.extraDataForm.controls.has_address_in_foreign_countries_list as FormArray;
      formArray.clear();
    } else {
      this.addAddressInForeignCountry();
    }
  }

  addAddressInForeignCountry(items?: string[]) {
    const formArray = this.extraDataForm.controls.has_address_in_foreign_countries_list as FormArray;
    if (items?.length > 0) {
      formArray.clear();
      items.forEach((n) => {
        formArray.push(
          this.fb.group({
            name: new FormControl(n, Validators.required),
          })
        );
      });
    } else {
      formArray.push(
        this.fb.group({
          name: new FormControl(null, [Validators.required, Validators.maxLength(255)]),
        })
      );
    }
  }

  removeAddressInForeignCountry(index: number) {
    (this.extraDataForm.controls.has_address_in_foreign_countries_list as FormArray).removeAt(index);
  }

  onChangeResidencePermit(event: MatRadioChange) {
    if (!event.value) {
      const formArray = this.extraDataForm.controls.has_residence_permit_in_countries_list as FormArray;
      formArray.clear();
    } else {
      this.addResidencePermit();
    }
  }

  addResidencePermit(items?: string[]) {
    const formArray = this.extraDataForm.controls.has_residence_permit_in_countries_list as FormArray;
    if (items?.length > 0) {
      formArray.clear();
      items.forEach((n) => {
        formArray.push(
          this.fb.group({
            name: new FormControl(n, Validators.required),
          })
        );
      });
    } else {
      formArray.push(
        this.fb.group({
          name: new FormControl(null, Validators.required),
        })
      );
    }
  }

  removeResidencePermit(index: number) {
    (this.extraDataForm.controls.has_residence_permit_in_countries_list as FormArray).removeAt(index);
  }

  getObjectDiff(obj1, obj2) {
    let diff = {};
    // debugger;
    Object.entries(obj1).map(([obj_key, obj_value]) => {
      if (typeof obj_value === 'object') {
        if (Array.isArray(obj_value)) {
          const diff_list = (obj_value as [])
            .sort((a, b) => a - b)
            .filter((val) => !(obj2[obj_key] as []).sort((a, b) => a - b).includes(val));
          if (diff_list.length > 0) {
            Object.assign(diff, {
              [obj_key]: (obj_value as [])
                .sort((a, b) => a - b)
                .filter((val) => !(obj2[obj_key] as []).sort((a, b) => a - b).includes(val)),
            });
          }
        } else {
          try {
            Object.assign(diff, {
              [obj_key]: this.getObjectDiff(obj_value, obj2[obj_key]),
            });
          } catch (exc) {}
        }
      } else if (obj_value !== obj2[obj_key]) {
        Object.assign(diff, {
          [obj_key]: obj_value,
        });
      }
    });
    return diff;
  }

  initAddress() {
    const obj: Address = {
      country: null,
      region: null,
      region_en: null,
      locality_type_id: null,
      locality: null,
      locality_en: null,
      street_type_id: null,
      street: null,
      street_en: null,
      region_rus: null,
      district_rus: null,
      district_en: null,
      city_rus: null,
      city_en: null,
      locality_rus: null,
      street_rus: null,
      house_number: null,
      house_unit: null,
      apartment_number: null,
      zip_code: null,
    };
    return obj;
  }

  clearForm() {
    this.profileService.goToStep(0);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.isAllowedCheckobserver.unsubscribe();
    this.clearForm();
  }
}
