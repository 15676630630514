<div class="container-fluid bg-white p-4"
  [@open]="'opened'">
  <div class="title">
    <h1>{{ 'CLAIM:FILLING' | translate }}</h1>
    <div class="hint">
      <p>{{ 'CLAIM_TYPE:CASH_WITHDRAWAL' | translate }}</p>
    </div>
  </div>
  <div class="row">
    <ui-step-view class="col-12 mt-3"
      [total]="2"
      [current]="cfService.stepKey + 1"
      [stepValues]="['CLAIM_STEP:CREATION', 'CLAIM_STEP:SIGN']">
    </ui-step-view>
    <div class="order-container">
      <div class="form-data col-12">
        <ng-container *ngIf="cfService.stepKey===0">
          <div class="order-title">
            <p>{{ 'WITHDRAWAL:AREA_HINT' | translate }}</p>
          </div>
          <form [formGroup]="form">
            <ng-container formGroupName="msk_stock_market">
              <div class="chapter-title">
                <h4>{{ 'AREA:MSK_STOCK_MARKET' | translate }}</h4>
              </div>
              <div class="chapter-form">
                <div class="row">
                  <div class="col">
                    <mat-form-field appearance="outline">
                      <mat-label>{{ 'BANK:CURRENCY_CODE' | translate }}</mat-label>
                      <mat-select formControlName="currency">
                        <mat-option *ngFor="let code of currencies"
                          [value]="code">
                          {{ code }}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="form.get('msk_stock_market').get('currency').errors?.required">
                        {{'ERROR:REQUIRED' | translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col">
                    <mat-form-field appearance="outline">
                      <mat-label>{{ 'FIELD:AMOUNT' | translate }}</mat-label>
                      <input amount
                        [decimalPlace]="2"
                        matInput
                        type="text"
                        formControlName="amount">
                    </mat-form-field>
                  </div>
                </div>
                <mat-checkbox (change)="onCheckboxChanged($event, 'msk_stock_market')"
                  class="mb-4"
                  color="primary"
                  formControlName="withdraw_all_money">{{
                    'WITHDRAWAL:IN_THE_AMOUNT_OF_FREE_BALANCE' | translate }}</mat-checkbox>
              </div>
            </ng-container>
            <ng-container formGroupName="msk_currency_market">
              <div class="chapter-title">
                <h4>{{ 'AREA:MSK_CURRENCY_MARKET' | translate }}</h4>
              </div>
              <div class="chapter-form">
                <div class="row">
                  <div class="col">
                    <mat-form-field appearance="outline">
                      <mat-label>{{ 'BANK:CURRENCY_CODE' | translate }}</mat-label>
                      <mat-select formControlName="currency">
                        <mat-option *ngFor="let code of currencies"
                          [value]="code">
                          {{ code }}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="form.get('msk_currency_market').get('currency').errors?.required">
                        {{'ERROR:REQUIRED' | translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col">
                    <mat-form-field appearance="outline">
                      <mat-label>{{ 'FIELD:AMOUNT' | translate }}</mat-label>
                      <input amount
                        [decimalPlace]="2"
                        matInput
                        type="text"
                        formControlName="amount">
                    </mat-form-field>
                  </div>
                </div>
                <mat-checkbox (change)="onCheckboxChanged($event, 'msk_currency_market')"
                  class="mb-4"
                  color="primary"
                  formControlName="withdraw_all_money">{{
                    'WITHDRAWAL:IN_THE_AMOUNT_OF_FREE_BALANCE' | translate }}</mat-checkbox>
              </div>
            </ng-container>
            <ng-container formGroupName="foreign_market">
              <div class="chapter-title">
                <h4>{{ 'AREA:FOREIGN_MARKET' | translate }}</h4>
              </div>
              <div class="chapter-form">
                <div class="row">
                  <div class="col">
                    <mat-form-field appearance="outline">
                      <mat-label>{{ 'BANK:CURRENCY_CODE' | translate }}</mat-label>
                      <mat-select formControlName="currency">
                        <mat-option *ngFor="let code of currencies"
                          [value]="code">
                          {{ code }}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="form.get('foreign_market').get('currency').errors?.required">
                        {{'ERROR:REQUIRED' | translate }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col">
                    <mat-form-field appearance="outline">
                      <mat-label>{{ 'FIELD:AMOUNT' | translate }}</mat-label>
                      <input amount
                        [decimalPlace]="2"
                        matInput
                        type="text"
                        formControlName="amount">
                    </mat-form-field>
                  </div>
                  <mat-form-field appearance="outline">
                    <mat-label>{{ 'WITHDRAWAL:AREA_NAME' | translate }}</mat-label>
                    <input matInput
                      formControlName="platform_name">
                  </mat-form-field>
                </div>
                <mat-checkbox (change)="onCheckboxChanged($event, 'foreign_market')"
                  class="mb-4"
                  color="primary"
                  formControlName="withdraw_all_money">{{
                    'WITHDRAWAL:IN_THE_AMOUNT_OF_FREE_BALANCE' | translate }}</mat-checkbox>
              </div>
            </ng-container>
            <div class="mt-4">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'WITHDRAWAL:ADDITIONAL_TERMS' | translate }}</mat-label>
                <input matInput
                  formControlName="additional_terms">
              </mat-form-field>
            </div>
            <div class="bank-container">
              <div class="bank-info-title">
                <h3>{{ 'BANK_INFO:TITLE' | translate }}</h3>
                <div class="hint">
                  <p>{{ 'BANK_INFO:WITHDRAWAL_HINT' | translate }}</p>
                </div>
              </div>
              <div class="add-action">
                <button mat-button
                  type="button"
                  (click)="onAddBankAccount()">
                  <mat-icon>add</mat-icon>
                  {{ 'ADD_BANK_ACCOUNT' | translate }}
                </button>
              </div>
            </div>
            <mat-radio-group formControlName="bank_account">
              <ng-container *ngFor="let bankAccount of bankAccounts">
                <div class="edit-action text-align-right">
                  <button mat-button
                    type="button"
                    (click)="onEditBankAccount(bankAccount.id)">
                    <mat-icon>edit</mat-icon>
                    {{ 'CHANGE' | translate }}
                  </button>
                </div>
                <div class="bank-card">
                  <mat-radio-button class="bank-card-radio-button"
                    color="primary"
                    [value]="bankAccount.id">
                  </mat-radio-button>
                  <ai-ui-bank-account-card [bankAccount]="bankAccount"></ai-ui-bank-account-card>
                </div>
              </ng-container>
            </mat-radio-group>
          </form>
          <div class="actions">
            <div class="d-flex justify-content-end mb-3">
              <button [disabled]="form.invalid"
                class="filled"
                mat-flat-button
                (click)="next()">
                {{ 'NEXT' | translate }}
              </button>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="cfService.stepKey===1">
          <div class="col-12">
            <ai-ui-documents [attachments]="attachmentsToSign"
              [isCreatingOrder]="true"></ai-ui-documents>
          </div>
          <div class="col-10 confirmation">
            <h4>{{ 'CLAIM_SIGNATURE_TITLE' | translate }}</h4>
            <div class="hint">
              <p>{{ 'CLAIM_SIGNATURE_INFORMATION' | translate }}
              </p>
            </div>
          </div>
          <div class="actions">
            <button class="back"
              (click)="back()"
              mat-button
              type="button">
              {{ 'BACK' | translate }}
            </button>
            <button mat-button
              class="filled"
              type="button"
              (click)="sendToSign()">
              {{ 'SIGN' | translate }}
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>