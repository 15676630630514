import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TranslationModule } from '@localization';
import { MaterialModule } from 'src/app/material.module';
import { SharedModule } from 'src/app/shared/shared-components/shared.module';
import { SharedPipesModule } from 'src/app/shared/shared-pipes/shared-pipes.module';
import { BrokingComponent } from './broking/broking.component';
import { DepositoryComponent } from './depository/depository.component';
import { PortfolioComponent } from './portfolio.component';

const routes: Routes = [
  {
    path: '',
    component: PortfolioComponent,
  },
  {
    path: 'broking',
    component: BrokingComponent,
  },
  {
    path: 'depository',
    component: DepositoryComponent,
  },
];

const PortfolioRouterModule: ModuleWithProviders<RouterModule> = RouterModule.forChild(routes);

@NgModule({
  declarations: [PortfolioComponent, BrokingComponent, DepositoryComponent],
  imports: [CommonModule, MaterialModule, TranslationModule, SharedModule, SharedPipesModule, PortfolioRouterModule],
})
export class PortfolioModule {}
