import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[time]',
})
export class TimeDirective {
  private _allowedKeys: string[] = [
    'Backspace',
    'Tab',
    'Enter',
    'Escape',
    'ArrowLeft',
    'ArrowRight',
    'Delete',
    'PrintScreen',
  ];

  pattern: RegExp = new RegExp('^[0-9]+$');

  element: HTMLInputElement;

  constructor(element: ElementRef) {
    this.element = element.nativeElement;
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: any) {
    if (this._allowedKeys.includes(event.key)) {
      return true;
    }
    ''.toString();
    const value = event.target.value.replace(':', '');
    if (
      !this.pattern.test(event.key) ||
      (value.length === 0 && event.key > 2) ||
      (value.length === 1 && value + event.key > 23) ||
      (value.length === 2 && event.key > 5) ||
      (value.length === 3 && event.target.value + event.key > 59) ||
      event.target.value.length + event.key.length > 5
    ) {
      return false;
    }
    if (event.target.value.length === 2 && event.key !== ':') {
      event.target.value = event.target.value + ':';
    }
    return true;
  }

  @HostListener('paste', ['$event'])
  onPaste(event: any) {
    return false;
  }
}
