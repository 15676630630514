import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslationService } from '@localization';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { ConfirmOperation, CountryCode } from 'src/app/utils/utils';
import { ToasterService } from '../../shared-services/toaster/toaster.service';
import { AiUiConfirmDialogComponent } from '../ai-ui-confirm-dialog/ai-ui-confirm-dialog.component';

@Component({
  selector: 'app-ai-ui-change-phone',
  templateUrl: './ai-ui-change-phone.component.html',
  styleUrls: ['./ai-ui-change-phone.component.scss'],
})
export class AiUiChangePhoneComponent implements OnInit {
  form: FormGroup;

  countries = Array.from(new Set(Object.keys(CountryCode)));

  @ViewChild('phone') phone;

  constructor(
    private authService: AuthService,
    private profileService: ProfileService,
    private translation: TranslationService,
    private toaster: ToasterService,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private loader: LoaderService,
    private dialogRef: MatDialogRef<AiUiChangePhoneComponent>
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.cd.markForCheck();
  }

  initForm() {
    this.form = new FormGroup({
      phone: new FormControl(null, Validators.required),
    });
  }

  onChangePhoneCountry(event: any) {
    if (!this.countries.includes(event.flagClass)) {
      this.phone.selectedCountry = {
        dialCode: '7',
        flagClass: 'RU',
        name: 'RU',
        iso2: 'ru',
        placeHolder: '+79123456789',
      };
    }
  }

  sendCode() {
    if (this.form.invalid) {
      return;
    }
    this.dialogRef.close();
    const phone = this.form.value.phone;
    this.loader.showLoader();
    this.profileService.changePhone(phone).subscribe({
      next: (data: any) => {
        this.dialog
          .open(AiUiConfirmDialogComponent, {
            data: {
              attempt_id: data.attempt_id,
              operation: ConfirmOperation.phone,
              callback: this.profileService.changePhoneConfirm.bind(this.profileService),
            },
          })
          .afterClosed()
          .subscribe({
            next: (res: boolean) => {
              if (res) {
                this.authService.getUserProfile();
                this.toaster.showSuccess(this.translation.translate('PHONE_HAS_BEEN_CHANGED'));
              }
            },
          });
        this.loader.hideLoader();
      },
      error: () => {
        this.form.enable();
        this.loader.hideLoader();
      },
    });
  }
}
