import { Component, Input } from '@angular/core';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'ui-step-view',
  templateUrl: './ui-step-view.component.html',
  styleUrls: ['./ui-step-view.component.scss'],
})
export class UiStepViewComponent {
  @Input() set total(value: number) {
    if (value && value > 0) {
      this.totalSteps = Array.from({ length: value }, (_, index) => index + 1);
    }
  }

  @Input() set current(value: number) {
    this.currentStep = value || 1;
  }

  @Input() set hideLegend(value: BooleanInput) {
    this._hideLegend = coerceBooleanProperty(value);
  }

  @Input() stepValues: string[] = null;

  get hideLegend(): boolean {
    return this._hideLegend;
  }

  totalSteps = [];
  currentStep: number = 1;
  private _hideLegend;
}
