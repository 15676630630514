<div class="container">
  <form [formGroup]="form" (ngSubmit)="sendCode()">
    <h3>{{ 'CHANGING_EMAIL' | translate }}</h3>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'FIELD:NEW_EMAIL' | translate }}</mat-label>
      <div class="d-flex">
        <input formControlName="email" matInput>
      </div>
      <mat-error *ngIf="form.get('email').errors?.required">
        {{'ERROR:REQUIRED' | translate }}
      </mat-error>
      <mat-error *ngIf="form.get('email').errors?.pattern">
        {{'ERROR:EMAIL_INCORRECT' | translate }}
      </mat-error>
    </mat-form-field>
    <div class="d-flex justify-content-center mb-3">
      <button [disabled]="form.invalid" class="signin-btn" mat-flat-button type="submit">
        {{ 'NEXT' | translate }}
      </button>
    </div>
  </form>
</div>