import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslationService } from '@localization';
import { Subscription } from 'rxjs';
import { BankAccountResponse } from 'src/app/interfaces/api/bank.interface';
import { NotificationSettings } from 'src/app/interfaces/api/notification';
import { ProfileResponse } from 'src/app/interfaces/api/profile.interface';
import { AuthService } from 'src/app/services/auth/auth.service';
import { BankService } from 'src/app/services/bank/bank.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { NotificationService } from 'src/app/services/notifications/notifications.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { AiUiAddBankAccountComponent } from 'src/app/shared/shared-components/ai-ui-add-bank-account/ai-ui-add-bank-account.component';
import { AiUiCautionComponent } from 'src/app/shared/shared-components/ai-ui-caution/ai-ui-caution.component';
import { AiUiChangeEmailComponent } from 'src/app/shared/shared-components/ai-ui-change-email/ai-ui-change-email.component';
import { AiUiChangeLoginComponent } from 'src/app/shared/shared-components/ai-ui-change-login/ai-ui-change-login.component';
import { AiUiChangePasswordComponent } from 'src/app/shared/shared-components/ai-ui-change-password/ai-ui-change-password.component';
import { AiUiChangePhoneComponent } from 'src/app/shared/shared-components/ai-ui-change-phone/ai-ui-change-phone.component';
import { AiUiConfirmDialogComponent } from 'src/app/shared/shared-components/ai-ui-confirm-dialog/ai-ui-confirm-dialog.component';
import { AiUiEditBankAccountComponent } from 'src/app/shared/shared-components/ai-ui-edit-bank-account/ai-ui-edit-bank-account.component';
import { ToasterService } from 'src/app/shared/shared-services/toaster/toaster.service';
import { animationOptions } from 'src/app/utils/animation';
import { ConfirmOperation } from 'src/app/utils/utils';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  animations: animationOptions,
})
export class SettingsComponent implements OnInit, OnDestroy {
  step: number = 0;

  observer: Subscription = new Subscription();

  mailingSettingsForm: FormGroup;

  contactDataForm: FormGroup;

  bankAccounts: BankAccountResponse[] = [];

  @ViewChild('phone') phone;

  preferredCountry: string;

  constructor(
    private dialog: MatDialog,
    public auth: AuthService,
    private loader: LoaderService,
    private profileService: ProfileService,
    private notification: NotificationService,
    private toaster: ToasterService,
    private translation: TranslationService,
    private bankService: BankService,
    private cd: ChangeDetectorRef,
    private router: Router
  ) {
    const stepState = this.router.getCurrentNavigation()?.extras?.state?.step;
    if (stepState) {
      this.step = stepState;
    }
  }

  ngOnInit(): void {
    this.mailingSettingsForm = this.initMailingSettingsForm();
    this.getSettings();
    this.getBankAccounts();
    this.contactDataForm = this.initContactDataForm();
    this.observer = this.auth.userProfile$.subscribe((data: ProfileResponse) => {
      if (data) {
        this.preferredCountry = new RegExp('^[+]375|375').test(data.phone) ? 'by' : 'ru';
        this.getSelectedCountry(data.phone);
        this.contactDataForm.patchValue({
          phone: '+' + data.phone,
          email: data.email,
        });
        this.cd.markForCheck();
      }
    });
  }

  initMailingSettingsForm() {
    return new FormGroup({
      main: new FormControl(true),
      claim: new FormControl(true),
      news: new FormControl(true),
    });
  }

  initContactDataForm() {
    return new FormGroup({
      phone: new FormControl({ value: '', disabled: true }, Validators.required),
      email: new FormControl({ value: '', disabled: true }, Validators.required),
    });
  }

  onLoginChange() {
    this.dialog.open(AiUiChangeLoginComponent, {
      panelClass: 'popup-container',
    });
  }

  onPasswordChange() {
    this.dialog.open(AiUiChangePasswordComponent, {
      panelClass: 'popup-container',
    });
  }

  onKeywordChange() {
    this.router.navigate(['claims', 'keyword_new']);
  }

  bindAccount() {
    this.profileService.aigenisInvestBinding();
  }

  onPhoneChange() {
    this.dialog.open(AiUiChangePhoneComponent, {
      panelClass: 'popup-container',
    });
  }

  onEmailChange() {
    this.dialog.open(AiUiChangeEmailComponent, {
      panelClass: 'popup-container',
    });
  }

  onEmailConfirm() {
    this.loader.showLoader();
    this.profileService.emailConfirmationRequest().subscribe({
      next: (data: any) => {
        this.dialog
          .open(AiUiConfirmDialogComponent, {
            data: {
              attempt_id: data.attempt_id,
              email: this.contactDataForm.get('email')?.value,
              operation: ConfirmOperation.email,
              callback: this.profileService.emailConfirmationCheck.bind(this.profileService),
            },
          })
          .afterClosed()
          .subscribe({
            next: (res: boolean) => {
              if (res) {
                this.auth.getUserProfile();
                this.toaster.showSuccess(this.translation.translate('EMAIL_HAS_BEEN_CONFIRMED'));
              }
            },
          });
        this.loader.hideLoader();
      },
      error: () => {
        this.loader.hideLoader();
      },
    });
  }

  getSettings() {
    this.loader.showLoader();
    this.notification.getSettings().subscribe({
      next: (data) => {
        this.mailingSettingsForm.patchValue(data);
        this.loader.hideLoader();
      },
      error: () => {
        this.loader.hideLoader();
      },
    });
  }

  saveSettings() {
    if (this.mailingSettingsForm.invalid) {
      return;
    }
    const payload = this.mailingSettingsForm.value as NotificationSettings;
    this.loader.showLoader();
    this.notification.updateSettings(payload).subscribe({
      next: () => {
        this.toaster.showInfo(this.translation.translate('SETTINGS_HAS_BEEN_UPDATED'));
        this.loader.hideLoader();
      },
      error: () => {
        this.loader.hideLoader();
      },
    });
  }

  getBankAccounts() {
    this.loader.showLoader();
    this.bankService.bankAccounts().subscribe({
      next: (res) => {
        if (res.length) {
          this.bankAccounts = res;
          this.cd.detectChanges();
          this.cd.markForCheck();
        }
        this.loader.hideLoader();
      },
      error: () => {
        this.loader.hideLoader();
      },
    });
  }

  onAddBankAccount() {
    this.dialog
      .open(AiUiAddBankAccountComponent, {
        panelClass: 'popup-container',
        width: '800px',
      })
      .afterClosed()
      .subscribe((res: boolean) => {
        if (res) {
          this.getBankAccounts();
        }
      });
  }

  onEditBankAccount(id: number) {
    this.dialog
      .open(AiUiEditBankAccountComponent, {
        panelClass: 'popup-container',
        width: '800px',
        data: {
          bankAccountId: id,
        },
      })
      .afterClosed()
      .subscribe((res: boolean) => {
        if (res) {
          this.getBankAccounts();
        }
      });
  }

  onDeleteBankAccount(id: number) {
    this.dialog
      .open(AiUiCautionComponent, {
        width: '500px',
        data: {
          content: this.translation.translate('CAUTION:REMOVE_BANK_ACCOUNT'),
        },
      })
      .afterClosed()
      .subscribe((result: boolean) => {
        if (!result) {
          return;
        }
        this.loader.showLoader();
        this.bankService.deleteBankAccount(id).subscribe({
          next: () => {
            this.toaster.showInfo(this.translation.translate('BANK_ACCOUNT_REMOVED'));
            this.loader.hideLoader();
            this.getBankAccounts();
          },
          error: () => {
            this.loader.hideLoader();
          },
        });
      });
  }

  getSelectedCountry(phone: string) {
    if (this.phone) {
      if (new RegExp('^[+]375|375').test(phone)) {
        this.phone.selectedCountry = {
          dialCode: '375',
          flagClass: 'BY',
        };
      } else {
        this.phone.selectedCountry = {
          dialCode: '7',
          flagClass: 'RU',
        };
      }
    }
    this.cd.markForCheck();
  }

  ngOnDestroy(): void {
    this.observer.unsubscribe();
  }
}
