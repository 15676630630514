import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslationService } from '@localization';
import { forkJoin, interval, mergeMap, Subscription } from 'rxjs';
import { PHONE_CONFIRM_ATTEMPT_IN_SECONDS } from 'src/app/constants/constants';
import { AuthSettings, PersonalInfo } from 'src/app/interfaces/api/registration.interface';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { PushNotificationService } from 'src/app/services/push-notification/push-notification.service';
import { RegistrationService } from 'src/app/services/registration/registration.service';
import { AiUiConfirmDialogComponent } from 'src/app/shared/shared-components/ai-ui-confirm-dialog/ai-ui-confirm-dialog.component';
import { AiUiInfoDialogComponent } from 'src/app/shared/shared-components/ai-ui-info-dialog/ai-ui-info-dialog.component';
import { ToasterService } from 'src/app/shared/shared-services/toaster/toaster.service';
import { animationOptions } from 'src/app/utils/animation';
import {
  ConfirmOperation,
  CountryCode,
  dateOfBirthValidator,
  DIGITS_REGEXP,
  EMAIL_REGEXP,
  getFormatDate,
  getPasswordValidationMessage,
  LOWERCASE_REGEXP,
  NAME_REGEXP,
  passwordPatternValidator,
  SPECIAL_CHARACTERS_REGEXP,
  UPPERCASE_REGEXP,
} from 'src/app/utils/utils';

@Component({
  selector: 'app-client-registration-flow',
  templateUrl: './client-registration-flow.component.html',
  styleUrls: ['./client-registration-flow.component.scss'],
  animations: animationOptions,
})
export class ClientRegistrationFlowComponent implements OnInit, OnDestroy {
  interval: Subscription;

  timeLeft = PHONE_CONFIRM_ATTEMPT_IN_SECONDS;

  today: Date = new Date();

  countries = Array.from(new Set(Object.keys(CountryCode)));

  @ViewChild('phone') phone;

  phoneForm: FormGroup;

  confirmForm: FormGroup;

  personalInfoForm: FormGroup;

  passwordForm: FormGroup;

  electronicDocumentProcessing: FormControl = new FormControl(false);

  personalDataProcessing: FormControl = new FormControl(false);

  isEdmConsentRead: boolean = false;

  isPersonalDataConsentRead: boolean = false;

  consents: Map<string, string> = new Map<string, string>();

  totalSteps = Array.from({ length: this.registration.registrationStepsCount }, (_, index) => index + 1);

  readonly getPasswordValidationMessage = getPasswordValidationMessage;

  constructor(
    public registration: RegistrationService,
    private router: Router,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private pn: PushNotificationService,
    private toasterService: ToasterService,
    private translation: TranslationService,
    private loader: LoaderService
  ) {}

  ngOnInit(): void {
    this.initSignUpForm();
    this.initConfirmForm();
    this.initMainInfoForm();
    this.initPasswordForm();
    if (!this.registration.registration_uuid) {
      return;
    }
    this.loader.showLoader();
    this.registration.lastStep().subscribe({
      next: (stepName) => {
        if (stepName === 'confirm_phone') {
          const phone = this.registration?.payload?.phone;
          this.phoneForm.patchValue({
            phone,
          });
          this.startTimer();
        } else if (stepName === 'auth_settings') {
          this.getConsents();
        }
        const step = this.registration.getRegistrationStepByValue(stepName);
        this.registration.goToStep(step);
        this.cd.detectChanges();
        this.loader.hideLoader();
      },
      error: (err: HttpErrorResponse) => {
        const uuidInvalid = err.error.find((e) => ['uuid_required', 'uuid_invalid'].indexOf(e.code) !== -1);
        if (uuidInvalid) {
          this.registration.goToStep(0);
        }
        this.loader.hideLoader();
      },
    });
  }

  initSignUpForm() {
    this.phoneForm = new FormGroup({
      phone: new FormControl(null, Validators.required),
    });
  }

  initConfirmForm() {
    this.confirmForm = new FormGroup({
      code: new FormControl(null, [Validators.required, Validators.minLength(4)]),
    });
  }

  initMainInfoForm() {
    this.personalInfoForm = new FormGroup({
      last_name: new FormControl(null, [
        Validators.required,
        Validators.pattern(NAME_REGEXP),
        Validators.maxLength(15),
      ]),
      first_name: new FormControl(null, [
        Validators.required,
        Validators.pattern(NAME_REGEXP),
        Validators.maxLength(15),
      ]),
      middle_name: new FormControl(null, [Validators.pattern(NAME_REGEXP), Validators.maxLength(15)]),
      birth_date: new FormControl(null, [Validators.required, dateOfBirthValidator()]),
      email: new FormControl(null, [Validators.required, Validators.pattern(EMAIL_REGEXP)]),
    });
  }

  initPasswordForm() {
    this.passwordForm = new FormGroup(
      {
        password1: new FormControl(null, [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(LOWERCASE_REGEXP),
          Validators.pattern(UPPERCASE_REGEXP),
          Validators.pattern(DIGITS_REGEXP),
          Validators.pattern(SPECIAL_CHARACTERS_REGEXP),
          passwordPatternValidator,
        ]),
        password2: new FormControl(null, Validators.required),
        recaptcha: new FormControl(null, Validators.required),
      },
      { validators: [passwordMatchValidator] }
    );
  }

  signIn() {
    this.router.navigate(['users', 'sign_in']);
  }

  onChangePhoneCountry(event: any) {
    if (!this.countries.includes(event.flagClass)) {
      this.phone.selectedCountry = {
        dialCode: '7',
        flagClass: 'RU',
        name: 'RU',
        iso2: 'ru',
        placeHolder: '+79123456789',
      };
    }
  }

  next() {
    this.registration.goToStep(this.registration.registrationCurrentStepKey + 1);
    if (this.registration.registrationCurrentStepValue === 'auth_settings') {
      this.getConsents();
    }
  }

  getConsents() {
    forkJoin({
      edm_consent: this.registration.getEdmConsent(),
      personal_data_consent: this.registration.getPersonalDataConsent(),
    }).subscribe({
      next: (data) => {
        this.consents['edm_consent'] = data['edm_consent'];
        this.consents['personal_data_consent'] = data['personal_data_consent'];
        this.cd.markForCheck();
      },
      error: () => {},
    });
  }

  back() {
    if (!this.registration.registrationCurrentStepKey) {
      this.router.navigate(['users', 'sign_in']);
      return;
    }
    if (this.registration.registrationCurrentStepKey === 1) {
      this.interval?.unsubscribe();
    }
    this.registration.goToStep(this.registration.registrationCurrentStepKey - 1);
  }

  initiate() {
    const phone = this.phoneForm.get('phone').value;
    this.loader.showLoader();
    this.registration.initiate(phone).subscribe({
      complete: () => {
        this.registration.payload = {
          phone: phone,
        };
        this.startTimer();
        this.next();
        this.cd.detectChanges();
        this.loader.hideLoader();
      },
      error: () => {
        this.loader.hideLoader();
      },
    });
  }

  terminateRegistration() {
    this.clearData();
    this.router.navigate(['users', 'sign_in']);
  }

  startTimer() {
    this.timeLeft = PHONE_CONFIRM_ATTEMPT_IN_SECONDS;
    this.interval = interval(1000).subscribe(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
        this.cd.markForCheck();
      }
    });
  }

  isAllowedToResend() {
    return this.timeLeft === 0;
  }

  resendCode() {
    this.loader.showLoader();
    this.registration.resendCode().subscribe({
      complete: () => {
        this.timeLeft = 30;
        this.dialog.open(AiUiInfoDialogComponent, {
          disableClose: true,
          data: {
            content: `СМС-код с подтверждением был повторно отправлен на номер ${this.phoneForm.get('phone')?.value}.`,
          },
        });
        this.loader.hideLoader();
      },
      error: () => {
        this.loader.hideLoader();
      },
    });
  }

  confirmCode() {
    const code = this.confirmForm.get('code')?.value;
    if (code.length === 4) {
      this.loader.showLoader();
      this.registration.checkCode(code).subscribe({
        next: () => {
          this.interval.unsubscribe();
          this.next();
          this.cd.markForCheck();
          this.loader.hideLoader();
        },
        error: (err: HttpErrorResponse) => {
          const codeInvalid = err.error.find((e) => e.code === 'code_invalid');
          if (codeInvalid) {
            this.dialog.open(AiUiInfoDialogComponent, {
              disableClose: true,
              data: {
                content: this.translation.translate('ERROR:code_invalid'),
              },
            });
          }
          const codeIsExpired = err.error.find((e) => e.code === 'code_check_limit_exceeded');
          if (codeIsExpired) {
            this.dialog.open(AiUiInfoDialogComponent, {
              disableClose: true,
              data: {
                content: `Введен неверно код 3 раза. Вы можете повторно выслать СМС с кодом или прервать регистрацию.`,
              },
            });
          }
          this.confirmForm.reset();
          this.loader.hideLoader();
        },
      });
    }
  }

  sendPersonalInfo() {
    if (this.personalInfoForm.invalid) {
      return;
    }
    const payload = this.personalInfoForm.getRawValue() as PersonalInfo;
    payload.birth_date = getFormatDate(payload.birth_date);
    this.loader.showLoader();
    this.registration.personalInfo(payload).subscribe({
      next: () => {
        this.next();
        this.cd.markForCheck();
        this.loader.hideLoader();
      },
      error: () => {
        this.loader.hideLoader();
      },
    });
  }

  sendConsentsToSign() {
    if (this.passwordForm.invalid) {
      return;
    }
    if (!this.isEdmConsentRead) {
      this.toasterService.showError(this.translation.translate('ERROR:READ_ELECTRONIC_DOCUMENT_PROCESSING'));
      return;
    }
    if (!this.isPersonalDataConsentRead) {
      this.toasterService.showError(this.translation.translate('ERROR:READ_PERSONAL_DATA_PROCESSING'));
      return;
    }

    this.registration.resendCode().subscribe({
      next: (data: any) => {
        this.dialog
          .open(AiUiConfirmDialogComponent, {
            data: {
              operation: ConfirmOperation.phone,
              phone: this.registration?.payload?.phone,
              callback: this.registration.checkSignConsentsCode.bind(this.registration),
            },
          })
          .afterClosed()
          .subscribe({
            next: (res: boolean) => {
              if (res) {
                this.authSettings();
              }
            },
          });
        this.loader.hideLoader();
      },
      error: () => {
        this.loader.hideLoader();
      },
    });
  }

  authSettings() {
    const payload = this.passwordForm.getRawValue() as AuthSettings;
    this.loader.showLoader();
    this.registration.authSettings(payload).subscribe({
      complete: () => {
        this.router.navigate(['profile', 'settings'], { state: { step: 2 } });
        this.cd.markForCheck();
        this.cd.detectChanges();
        this.loader.hideLoader();
      },
      error: (err: HttpErrorResponse) => {
        const passwordIsCommon = ['new_password1_invalid', 'new_password2_invalid'].indexOf(err.error[0]?.code) !== -1;
        if (passwordIsCommon) {
          this.toasterService.showError(this.translation.translate('ERROR:password_is_common'));
        }
        this.loader.hideLoader();
      },
    });
  }
  ngOnDestroy(): void {
    this.clearData();
  }

  clearData() {
    this.registration.goToStep(0);
    this.phoneForm.reset();
    this.confirmForm.reset();
    this.personalInfoForm.reset();
    this.passwordForm.reset();
    if (sessionStorage.getItem(this.registration.REGISTRATION_UUID_KEY)) {
      sessionStorage.removeItem(this.registration.REGISTRATION_UUID_KEY);
    }
    if (sessionStorage.getItem(this.registration.PAYLOAD_KEY)) {
      sessionStorage.removeItem(this.registration.PAYLOAD_KEY);
    }
    this.cd.markForCheck();
    this.cd.detectChanges();
  }
}

function passwordMatchValidator(form: FormGroup) {
  const isMatch = form.get('password1').value === form.get('password2').value;
  isMatch
    ? delete form.get('password2')?.errors?.passwordMismatch
    : form.get('password2').setErrors({ passwordMismatch: true });
  return form.get('password2')?.errors;
}
