<div class="container-fluid bg-white p-4" [@open]="'opened'">
  <div class="row">
    <h1>{{ 'CLAIMS' | translate }}</h1>
    <!-- <div class="col-sm-6 col-12 text-align-right add-action">
      <button mat-button type="button" routerLink="/claims/appeal_manual_assignment_new">
        <mat-icon>playlist_add</mat-icon>
        {{ 'ADD_CLAIM' | translate }}
      </button>
    </div> -->
  </div>
  <div class="row">
    <!-- <ng-container *ngIf="(auth.userProfile$ | async)?.status==='pii_not_filled';else content">
      <ai-ui-restriction></ai-ui-restriction>
    </ng-container>
    <ng-template #content> -->
      <mat-tab-group mat-stretch-tabs="true">
        <mat-tab label="{{ 'broker_service' | translate }}">
          <div class="operations">
            <div class="operations-title">
              <h3>{{ 'OPERATIONS_UNDER_THE_CONTRACT' | translate }}</h3>
            </div>
            <div class="operations-cards">
              <div class="col-xl-6 col-lg-6 col-12" *ngFor="let operation of bs_operations">
                <div class="operation-card" [routerLink]="operation.url">
                <!-- <div class="operation-card col-xl-8 col-lg-8 col-12" [routerLink]="operation.url"> -->
                  <img [src]="operation.logo" alt="logo">
                  <span>{{ operation.name }}</span>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="{{ 'depositary_service' | translate }}">
          <div class="operations">
            <div class="operations-title">
              <h3>{{ 'OPERATIONS_UNDER_THE_CONTRACT' | translate }}</h3>
            </div>
            <div class="operations-cards">
              <div class="col-xl-6 col-lg-6 col-12" *ngFor="let operation of depo_operations">
                <div class="operation-card" [routerLink]="operation.url">
                  <img [src]="operation.logo" alt="logo">
                  <span>{{ operation.name }}</span>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    <!-- </ng-template> -->
  </div>
</div>