import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslationService } from '@localization';
import { Observable, catchError, throwError } from 'rxjs';
import { AiUiServerErrorComponent } from '../../shared-components/ai-ui-server-error/ai-ui-server-error.component';
import { ToasterService } from '../../shared-services/toaster/toaster.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  handleError = (err: any) => {
    if (err.status === 500 && !this.dialogRef.id) {
      this.router.navigate(['/']);
      this.dialogRef = this.dialog.open(AiUiServerErrorComponent, {
        panelClass: 'error-popup',
        disableClose: true,
      });
    } else {
      const code = err.error[0]?.code || err.error.code;
      if (!['not_authenticated', 'token_not_valid', 'undefined', undefined].includes(code)) {
        this.toaster.showError(this.translation.translate(`ERROR:${code}`));
      }
      return throwError(() => err);
    }
    return throwError(() => err);
  };

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private toaster: ToasterService,
    private translation: TranslationService,
    private dialogRef: MatDialogRef<AiUiServerErrorComponent>
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(catchError(this.handleError));
  }
}
