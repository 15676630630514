import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { BankAccountResponse } from 'src/app/interfaces/api/bank.interface';
import { ClaimResponse } from 'src/app/interfaces/api/claims.interface';
import { DepositaryAccountResponse } from 'src/app/interfaces/api/depositary-account.interface';
import { AuthService } from 'src/app/services/auth/auth.service';
import { BankService } from 'src/app/services/bank/bank.service';
import { DepositaryAccountService } from 'src/app/services/depositary-account/depositary-account.service';
import { ExchangeService } from 'src/app/services/exchange/exchange.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { ClaimCategory, OtherType } from 'src/app/utils/utils';
import { AiUiImageDialogComponent } from '../ai-ui-image-dialog/ai-ui-image-dialog.component';

@Component({
  selector: 'app-ai-ui-claim-details',
  templateUrl: './ai-ui-claim-details.component.html',
  styleUrls: ['./ai-ui-claim-details.component.scss'],
})
export class AiUiClaimDetailsComponent implements OnInit {
  bankAccount: BankAccountResponse;

  depositaryAccount: DepositaryAccountResponse;

  showMainData: boolean;

  showBankAccountData: boolean;

  showClaimData: boolean;

  constructor(
    public auth: AuthService,
    public exchangeService: ExchangeService,
    public depositaryAccountService: DepositaryAccountService,
    public profileService: ProfileService,
    private cd: ChangeDetectorRef,
    private bankService: BankService,
    private loader: LoaderService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: ClaimResponse
  ) {}

  ngOnInit(): void {
    if (this.data.bank_account) {
      this.loader.showLoader();
      this.bankService.bankAccountById(this.data.bank_account).subscribe({
        next: (res) => {
          this.bankAccount = res;
          this.cd.markForCheck();
          this.loader.hideLoader();
        },
        error: () => {
          this.loader.hideLoader();
        },
      });
    }
    if (this.data.depositary_account) {
      this.loader.showLoader();
      this.depositaryAccountService.depoAccountById(this.data.depositary_account).subscribe({
        next: (res) => {
          this.depositaryAccount = res;
          this.cd.markForCheck();
          this.loader.hideLoader();
        },
        error: () => {
          this.loader.hideLoader();
        },
      });
    }
    if (this.data.type === OtherType.edit_profile) {
      this.loader.showLoader();
      this.profileService.getDiffByClaimId(this.data.id).subscribe({
        next: (data) => {
          this.profileService.diffData = [];
          this.profileService.deletedPassportScansDiffData = [];
          this.profileService.getObjectData(data, this.data.status);
          this.loader.hideLoader();
        },
        error: () => {
          this.loader.hideLoader();
        },
      });
    }
  }

  open(url: string) {
    this.dialog.open(AiUiImageDialogComponent, {
      panelClass: 'attachment-container',
      data: {
        url,
      },
    });
  }

  getExchangeName = (id: number) => this.exchangeService.exchanges$.value.find((e) => e.id === id).name ?? '';
}
