<div class="form_container">
  <div class="header_logo">
    <a href="https://aigenis.ru/"
      target="_blank">
      <img alt="logo"
      src="./assets/logos/aigenis_dark.svg"/>
    </a>
  </div>
  <div class="form__title">
    <span>{{ 'REGISTRATION:NEW_USER' | translate }}</span>
  </div>
  <div class="stepper">
    <div class="mb-2 title">{{ 'REGISTRATION:STEP_BY_STEP' | translate: [registration.registrationCurrentStepKey + 1, totalSteps.length] }}</div>
    <div class="d-flex align-items-center">
      <ng-container *ngFor="let step of totalSteps; let first = first">
        <div *ngIf="!first"
          [class.completed]="step <= registration.registrationCurrentStepKey + 1"
          class="line"></div>
        <div [class.completed]="step <= registration.registrationCurrentStepKey + 1"
          class="step"></div>
      </ng-container>
    </div>
  </div>
  <form [@open]="'opened'"
    (ngSubmit)="initiate()"
    *ngIf="registration.registrationCurrentStepValue==='enter_phone'"
    [formGroup]="phoneForm">
    <mat-form-field class="mb-3">
      <mat-label>{{ 'REGISTRATION:ENTER_PHONE_NUMBER' | translate }}</mat-label>
      <ngx-mat-intl-tel-input #phone
        [enablePlaceholder]="true"
        [enableSearch]="false"
        [onlyCountries]="['ru','by']"
        formControlName="phone"
        (countryChanged)="onChangePhoneCountry($event)"></ngx-mat-intl-tel-input>
      <mat-error *ngIf="phoneForm.get('phone').errors?.validatePhoneNumber">
        {{ 'ERROR:PHONE_NUMBER_INCORRECT' | translate }}
      </mat-error>
    </mat-form-field>
    <div class="d-flex justify-content-center mb-2">
      <button [disabled]="!phoneForm.valid"
        class="signin-btn"
        mat-flat-button
        type="submit">
        {{ 'NEXT' | translate }}
      </button>
    </div>
  </form>
  <form [@open]="'opened'"
    *ngIf="registration.registrationCurrentStepValue === 'confirm_phone'"
    [formGroup]="confirmForm">
    <div class="sms-section text-start">
      <p class="send-code-info">{{ 'REGISTRATION:CODE_HAS_BEEN_SENT' | translate: [phoneForm.get('phone')?.value] }}</p>
      <div class="d-flex align-items-end mb-2">
        <ui-code-input formControlName="code"
          class="me-1">
          {{ 'REGISTRATION:CONFIRM_CODE' | translate }}
        </ui-code-input>
        <button class="signin-btn"
          [disabled]="confirmForm.invalid"
          (click)="confirmCode()"
          mat-flat-button
          type="button">{{'NEXT' | translate}}</button>
      </div>
      <div class="resend-code">
        <div *ngIf="!isAllowedToResend()"
          class="resend-message">{{ 'REGISTRATION:RESEND_CODE_TIMEOUT' | translate: [timeLeft] }}
        </div>
        <button (click)="resendCode()"
          *ngIf="isAllowedToResend()"
          color="primary"
          mat-button
          type="button">
          {{ 'REGISTRATION:RESEND_CODE' | translate }}
        </button>
      </div>
      <div class="note mb-3"
        [matTooltip]="'REGISTRATION:DO_NOT_RECEIVE_SMS:NOTE' | translate"
        matTooltipPosition="after"
        matTooltipClass="custom-tooltip">
        {{'REGISTRATION:DO_NOT_RECEIVE_SMS' | translate}}
      </div>
    </div>
    <div class="back">
      <a (click)="terminateRegistration()"
        class="sign-link">{{ 'BACK_TO_MAIN' | translate }}</a>
    </div>
  </form>
  <form (ngSubmit)="sendPersonalInfo()"
    *ngIf="registration.registrationCurrentStepValue==='personal_info'"
    [formGroup]="personalInfoForm">
    <div class="row">
      <mat-form-field class="col-lg-6">
        <mat-label>{{ 'FIELD:LAST_NAME' | translate }}</mat-label>
        <input russianLetters
          formControlName="last_name"
          matInput
          required/>
        <mat-error *ngIf="personalInfoForm.get('last_name').errors?.required">
          {{'ERROR:REQUIRED' | translate }}
        </mat-error>
        <mat-error *ngIf="personalInfoForm.get('last_name').errors?.pattern">
          {{'ERROR:ONLY_LETTERS_AND_SPECIAL_CHARACTERS' | translate }}
        </mat-error>
        <mat-error *ngIf="personalInfoForm.get('last_name').errors?.maxlength">
          {{'ERROR:MAX_LENGTH' | translate: [15] }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-lg-6">
        <mat-label>{{ 'FIELD:FIRST_NAME' | translate }}</mat-label>
        <input russianLetters
          formControlName="first_name"
          matInput
          required/>
        <mat-error *ngIf="personalInfoForm.get('first_name').errors?.required">
          {{'ERROR:REQUIRED' | translate }}
        </mat-error>
        <mat-error *ngIf="personalInfoForm.get('first_name').errors?.pattern">
          {{'ERROR:ONLY_LETTERS_AND_SPECIAL_CHARACTERS' | translate }}
        </mat-error>
        <mat-error *ngIf="personalInfoForm.get('first_name').errors?.maxlength">
          {{'ERROR:MAX_LENGTH' | translate: [15] }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-lg-6">
        <mat-label>{{ 'FIELD:MIDDLE_NAME' | translate }}</mat-label>
        <input russianLetters
          formControlName="middle_name"
          matInput/>
        <mat-error *ngIf="personalInfoForm.get('middle_name').errors?.maxlength">
          {{'ERROR:MAX_LENGTH' | translate: [15] }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-lg-6">
        <mat-label>{{ 'FIELD:BIRTH_DATE' | translate }}</mat-label>
        <input dateFormat
          [matDatepicker]="picker"
          [max]="today"
          formControlName="birth_date"
          matInput/>
        <mat-datepicker-toggle [for]="picker"
          matSuffix></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="personalInfoForm.get('birth_date').errors?.required">
          {{'ERROR:REQUIRED' | translate }}
        </mat-error>
        <mat-error *ngIf="personalInfoForm.get('birth_date').errors?.young">
          {{'ERROR:NOT_UNDER_18' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'FIELD:EMAIL' | translate }}</mat-label>
        <input formControlName="email"
          matInput
          required/>
        <mat-error *ngIf="personalInfoForm.get('email').errors?.required">
          {{'ERROR:REQUIRED' | translate }}
        </mat-error>
        <mat-error *ngIf="personalInfoForm.get('email').errors?.pattern">
          {{ 'ERROR:EMAIL_INCORRECT' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="d-flex justify-content-center mb-2">
      <button [disabled]="!personalInfoForm.valid"
        class="signin-btn"
        mat-flat-button
        type="submit">
        {{ 'NEXT' | translate }}
      </button>
    </div>
    <div class="hint mb-3">
      <img src="assets/images/schield.svg"
        alt="security"
        class="me-2">
      {{'SECURITY_HINT' | translate}}
    </div>
    <div class="back">
      <a (click)="terminateRegistration()"
        class="sign-link">{{ 'BACK_TO_MAIN' | translate }}</a>
    </div>
  </form>
  <form [@open]="'opened'"
    (ngSubmit)="sendConsentsToSign()"
    *ngIf="registration.registrationCurrentStepValue==='auth_settings'"
    [formGroup]="passwordForm">
    <div class="validation-note">
      <p>{{'REGISTRATION:PASSWORD_TITLE' | translate }}</p>
      <ul>
        <li>{{'REGISTRATION:PASSWORD_CONTENT_1' | translate }}</li>
        <li>{{'REGISTRATION:PASSWORD_CONTENT_2' | translate }}</li>
        <li>{{'REGISTRATION:PASSWORD_CONTENT_3' | translate }}</li>
      </ul>
    </div>

    <div class="row">
      <mat-form-field [matTooltip]="getPasswordValidationMessage(passwordForm.get('password1').errors)"
        matTooltipClass="custom-tooltip"
        matTooltipPosition="after"
        class="col-lg-6">
        <mat-label>{{ 'FIELD:PASSWORD' | translate }}</mat-label>
        <input [type]="password1.type"
          formControlName="password1"
          matInput
          required/>
        <ai-ui-toggle-visibility #password1
          matSuffix></ai-ui-toggle-visibility>
      </mat-form-field>
      <mat-form-field class="col-lg-6">
        <mat-label>{{ 'FIELD:REPEAT_PASSWORD' | translate }}</mat-label>
        <input [type]="password2.type"
          formControlName="password2"
          matInput
          required/>
        <mat-error *ngIf="passwordForm.get('password2').errors?.required">
          {{ 'ERROR:REQUIRED' | translate }}
        </mat-error>
        <mat-error *ngIf="passwordForm.get('password2').errors?.passwordMismatch">
          {{ 'ERROR:PASSWORD_MISMATCH' | translate }}
        </mat-error>
        <ai-ui-toggle-visibility #password2
          matSuffix></ai-ui-toggle-visibility>
      </mat-form-field>
      <re-captcha class="recaptcha col-12"
        formControlName="recaptcha"></re-captcha>
    </div>
    <div class="confirmation word-wrap">
      <mat-checkbox [formControl]="electronicDocumentProcessing"
        [matTooltip]="isEdmConsentRead ? null : 'REGISTRATION:CONSENT_DID_NOT_READ' | translate"
        matTooltipPosition="above"
        matTooltipClass="custom-tooltip"
        color="primary"
        [disabled]="!isEdmConsentRead"
        class="dark">{{
          'REGISTRATION:ELECTRONIC_DOCUMENT_PROCESSING_PART_1' | translate }}
        <a [href]="consents['edm_consent']"
          target="_blank"
          (click)="isEdmConsentRead = true">{{
            'REGISTRATION:ELECTRONIC_DOCUMENT_PROCESSING_PART_2' | translate }}</a>
      </mat-checkbox>
    </div>
    <div class="confirmation word-wrap mb-4">
      <mat-checkbox [formControl]="personalDataProcessing"
        [disabled]="!isPersonalDataConsentRead"
        [matTooltip]="isPersonalDataConsentRead ? null : 'REGISTRATION:CONSENT_DID_NOT_READ' | translate"
        matTooltipPosition="above"
        matTooltipClass="custom-tooltip"
        color="primary"
        class="dark">{{
          'REGISTRATION:PERSONAL_DATA_PROCESSING_PART_1' | translate }}
        <a [href]="consents['personal_data_consent']"
          target="_blank"
          (click)="isPersonalDataConsentRead = true">{{
            'REGISTRATION:PERSONAL_DATA_PROCESSING_PART_2' | translate }}</a>
      </mat-checkbox>
    </div>
    <button [disabled]="!passwordForm.valid || !electronicDocumentProcessing.value || !personalDataProcessing.value"
      class="signin-btn mb-3"
      mat-flat-button
      type="submit">
      {{ 'REGISTER' | translate }}
    </button>
    <div class="back">
      <a (click)="terminateRegistration()"
        class="sign-link">{{ 'BACK_TO_MAIN' | translate }}</a>
    </div>
  </form>
  <button (click)="back()"
    mat-icon-button
    class="sign-terminate"
    type="button">
    <mat-icon>keyboard_arrow_left</mat-icon>
  </button>
</div>
<div class="sign-illustration"></div>