import { Component, OnDestroy } from '@angular/core';
import { TranslationService } from '@localization';
import { RefillAccountResponse } from 'src/app/interfaces/api/refill-account.interface';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { RefillAccountService } from 'src/app/services/refill_account/refill-account.service';
import { AreaType, CountryCode, formatDate } from 'src/app/utils/utils';

@Component({
  selector: 'app-ai-ui-refill-account-method',
  templateUrl: './ai-ui-refill-account-method.component.html',
  styleUrls: ['./ai-ui-refill-account-method.component.scss'],
})
export class AiUiRefillAccountMethodComponent implements OnDestroy {
  paymentMethodsWithConsent: RefillAccountResponse[] = [];
  paymentMethodsWithoutConsent: RefillAccountResponse[] = [];

  categories = Array.from(new Set(Object.keys(AreaType).filter((n) => n !== 'sstock')));
  purposeOfPayment: string = '';

  constructor(
    public auth: AuthService,
    public refillPaymentService: RefillAccountService,
    private loader: LoaderService,
    private translation: TranslationService
  ) {}

  next(category: string) {
    this.loader.showLoader();
    this.refillPaymentService.paymentMethods().subscribe({
      next: (data) => {
        this.paymentMethodsWithConsent = data.filter((n) => n.type === 'with_consent');
        this.paymentMethodsWithoutConsent = data.filter((n) => n.type === 'without_consent');
        this.purposeOfPayment = this.definePurposeOfPayment(category);
        this.refillPaymentService.goToStep(this.refillPaymentService.stepKey + 1);
        this.loader.hideLoader();
      },
      error: () => {
        this.loader.hideLoader();
      },
    });
  }

  definePurposeOfPayment(category: string) {
    const citizenship = this.auth?.userProfile$?.value.citizenship;
    const brokerage_agreement = this.auth?.brokerageService$?.value;
    const activation_date = brokerage_agreement.activation_datetime.split('T')[0];
    const params = [brokerage_agreement.number, formatDate(activation_date)];
    if (citizenship === CountryCode.BY) {
      switch (category) {
        case AreaType.mstock:
          return this.translation.translate('PURPOSE_OF_PAYMENT:BY_MSTOCK', params);
        case AreaType.mcur:
          return this.translation.translate('PURPOSE_OF_PAYMENT:BY_MCUR', params);
        case AreaType.offe:
          return this.translation.translate('PURPOSE_OF_PAYMENT:BY_OFFE', params);
        default:
          return '';
      }
    } else if (citizenship === CountryCode.RU) {
      switch (category) {
        case AreaType.mstock:
          return this.translation.translate('PURPOSE_OF_PAYMENT:RU_MSTOCK', params);
        case AreaType.mcur:
          return this.translation.translate('PURPOSE_OF_PAYMENT:RU_MCUR', params);
        case AreaType.offe:
          return this.translation.translate('PURPOSE_OF_PAYMENT:RU_OFFE', params);
        default:
          return '';
      }
    } else {
      return '';
    }
  }

  ngOnDestroy(): void {
    this.refillPaymentService.step = 0;
  }
}
