<div class="container-fluid bg-white p-4" [@open]="'opened'">
  <h1>{{ 'PROFILE:PERSONAL_DATA' | translate }}</h1>
  <div class="row not-reverse">
    <div class="col-12">
      <ng-container *ngIf="(auth.userProfile$ | async)?.status === 'pii_validating'">
        <ai-ui-restriction [type]="'waitingForApproval'" [showFaq]="false"></ai-ui-restriction>
      </ng-container>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <ng-container *ngIf="(auth.userProfile$ | async)?.status === 'pii_not_filled'; else profileInfo">
        <ai-ui-restriction [type]="'fillPersonalData'"></ai-ui-restriction>
      </ng-container>
    </div>
    <ng-template #profileInfo>
      <div class="col charts">
        <!-- <mat-button-toggle-group vertical="true" name="favoriteColor" aria-label="Favorite Color">
        <mat-button-toggle checked>
          Паспорт
        </mat-button-toggle>
        <mat-button-toggle>
          Телефон, почта и другое
        </mat-button-toggle>
        <mat-button-toggle>
          FATCA, CRS и другие данные
        </mat-button-toggle>
      </mat-button-toggle-group> -->
        <button [disabled]="is_forbidden" class="filled" mat-button routerLink="/profile/edit">
          {{ 'PROFILE:CHANGE_DATA' | translate }}
        </button>
      </div>
      <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
        <div class="table-responsive" id="passport">
          <h3>{{ 'PROFILE:PASSPORT' | translate }}</h3>
          <table class="table borderless">
            <tbody>
              <tr>
                <th>{{ 'PROFILE:FULL_NAME' | translate }}</th>
                <td>
                  {{
                    personalData?.last_name +
                      ' ' +
                      personalData?.first_name +
                      ' ' +
                      (personalData?.middle_name ? personalData?.middle_name : '')
                  }}
                </td>
              </tr>
              <tr>
                <th>{{ 'FIELD:SEX' | translate }}</th>
                <td>{{ 'SEX:' + personalData?.sex | translate }}</td>
              </tr>
              <tr>
                <th>{{ 'PROFILE:DATE_OF_BIRTH' | translate }}</th>
                <td>{{ personalData?.birth_date | date : 'dd.MM.yyyy' }}</td>
              </tr>
              <tr>
                <th>{{ 'PROFILE:PLACE_OF_BIRTH' | translate }}</th>
                <td>{{ personalData?.passport?.birth_place }}</td>
              </tr>
              <tr>
                <th>{{ 'DOCUMENT' | translate }}</th>
                <!-- <td>{{ 'PASSPORT_INFO' | translate: [
                'РБ',
                personalData?.passport?.number,
                personalData?.passport?.date_of_issue?.toString(),
                personalData?.passport?.type === 'passport' ? personalData?.passport?.issued_by : 
                personalData?.passport?.type === 'id_card' ? personalData?.passport?.issued_by_code : ''
                ] }}
              </td> -->
                <td>{{ getPassportInfoStr(personalData?.passport) }}</td>
              </tr>
              <!-- <tr>
              <th>Сканы паспорта</th>
              <td>Тестов</td>
            </tr> -->
            </tbody>
          </table>
        </div>
        <div class="table-responsive" id="address">
          <h3>{{ 'PROFILE:CONTACT_DATA' | translate }}</h3>
          <table aria-label="Data Grid" class="table borderless">
            <tbody>
              <tr>
                <th>{{ 'FIELD:EMAIL' | translate }}</th>
                <td>{{ (auth.userProfile$ | async)?.email }}</td>
              </tr>
              <tr>
                <th>{{ 'FIELD:PHONE_NUMBER' | translate }}</th>
                <td>{{ (auth.userProfile$ | async)?.phone }}</td>
              </tr>
              <tr>
                <th>{{ 'PROFILE:REGISTRATION_ADDRESS' | translate }}</th>
                <td>{{ getAddressStr(contactData?.registration_address) }}</td>
              </tr>
              <tr>
                <th>{{ 'PROFILE:LIVING_ADDRESS' | translate }}</th>
                <ng-container *ngIf="!contactData?.is_living_address_as_reg; else livingAddressIsSame">
                  <td>{{ getAddressStr(contactData?.living_address) }}</td>
                </ng-container>
                <ng-template #livingAddressIsSame>
                  <td>{{ 'PROFILE:ADDRESS_IS_THE_SAME' | translate }}</td>
                </ng-template>
              </tr>
              <tr>
                <th>{{ 'PROFILE:POSTAL_ADDRESS' | translate }}</th>
                <ng-container *ngIf="!contactData?.is_postal_address_as_living; else postalAddressIsSame">
                  <td>{{ getAddressStr(contactData?.postal_address) }}</td>
                </ng-container>
                <ng-template #postalAddressIsSame>
                  <td>{{ 'PROFILE:POSTAL_ADDRESS_IS_THE_SAME' | translate }}</td>
                </ng-template>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="table-responsive" id="extra">
          <h3>{{ 'PROFILE:TAX_COMPLIANCE' | translate }}</h3>
          <table aria-label="Data Grid" class="table borderless">
            <tbody>
              <tr>
                <th>{{ 'FIELD:TAX_RESIDENT_TYPE' | translate }}</th>
                <td>{{ extraData?.tax_resident_type | translate }}</td>
              </tr>
              <tr *ngIf="extraData?.tax_resident_type === 'tax_resident_of_another_country'">
                <th>{{ 'FIELD:TAX_RESIDENT_COUNTRY_NAME_INFO' | translate }}</th>
                <td>{{ extraData?.tax_resident_country_name | translate }}</td>
              </tr>
              <tr>
                <th>{{ 'FIELD:WORK_CAPACITY' | translate }}</th>
                <td>{{ 'EFFICIENCY:' + extraData?.work_capacity | translate }}</td>
              </tr>
              <tr>
                <th>{{ 'FIELD:IS_PROTECTOR' | translate }}</th>
                <td>{{ (extraData?.is_protector ? 'YES' : 'NO') | translate }}</td>
              </tr>
              <tr>
                <th>{{ 'FIELD:IS_PUBLIC_OFFICIAL' | translate }}</th>
                <td>{{ (extraData?.is_public_official ? 'YES' : 'NO') | translate }}</td>
              </tr>
              <tr>
                <th>{{ 'FIELD:IS_FOREIGN_PUBLIC_OFFICIAL' | translate }}</th>
                <td>{{ (extraData?.is_foreign_public_official ? 'YES' : 'NO') | translate }}</td>
              </tr>
              <tr>
                <th>{{ 'FIELD:IS_OFFICIAL_OF_INT_ORG' | translate }}</th>
                <td>{{ (extraData?.is_official_of_int_org ? 'YES' : 'NO') | translate }}</td>
              </tr>
              <tr>
                <th>{{ 'FIELD:IS_RELATIVE_OF_OFFICIAL' | translate }}</th>
                <td>{{ (extraData?.is_relative_of_official ? 'YES' : 'NO') | translate }}</td>
              </tr>
              <tr>
                <th>{{ 'FIELD:IS_USA_TAX_RESIDENT' | translate }}</th>
                <td>{{ (extraData?.is_usa_tax_resident ? 'YES' : 'NO') | translate }}</td>
              </tr>
              <tr>
                <th>{{ 'FIELD:HAS_FOREIGN_TAX_NUMBER' | translate }}</th>
                <td>
                  {{ (extraData?.has_foreign_tax_number ? 'YES' : 'NO') | translate }}
                  <ng-container *ngIf="extraData?.has_foreign_tax_number"
                    >({{ extraData?.foreign_tax_number }})</ng-container
                  >
                </td>
              </tr>
              <tr>
                <th>{{ 'FIELD:IS_OTHER_COUNTRY_TAX_RESIDENT' | translate }}</th>
                <td>{{ (extraData?.is_other_country_tax_resident ? 'YES' : 'NO') | translate }}</td>
              </tr>
              <tr>
                <th>{{ 'FIELD:IS_USA_CITIZEN' | translate }}</th>
                <td>{{ (extraData?.is_usa_citizen ? 'YES' : 'NO') | translate }}</td>
              </tr>
              <tr>
                <th>{{ 'FIELD:IS_BIRTH_PLACE_USA_AND_NOT_CITIZEN' | translate }}</th>
                <td>
                  {{
                    extraData?.is_birth_place_usa_and_not_citizen === 'not_applicable'
                      ? ('PROFILE:IS_THE_BIRTH_PLACE_IS_USA_OPTION_1' | translate)
                      : extraData?.is_birth_place_usa_and_not_citizen === 'did_not_refuse'
                      ? ('PROFILE:IS_THE_BIRTH_PLACE_IS_USA_OPTION_2' | translate)
                      : extraData?.is_birth_place_usa_and_not_citizen === 'refused'
                      ? ('PROFILE:IS_THE_BIRTH_PLACE_IS_USA_OPTION_3' | translate)
                      : '-'
                  }}
                </td>
              </tr>
              <tr>
                <th>{{ 'FIELD:HAS_ADDRESS_IN_FOREIGN_COUNTRY' | translate }}</th>
                <td>
                  {{
                    extraData?.has_address_in_foreign_country === 'usa'
                      ? ('PROFILE:IS_RESIDENTIAL_ADDRESS_IN_THE_FOREIGN_COUNTRY_OPTION_1' | translate)
                      : extraData?.has_address_in_foreign_country === 'other_country'
                      ? ('PROFILE:IS_RESIDENTIAL_ADDRESS_IN_THE_FOREIGN_COUNTRY_OPTION_2' | translate)
                      : extraData?.has_address_in_foreign_country === 'no'
                      ? ('PROFILE:IS_RESIDENTIAL_ADDRESS_IN_THE_FOREIGN_COUNTRY_OPTION_3' | translate)
                      : '-'
                  }}
                </td>
              </tr>
              <tr>
                <th>{{ 'FIELD:IS_FOREIGN_PERSON_AUTHORIZED_TO_MANAGE_ACCOUNT' | translate }}</th>
                <td>
                  {{
                    extraData?.is_foreign_person_authorized_to_manage_account === 'usa_person'
                      ? ('PROFILE:IS_RESIDENTIAL_ADDRESS_IN_THE_FOREIGN_COUNTRY_OPTION_1' | translate)
                      : extraData?.is_foreign_person_authorized_to_manage_account === 'other_country_person'
                      ? ('PROFILE:IS_RESIDENTIAL_ADDRESS_IN_THE_FOREIGN_COUNTRY_OPTION_2' | translate)
                      : extraData?.is_foreign_person_authorized_to_manage_account === 'no'
                      ? ('PROFILE:IS_RESIDENTIAL_ADDRESS_IN_THE_FOREIGN_COUNTRY_OPTION_3' | translate)
                      : '-'
                  }}
                </td>
              </tr>
              <tr>
                <th>{{ 'FIELD:HAS_ORDER_TO_TRANSFER_FUNDS_TO_FOREIGN_COUNTRY' | translate }}</th>
                <td>
                  {{
                    extraData?.has_order_to_transfer_funds_to_foreign_country === 'usa'
                      ? ('PROFILE:IS_TRANSFER_ORDER_TO_USA_OPTION_1' | translate)
                      : extraData?.has_order_to_transfer_funds_to_foreign_country === 'other_country'
                      ? ('PROFILE:IS_TRANSFER_ORDER_TO_USA_OPTION_2' | translate)
                      : extraData?.has_order_to_transfer_funds_to_foreign_country === 'no'
                      ? ('PROFILE:IS_TRANSFER_ORDER_TO_USA_OPTION_3' | translate)
                      : '-'
                  }}
                </td>
              </tr>
              <tr>
                <th>{{ 'FIELD:HAS_GREEN_CARD' | translate }}</th>
                <td>{{ (extraData?.has_green_card ? 'YES' : 'NO') | translate }}</td>
              </tr>
              <tr>
                <th>{{ 'FIELD:HAS_USA_PHONE' | translate }}</th>
                <td>{{ (extraData?.has_usa_phone ? 'YES' : 'NO') | translate }}</td>
              </tr>
              <tr>
                <th>{{ 'FIELD:HAS_FOREIGN_PHONE' | translate }}</th>
                <td>
                  {{ (extraData?.has_foreign_phone ? 'YES' : 'NO') | translate }}
                  <ng-container *ngIf="extraData?.has_foreign_phone"
                    >({{ extraData?.foreign_phone_number }})</ng-container
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </ng-template>
  </div>
</div>
