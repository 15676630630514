<div class="container">
  <div class="title mb-4">
    <h3>{{ 'CAPTION:YOU_HAVE_EDITABLE_DATA' | translate }}</h3>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="changed-data">
        <ng-container *ngFor="let data of data.diffData">
          <div class="diff">
            <div *ngIf="data.objectName" class="diff__caption">
              <h4>{{ 'PROFILE:' + data.objectName | uppercase | translate }}</h4>
            </div>
            <div class="diff__title">
              {{ 'FIELD:' + data.fieldName | uppercase | translate }}
            </div>
            <div class="diff__content">
              {{ data.current | translate }} {{ data?.current_name_value ? '(' + data?.current_name_value + ')' : '' }}
              <mat-icon class="arrow-right">arrow_right_alt</mat-icon>
              <mat-icon class="arrow-downward">arrow_downward</mat-icon>
              {{ data.updated | translate }} {{ data?.updated_name_value ? '(' + data?.updated_name_value + ')' : '' }}
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="data?.deletedPassportScansDiffData.length > 0">
          <div class="diff">
            <div class="diff__caption">
              <h4>{{ 'PASSPORT:DELETED_SCANS' | translate }}</h4>
            </div>
            <ng-container *ngFor="let attachment of data.deletedPassportScansDiffData">
              <div class="row attachments">
                <div class="attachment col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                  <ng-container *ngIf="attachment.isImage; else document">
                    <img [src]="attachment.url" [alt]="attachment.file_name" (click)="open(attachment.url)" />
                  </ng-container>
                  <ng-template #document>
                    <div class="document">
                      <img src="./assets/images/document.svg" alt="logo" />
                      <p>{{ attachment.file_name }}</p>
                      <div class="actions">
                        <div class="open">
                          <a [href]="attachment.url" target="_blank">
                            <div>
                              <mat-icon>visibility</mat-icon>
                            </div>
                            {{ 'OPEN' | translate }}
                          </a>
                        </div>
                        <div class="download">
                          <a [href]="attachment.url" [download]="attachment.file_name" target="_blank">
                            <div>
                              <mat-icon>download</mat-icon>
                            </div>
                            {{ 'DOWNLOAD' | translate }}
                          </a>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="data?.uploadedPassportScansDiffData.length > 0">
          <div class="diff">
            <div class="diff__caption">
              <h4>{{ 'PASSPORT:UPLOADED_SCANS' | translate }}</h4>
            </div>
            <ng-container *ngFor="let attachment of data.uploadedPassportScansDiffData">
              <div class="row attachments">
                <div class="attachment col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                  <ng-container *ngIf="attachment.isImage; else document">
                    <img [src]="attachment.url" [alt]="attachment.file_name" (click)="open(attachment.url)" />
                  </ng-container>
                  <ng-template #document>
                    <div class="document">
                      <img src="./assets/images/document.svg" alt="logo" />
                      <p>{{ attachment.file_name }}</p>
                      <div class="actions">
                        <div class="open">
                          <a [href]="attachment.url" target="_blank">
                            <div>
                              <mat-icon>visibility</mat-icon>
                            </div>
                            {{ 'OPEN' | translate }}
                          </a>
                        </div>
                        <div class="download">
                          <a [href]="attachment.url" [download]="attachment.file_name" target="_blank">
                            <div>
                              <mat-icon>download</mat-icon>
                            </div>
                            {{ 'DOWNLOAD' | translate }}
                          </a>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
      <div class="d-flex justify-content-between mb-3 actions">
        <button class="btn btn-outline-dark filled" mat-button (click)="rollback()">
          {{ 'CANCEL_EDITING' | translate }}
        </button>
        <button class="btn btn-outline-dark filled" mat-button mat-dialog-close>
          {{ 'CONTINUE_EDITING' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
