<div class="container-fluid bg-white p-4"
  [@open]="'opened'">
  <h1>{{ 'CLAIM:FILLING' | translate }}</h1>
  <div class="hint">
    <p>{{ 'keyword' | translate }}</p>
  </div>
  <div class="row">
    <ui-step-view class="col-12 mt-3"
      [total]="2"
      [current]="cfService.stepKey + 1"
      [stepValues]="['CLAIM_STEP:CREATION', 'CLAIM_STEP:SIGN']">
    </ui-step-view>
    <div class="order-container">
      <div class="form-data  col-12">
        <ng-container *ngIf="cfService.stepKey===0">
          <form [formGroup]="form">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'CLAIM:CODEWORD' | translate }}</mat-label>
              <input formControlName="keyword"
                matInput>
              <mat-error *ngIf="form.get('keyword').errors?.required">
                {{'ERROR:REQUIRED' | translate }}
              </mat-error>
              <mat-error *ngIf="form.get('keyword').errors?.minlength">
                {{'ERROR:MIN_LENGTH' | translate : [6] }}
              </mat-error>
              <mat-error *ngIf="form.get('keyword').errors?.maxlength">
                {{'ERROR:MAX_LENGTH' | translate : [20] }}
              </mat-error>
            </mat-form-field>
          </form>
          <div class="actions">
            <div class="d-flex justify-content-end mb-3">
              <button [disabled]="form.invalid"
                class="filled"
                mat-flat-button
                (click)="next()">
                {{ 'NEXT' | translate }}
              </button>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="cfService.stepKey===1">
          <div class="col-12">
            <ai-ui-documents [attachments]="attachmentsToSign"
              [isCreatingOrder]="true"></ai-ui-documents>
          </div>
          <div class="col-10 confirmation">
            <h4>{{ 'CLAIM_SIGNATURE_TITLE' | translate }}</h4>
            <div class="hint">
              <p>{{ 'CLAIM_SIGNATURE_INFORMATION' | translate }}
              </p>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <button class="back"
              (click)="back()"
              mat-button
              type="button">
              <mat-icon>keyboard_arrow_left</mat-icon>
              <span>{{ 'BACK' | translate }}</span>
            </button>
            <button mat-button
              class="filled"
              type="button"
              (click)="sendToSign()">
              {{ 'SIGN' | translate }}
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>